import React from "react"

export default function TooltipIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			<defs>
				<style>
					{`.tooltip_icon_a{fill:#fff}.tooltip_icon_b{fill:none;stroke:#444;stroke-width:2px}`}
				</style>
			</defs>
			<g id="Group_16506" data-name="Group 16506" transform="translate(-865 -698)">
				<g id="Group_16503" data-name="Group 16503" transform="translate(865 698)">
					<g id="Group_15230" data-name="Group 15230">
						<circle id="Ellipse_1196" cx="18" cy="18" r="18" className="tooltip_icon_a" data-name="Ellipse 1196"/>
					</g>
				</g>
				<path id="Line_811" d="M0 0h12" className="tooltip_icon_b" data-name="Line 811"
					  transform="translate(877.5 716.5)"/>
				<path id="Line_812" d="M0 0h12" className="tooltip_icon_b" data-name="Line 812" transform="rotate(90 86.5 797)"/>
			</g>
		</svg>
	)
}