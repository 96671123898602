import React from "react";

export default function SkipIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			<defs>
				<style>
					{`.skip_icon_a{fill:#4d73ed}.skip_icon_b,.skip_icon_c,.skip_icon_e{fill:#fff}.skip_icon_d,.skip_icon_e{stroke:none}`}
				</style>
			</defs>
			<g id="Group_16543" data-name="Group 16543" transform="translate(-1035 -406)">
				<g id="Group_16540" data-name="Group 16540" transform="translate(170 -280)">
					<g id="Group_16503" data-name="Group 16503" transform="translate(865 686)">
						<g id="Group_15230" data-name="Group 15230">
							<circle id="Ellipse_1196" cx="18" cy="18" r="18" className="skip_icon_a" data-name="Ellipse 1196"
									fillOpacity="0.0"/>
						</g>
					</g>
				</g>
				<g id="Group_16537" data-name="Group 16537" transform="translate(1044.926 415.597)">
					<path id="Path_6868" d="M333.39 73.5a2.39 2.39 0 1 0 2.39 2.39 2.394 2.394 0 0 0-2.39-2.39z"
						  className="skip_icon_b" data-name="Path 6868" transform="translate(-331 -61.334)"/>
					<path id="Path_6869" d="M370.19 73.5a2.39 2.39 0 1 0 2.39 2.39 2.394 2.394 0 0 0-2.39-2.39z"
						  className="skip_icon_b" data-name="Path 6869" transform="translate(-361.645 -61.334)"/>
					<path id="Path_6870" d="M406.89 73.5a2.39 2.39 0 1 0 2.39 2.39 2.394 2.394 0 0 0-2.39-2.39z"
						  className="skip_icon_b" data-name="Path 6870" transform="translate(-392.206 -61.334)"/>
					<g id="Path_6871" className="skip_icon_c" data-name="Path 6871">
						<path
							d="M353.319 11.555l-.415-.593-2.091-2.991-.55-.787h1.367v-.072c0-2.045-1.913-3.708-4.265-3.708-2.352 0-4.266 1.663-4.266 3.708v3.692H339.7V7.112c0-3.756 3.431-6.812 7.649-6.812 4.217 0 7.648 3.056 7.648 6.812v.072h1.306l-.534.782-2.042 2.992-.408.597z"
							className="skip_icon_d" transform="translate(-338.883 -.8)"/>
						<path
							d="M353.314 10.676l2.042-2.992h-.859v-.572c0-3.478-3.21-6.312-7.148-6.312-3.94 0-7.149 2.834-7.149 6.312v3.192h2.399V7.112c0-2.319 2.14-4.208 4.766-4.208s4.765 1.89 4.765 4.208v.572h-.907l2.09 2.992m.01 1.759l-.829-1.186-2.09-2.992-1.1-1.573h1.793c-.246-1.567-1.826-2.78-3.732-2.78-2.077 0-3.766 1.439-3.766 3.208v4.192H339.2V7.112c0-4.032 3.655-7.312 8.149-7.312 4.333 0 7.887 3.05 8.134 6.884h1.767l-1.068 1.564-2.042 2.992-.817 1.195z"
							className="skip_icon_e" transform="translate(-338.883 -.8)"/>
					</g>
				</g>
			</g>
		</svg>
	)
}