import * as Types from "../../actions/v1/questions"

const initialState = {};

export default function surveyQuestions(state = initialState, action = {questions: [], question: {}}) {

	switch (action.type) {

		case Types.SET_SURVEY_QUESTIONS: {
			const surveyQuestions = {...state};
			const currentSurveyQuestions = {};
			const currentSurveyId = ((action.questions || [])[0] || {}).survey;

			(action.questions || []).forEach((question) => {
				currentSurveyQuestions[question.id] = question;
			});
			surveyQuestions[currentSurveyId] = currentSurveyQuestions;
			
			return surveyQuestions;
		}


		case Types.SET_SURVEY_QUESTION: {

			const surveyQuestions = {...state};
			const question = action.question;
			surveyQuestions[question.survey] = {
				...(surveyQuestions[question.survey] || {}),
				[question.id]: question
			};

			return surveyQuestions;
		}

		case Types.REMOVE_SURVEY_QUESTION: {

			const surveyQuestions = {...state};
			const question = action.question;
			delete (surveyQuestions[question.survey] || {})[question.id];

			return surveyQuestions;
		}

		case Types.REMOVE_SURVEY_QUESTIONS: {
			return {
				...state,
				[action.surveyId]: {},
			};
		}



		default: {
			return state
		}
	}
}