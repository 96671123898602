import React, {useEffect, useState} from "react"
import {Route, Switch} from "react-router-dom";
import SurveyEnterpriseDashboard from "./routes/surveyDashboard/SurveyEnterpriseDashboard";
import SurveyDashboard from "./routes/surveyDashboard/SurveyDashboard";
import CompletedSurveys from "./routes/completedSurveys/CompletedSurveys";
import Routes from "../../../constants/routes";
import CreateSurvey from "./routes/createSurvey/CreateSurvey";
import Survey from "./routes/survey/Survey";
import connect from "react-redux/es/connect/connect";
import {AccountType} from "../../../types/model/StickybeakAccount";
import Accounts from "../accounts/Accounts";


function Surveys(props) {
    const isEnterprise = (props.selectedAccount.accountType || {}) === AccountType.ENTERPRISE;

    return (
        <Switch location={props.location}>
            <Route path={Routes.Accounts.Completed} component={Accounts}/>
            <Route path={Routes.Surveys.Completed} component={CompletedSurveys}/>
            <Route path={Routes.Surveys.Tutorial}
                   component={isEnterprise ? SurveyEnterpriseDashboard : SurveyDashboard}/>
            <Route path={Routes.Surveys.Create.root} component={CreateSurvey}/>
            <Route path={Routes.Surveys.Survey.root} component={Survey}/>
            <Route path={Routes.Accounts.root}
                   component={Accounts}/>
            <Route path={Routes.Surveys.root}
                   component={isEnterprise ? SurveyEnterpriseDashboard : SurveyDashboard}/>
        </Switch>

    )
}

export default connect((state, props) => {
    const currentOrg = state.settings.organisation;
    const selectedAccount = (state.organisations.accounts || {})[currentOrg];
    const accountId = props.match.params.accountId || selectedAccount.id;

    return {
        selectedAccount,
        accountId,
        currentOrg
    }
})(Surveys);
