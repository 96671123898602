/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CreditCardForm.module.scss";
import classnames from "classnames";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement
} from "@stripe/react-stripe-js";
import PoweredByStripe from "../../../resources/js/PoweredByStripe";
import TextInput from "../textInput/TextInput";

export default class CreditCardForm extends Component {

    static propTypes = {
        className: PropTypes.string,
        showPoweredBy: PropTypes.bool,
        isUpdating: PropTypes.bool,
        ccRef: PropTypes.func,
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        showPoweredBy: true,
        isUpdating: false,
        ccRef: () => null,
        onSuccess: () => null,
        onError: () => null,
    };

    state = {
        loading: false,

        incomplete_number: null,
        incomplete_expiry: null,
        incomplete_cvc: null,
    };

    constructor (props) {
        super(props);

        if ( !!props.ccRef ) {
            props.ccRef(this)
        }
    }

    onChange = (key, override) => {
        return (value) => {
            this.setState({
                [key]: !!override ? override : value,
            });
        }
    };

    getCreditCardToken = () => {

        const cardNumberElement = this.props.elements.getElement(CardNumberElement)


        this.props.stripe.createToken(cardNumberElement).then((r) => {
            if ( !!r.error ) {
                this.onChange("loading", false)();
                this.onError(r.error.message);
                this.onChange(r.error.code, " ")();
            } else {
                const token = r.token.id;
                this.onSuccess(token);
            }
        }).catch((err) => {
            this.onError(err.message);
        });
    };

    onSuccess = (token, name) => {
        this.props.onSuccess(token, name);
    };

    onError = (message) => {
        this.props.onError(message);
    };

    render () {
        const className = classnames(styles.credit_card_form, this.props.className);

        return (
            <div className={className}>

                <TextInput className={styles.cc_number}
                           classNameWrapper={styles.stripe_text_input_wrapper}
                           onTextChange={this.onChange("incomplete_number")}
                           error={this.state.incomplete_number}
                           inputComponent={CardNumberElement}
                           label={"Card number"}/>

                <div className={styles.expiry_cvv_wrapper}>
                    <TextInput className={styles.expiry}
                               classNameWrapper={styles.stripe_text_input_wrapper}
                               onTextChange={this.onChange("incomplete_expiry")}
                               error={this.state.incomplete_expiry}
                               inputComponent={CardExpiryElement}
                               label={"Expiry Date"}/>


                    <TextInput className={styles.cvv}
                               classNameWrapper={styles.stripe_text_input_wrapper}
                               onTextChange={this.onChange("incomplete_cvc")}
                               error={this.state.incomplete_cvc}
                               inputComponent={CardCvcElement}
                               label={"CVV"}/>
                </div>
                {this.props.showPoweredBy &&
                <div className={styles.power_by_stripe_wrapper}>
                    <a href={"http://stripe.com"} target={"_blank"} rel={"noopener noreferrer"}>
                        <PoweredByStripe/>
                    </a>
                </div>}
            </div>
        );
    }
}
