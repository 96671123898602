import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RenameSurveyDialog from "../../../../../../widgets/dialogs/renameSurveyDialog/RenameSurveyDialog";
import DuplicateSurveyDialog from "../../../../../../widgets/dialogs/duplicateSurveyDialog/DuplicateSurveyDialog";
import { completeSurvey } from "../../../../../../../redux/actions/v1/surveys";
import { showError, showSuccess } from "../../../../../../../redux/actions/v1/snackbars";
import styles from "./ActiveSurveyCard.module.scss";
import Dialog from "../../../../../../widgets/dialog/Dialog";
import { withRouter } from "react-router-dom";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

class ActiveSurveysCard extends Component {

    static propTypes = {
        onCardPress: PropTypes.func,
        index: PropTypes.number,
        survey: PropTypes.object,
        org: PropTypes.object,
    };

    static defaultProps = {
        onCardPress: () => null,
        index: null,
        survey: {},
        org: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            submitting: false,
        };
    }


    handleOptionsPress = (e) => {
        this.setState({ anchorEl: e.target });
    };

    handleOptionsClose = () => {
        this.setState({ anchorEl: null });
    };

    onCardPress = () => {
        this.props.onCardPress(this.props.survey);
    };

    onCompletePress = () => {
        this.handleOptionsClose();
        this.completeSurveyDialog.show();
    };

    handleCompleteSurvey = () => {
        this.setState({ submitting: true });

        this.props.dispatch(completeSurvey({
            orgId: this.props.survey.account,
            surveyId: this.props.survey.id,
            onSuccess: () => {
                this.setState({ submitting: false });
                this.completeSurveyDialog && this.completeSurveyDialog.hide();
                this.props.dispatch(showSuccess("Survey marked as completed."))
            },
            onError: (err) => {
                this.setState({ submitting: false });
                this.props.dispatch(showError(err.message));
            }
        }));
    };

    onRenamePress = () => {
        this.handleOptionsClose();
        this.renameSurveyDialog.show();
    };

    onDuplicatePress = () => {
        this.handleOptionsClose();
        this.duplicateSurveyDialog.show();
    };

    renderRespondentCount = () => {
        const respondentCount = (this.props.survey.insights || {}).respondentsCompleted || 0;

        return `${respondentCount === 0 ? "No" : respondentCount} respondent${respondentCount === 1 ? "" : "s"} completed`;
    };

    render() {
        const { submitting, anchorEl } = this.state;
        const popoverOpen = Boolean(this.state.anchorEl);
        const survey = this.props.survey || {};

        return (
            <div className={styles.active_survey_card}>
                <div className={styles.active_survey_card_header}>
                    <div className={styles.status_chip}>Live</div>
                    <IconButton className={styles.options_button}
                                onClick={this.handleOptionsPress}>
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <div className={styles.active_survey_card_body} onClick={this.onCardPress}>
                    <img src={this.props.previewImage} alt={""} />

                    <div className={styles.active_surveys_textarea}>
                        <div
                            className={styles.date_started}>{this.props.survey.startedAt && moment(this.props.survey.startedAt).format("DD/MM/YYYY")}</div>
                        <div className={styles.title}>{survey.name}</div>
                        {/*<div className={styles.organisation_name}>{this.props.org.name}</div>*/}
                        <div className={styles.respondents_completed}>{this.renderRespondentCount()}</div>
                    </div>
                </div>
                <Popover open={popoverOpen}
                         onClose={this.handleOptionsClose}
                         anchorEl={anchorEl}
                         elevation={1}>
                    <MenuItem onClick={this.onCompletePress}>Move to Completed</MenuItem>
                    <MenuItem onClick={this.onRenamePress}>Rename</MenuItem>
                    <MenuItem onClick={this.onDuplicatePress}>Duplicate</MenuItem>
                </Popover>

                <Dialog ref={ref => this.completeSurveyDialog = ref}
                        title={"Move to Completed"}
                        text={"You're about to complete this survey. You will stop collecting responses and the results will be finalised, are you sure you want to proceed?"}
                        disableDismiss={submitting}
                        actions={[
                            {
                                text: "Cancel",
                                props: {
                                    plain: true,
                                    disabled: submitting
                                },
                                onPress: () => this.completeSurveyDialog.hide()
                            },
                            {
                                text: "Move",
                                props: {
                                    blue: true,
                                    loading: submitting
                                },
                                onPress: this.handleCompleteSurvey
                            }
                        ]} />

                <RenameSurveyDialog survey={this.props.survey}
                                    dialogRef={ref => this.renameSurveyDialog = ref} />

                <DuplicateSurveyDialog survey={this.props.survey}
                                       dialogRef={ref => this.duplicateSurveyDialog = ref} />

            </div>
        );
    }
}

export default connect()(withRouter(ActiveSurveysCard));

