import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import fromCents from "../../../../../../../../../../constants/localization/currencies/fromCents";
import connect from "react-redux/es/connect/connect";
import Card from "../../../../../../../../../widgets/card/Card";
import styles from "../SummaryCard.module.scss";
import CreditCardSelectDialog
    from "../../../../../../../../../widgets/dialogs/creditCardSelectDialog/CreditCardSelectDialog";
import { AccountType } from "../../../../../../../../../../types/model/StickybeakAccount";
import { createOrUpdateEstimate, updateSurveyCost } from "../../../../../../../../../../redux/actions/estimate";
import { Form, Formik } from "formik";
import FormikInput from "../../../../../../../../../widgets/input/FormikInput";
import Button from "../../../../../../../../../widgets/button/Button";

class NicheSummary extends Component {

    static propTypes = {
        survey: PropTypes.object,
        questions: PropTypes.number,
        skipLogicQuestions: PropTypes.number,
        validatingQuestionCount: PropTypes.number,
        audienceType: PropTypes.string,
        selectedCard: PropTypes.string,
        onCardSelect: PropTypes.func,
        onAddCard: PropTypes.func,
    };

    static defaultProps = {
        survey: {},
        skipLogicQuestions: null,
        validatingQuestionCount: null,
        audienceType: null,
        audienceMembers: [],
        selectedCard: ""
    };
    state = {
        editing: false,
        initialValues: {}
    };

    componentDidMount () {
        this.props.dispatch(createOrUpdateEstimate(this.props.survey.id, this.props.survey.targeting));
        this.setState({
            initialValues: {
                total: "",
                costPerRespondent: ""
            }
        })
    }

    onOpenDialog = () => {
        this.cardSelectDialog.show();
    };

    onCloseDialog = () => {
        this.cardSelectDialog.hide();
    };

    onValueClick = () => {
        if(this.props.survey.targeting.maxSpend===null){
            this.setState({ editing: true })
        }
    }

    renderRow = (label, value, name, setFieldValue) => {
        if ( this.state.editing && !!name ) {
            return this.renderInputField(label, value, name, setFieldValue)
        }
        return (
            <div className={styles.card_row}>
                <div className={styles.card_row_main}
                     onClick={(!!name && this.props.isAdmin) ? this.onValueClick : null}>
                    <h4>{label}</h4>
                    <div style={{ textDecoration: label === "Previous cost per respondent" ? "line-through" : "none" }}>
                        {
                            Array.isArray(value)
                                ? value.map((v) => <p key={uuid()}>{v}</p>)
                                : value
                        }
                    </div>
                </div>
            </div>
        );
    };

    onInputChange = (e, name, setFieldValue) => {
        const value = e.target.value;
        if ( name === "total" ) {
            setFieldValue("costPerRespondent", (value / this.props.priceEstimate.estNumberRespondents).toFixed(2))
        } else {
            setFieldValue("total", (value * this.props.priceEstimate.estNumberRespondents).toFixed(2))
        }
    }

    renderInputField = (label, value, name, setFieldValue) => {
        return (
            <div className={styles.card_row}>
                <div className={styles.card_row_main}>
                    <h4>{label}</h4>
                    <div>
                        <FormikInput className={styles.row_input} name={name}
                                     onChange={(e) => this.onInputChange(e, name, setFieldValue)} placeholder={"$0.00"}/>
                    </div>
                </div>
            </div>
        )
    }

    onNewBudgetSubmit = async (values) => {
        await this.props.dispatch(updateSurveyCost(this.props.orgId, this.props.surveyId, {budget: values.total*100, costPerRespondent: values.costPerRespondent*100}));
        this.setState({editing: false})
    }

    render () {

        const { ageGroups, detailed, genders, geoLocations } = this.props.survey.targeting;
        const locationCount = geoLocations.length;
        const ageGroupsArray = ageGroups.map((ageGroup) => `${ageGroup.minAge} - ${ageGroup.maxAge >= 65 ? "65+" : ageGroup.maxAge}`);
        const genderText = genders.length === 2 ? "Male 50%/Female 50%" : genders[0];
        const interestCount = detailed.length;
        const priceEstimate = this.props.priceEstimate || {
            estBudget: 0,
            estNumberRespondents: 0
        };
        let budget = priceEstimate.estBudget;
        if ( priceEstimate.estNumberRespondents > 0 && budget < 20000 ) {
            budget = 20000;
        }

        const { desiredSampleSize, maxSpend } = this.props.survey;
        const selectedCard = this.props.cards ? this.props.cards[this.props.selectedCard] : null;
        return (
            <>
                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Survey details</h3>
                    {this.renderRow("Name", this.props.survey.name)}
                    {this.renderRow("Questions", this.props.questions)}
                    {this.renderRow("Skip logic", this.props.skipLogicQuestions)}
                    {this.renderRow("Validating questions", this.props.validatingQuestionCount)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience Details</h3>
                    {this.renderRow("Audience type", this.props.audienceType)}
                    {this.renderRow("Locations", locationCount)}
                    {this.renderRow("Age range", ageGroupsArray)}
                    {this.renderRow("Gender", genderText)}
                    {this.renderRow("Interests", interestCount)}
                    {priceEstimate && priceEstimate.estNumberRespondents === 0 && (
                        <div className={styles.quota_broaden_audience}>
                            <h3>⚠️ Your audience is too narrow.</h3>
                            <p>Please head back to step 1 and broadening your audience.</p>
                        </div>
                    )}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience size and budget</h3>
                    {maxSpend && this.renderRow("My budget", fromCents(budget, priceEstimate.currency, true))}
                    {desiredSampleSize && this.renderRow("I'd like to hear from", `${priceEstimate.estNumberRespondents} people`)}
                    {this.renderRow("Max. people we can find you", priceEstimate.estNumberRespondents)}
                    <Formik initialValues={this.state.initialValues} enableReinitialize
                            onSubmit={this.onNewBudgetSubmit}>
                        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, dirty }) => (
                            <Form>
                                {!priceEstimate.prevEstCostPerRespondent ? this.renderRow("Cost per respondent", fromCents(priceEstimate.estCostPerRespondent, priceEstimate.currency, true), "costPerRespondent", setFieldValue) : null}
                                {priceEstimate.prevEstCostPerRespondent ? this.renderRow("Previous cost per respondent", fromCents(priceEstimate.prevEstCostPerRespondent, priceEstimate.currency, true)) : null}
                                {priceEstimate.prevEstCostPerRespondent ? this.renderRow("Updated cost per respondent", fromCents(priceEstimate.estCostPerRespondent, priceEstimate.currency, true), "costPerRespondent", setFieldValue) : null}
                                {this.renderRow("Total", fromCents(budget, priceEstimate.currency, true), "total", setFieldValue)}
                                {
                                    this.state.editing ?
                                        <div className={styles.card_row}>
                                            <div className={styles.save_button}>
                                                <Button type={"submit"} disabled={isSubmitting || (values.total<=0 && values.costPerRespondent<= 0) || !dirty}
                                                        onPress={handleSubmit}>
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                        : null
                                }
                            </Form>
                        )}
                    </Formik>
                    {priceEstimate.estNumberRespondents > 0 && priceEstimate.estBudget < 20000 && this.renderRow("Please note that there is a minimum spend of $200")}
                    {this.props.isEnterprise && (
                        <div className={styles.card_row}>
                            <div className={styles.card_row_main}>
                                <div>
                                    <h4>Payment method</h4>
                                    <span className={styles.change_payment_text}
                                          onClick={this.onOpenDialog}>Change</span>
                                </div>
                                <div>
                                    {
                                        selectedCard
                                            ? (
                                                <>
                                                    <h4>{selectedCard.name}</h4>
                                                    <h4>●●●● ●●●● ●●●● {`${selectedCard.last4}`}</h4>
                                                </>
                                            )
                                            : <h4>No card selected</h4>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </Card>

                <CreditCardSelectDialog dialogRef={(ref) => this.cardSelectDialog = ref}
                                        submitCard={(id) => this.props.onCardSelect(id)}
                                        onAddCard={this.props.onAddCard}
                                        initialValue={this.props.selectedCard}
                                        cards={this.props.cards}/>
            </>
        );
    }
}

export default connect((state) => {
    const orgId = state.settings.organisation;
    const selectedAccount = state.organisations.accounts[orgId];

    return {
        orgId,
        priceEstimate: state.estimate,
        cards: state.organisations.cards[orgId],
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
        isAdmin: state.user.role === "SUPER_ADMIN"
    };
})(NicheSummary);
