import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./CreateUpdateAudienceColumnDialog.module.scss"
import {reduxForm, reset} from "redux-form";
import connect from "react-redux/es/connect/connect";
import TextInputField from "../../textInput/TextInputField";
import SelectField from "../../select/SelectField";
import {createAudienceColumn} from "../../../../redux/actions/v1/audiences";

class CreateUpdateAudienceColumnDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        orgId: PropTypes.number,
        audienceId: PropTypes.number,
        audienceColumnId: PropTypes.number,
    };

    static defaultProps = {
        dialogRef: () => null,
        orgId: null,
        audienceId: null,
        audienceColumnId: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
        };

        this.selectOptions = [
            {label: "Text", value: "STRING"},
            {label: "Number", value: "NUMBER"},
            {label: "Date", value: "DATE"},
            {label: "Date and Time", value: "DATE_TIME"},
            {label: "Yes/No", value: "BOOLEAN"},
        ];
    }

    onCancelPress = () => {
        this.hide()
    };

    onSubmit = (values) => {

        const payload = {
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            data: values,
            onSuccess: this.onSuccess,
            onError: this.onError,
        };

        this.setState({
            loading: true,
        });

        if (this.props.isUpdating) {




        } else {
            this.props.dispatch(createAudienceColumn(payload));
        }
    };

    onSuccess = () => {
        this.hide();
        this.setState({loading: false});
        this.props.dispatch(reset(this.props.form));
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        });
    };

    hide = () => {
        this.setState({
            loading: false,
            error: null,
        });

        this.dialog.hide();
    };

    render() {
        const actions = [
            {
                text: "Cancel",
                onPress: this.onCancelPress,
                props: {
                    plain: true,
                }
            },
            {
                text: "Done",
                props: {
                    type: "submit",
                    blue: true,
                    disabled: this.props.pristine || !this.props.valid,
                    loading: this.state.loading
                }
            }
        ];

        return (
            <Dialog title={this.props.isUpdating ? "Update Column" : "Create Column"}
                    actions={actions}
                    error={this.state.error}
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>

                <TextInputField label={"Column name"}
                                name={"name"}/>

                <SelectField label={"Data type"}
                             name={"type"}
                             className={styles.data_selector}
                             labelClassName={styles.data_selector_label}
                             options={this.selectOptions}/>

            </Dialog>
        )
    }
}

CreateUpdateAudienceColumnDialog = reduxForm({
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (!values.type) {
            errors.type = "Required";
        }

        return errors;
    }
})(CreateUpdateAudienceColumnDialog);

export default connect((state, props) => {

    let audienceColumnId = props.audienceColumnId;

    const isUpdating = !!audienceColumnId;

    return {
        form: `createUpdateAudienceAttribute${audienceColumnId}`,
        isUpdating: isUpdating,

    };
})(CreateUpdateAudienceColumnDialog);