import React, {PureComponent} from "react"
import PropTypes from "prop-types"
import Dialog from "../../dialog/Dialog"

class DeleteAudienceFormRowDialog extends PureComponent {

    static propTypes = {
        onSubmit: PropTypes.func,
        dialogRef: PropTypes.func,
        index: PropTypes.number,
    };

    static defaultProps = {
        onSubmit: () => null,
        dialogRef: () => null,
        index: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            disableDismiss: false,
            questionType: props.questionType,
        };

        this.actions = [
            {text: "Cancel", onPress: this.hide, props: {plain: true}},
            {text: "Yes", onPress: this.onSubmit, props: {blue: true}}
        ];

    }

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.props.onSubmit(this.props.index);
    };

    render() {
        return (
            <Dialog
                title={"Delete Row"}
                text={"Are you sure you would like to delete this row?"}
                actions={this.actions}
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref)
                }}/>
        )
    }
}

export default DeleteAudienceFormRowDialog