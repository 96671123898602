import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import TextInput from "../../textInput/TextInput";
import { duplicateSurvey } from "../../../../redux/actions/v1/surveys";
import { showError } from "../../../../redux/actions/v1/snackbars";
import { withRouter } from "react-router-dom";

class DuplicateSurveyDialog extends PureComponent {

    static propTypes = {
        survey: PropTypes.object,
        dialogRef: PropTypes.func
    };

    static defaultProps = {
        survey: {},
        dialogRef: () => null
    };

    state = {
        surveyName: "",
        submitting: false
    };

    hide = () => {
        this.setState({ surveyName: "" });
        this.dialog.hide();
    };

    onSubmit = () => {
        this.setState({ submitting: true });

        this.props.dispatch(duplicateSurvey({
            orgId: this.props.survey.account,
            surveyId: this.props.survey.id,
            survey: {
                name: this.state.surveyName
            },
            onSuccess: () => {
                this.setState({ submitting: false });
                this.dialog.hide();
            },
            onError: (err) => {
                this.setState({ submitting: false });
                this.props.dispatch(showError(err.message))
            }
        }))
    };

    handleTextChange = (text) => {
        this.setState({ surveyName: text })
    };

    render() {

        const actions = [
            {
                text: "Cancel",
                onPress: this.hide,
                props: {
                    plain: true,
                    disabled: this.state.submitting
                }
            },
            {
                text: "Yes",
                onPress: this.onSubmit,
                props: {
                    blue: true,
                    loading: this.state.submitting,
                    disabled: !this.state.surveyName
                }
            }
        ];

        return (
            <Dialog title={"Duplicate Survey"}
                    actions={actions}
                    disableDismiss={this.state.submitting}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                <p>Give your new survey a title</p>
                <TextInput placeholder={"Enter a name..."}
                           text={this.state.surveyName}
                           onTextChange={this.handleTextChange}/>
            </Dialog>
        )
    }
}

export default connect()(withRouter(DuplicateSurveyDialog))