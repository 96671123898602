/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import get from "./util/get";


function getAdSetsForSurvey(surveyId: number) {
    return get(`/v1/surveys/${surveyId}/ad-sets`);
}

const AdSetsAPI = {
    getAdSetsForSurvey
};

export default AdSetsAPI;