/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, {useState} from "react";
import useDispatch from "../../../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../../../hooks/useSelector";
import {getSurveyReport} from "../../../../../../../../../redux/actions/reports";
import {SurveyStatus} from "../../../../../../../../../types/model/Survey";
import Filter from "./components/filter/Filter";
import styles from "./ReportSideBar.module.scss";
import getSurveyCardDetails from "../../../../../../../../../util/getSurveyCardDetails";
import classNames from "classnames";
import useAdminState from "../../../../../../../../../hooks/useAdminState";
import PerformanceTab from "./components/performanceTab/PerformanceTab";
import FilterTab from "./components/filterTab/FilterTab";

export default function ReportSideBar(props) {
    const {
        surveyId,
        respondents
    } = props;

    const survey = useSelector((state => state.surveys[surveyId]));
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("Filter");
    const [, isAdmin] = useAdminState();
    const report = useSelector((state => state.reports.surveys[surveyId]));

    useMountEffect(async () => {
        await dispatch(getSurveyReport(surveyId));
    });
    const tickStyle = {
        opacity: 0
    };
    if (survey.status === SurveyStatus.COMPLETED) {
        tickStyle.opacity = 1;
    }

    const onTabClick = (tab) => {
        setSelectedTab(tab)
    }
    if (!report) {
        return null;
    }


    const cardDetails = getSurveyCardDetails(survey, respondents);


    let interests = "";
    let locations = "";
    let ageGroups = "";
    if (survey.type === "FACEBOOK") {

        cardDetails.tags.interests.forEach((interest, index) => {
            if (index + 1 < cardDetails.tags.interests.length) {
                interests = interests + interest + ", ";
            } else {
                interests = interests + interest;
            }
        });
        cardDetails.tags.locations.forEach((location, index) => {
            if (index + 1 < cardDetails.tags.locations.length) {
                locations = locations + location + " ";
            } else {
                locations = locations + location;
            }
        });
        cardDetails.tags.age.forEach((ageGroup, index) => {
            if (index + 1 < cardDetails.tags.age.length) {
                ageGroups = ageGroups + ageGroup + ", ";
            } else {
                ageGroups = ageGroups + ageGroup;
            }
        });
    }

    return (
        <div className={styles.side_bar}>
            <div className={styles.tab_header}>
                <div className={classNames(styles.tab, selectedTab === "Filter" ? styles.selected : "")}
                     onClick={() => onTabClick("Filter")}>
                    <span>
                        Filter
                    </span>
                </div>
                {survey.type === "FACEBOOK" && isAdmin &&
                <div className={classNames(styles.tab, selectedTab === "Performance" ? styles.selected : "")}
                     onClick={() => onTabClick("Performance")}>
                    <span>
                        Performance
                    </span>
                </div>
                }
            </div>
            {selectedTab === "Filter" && (props.enterprise || isAdmin) && (
                <FilterTab {...props} cardDetails={cardDetails}/>
            )}
            {selectedTab === "Performance" &&  isAdmin && (
                <PerformanceTab surveyId={surveyId} respondents={respondents} cardDetails={cardDetails}/>
            )}
            {(!props.enterprise && !isAdmin) && (
                <FilterTab {...props} cardDetails={cardDetails}/>
            )}
        </div>
    );
}
