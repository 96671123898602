
export function createAudienceAPI(token, orgId, data) {
    return window.post(`/v1/accounts/${orgId}/audiences`, token, data);
}

export function updateAudienceAPI(token, orgId, audienceId, data) {
    return window.put(`/v1/accounts/${orgId}/audiences/${audienceId}`, token, data);
}

export function deleteAudienceAPI(token, orgId, audienceId) {
    return window.del(`/v1/accounts/${orgId}/audiences/${audienceId}`, token)
}

export function getAudiencesForOrganisationAPI(token, orgId) {
    return window.get(`/v1/accounts/${orgId}/audiences`, token);
}

export function createAudienceColumnAPI(token, orgId, audienceId, data) {
    return window.post(`/v1/accounts/${orgId}/audiences/${audienceId}/columns`, token, data);
}

export function deleteAudienceColumnAPI(token, orgId, audienceId, attributeId) {
    return window.del(`/v1/accounts/${orgId}/audiences/${audienceId}/columns/${attributeId}`, token);
}