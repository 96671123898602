import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Loader from "../../../../../../../../../widgets/loader/Loader";
import Button from "../../../../../../../../../widgets/button/Button";
import {withRouter} from "react-router";
import styles from "../SummaryCard.module.scss";
import {v4 as uuid} from "uuid";
import Card from "../../../../../../../../../widgets/card/Card";
import {AccountType} from "../../../../../../../../../../types/model/StickybeakAccount";

class ListSummary extends Component {

    static propTypes = {
        organisation: PropTypes.object,
        survey: PropTypes.object,
        questions: PropTypes.number,
        skipLogicQuestions: PropTypes.number,
        validatingQuestionCount: PropTypes.number,
        audienceType: PropTypes.string,
        audience: PropTypes.object,
        audienceMembers: PropTypes.array,
        subscription: PropTypes.object,
    };

    static defaultProps = {
        organisation: {},
        survey: {},
        skipLogicQuestions: null,
        validatingQuestionCount: null,
        audienceType: null,
        audience: {},
        audienceMembers: [],
        subscription: {},
    };

    renderRow = (label, value) => {
        return (
            <div className={styles.card_row}>
                <div className={styles.card_row_main}>
                    <h4>{label}</h4>
                    <div>
                        {
                            Array.isArray(value)
                                ? value.map((v) => <p key={uuid()}>{v}</p>)
                                : value
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderValidAudience = () => {
        const emptyAudience = !this.props.loadingAudienceSize && this.props.audienceSize === 0
        return (
            <>
                {this.renderRow("Audience type", this.props.audienceType)}
                {this.renderRow("Audience", this.props.audience.name)}
                {this.renderRow("Group size", this.props.loadingAudienceSize ? <Loader/> : this.props.audienceSize)}
                {emptyAudience && this.renderRow("Your audience has no members.")}
            </>
        )
    }

    renderSaasDetails = () => {
        if (this.props.subscription && this.props.subscription.tier && !this.props.isEnterprise) {
            return (
                <>
                    {this.renderRow("Subscription", this.props.subscription.tier.name)}
                    {this.renderRow("Response limit", this.props.subscription.tier.maxRespondentsPerSurvey)}
                </>
            )
        }
    }

    render() {
        return (
            <>
                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Survey details</h3>
                    {this.renderRow("Name", this.props.survey.name)}
                    {this.renderRow("Questions", this.props.questions)}
                    {this.renderRow("Skip logic", this.props.skipLogicQuestions)}
                    {this.renderRow("Validating questions", this.props.validatingQuestionCount)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience details</h3>
                    {this.props.validAudience
                        ? this.renderValidAudience()
                        : this.renderRow("You haven't selected an audience yet. Select one below before starting your survey.")}
                    {!this.props.validAudience && (
                        <div className={styles.button_wrapper}>
                            <Button green
                                    onPress={() => this.props.history.push(`/surveys/${this.props.survey.id}/audience`)}>Select
                                audience</Button>
                        </div>
                    )}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Account details</h3>
                    {this.renderRow("Account name", this.props.organisation.name)}
                    {this.renderSaasDetails()}
                </Card>
            </>
        )
    }
}

export default withRouter(connect((state) => {
    const currentOrg = state.settings.organisation;
    const selectedAccount = (state.organisations.accounts[currentOrg] || {})

    return {
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
    }
})(ListSummary));
