/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

const Onboarding = {
    root: "*",
    Login: "/login",
    Verification: "*",
    SignUp: {
        root: "/signup",
        Invite: "/signup/invite",
        Payment: "/signup/payment",
        Success: "/signup/success",
    },
    ForgotPassword: "/forgot"
};


const Surveys = {
    root: "/surveys",
    Tutorial: "/surveys/tutorial",
    Completed: "/surveys/completed",
    Create: {
        root: "/surveys/create",
        SelectAgent: "/surveys/create/agent",
        SelectTemplate: "/surveys/create/template",
    },
    Survey: {
        root: "/surveys/:surveyId",
        Report: "/surveys/:surveyId/report",
        ReportPdf: "/surveys/:surveyId/report/pdf",
        Ads: "/surveys/:surveyId/ads",
        Audience: "/surveys/:surveyId/audience",
        Score: "/surveys/:surveyId/score",
        Settings: "/surveys/:surveyId/settings",
        Summary: "/surveys/:surveyId/summary",

    },
};

const Accounts = {
    root: "/accounts/:accountId/surveys",
    Completed: "/accounts/:accountId/surveys/completed",
    Create: {
        root: "/accounts/:accountId/surveys/create",
        SelectAgent: "/accounts/:accountId/surveys/create/agent",
        SelectTemplate: "/accounts/:accountId/surveys/create/template",
    },
    Survey: {
        root: "/accounts/:accountId/surveys/:surveyId",
        Report: "/accounts/:accountId/surveys/:surveyId/report",
        ReportPdf: "/accounts/:accountId/surveys/:surveyId/report/pdf",
        Ads: "/accounts/:accountId/surveys/:surveyId/ads",
        Audience: "/accounts/:accountId/surveys/:surveyId/audience",
        Score: "/accounts/:accountId/surveys/:surveyId/score",
        Settings: "/accounts/:accountId/surveys/:surveyId/settings",
        Summary: "/accounts/:accountId/surveys/:surveyId/summary",

    },
};

const Settings = {
    root: "/settings",
};

const Profile = {
    root: "/profile",
};

const Receipt = {
    root: "/receipt",
    SubscriptionReceipt: "/receipt/subscription",
    SurveyReceipt: "/receipt/survey"
}

const Audiences = {
    root: "/audiences",
    Update: "/audiences/update/:audienceId",
    AddManually: "/audiences/:audienceId/add/manually",
    Lists: "/audiences/:audienceId",
    Pricing: "/audiences/pricing",
};

const Help = {
    root: "/help"
};



const Routes = {
    Loading: "*",
    Onboarding: Onboarding,
    Surveys: Surveys,
    Audiences: Audiences,
    Settings: Settings,
    Profile: Profile,
    Receipt: Receipt,
    Help: Help,
    Accounts: Accounts,
};

export default Routes;
