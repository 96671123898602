import React, {Component} from "react"
import PropTypes from "prop-types"
import styles from "./AddQuestion.module.scss";
import MessageItem from "../../../../../../../../../widgets/messageItem/MessageItem";

export default class AddQuestion extends Component {

    static propTypes = {
        onPress: PropTypes.func,
        question: PropTypes.object,
    };

    static defaultProps = {
        onPress: () => null,
        question: {},
    };

    render() {
        return (
            <div className={styles.add_question_container}>
                <div className={styles.content}>
                    <div className={styles.left}/>
                    <div className={styles.add_question_wrapper}
                         onClick={this.props.onPress}>
                        <div className={styles.agent_left}>
                            <div className={styles.agent_icon}/>
                            <MessageItem className={styles.message}
                                         classNameText={styles.message_text}
                                         message={"..."}/>
                        </div>
                        <h3 className={styles.question_text}>Add question</h3>
                        {/*<Button*/}
                        {/*    plain*/}
                        {/*    onPress={this.props.onPress}*/}
                        {/*    className={styles.button}>Add question</Button>*/}
                    </div>
                </div>
            </div>
        )
    }
}
