/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import styles from "./Loading.module.scss";
import loadingAnimation from "../../../resources/gif/rueben/loading.gif";

export default class Loading extends Component {
    render() {
        return (
            <div className={styles.loading}>
                <div className={styles.loading_animation}>
                    <img src={loadingAnimation} alt="loading"/>
                </div>
                <h4>Loading</h4>
            </div>
        );
    }
}

