/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-08.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

const roles = [
    {label: "User", value: "USER"},
    {label: "Super Admin", value: "SUPER_ADMIN"},
];

export default roles
