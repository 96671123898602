import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "../cancelServiceDialog/CancelServiceDialog.module.scss";
import {connect} from "react-redux";
import Dialog from "../../dialog/Dialog";

class ConfirmDeleteAudienceMemberDialog extends Component {
    static propTypes = {
        dialogRef: PropTypes.func,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        dialogRef: () => null,
        onSubmit: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
        };

        this.actions = [
            {
                text: "Cancel",
                onPress: this.hide,
                props: {
                    plain: true
                }
            },
            {
                text: "Delete respondents",
                onPress: this.onSubmit,
                props: {
                    blue: true,
                    loading: this.state.loading,
                }
            }
        ];
    }

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.setState({loading: true});
        this.props.onSubmit();
    };

    render() {
        return (
            <Dialog title={"Delete respondents"}
                    className={styles.cancel_service}
                    actions={this.actions}
                    disableDismiss={this.state.loading}
                    loading={this.state.loading}
                    error={this.state.error}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                <p>Are you sure you would like to delete these respondents?</p>
                <p>This action cannot be undone.</p>
            </Dialog>
        )
    }
}

export default ConfirmDeleteAudienceMemberDialog = connect()(ConfirmDeleteAudienceMemberDialog);