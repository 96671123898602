import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import values from "lodash/values";
import styles from "./CompletedSurveys.module.scss";
import { getOrganisationCompletedSurveys } from "../../../../../redux/actions/v1/surveys";
import CompletedSurveyRow from "./completedSurveyRow/CompletedSurveyRow";
import Content from "../../../../widgets/content/Content";
import Loader from "../../../../widgets/loader/Loader";
import Routes from "../../../../../constants/routes";
import Pagination from "../surveyDashboard/activeSurveysContent/Pagination/Pagination";
import { AccountType } from "../../../../../types/model/StickybeakAccount";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { debounce } from "lodash";
import Header from "../../../../widgets/header/Header";
import SearchSurvey from "../../../../widgets/searchSurvey/SearchSurvey";

class CompletedSurveys extends Component {

    constructor(props) {
        super(props);

        this.headerButtons = [
            {
                text: "Active Surveys",
                onPress: this.onActiveSurveysPress,
                props: { plain: true, border: true }
            },
            {
                text: "Create New",
                onPress: this.onCreateNewPress,
                props: { greyDark: true }
            }];

        this.state = {
            surveysLoading: true,
            cursorList: [],
            search: ""
        };

        this.onSearchChangeDebounce = debounce(this.onSearchSurveys, 250, { trailing: true, leading: false });
    }

    static propTypes = {
        organisations: PropTypes.object,
        surveys: PropTypes.object,
    };

    static defaultProps = {
        organisations: {},
        surveys: {},
    };

    componentDidMount() {
        this.getAccountCompletedSurveys();
    }

    getNextSurveys = () => {
        const cursor = this.props.completedSurveys?.nextCursor;
        this.getAccountCompletedSurveys(cursor);
        const cursorList = this.state.cursorList;
        this.setState({ cursorList: [...cursorList, cursor] }
            , function () {
                console.log(this.state.cursorList);
            });
    };

    getPrevSurveys = () => {
        const cursorList = this.state.cursorList;
        if (cursorList.length > 0) {
            const lastIndex = this.state.cursorList.length - 1;
            this.setState({ cursorList: cursorList.filter((item, index) => index !== lastIndex) }
                , function () {
                    console.log(this.state.cursorList);
                });
            const cursor = this.state.cursorList[this.state.cursorList.length - 2];
            this.getAccountCompletedSurveys(cursor);
        }
    };

    getAccountCompletedSurveys = (cursor) => {
        this.setState({
            surveysLoading: true
        });
        this.props.dispatch(getOrganisationCompletedSurveys({
            orgId: this.props.orgId,
            cursor: cursor,
            limit: 21,
            onSuccess: () => {
                this.setState({ surveysLoading: false });
            }
        }));
    };

    onActiveSurveysPress = () => {
        this.props.history.push(`${Routes.Surveys.root}`);
    };

    onCreateNewPress = () => {
        this.props.history.push(Routes.Surveys.Create.SelectTemplate);
    };

    renderCompletedSurveys = (selectedCompletedSurveys) => {
        return selectedCompletedSurveys.map((survey) => {
            return (
                <CompletedSurveyRow
                    key={survey.id}
                    orgId={this.props.orgId}
                    survey={survey} />
            );
        });
    };

    onSearchChange = (text) => {
        this.setState({ search: text });
        this.onSearchChangeDebounce(text);
    };

    onClearSearchPress = () => {
        this.setState({ search: "" });
        this.onSearchChangeDebounce("");
    };

    onSearchSurveys = (text) => {
        this.setState({
            surveysLoading: true
        });

        this.props.dispatch(getOrganisationCompletedSurveys({
            orgId: this.props.orgId,
            cursor: "",
            limit: 21,
            search: text,
            onSuccess: () => {
                this.setState({
                    surveysLoading: false
                });
            }
        }));
    };

    render() {
        const selectedCompletedSurveys = values(this.props.completedSurveys?.items).filter((survey) => {
            return (survey.status === "COMPLETED" && survey.account === this.props.orgId);
        });
        return (
            <Content headerText={"Surveys"}
                     headerButtons={this.headerButtons}
                     className={styles.completed_container}>
                <Header
                    actions={<SearchSurvey value={this.state.search}
                                           onChange={this.onSearchChange}
                                           title={"Completed Surveys"}
                                           onClearPress={this.onClearSearchPress} />}
                    noBorder />
                {
                    this.state.surveysLoading
                        ? <div className={styles.loader_wrapper}>
                            <Loader large />
                        </div>
                        : selectedCompletedSurveys.length
                            ? (
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Respondents</TableCell>
                                                <TableCell>Started at</TableCell>
                                                <TableCell>Completed at</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        {this.renderCompletedSurveys(selectedCompletedSurveys)}
                                    </Table>
                                </>
                            )
                            :
                            <> {this.state.cursorList.length > 0 ? <></> : <p>No surveys completed yet</p>}</>

                }
                {(this.props.completedSurveys?.nextCursor || this.state.cursorList.length > 0) &&
                    <Pagination data={this.props.completedSurveys}
                                getNext={this.getNextSurveys}
                                getPrev={this.getPrevSurveys}
                                refresh={this.getAccountCompletedSurveys}
                    />}
            </Content>
        );
    }
}

export default connect((state) => {
    const organisations = (state.organisations.accounts || {});

    const orgId = state.settings.organisation;
    const selectedAccount = state.organisations.accounts[orgId];
    const completedSurveys = state.surveys.completedSurveys[orgId];
    const surveys = state.surveys;

    return {
        organisations: organisations,
        orgId: orgId,
        organisation: organisations[orgId],
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
        surveys: surveys,
        completedSurveys: completedSurveys,
    };
})(CompletedSurveys);
