import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./DeleteSurveyDialog.module.scss"

export default class DeleteSurveyDialog extends PureComponent {

	static propTypes = {
		dialogRef: PropTypes.func,
		onSubmit: PropTypes.func,
		loading: PropTypes.bool,
	};

	static defaultProps = {
		dialogRef: () => null,
		onSubmit: () => null,
		loading: false,
	};

	hide = () => {
		this.dialog.hide();
	};

	onSubmit = () => {
		this.props.onSubmit();
	};

	render() {

		const actions = [
			{
				text: "Cancel",
				onPress: this.hide,
				props: {
					plain: true,
					disabled: this.props.loading
				}
			},
			{
				text: "Yes",
				onPress: this.onSubmit,
				props: {
					blue: true,
					loading: this.props.loading
				}
			}
		];

		return (
			<Dialog title={"Delete Survey"}
					actions={actions}
					disableDismiss={this.props.loading}
					ref={(ref) => {
						this.dialog = ref;
						this.props.dialogRef(ref)
					}}>
				<p className={styles.text}>Are you sure you would like to delete this survey?</p>
			</Dialog>
		)
	}
}