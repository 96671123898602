import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Locations.module.scss";
import classnames from "classnames";
import LocationInput from "./components/locationInput/LocationInput";
import DeleteCircle from "../../../../../../../../../../../../resources/js/DeleteCircle";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IconButton from "@mui/material/IconButton";

class Locations extends Component {

    state = {
        location: "",
        searching: true,
    };

    onLocationSuggestionPress = (location) => {
        let l;

        if (location.type === "city") {
            l = {
                country: location.country_name,
                countryKey: location.country_code,

                region: location.region,
                regionKey: location.region_id,

                city: location.name,
                cityKey: location.key,
            };
        } else if (location.type === "region") {
            l = {
                country: location.country_name,
                countryKey: location.country_code,

                region: location.name,
                regionKey: location.key,
            };
        } else {
            l = {
                country: location.country_name,
                countryKey: location.country_code,
            };
        }

        this.props.fields.push({
            locations: [{...l}]
        });

        this.setState({
            location: "",
            searching: false
        });
    };

    onTextChange = (key) => {
        return (value) => {
            this.setState({
                [key]: value,
            });
        };
    };

    onRemoveLocationPress = (index) => {
        return () => {
            this.props.fields.remove(index);
        };
    };

    onAddLocationPress = () => {
        this.setState({
            searching: true
        });
    };

    renderTooltip = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <h4 style={{ fontWeight: "bold" }}>
                    Max locations reached
                </h4>
                <span>
                    Surveys that target too many locations <br /> usually need custom reporting and <br /> audience management. Please contact <br /><a
                    href={"mailto:support@stickybeak.co?subject=I want to run a multi-location survey"}
                    style={{ color: "black" }}>support@stickybeak.co</a> if you want to <br /> launch a multi-location survey.
                </span>
            </div>
        );
    };

    renderLocation = (name, i) => {

        const geoLocations = this.props.geoLocations[i];
        const location = geoLocations.locations[0];
        const city = !!location.city ? `${location.city}, ` : null;
        const region = !!location.region ? `${location.region}, ` : null;
        const country = location.country;

        return (
            <div className={styles.location} key={name}>
                <div>{city}{region}{country}</div>
                <div>
                    <IconButton onClick={this.onRemoveLocationPress(i)} className={styles.delete_button}>
                        <DeleteCircle />
                    </IconButton>
                </div>
            </div>
        );
    };


    render() {
        const className = classnames(styles.locations, this.props.className);
        const locationsDisabled = this.props.fields.getAll().length >= 10;
        const tooltip = this.renderTooltip();

        return (
            <div className={className}>
                {this.props.fields.getAll().length !== 0 &&
                <div className={styles.location_list}>
                    {this.props.fields.map(this.renderLocation)}
                </div>}

                {this.state.searching ?
                    <div className={styles.search_field} data-tip={tooltip} data-for={"locations-tooltip"}>
                        <div className={styles.input}>
                            <span>Search by Country, Region or City</span>
                            <LocationInput onSuggestionPress={this.onLocationSuggestionPress}
                                           text={this.state.location}
                                           disabled={locationsDisabled}
                                           onSuggestionTextChange={this.onTextChange("location")}
                            />
                        </div>

                        {locationsDisabled &&
                        <ReactTooltip clickable={true} type={"info"} id={"locations-tooltip"} class={styles.tooltip}
                                      delayHide={500}
                                      backgroundColor={"white"} textColor={"black"} insecure={false}
                                      effect={"solid"} place={"right"}>
                            {this.renderTooltip()}
                        </ReactTooltip>
                        }
                    </div>
                    :
                    <div className={styles.add_new}>
                        <a onClick={this.onAddLocationPress}>+ Add location</a>
                    </div>}
            </div>
        );
    }
}

Locations = connect((state) => {
    return {
        geoLocations: ((state.form.audience || {}).values || {}).geoLocations,
    };
})(Locations);

Locations.propTypes = {
    className: PropTypes.string,
};

Locations.defaultProps = {
    className: null,
};


export default Locations;
