import React, {PureComponent} from "react";
import styles from "./ComparisonFilter.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import ComparisonButton from "./ComparisonButton";
import {connect} from "react-redux";

class ComparisonFilter extends PureComponent {

    static propTypes = {
        onFilterSelect: PropTypes.func,
        surveyId: PropTypes.string,
        demographicSelection: PropTypes.object,
        onFilterClear: PropTypes.func,
        loading: PropTypes.bool,
        showCompletedOnly: PropTypes.bool,
    };
    static defaultProps = {
        onFilterSelect: () => null,
        onFilterClear: () => null,
        demographicSelection: null,
        surveyId: null,
        loading: true,
        showCompletedOnly: true
    };

    renderAge = () => {
        const buttonWrapperClassName = classnames(styles.comparison_filter_demographic_wrapper);
        const reportFiltering = this.props.reportFiltering[this.props.surveyId] || {};
        let selection = (this.props.demographicSelection["Ages"] || {});
        return (
            <div className={buttonWrapperClassName}>
                <div>
                    <h4 className={styles.demographic_title}>Age (Select to filter)</h4>
                    {!(Object.keys(selection).length === 0) &&
                    <h4 className={styles.clear_text} onClick={() => this.props.onFilterClear("Ages")}>clear</h4>}
                </div>
                {reportFiltering.demographics?.ages.map((age) => {
                    const fill = Number(((age.respondents / reportFiltering.totalNumOfRespondents) || 0) * 100);
                    const percentage = Number(fill.toFixed(1));
                    let ageGroup = age.minAge + '-' + age.maxAge;
                    return <ComparisonButton
                        loading={this.props.loading}
                        showCompletedOnly={this.props.showCompletedOnly}
                        onPress={() => this.props.onFilterSelect("Ages", ageGroup)}
                        selected={!!selection[ageGroup]}
                        text={ageGroup}
                        number={age.respondents}
                        fill={fill}
                        percentage={percentage}/>
                })
                }
            </div>
        )
    }

    renderGender = () => {
        const buttonWrapperClassName = classnames(styles.comparison_filter_demographic_wrapper);
        const reportFiltering = this.props.reportFiltering[this.props.surveyId] || {};
        let selection = (this.props.demographicSelection["Genders"] || {});
        return (
            <div className={buttonWrapperClassName}>
                <div>
                    <h4 className={styles.demographic_title}>Gender (Select to filter)</h4>
                    {!(Object.keys(selection).length === 0) &&
                    <h4 className={styles.clear_text} onClick={() => this.props.onFilterClear("Genders")}>clear</h4>}
                </div>
                {reportFiltering.demographics?.genders.map((gender) => {
                    const fill = Number(((gender.respondents / reportFiltering.totalNumOfRespondents) || 0) * 100);
                    const percentage = Number(fill.toFixed(1));
                    let genderText;
                    if (gender.gender === "MALE") {
                        genderText = "Men"
                    } else if (gender.gender === "FEMALE") {
                        genderText = "Women"
                    } else if (gender.gender === "ALL") {
                        genderText = "Men & Women"
                    }
                    return <ComparisonButton
                        loading={this.props.loading}
                        showCompletedOnly={this.props.showCompletedOnly}
                        onPress={() => this.props.onFilterSelect("Genders", gender.gender)}
                        selected={!!selection[gender.gender]}
                        text={genderText}
                        number={gender.respondents}
                        fill={fill}
                        percentage={percentage}/>
                })
                }
            </div>
        )
    }

    render() {
        const wrapperClassName = classnames(styles.comparison_filtering_wrapper, this.props.wrapperClassName);
        const reportFiltering = this.props.reportFiltering[this.props.surveyId] || {};
        const reportFilteringRespondentsCompleted = (this.props.reportSurvey[this.props.surveyId] || {}).respondentsCompleted || 0;
        return (
            <div className={wrapperClassName}>
                {reportFiltering && reportFilteringRespondentsCompleted > 0 &&
                <>
                    {this.props.showCompletedOnly ?
                    <h3 className={styles.filter_title}>Survey Filtering</h3> : <h3 className={styles.filter_title}>Survey Filtering (Unavailable for incomplete survey answers)</h3>}
                    {this.renderAge()}
                    {this.renderGender()}
                </>}
            </div>
        );
    }
}

export default ComparisonFilter = connect((state, props) => {
    return {
        reportFiltering: state.reports.filtering,
        reportSurvey: state.reports.surveys,
    };
})(ComparisonFilter);
