import React, {Component} from "react";
import styles from "./FormGroupHeader.module.scss";

export default class FormGroupheader extends Component {
    render() {
    return (
        <div className={styles.form_group_header}>
            {this.props.strapline && <p className={styles.strapline}><span>{this.props.strapline}</span></p>}
            <h3 className={styles.heading}>
                <span>{this.props.step} </span>{this.props.heading}
            </h3>
            <p className={styles.header_description}>
                {this.props.description && this.props.description}
            </p>
        </div>
    )};
}
