import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./Header.module.scss";
import classnames from "classnames";
import Button from "../button/Button";
import TextInputField from "../textInput/TextInputField";

export default class Header extends Component {

    static propTypes = {
        className: PropTypes.string,
        editable: PropTypes.bool,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        actions: PropTypes.node,
        text: PropTypes.string,
        buttons: PropTypes.array,
        medium: PropTypes.bool,
        small: PropTypes.bool,
        bold: PropTypes.bool,
        noBorder: PropTypes.bool,
        wrapHeight: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        actions: null,
        text: "",
        buttons: [],
        medium: false,
        small: false,
        bold: false,
        noBorder: false,
        wrapHeight: false,
    };

    renderTextHeader() {
        const textClassName = classnames(styles.header_text);

        return (
            <div className={styles.header_text_wrapper}>
                {this.props.editable ? <TextInputField name={this.props.name}
                                                       classNameWrapper={styles.question_name}
                                                       classNameFocused={styles.question_name_focused}
                                                       placeholder={this.props.placeholder}
                                                       helperText={"Tap to edit"}/> : <span className={textClassName}>{this.props.text}</span>}
            </div>
        )
    }

    renderActionsHeader() {
        return (
            <div className={styles.header_actions_wrapper}>
                {this.props.actions}
            </div>
        )
    }

    render() {
        const className = classnames(styles.header, {
            [styles.medium]: this.props.medium,
            [styles.small]: this.props.small,
            [styles.bold]: this.props.bold,
            [styles.no_border]: this.props.noBorder,
            [styles.wrap_height]: this.props.wrapHeight,
        }, this.props.className);
        const buttons = this.props.buttons.map((button) => (
            <Button key={button.text} onPress={button.onPress} {...(button.props || {})}>{button.text}</Button>));

        return (
            <div className={className}>
                <div className={styles.inner}>
                    {!this.props.actions && this.renderTextHeader()}
                    {this.props.actions && this.renderActionsHeader()}
                    <div className={styles.buttons_wrapper}>
                        {buttons}
                    </div>
                </div>
            </div>
        );
    }
}