/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/



export default function fetchJSON(method, url, token, data) {
    return new Promise((resolve, reject) => {
        url = process.env.REACT_APP_API_URL + url;

        const headers = new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": token
        });
        
        let body = null;

        if (method !== 'get' && data != null) {
            if (data.isformdata) {
                body = data;
                headers.delete("Content-Type");
            } else {
                body = JSON.stringify(data);
            }
        }

        const request = new Request(url, {
            method: method,
            headers: headers,
            body: body,
        });

        fetch(request).then((resp) => {
            return resp.json().then((json) => {
                return resp.ok ? resolve(json) : reject({...json, url: url})
            }).catch((err) => {
                reject({...err, url: url});
            })
        }).catch((err) => {
            reject({...err, url: url});
        });
    });
}
