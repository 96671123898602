/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreateUpdateOrganisation.module.scss";
import classnames from "classnames";
import TextInputField from "../../textInput/TextInputField";
import {reduxForm, reset} from "redux-form";
import connect from "react-redux/es/connect/connect";
import Dialog from "../../dialog/Dialog";
import {createOrganisation, updateOrganisation} from "../../../../redux/actions/v1/organisations";

const INITIAL_STATE = {
    loading: false,
    err: null,
};

class CreateUpdateOrganisation extends Component {

    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        disableDismiss: PropTypes.bool,

        title: PropTypes.string,
        text: PropTypes.string,
        positiveButtonText: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
        disableDismiss: false,

        title: null,
        text: null,
        positiveButtonText: null,
    };

    state = INITIAL_STATE;

    onHandleSubmit = (values) => {

        this.setState({
            loading: true,
            err: null,
        });

        if (this.props.updating) {
            this.props.dispatch(updateOrganisation({
                accountId: this.props.org.id,
                account: values,
                onSuccess: this.onSuccess,
                onError: this.onError,
            }));
        } else {
            this.props.dispatch(createOrganisation({
                account: values,
                onSuccess: this.onSuccess,
                onError: this.onError,
            }));
        }
    };

    onSuccess = () => {
        this.setState({
            loading: false,
            error: null,
        }, this.hide)
    };

    onError = (err) => {
        this.setState({
            loading: false,
            err: err.message,
        })
    };

    hide = () => {

        if (this.state.loading) {
            return;
        }

        this.dialog.hide();
    };

    onClose = () => {
        this.setState(INITIAL_STATE);
        this.props.onClose();
        this.props.dispatch(reset(this.props.form));
    };

    render() {
        const className = classnames(styles.create_update_organisation, this.props.className);
        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                className={className}
                disableDismiss={this.state.loading || this.props.disableDismiss}
                title={!!this.props.title ? this.props.title : this.props.updating ? "Update Organisation" : "Create Organisation"}
                onClose={this.onClose}
                onSubmit={this.props.handleSubmit(this.onHandleSubmit)}
                text={this.props.text}
                actions={[
                    !this.props.disableDismiss ? {text: "Cancel", onPress: () => this.dialog.hide(), props: {plain: true, disabled: this.state.loading}} : null,
                    {text: !!this.props.positiveButtonText ? this.props.positiveButtonText : this.props.updating ? "Update" : "Create", props: {type: "submit", disabled: (!this.props.valid || this.props.pristine), blue: true, loading: this.state.loading}},
                ]}
            >
                <TextInputField name={"name"}
                                label={"Organisation Name"}
                                placeholder={"Stickybeak Ltd"}/>
                {this.props.children}
            </Dialog>
        );
    }
}

CreateUpdateOrganisation = reduxForm({
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        return errors;
    }
})(CreateUpdateOrganisation);

export default connect((state, props) => {

    const orgId = props.orgId;
    const org = (state.organisations.accounts[orgId] || {});

    return {
        form: `createUpdateOrganisation${orgId}`,
        orgId: orgId,
        org: org,
        updating: !!orgId,
        initialValues: {
            name: org.name
        },
    };
})(CreateUpdateOrganisation);