/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2018-12-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import Dialog from "../../dialog/Dialog";
import {leaveOrganisation} from "../../../../redux/actions/v1/organisations";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import styles from "./LeaveOrgDialog.module.scss";
import {setActiveOrganisation} from "../../../../redux/actions/v1/settings";
import keys from "lodash/keys"
import Routes from "../../../../constants/routes";

class LeaveOrgDialog extends Component {

    static propTypes = {
        className: PropTypes.string,

        dialogRef: PropTypes.func.isRequired,
        orgId: PropTypes.number.isRequired,
        userId: PropTypes.string.isRequired,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        className: null,

        dialogRef: () => null,
        orgId: null,
        userId: null,
        onClose: () => null,
    };

    state = {
        loading: false,
    };


    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.setState({
            loading: false,
        });
        this.props.onClose();
    };

    onConfirmLeavePress = () => {
        const remainingOrganisations = keys(this.props.organisations).filter((orgId) => orgId !== this.props.orgId.toString());

        this.setState({
            loading: true,
        });

        this.props.dispatch(leaveOrganisation({
            orgId: this.props.orgId,
            userId: this.props.userId,
            onSuccess: () => {
                this.props.dispatch(setActiveOrganisation(Number(remainingOrganisations[0])));
                this.props.history.replace(Routes.Settings.root);
            },
        }));
    };

    render() {
        let isUserTheOnlyAdmin = true;

        if (!!this.props.orgUsers && this.props.orgUsers.length > 1) {
            this.props.orgUsers.map((user) => {
                if (user.id !== this.props.userId && (user.role === "SUPER_ADMIN")) {
                    isUserTheOnlyAdmin = false;
                    return null;
                }
                return null;
            });
        }

        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                title={"Leave Organisation"}
                onClose={this.onClose}
                disableDismiss={this.state.loading}
                actions={[
                    {
                        text: "Cancel", onPress: this.hide, props: {plain: true}
                    },
                    {
                        text: "Leave",
                        onPress: this.onConfirmLeavePress,
                        props: {loading: this.state.loading, disabled: isUserTheOnlyAdmin}
                    }]}
            >
                {isUserTheOnlyAdmin ?
                    <div className={styles.warning}>
                        <div>
                            You are the only administrator for this account.
                        </div>
                        <div>
                            Please make someone else an admin before leaving this organisation.
                        </div>
                    </div>
                    :
                    <h3>Confirm to leave organisation.</h3>
                }
            </Dialog>
        );
    }
}

LeaveOrgDialog = withRouter(LeaveOrgDialog);
export default connect((state) => {
    return {
        organisations: state.organisations.accounts
    }
})(LeaveOrgDialog)
