import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "../../button/Button";
import Dialog from "../../../widgets/dialog/Dialog";
import {
    FREE_TEXT,
    LONG_FORM_MULTI_CHOICE, LONG_FORM_SINGLE_CHOICE,
    MULTI_CHOICE, RATING, EMOJI_RATING,
    SINGLE_CHOICE, LOVE_HATE, QuestionText, RANKING, NPS, MULTI_ATTRIBUTE
} from "../../../../constants/QuestionTypes";
import styles from "./CreateSurveyRowDialog.module.scss";
import emojiRatingPreview from "../../../../resources/png/emojiRatingPreview@2x.png";
import newRatingPreview from "../../../../resources/png/newRatingPreview@2x.png";
import freeTextPreview from "../../../../resources/png/freeTextPreview@2x.png";
import multiChoicePreview from "../../../../resources/png/multichoicePreview@2x.png";
import singleChoicePreview from "../../../../resources/png/singleChoicePreview@2x.png";
import longListSinglePreview from "../../../../resources/png/longListSinglePreview@2x.png";
import longListMultiPreview from "../../../../resources/png/longListMultiPreview@2x.png";
import loveHatePreview from "../../../../resources/png/loveHatePreview@2x.png";
import rankingPreview from "../../../../resources/png/ranking-q@2x.png";
import npsPreview from "../../../../resources/png/nps_question@2x.png";
import multiAttribute from "../../../../resources/png/multi-attribute.png"
import bluePadlock from "../../../../resources/svg/padlock-blue-circle.svg";
import yellowPadlock from "../../../../resources/svg/padlock-yellow-circle.svg";
import { connect } from "react-redux";
import UpgradePromptDialog from "../upgradePromptDialog/UpgradePromptDialog";
import MenuItem from "@mui/material/MenuItem";

class CreateSurveyRowDialog extends PureComponent {
    static propTypes = {
        rowIndex: PropTypes.number,
        onSubmit: PropTypes.func,
        dialogRef: PropTypes.func,
    };

    static defaultProps = {
        rowIndex: null,
        onSubmit: () => null,
        dialogRef: () => null,
    };

    state = {
        previewQuestion: {},
    };

    componentDidMount() {
    }

    onMouseOver = (questionType) => {
        this.setState({ previewQuestion: questionType });
    };

    onPreviewPress = () => {
        this.state.previewQuestion.id && this.onSubmit(this.state.previewQuestion);
    };

    onUpgradePress = () => {
        this.upgradePromptDialog.show();
    };

    onSubmit = (questionType) => {
        const index = this.props.rowIndex;
        const type = questionType;
        //TD-2053 Default to false unless questionType is free text
        const canSkip = type === "FREE_TEXT";
        let question = {
            type: type,
            canSkip: canSkip,
            content: [
                {
                    type: "QUESTION_TEXT",
                }
            ],
        };
        if(questionType === "MULTI_ATTRIBUTE"){
            question = {
                type: type,
                canSkip: canSkip,
                content: [
                    {
                        type: "QUESTION_TEXT",
                        placeholder: "Ask your question here..."

                    },
                    {
                        type: "QUESTION_TEXT",
                        placeholder: "First attribute"
                    },
                    {
                        type: "QUESTION_TEXT",
                        placeholder: "Second attribute"
                    },
                    {
                        type: "QUESTION_TEXT",
                        placeholder: "Third attribute"
                    }
                ],
            };
        }
        if ((questionType === "NPS" && this.props.upgradeRequired) || (questionType === "RATING" && this.props.upgradeRequired)) {
            this.upgradePromptDialog.show();
        } else {
            this.props.onSubmit(index, question);
            this.onClose();
            this.hide();
        }

    };


    onClose = () => {
        this.setState({
            previewQuestion: {},
        });
    };

    hide = () => {
        this.dialog.hide();
    };

    render() {

        return (
            <>
                <Dialog
                    className={styles.create_question_dialog}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}
                    custom
                    onClose={this.onClose}>
                    <div className={styles.header}>
                        <h3 className={styles.header_text}>Add question</h3>
                        <Button plain onPress={this.hide}>Cancel</Button>
                    </div>

                    <div className={styles.question_container}>
                        <div className={styles.options_wrapper}>
                            <div>
                                <div className={styles.option_row}>

                                    <MenuItem key={SINGLE_CHOICE}
                                              onClick={() => this.onSubmit(SINGLE_CHOICE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[SINGLE_CHOICE]}</span>
                                            <img src={singleChoicePreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>
                                    <MenuItem key={RATING}
                                              onClick={() => this.onSubmit(RATING)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                        <span
                                            className={styles.option_item}>{QuestionText[RATING]}</span>
                                                {this.props.upgradeRequired &&
                                                    <img alt={"yellow padlock"} src={yellowPadlock} />
                                                }
                                            </div>
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}>
                                                <img src={newRatingPreview} width={"100%"} alt={""} />
                                            </div>
                                        </div>
                                    </MenuItem>


                                </div>
                                <div className={styles.option_row}>

                                    <MenuItem key={MULTI_CHOICE}
                                              onClick={() => this.onSubmit(MULTI_CHOICE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[MULTI_CHOICE]}</span>
                                            <img src={multiChoicePreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>



                                    <MenuItem key={MULTI_ATTRIBUTE}
                                              onClick={() => this.onSubmit(MULTI_ATTRIBUTE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                        <span
                                            className={styles.option_item}>{QuestionText[MULTI_ATTRIBUTE]}</span>

                                            </div>
                                            <img src={multiAttribute} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>
                                </div>
                                <div className={styles.option_row}>
                                    <MenuItem key={FREE_TEXT}
                                              onClick={() => this.onSubmit(FREE_TEXT)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[FREE_TEXT]}</span>
                                            <img src={freeTextPreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>

                                    <MenuItem key={EMOJI_RATING}
                                              onClick={() => this.onSubmit(EMOJI_RATING)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[EMOJI_RATING]}</span>
                                            <img src={emojiRatingPreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>

                                </div>
                                <div className={styles.option_row}>
                                    <MenuItem key={LONG_FORM_SINGLE_CHOICE}
                                              onClick={() => this.onSubmit(LONG_FORM_SINGLE_CHOICE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[LONG_FORM_SINGLE_CHOICE]}</span>
                                            <img src={longListSinglePreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>

                                    <MenuItem key={LONG_FORM_MULTI_CHOICE}
                                              onClick={() => this.onSubmit(LONG_FORM_MULTI_CHOICE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                        <span
                                            className={styles.option_item}>{QuestionText[LONG_FORM_MULTI_CHOICE]}</span>
                                            <img src={longListMultiPreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>

                                </div>
                                {this.props.upgradeRequired &&
                                    <div className={styles.upgrade_prompt_row}>
                                        <div className={styles.upgrade_padlock_wrapper}>
                                            <img className={styles.yellow_padlock} src={yellowPadlock}
                                                 alt={"yellow padlock"} />
                                            <img src={bluePadlock} alt={"blue padlock"} />
                                        </div>
                                        <div>
                                    <span>
                                        Upgrade plan to unlock our premium features.
                                    </span>
                                            <Button plain onPress={() => this.onUpgradePress()}>Click here</Button>
                                        </div>

                                    </div>
                                }
                                <div className={styles.option_row}>
                                    <MenuItem key={NPS}
                                              onClick={() => this.onSubmit(NPS)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                        <span
                                            className={styles.option_item}>{QuestionText[NPS]}</span>
                                                {this.props.upgradeRequired &&
                                                    <img alt={"yellow padlock"} src={yellowPadlock} />
                                                }
                                            </div>
                                            <img src={npsPreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>
                                    <MenuItem key={"LOVE_HATE"}
                                              onClick={() => this.onSubmit(LOVE_HATE)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                            <span className={styles.option_item}>Thumbs up/down</span>
                                            <img src={loveHatePreview} width={"40%"} alt={""} />
                                        </div>
                                    </MenuItem>


                                </div>
                                <div className={styles.option_row}>
                                    <MenuItem key={RANKING}
                                              onClick={() => this.onSubmit(RANKING)}
                                              className={styles.option_item}
                                    >
                                        <div className={styles.question_type}>
                                                <span
                                                    className={styles.option_item}>{QuestionText[RANKING]}</span>
                                            <img src={rankingPreview} width={"100%"} alt={""} />
                                        </div>
                                    </MenuItem>
                                </div>
                            </div>

                        </div>
                    </div>
                </Dialog>
                <UpgradePromptDialog
                    dialogRef={(ref) => this.upgradePromptDialog = ref}
                />
            </>

        );
    }
}

export default connect((state, props) => {
    const survey = state.form.editSurvey;
    const org = state.settings.organisation;
    const tier = state.organisations.accounts[org].subscription.tier.name;
    const isEnterprise = state.organisations.accounts[org].accountType === "ENTERPRISE";

    // let tier = "FREE"
    const upgradeRequired = (tier === "FREE" && (survey.values || {}).type !== "FACEBOOK" && !isEnterprise);
    return {
        survey: survey,
        tier: tier,
        upgradeRequired: upgradeRequired
    };
})(CreateSurveyRowDialog);
