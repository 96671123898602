import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./AutoCompleteInput.module.scss";
import * as classnames from "classnames";
import Loader from "../loader/Loader";
import TextInputField from "../textInput/TextInputField";
import TextInput from "../textInput/TextInput";
import MenuItem from "@mui/material/MenuItem";

export default class AutoCompleteInput extends Component {

    static propTypes = {
        suggestions: PropTypes.array,
        onSuggestionTextChange: PropTypes.func,
        onSuggestionPress: PropTypes.func,
        renderSuggestion: PropTypes.func,
        text: PropTypes.string,
        loading: PropTypes.bool,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        fieldName: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        suggestions: [],
        onSuggestionTextChange: () => null,
        onSuggestionPress: () => null,
        renderSuggestion: null,
        text: null,
        loading: false,
        placeholder: null,
        label: null,
        fieldName: null,
        className: null,
        disabled: null,
    };

    state = {
        focused: false,
        hideError: false
    };

    onFocus = () => {
        this.setState({
            focused: true,
            hideError: true
        });
    };

    onBlur = () => {
        setTimeout(() => {
            this.setState({
                hideError: false,
            });
        }, 150);
    };

    renderSuggestions = () => {
        const suggestions = (this.props.suggestions || []);

        let text = this.props.text;

        if (!!this.props.input) {
            text = this.props.input.value;
        }

        if (!this.state.loading && (!this.state.focused || !suggestions.length || !text)) {
            return null;
        }

        return (
            <div className={styles.suggestions_wrapper}>
                <div className={styles.suggestions}>
                    {
                        this.props.loading
                            ? <div className={styles.loader_wrapper}><Loader/></div>
                            : suggestions.map(this.renderSuggestion)
                    }
                </div>
            </div>
        );
    };

    renderSuggestion = (suggestion, i) => {

        if (!suggestion) {
            return null;
        }

        if (!!this.props.renderSuggestion && typeof this.props.renderSuggestion === "function") {
            return this.props.renderSuggestion(suggestion, this.onSuggestionPress, i);
        }
        const name = suggestion.name;

        return (
            <MenuItem key={i}
                      onClick={this.onSuggestionPress(suggestion)}
                      className={styles.suggestion}>
                {name}
            </MenuItem>
        );
    };

    onSuggestionPress = (suggestion) => {
        return () => {
            this.props.onSuggestionPress(suggestion);
            this.setState({ focused: false });
        };
    };

    render() {

        const className = classnames(styles.autocomplete_wrapper, this.props.className);
        const inputClassName = classnames(styles.input, {
            [styles.input_disabled]: this.props.disabled,
            [styles.focused]: this.state.focused,
        });

        const Component = !!this.props.fieldName ? TextInputField : TextInput;

        let text = this.props.text;
        if (!!this.props.input) {
            text = this.props.input.value;
        }

        return (
            <div className={className}>
                <Component inputRef={(ref) => this.input = ref}
                           name={this.props.fieldName}
                           className={inputClassName}
                           onFocus={this.onFocus}
                           onBlur={this.onBlur}
                           label={this.props.label}
                           onTextChange={this.props.onSuggestionTextChange}
                           text={text}
                           autoComplete={"invalid"}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           hideError={this.state.hideError}/>
                {this.renderSuggestions()}
            </div>
        );
    }
}
