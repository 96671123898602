/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-28.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./Login.module.scss";
import Card from "../../../../widgets/card/Card";
import { formValueSelector, reduxForm } from "redux-form";
import validateEmail from "../../../../../constants/validateEmail";
import { login, sendPasswordResetToEmail } from "../../../../../redux/actions/v1/user";
import TextInputField from "../../../../widgets/textInput/TextInputField";
import Button from "../../../../widgets/button/Button";
import Error from "../../../../widgets/error/Error";
import Routes from "../../../../../constants/routes";
import { setAuthLoading } from "../../../../../redux/actions/v1/auth";
import Dialog from "../../../../widgets/dialog/Dialog";
import sendEventToActiveCampaign from "../../../../../redux/actions/api/v1/eventTracking";
import { showError } from "../../../../../redux/actions/v1/snackbars";

class Login extends Component {
    state = {
        loading: false,
        error: null,
    };

    onLoginSuccess = (email) => {
        this.props.dispatch(setAuthLoading(true));
        sendEventToActiveCampaign(email, "Login", "Logged in");
    };

    onLoginSubmit = (values) => {
        this.setState({
            loading: true,
            error: null,
        });

        this.props.dispatch(
            login({
                email: values.email.trim(),
                password: values.password,
                onError: (err) => {
                    showError(err.message);
                    this.setState({
                        error: err.message,
                        loading: false,
                    });
                },
                onSuccess: () => {
                    this.onLoginSuccess(values.email.trim());
                },
            }),
        );
    };

    onSignUpPress = () => {
        this.props.history.push(Routes.Onboarding.SignUp.root);
    };

    onForgotPasswordPress = () => {
        !!this.props.email
            ? this.forgotPasswordDialog.show()
            : this.setState({ error: "Please enter your user email to receive a password reset link" });
    };

    onForgotPasswordSubmit = (email) => {
        this.setState({ loadingForgot: true });
        this.props.dispatch(
            sendPasswordResetToEmail({
                email: email,
                onSuccess: () => {
                    this.setState({
                        loadingForgot: false,
                        error: "Reset link successfully sent to email!",
                    });
                    this.forgotPasswordDialog.hide();
                },
                onError: (err) => {
                    this.setState({
                        error: err.message,
                        loadingForgot: false,
                    });
                    this.forgotPasswordDialog.hide();
                },
            }),
        );
    };

    render() {
        const forgotDialogActions = [
            {
                text: "Cancel",
                onPress: this.forgotPasswordDialog && this.forgotPasswordDialog.hide,
                props: {
                    plain: true,
                    disabled: this.state.loadingForgot,
                },
            },
            {
                text: "Confirm",
                onPress: () => this.onForgotPasswordSubmit(this.props.email),
                props: {
                    blue: true,
                    loading: this.state.loadingForgot,
                },
            },
        ];

        return (
            <Card className={styles.login}>
                <div className={styles.header}>
                    <h3 className={styles.header_text_left}>Log in</h3>
                    <Button plain onPress={this.onSignUpPress}>
                        Sign Up
                    </Button>
                </div>

                <form className={styles.login_form} onSubmit={this.props.handleSubmit(this.onLoginSubmit)}>
                    <TextInputField
                        name={"email"}
                        label={"Email Address"}
                        placeholder={"Email address"}
                        onFocus={() => this.setState({ error: null })}
                    />
                    <TextInputField name={"password"} label={"Password"} placeholder={"Password"} password />

                    <Error error={this.state.error} className={styles.error} />
                    <Button
                        green
                        className={styles.action_button}
                        disabled={!this.props.anyTouched || this.props.pristine || !this.props.valid}
                        loading={this.state.loading}
                        type={"submit"}>
                        Log in
                    </Button>
                    <Button plain className={styles.forgot_password_button} onPress={this.onForgotPasswordPress}>
                        Forgot password
                    </Button>
                </form>
                <Dialog
                    title={"Send password reset link"}
                    actions={forgotDialogActions}
                    disableDismiss={this.state.loadingForgot}
                    ref={(ref) => (this.forgotPasswordDialog = ref)}
                />
            </Card>
        );
    }
}

Login = reduxForm({
    form: "login",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = "Invalid email";
        } else if (!validateEmail(values.email.trim())) {
            errors.email = "Please enter a valid email.";
        }

        if (!values.password || values.password === "") {
            errors.password = "Invalid password";
        }

        return errors;
    },
})(Login);

const selector = formValueSelector("login");

export default Login = connect((state) => {
    return {
        email: selector(state, "email"),
    };
})(Login);
