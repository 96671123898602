/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { useCallback, useState } from "react";
import CheckBox from "../../../../../../../../../../widgets/checkBox/CheckBox";
import styles from "./Filter.module.scss";

type FilterProps = {
    getQuestionsReport: (showCompletedOnly?: boolean) => void;
}

export default function Filter(props: FilterProps) {
    const { getQuestionsReport } = props;

    const [showCompletedOnly, setShowCompletedOnly] = useState(true);
    const onShowCompletedChange = useCallback(() => {
        setShowCompletedOnly((state) => {
            const newState = !state;
            getQuestionsReport(newState);
            return newState
        });
    }, [setShowCompletedOnly, getQuestionsReport]);

    return (
        <div className={styles.filter}>
            <h3>Display Settings</h3>
            <div className={styles.option}>
                <CheckBox label={"Include answers from incomplete surveys"}
                          onChange={onShowCompletedChange}
                          checked={!showCompletedOnly} />
            </div>
        </div>
    );
}
