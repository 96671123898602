/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2018-12-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import Dialog from "../../dialog/Dialog";
import {removeUserFromOrganisation} from "../../../../redux/actions/v1/organisations";
import connect from "react-redux/es/connect/connect";

class RemoveUserFromOrgDialog extends Component {

    static propTypes = {
        className: PropTypes.string,

        dialogRef: PropTypes.func.isRequired,
        orgId: PropTypes.number.isRequired,
        userId: PropTypes.number,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        className: null,

        dialogRef: () => null,
        orgId: null,
        userId: null,
        onClose: () => null,
    };

    state = {
        loading: false,
        error: null,
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.setState({
            loading: false,
            error: null,
        });
        this.props.onClose();
    };

    onConfirmRemoveUserPress = () => {
        this.setState({
            loading: true,
        });

        this.props.dispatch(removeUserFromOrganisation({
            orgId: this.props.orgId,
            userId: this.props.userId,
            onSuccess: this.hide,
            onError: this.onError
        }));
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        });
    };

    render() {
        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                title={"Confirm user removal"}
                onClose={this.onClose}
                disableDismiss={this.state.loading}
                error={this.state.error}
                actions={[
                    {
                        text: "Cancel", onPress: this.hide, props: {plain: true}
                    },
                    {
                        text: "Remove",
                        onPress: this.onConfirmRemoveUserPress,
                        props: {loading: this.state.loading, red: true}
                    }]}
            >
                <h3>Confirm to remove user from organisation.</h3>
            </Dialog>
        );
    }
}

export default connect()(RemoveUserFromOrgDialog)
