import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./AddRow.module.scss";

export default class AddRow extends Component {

    static propTypes = {
        index: PropTypes.number,

        onAddNewQuestionPress: PropTypes.func,
        // onAddCommentPress: PropTypes.func,
        // onAddMediaPress: PropTypes.func,
    };

    static defaultProps = {
        index: null,

        onAddNewQuestionPress: () => null,
    };

    constructor(props) {
        super(props);

        this.editDialogtimer = null;

        this.state = {
            displayEditDialog: false,
        };

        this.addRowButton = React.createRef();
    }

    onEditHover = () => {
        this.setState({ displayEditDialog: true });
        clearTimeout(this.editDialogtimer);
    };

    onEditLeave = () => {
        this.editDialogtimer = setTimeout(() => {
            this.setState({ displayEditDialog: false });
        }, 0);
    };

    onAddNewQuestionPress = () => {
        if (this.props.setAnchorEl) {
            this.props.setAnchorEl(this.addRowButton.current);
        }

        this.props.onAddNewQuestionPress();
    };

    render() {
        const editorDialogClass = classnames(styles.editor_dialog, { [styles.hide_edit_dialog]: !this.state.displayEditDialog });
        const editBarClass = classnames(styles.new_row_line, { [styles.new_row_line_hover]: this.state.displayEditDialog });
        const editCircleClass = classnames(styles.editor_bar_circle, { [styles.editor_bar_circle_hover]: this.state.displayEditDialog });

        return (
            <div className={styles.editor_container}
                 onMouseOver={this.onEditHover}
                 onMouseLeave={this.onEditLeave}
                 onClick={this.onAddNewQuestionPress}>
                <div className={styles.new_row_left_col}>
                    <div className={editBarClass}/>
                    <div className={editCircleClass}/>
                </div>
                <div className={styles.new_row_right_col}>

                    <div className={editorDialogClass}
                         onMouseOver={this.onEditHover}
                         onMouseLeave={this.onEditLeave}>
                        <div ref={this.addRowButton}
                             className={classnames(styles.dialog_option, { [styles.padding_right]: !!this.props.setAnchorEl })}
                             onClick={this.onAddNewQuestionPress}>
                            {this.props.text}
                        </div>
                        {/*<MenuItem className={styles.dialog_option}*/}
                        {/*onClick={this.props.onAddCommentPress}>*/}
                        {/*Add*/}
                        {/*comment</MenuItem>*/}
                        {/*<MenuItem className={styles.dialog_option}*/}
                        {/*onClick={this.props.onAddMediaPress}>*/}
                        {/*Add media object</MenuItem>*/}
                        {/*<MenuItem className={styles.dialog_option} onClick={this.props.onAddEmailPress}>Email capture</MenuItem>*/}
                    </div>
                </div>
            </div>
        );
    }
}
