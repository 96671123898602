import wrapper from "../helpers/v1/wrapper";
import * as FacebookAPI from "../api/v1/facebook";
import createAction from "../helpers/v1/createAction";

export const SET_AUDIENCE_REACH = "facebook:SET_AUDIENCE_REACH";
export const SET_AUDIENCE_DETAILED = "facebook:SET_AUDIENCE_DETAILED";

export const SET_PRICE_ESTIMATE = "facebook:SET_PRICE_ESTIMATE";

export const setPriceEstimate = createAction(SET_PRICE_ESTIMATE, "priceEstimate");

export const getAudienceSize = wrapper(FacebookAPI.getAudienceSizeAPI);

export const getAudienceSizeForSurvey = wrapper(FacebookAPI.getAudienceSizeForSurveyAPI);

export const searchLocations = wrapper(FacebookAPI.searchLocationsAPI);

export const searchDetailed = wrapper(FacebookAPI.searchDetailedAPI);
