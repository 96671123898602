import React from "react";
import styles from "./Receipt.module.scss"
import Logo from "../../../resources/svg/horizontal-ruben-full-no-tag-yellow-grey.svg";
import classNames from "classnames";
import Routes from "../../../constants/routes";

export default class SubscriptionReceipt extends React.Component {

    getQueryParams = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    formatDate = (dateToFormat) => {
        const newDate = dateToFormat.toDateString().slice(4).split(" ")
        return `${newDate[1]} ${newDate[0]} ${newDate[2]}`
    }

    render() {
        const params = JSON.parse(this.getQueryParams("params", window.location.href));
        if (params === null) {
            this.props.history.push(Routes.Surveys.root)
            return null
        }
        let {amount, currency, orgName, subscription, dateOfCharge, last4, country, invoiceNumber} = params
        const dateTil = this.formatDate(new Date(params.dateTil))
        const dateFrom = this.formatDate(new Date(params.dateFrom))
        dateOfCharge = this.formatDate(new Date(dateOfCharge));
        let total;
        if (country.toUpperCase() === "NZ") {
            total = (amount * 20 / 23).toFixed(2)
        } else {
            total = amount
        }
        return (
            <div className={styles.pageContainer}>
                <div className={styles.headerContainer}>
                    <img src={Logo} alt={"Stickybeak"}/>
                    <h1>TAX RECEIPT</h1>
                </div>
                <div className={styles.addressContainer}>
                    <div className={styles.address}>
                        <h3>Stickybeak Limited</h3>
                        <p>
                            Level 1, Futureside<br/>
                            14 McDonald Street<br/>
                            Morningside<br/>
                            Auckland 1025<br/>
                            NEW ZEALAND<br/>
                            {country === "NZ" ? "GST:126-597-592" : ""}
                        </p>
                    </div>
                    <div className={styles.address}>
                        <h3>Billed to</h3>
                        <p>
                            {orgName}
                        </p>
                    </div>
                </div>

                <div className={styles.detailsContainer}>
                    <div className={styles.detailsHeader}>
                        <h2>Receipt details</h2>
                        <p>
                            Prices in {currency.toUpperCase()}
                        </p>
                    </div>
                    <div className={styles.detailsInner}>
                        <div className={styles.detailsReference}>
                            <h3>Invoice date</h3>
                            <p>{dateOfCharge}</p>
                            <h3>Invoice number</h3>
                            <p>{invoiceNumber}</p>
                            <h3>Reference</h3>
                            <p>Stickybeak Subscription Fee</p>
                        </div>
                        <div className={styles.detailsTable}>
                            <div className={styles.tableRow}>
                                <div>
                                    <p>Organisation</p>
                                </div>
                                <div>
                                    <p>{orgName}</p>
                                </div>
                            </div>
                            <>
                                <div className={styles.tableRow}>
                                    <div>
                                        <p>Subscription tier</p>
                                    </div>
                                    <div>
                                        <p>{subscription}</p>
                                    </div>
                                </div>
                                <div className={styles.tableRow}>
                                    <div>
                                        <p>Date from</p>
                                    </div>
                                    <div>
                                        <p>{dateFrom}</p>
                                    </div>
                                </div>
                                <div className={styles.tableRow}>
                                    <div>
                                        <p>Date till</p>
                                    </div>
                                    <div>
                                        <p>{dateTil}</p>
                                    </div>
                                </div>
                            </>
                            <div className={styles.tableRow}>
                                <div>
                                    <p>Total</p>
                                </div>
                                <div>
                                    <p>{total}</p>
                                </div>
                            </div>
                            {country === "NZ" &&
                            <div className={styles.tableRow}>
                                <div>
                                    <p>GST</p>
                                </div>
                                <div>
                                    <p>{(amount * 3 / 23).toFixed(2)}</p>
                                </div>
                            </div>
                            }
                            <div className={classNames(styles.tableRow, styles.borderHeavy)}>
                                <div>
                                    <p>Amount charged</p>
                                </div>
                                <div>
                                    <p style={{fontWeight: "bolder"}}>{amount}</p>
                                </div>
                            </div>
                            <div className={styles.tableRow}>
                                <div>
                                    <p>Date charged</p>
                                </div>
                                <div>
                                    <p>{dateOfCharge}</p>
                                </div>
                            </div>
                            <div className={styles.tableRow}>
                                <div>
                                    <p>Card</p>
                                </div>
                                <div>
                                    <p>Card ending •••• {last4}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerContainer}>
                    <p className={styles.footerText}>For any feedback, suggestions or help, email us
                        at support@stickybeak.co</p>
                </div>
            </div>
        )
    }
}
