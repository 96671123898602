import React, { Component, Fragment } from "react";
import styles from "./Subscriptions.module.scss";
import Button from "../../../widgets/button/Button";
import UpdateSubscriptionPlanDialog
    from "../../../widgets/dialogs/updateSubscriptionPlanDialog/UpdateSubscriptionPlanDialog";
import CancelServiceDialog from "../../../widgets/dialogs/cancelServiceDialog/CancelServiceDialog";
import FindAudienceDialog from "../../../widgets/dialogs/findAudienceDialog/FindAudienceDialog";
import PricingCard from "../../../widgets/pricingCard/PricingCard";
import { getSaasTiers } from "../../../../redux/actions/v1/saas";
import { connect } from "react-redux";
import Loader from "../../../widgets/loader/Loader";
import fromCents from "../../../../constants/localization/currencies/fromCents";
import KeepPlanDialog from "../../../widgets/dialogs/keepPlanDialog/KeepPlanDialog";
import moment from "moment";
import values from "lodash/values";
import find from "lodash/find";

class Subscriptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    componentDidMount = () => {
        this.getTiers();
    };

    getTiers = () => {
        const currentSub = this.props.org.subscription || {};
        const currentPricing = currentSub.pricing || {};
        const currency = currentPricing.currency || "USD";
        this.props.dispatch(getSaasTiers({
            params: {
                currency: currency,
            },
            onSuccess: (tiers) => {
                this.setState({
                    loading: false,
                });
            },
        }));
    };

    onSelectPress = (price, isSelected, tier) => {
        this.setState({
            newTier: tier,
            newPrice: price,
            isSelected: isSelected
        });
        if (isSelected) {
            this.keepPlanDialog && this.keepPlanDialog.show();
        } else {
            this.dialog && this.dialog.show(price);
        }
    };

    onCancelPress = () => {
        this.cancelDialog && this.cancelDialog.show();
    };

    onAudienceServicePress = () => {
        this.audienceDialog && this.audienceDialog.show();
    };

    renderTiers = () => {
        return values((this.props.tiers || {})).map(this.renderTier);
    };

    renderTier = (tier) => {
        const currentSub = (this.props.currentSub || {});
        const currentTier = (this.props.currentTier || {});
        const endTerm = moment(currentSub.currentPeriodEnd).format("DD MMM YY");
        // const currentPricing = (currentSub.pricing || { amount: 0, currency: "NZD" });
        // const subtitle = `${fromCents(currentPricing.amount, currentPricing.currency, true)}/${currentPricing.interval}`;
        const maxUsers = tier.maxUsers === 1000000 ? "Unlimited" : tier.maxUsers;
        const maxRespondentsPerSurvey = !tier.maxRespondentsPerSurvey ? "Unlimited" : tier.maxRespondentsPerSurvey;
        const maxAudiences = !tier.maxAudiences ? "Unlimited" : tier.maxAudiences;

        const body = (
            <p>
                {maxUsers === "Unlimited" ? "Unlimited Users" : `Up to ${maxUsers} User${maxUsers > 1 ? "s" : ""}`}
                <br />
                {maxRespondentsPerSurvey} responses per survey
                <br />
                {maxAudiences} contact lists
                <br />
                {/*{maxAudienceMembers} members per contact list*/}
                Find a custom audience
                <br />
                Find a representative survey
                <br />
            </p>
        );

        let price = null;
        (tier.pricing || []).map((pricing) => {
            if (pricing.interval === this.props.pricingInterval) {
                price = pricing;
            }
            return null;
        });

        const isSelected = (price || {}).tier === currentTier.id;

        const isUpgrading = this.props.upgradeTier && (price || {}).id === this.props.currentSub.upgradePricing.id ? this.props.upgradeTier.id === tier.id : false;

        let clickedPricing = find(tier.pricing, ["interval", this.props.pricingInterval]);

        const priceString = !!price ? `${fromCents(price.amount, price.currency, true)}/${price.interval}` : null;

        if (!price) {
            return null;
        }

        return (
            <PricingCard key={tier.id}
                         className={styles.subscription_card}
                         title={tier.name}
                         subtitle={priceString}
                         body={body}
                         onPress={() => this.onSelectPress(clickedPricing, isSelected, tier)}
                         buttonClassName={styles.current_button}
                         isSelected={isSelected}
                         isUpgrading={isUpgrading}
                         upgradeTier={this.props.upgradeTier}
                         currentTier={this.props.currentTier}
                         endTerm={endTerm}
            />
        );
    };

    renderTrialBanner = (subscription) => {
        const trialEndDate = moment(subscription.trialEnd).format("DD MMMM YYYY");
        return (
            <div className={styles.subscription_message}>
                <strong>You are currently using the free trial.</strong> Your first bill is on {trialEndDate} and each
                month after.<br />
                Contact <a style={{ color: "#333333" }} href={"mailto:support@stickybeak.co"}>Support</a> if you need
                help.
            </div>
        );
    };

    renderCancellingBanner = (subscription) => {
        const subscriptionEnd = moment(subscription.currentPeriodEnd).format("DD MMMM YYYY");

        return (
            <div className={styles.subscription_message}>
                <strong>You are currently on the {subscription.tier.name} tier. </strong>&nbsp;
                You have access to this account until {subscriptionEnd}<br />
                Contact <a style={{ color: "#333333" }} href={"mailto:support@stickybeak.co"}>Support</a> if you need
                help.
            </div>
        );
    };

    renderSubscriptionBanner = (subscription, isCancelling) => {
        if (isCancelling) {
            return this.renderCancellingBanner(subscription);
        } else if (subscription.status === "trialing") {
            return this.renderTrialBanner(subscription);
        } else {
            const subscriptionEnd = moment(subscription.currentPeriodEnd).format("DD MMMM YYYY");
            const subscriptionPricing = subscription.pricing || {};
            const isUpgrading = this.props.currentTier && this.props.upgradeTier;

            let upgradeText = null;
            let term = subscription.pricing.interval;

            if (isUpgrading) {
                term = (subscription.upgradePricing && subscription.upgradePricing.interval) ? subscription.upgradePricing.interval : null;
                upgradeText = `You will be changing to the ${this.props.upgradeTier.name} tier when your next bill is issued.`;
            }

            return (
                <div className={styles.subscription_message}>
                    <strong>You are currently on the {subscription.tier.name} tier. {upgradeText}</strong>&nbsp;
                    {subscriptionPricing.amount ? `Your next bill is on ${subscriptionEnd} and each ${term} after.` : ""}<br />
                    Contact <a style={{ color: "#333333" }} href={"mailto:support@stickybeak.co"}>Support</a> if you
                    need
                    help.
                </div>
            );
        }
    };

    render() {

        let subscription = null;

        let hasValidSub = false;
        let isCancelling = false;
        if (!!this.props.org && !!this.props.org.subscription) {
            subscription = this.props.org.subscription;
            if (!this.props.org.subscription.canceledAt) {
                hasValidSub = true;
            } else {
                isCancelling = true;
            }
        }

        return (
            <Fragment>
                <div className={styles.subscriptions}>
                    {this.state.loading ? <Loader large /> :
                        <Fragment>

                            {!!subscription ? this.renderSubscriptionBanner(subscription, isCancelling) : null}

                            <div className={styles.subscription_options_container}>
                                {this.renderTiers()}
                            </div>
                            <div className={styles.subscription_buttons}>
                                {hasValidSub ?
                                    <Button plain border onPress={this.onCancelPress}>Delete account</Button> : null}
                            </div>
                        </Fragment>}
                </div>
                <UpdateSubscriptionPlanDialog
                    dialogRef={(ref) => this.dialog = ref}
                    org={this.props.org || {}}
                    newTier={this.state.newTier}
                    newPrice={this.state.newPrice}
                    currentSub={this.props.currentSub}
                    pricingInterval={this.props.pricingInterval}
                    onClose={() => this.setState({ newPrice: null, newTier: null })}
                    isSelected={this.state.isSelected}
                    orgId={(this.props.org || {}).id} />

                <KeepPlanDialog
                    dialogRef={(ref) => this.keepPlanDialog = ref}
                    currentTier={this.props.currentTier}
                    upgradeTier={this.props.upgradeTier}
                    currentSub={this.props.currentSub}
                    orgId={this.props.org.id}
                />
                <CancelServiceDialog dialogRef={(ref) => this.cancelDialog = ref} orgId={(this.props.org || {}).id}
                                     currentSub={(this.props.currentSub || {})}
                                     upgradeTier={(this.props.upgradeTier || {})} />
                <FindAudienceDialog dialogRef={(ref) => this.audienceDialog = ref} />
            </Fragment>
        );
    }
}

export default Subscriptions = connect((state, props) => {

    const tiers = state.saas.tiers;
    const currentOrg = props.org || {};
    const currentSub = currentOrg.subscription || {};
    const currentTier = currentSub.tier || {};
    const currentPricing = currentSub.pricing || {};
    const pricingInterval = currentPricing.interval || "month";
    const upgradePricing = currentSub.upgradePricing || {};
    const upgradeTier = values(tiers).find(tier =>
        tier.pricing.some(pricing =>
            pricing.id === upgradePricing.id
            && pricing.interval === upgradePricing.interval));

    return {
        tiers: tiers,
        organisation: currentOrg,
        currentSub: currentSub,
        currentTier: currentTier,
        upgradeTier: upgradeTier,
        pricingInterval: pricingInterval,
    };
})(Subscriptions);
