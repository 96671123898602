import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import * as OrganisationsAPI from "../api/v1/organisations";
import {showError, showSuccess} from "./snackbars";
import {setActiveOrganisation} from "./settings";
import * as SurveysAPI from "../api/v1/surveys";
import {setSurvey} from "../surveys";

export const SET_ORGANISATION = "organisations:SET_ORGANISATION";
export const SET_ORGANISATIONS = "organisations:SET_ORGANISATIONS";
export const REMOVE_ORGANISATION = "organisations:REMOVE_ORGANISATION";

export const SET_ORGANISATION_USER = "organisations:SET_ORGANISATION_USER";
export const SET_ORGANISATION_USERS = "organisations:SET_ORGANISATION_USERS";
export const REMOVE_ORGANISATION_USER = "organisations:REMOVE_ORGANISATION_USER";

export const SET_ORGANISATION_CARD = "organisations:SET_ORGANISATION_CARD";
export const SET_ORGANISATION_CARDS = "organisations:SET_ORGANISATION_CARDS";
export const REMOVE_ORGANISATION_CARD = "organisations:REMOVE_ORGANISATION_CARD";
export const SET_ORGANISATION_TRANSACTIONS = "organisations:SET_ORGANISATION_TRANSACTIONS"

export const SET_SUBSCRIPTION_DIFFERENCE = "organisations:SET_SUBSCRIPTION_DIFFERENCE";


export const setOrganisation = createAction(SET_ORGANISATION, "account");
export const setOrganisations = createAction(SET_ORGANISATIONS, "accounts");
export const removeOrganisation = createAction(REMOVE_ORGANISATION, "orgId");
export const setSubscriptionDifference = createAction(SET_SUBSCRIPTION_DIFFERENCE, "difference");
export const setOrganisationTransactions = createAction(SET_ORGANISATION_TRANSACTIONS, "account", "transactions")

export const getOrganisations = wrapper(OrganisationsAPI.getOrganisationsAPI, (dispatch, accounts) => {
    dispatch(setOrganisations(accounts));
});

export const getOrganisation = wrapper(OrganisationsAPI.getOrganisationAPI, (dispatch, org) => {
    dispatch(setOrganisation(org));
    dispatch(setActiveOrganisation(org.id));

});

export const createOrganisation = wrapper(OrganisationsAPI.createOrganisationAPI, (dispatch, org) => {
    dispatch(setOrganisation(org));
    dispatch(setActiveOrganisation(org.id));
    dispatch(showSuccess("Successfully created organisation."));

});

export const updateOrganisation = wrapper(OrganisationsAPI.updateOrganisationAPI, (dispatch, org) => {
    dispatch(showSuccess("Successfully updated organisation."));
    dispatch(setOrganisation(org));
}, (dispatch, err) => {
    dispatch(showError(err.message));
});

export const deleteOrganisation = wrapper(OrganisationsAPI.deleteOrganisationAPI, (dispatch, orgId) => {
    dispatch(showSuccess("Successfully deleted organisation."));
    dispatch(setOrganisation(orgId));
});

export const setOrganisationUser = createAction(SET_ORGANISATION_USER, "user", "orgId");
export const setOrganisationUsers = createAction(SET_ORGANISATION_USERS, "users", "orgId");
export const removeUserFromOrganisationRedux = createAction(REMOVE_ORGANISATION_USER, "orgId", "userId");

export const getUsersForOrganisation = wrapper(OrganisationsAPI.getUsersForOrganisationAPI, (dispatch, users, orgId) => {
    dispatch(setOrganisationUsers(users, orgId));
});

export const addUserToOrganisation = wrapper(OrganisationsAPI.addUserToOrganisationAPI, (dispatch, user, orgId) => {
    dispatch(setOrganisationUser(user, orgId));
});

export const updateUserInOrganisation = wrapper(OrganisationsAPI.updateUserInOrganisationAPI, (dispatch, user, orgId) => {
    dispatch(setOrganisationUser(user, orgId));
});

export const leaveOrganisation = wrapper(OrganisationsAPI.removeUserFromOrganisationAPI, (dispatch, r, orgId) => {
    dispatch(removeOrganisation(orgId));
    dispatch(showSuccess("Successfully left organisation."));
});

export const removeUserFromOrganisation = wrapper(OrganisationsAPI.removeUserFromOrganisationAPI, (dispatch, r, orgId, userId) => {
    dispatch(removeUserFromOrganisationRedux(orgId, userId));
    dispatch(showSuccess("Successfully removed user."));
});

export const setOrganisationCard = createAction(SET_ORGANISATION_CARD, "orgId", "card");
export const setOrganisationCards = createAction(SET_ORGANISATION_CARDS, "orgId", "cards");
export const removeOrganisationCard = createAction(REMOVE_ORGANISATION_CARD, "orgId", "stripeCardId");


export const getOrganisationCards = wrapper(OrganisationsAPI.getOrganisationCardsAPI, (dispatch, cards, orgId) => {
    dispatch(setOrganisationCards(orgId, cards));
});

export const getOrganisationTransactions = wrapper(OrganisationsAPI.getOrganisationTransactions, (dispatch, transactions, orgId) => {
    dispatch(setOrganisationTransactions(orgId, transactions));
})

export const addOrganisationCard = wrapper(OrganisationsAPI.addOrganisationCardAPI, (dispatch, card, orgId) => {
    dispatch(setOrganisationCard(orgId, card));
});

export const setOrganisationDefaultCard = wrapper(OrganisationsAPI.setOrganisationDefaultCardAPI, (dispatch, cards, orgId) => {
    dispatch(setOrganisationCards(orgId, cards));
});

export const updateOrganisationCard = wrapper(OrganisationsAPI.updateOrganisationCardAPI, (dispatch, card, orgId) => {
    dispatch(setOrganisationCard(orgId, card));
});

export const deleteOrganisationCard = wrapper(OrganisationsAPI.deleteOrganisationCardAPI, (dispatch, bool, orgId, stripeCardId) => {
    dispatch(removeOrganisationCard(orgId, stripeCardId));
    dispatch(showSuccess("Successfully removed card."))
});

export const createUpdateSaaSSubscription = wrapper(OrganisationsAPI.createUpdateSaaSSubscriptionAPI, (dispatch, account) => {
        dispatch(setOrganisation(account));
    },
    (dispatch, err) => {
        dispatch(showError(err.message));
    });

export const cancelSaaSSubscriptionUpgrade = wrapper(OrganisationsAPI.cancelSaaSSubscriptionUpgradeAPI, (dispatch, account) => {
        dispatch(setOrganisation(account));
    },
    (dispatch, err) => {
        dispatch(showError(err.message));
    });

export const cancelSaaSSubscription = wrapper(OrganisationsAPI.cancelSaaSSubscriptionAPI, (dispatch, account) => {
        dispatch(setOrganisation(account));
    },
    (dispatch, err) => {
        dispatch(showError(err.message));
    });

export const calculateSubscriptionDifference = wrapper(OrganisationsAPI.calculateSubscriptionDifference, (dispatch, difference) => {
    dispatch(setSubscriptionDifference(difference));
});