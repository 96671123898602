/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import createAction from "../helpers/v1/createAction";

export const SET_AUTH_LOADING = "auth:SET_AUTH_LOADING";

export const SHOW_ONBOARD_DIALOG = "auth:SHOW_ONBOARD_DIALOG";

export const setAuthLoading = createAction(SET_AUTH_LOADING, "loading");
export const showOnboardDialog = createAction(SHOW_ONBOARD_DIALOG, "onboardPassword");