import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import styles from "./QuestionCard.module.scss";
import Card from "../../../../../../../../widgets/card/Card";
import PropTypes from "prop-types";
import Loader from "../../../../../../../../widgets/loader/Loader";
import classnames from "classnames";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { TagCloud } from "react-tagcloud";
import QuestionCardAnswer from "../questionCardAnswer/QuestionCardAnswer";
import PopperMenu from "../../../../../../../../widgets/popperMenu/PopperMenu";
import getQuestionCSVReport from "../../../../../../../../../constants/report/getQuestionCSVReport";
import getQuestionJSONReport from "../../../../../../../../../constants/report/getQuestionJSONReport";
import { orderBy, find } from "lodash/collection";
import RankingChoiceDialog from "../../../../../../../../widgets/dialogs/rankingChoiceDialog/RankingChoiceDialog";
import Button from "src/components/widgets/button/Button";
import Logo from "src/resources/svg/horizontal-ruben-partial-no-tag-grey.svg";
import DonutChart from "../donutChart/DonutChart";
import RatingView from "../ratingView/RatingView";
import { toArray } from "lodash";
import englishStopWords from "../../../../../../../../../constants/report/stopWords/english.json";
import { showError, showSuccess } from "src/redux/actions/v1/snackbars";
import NoDataFallback from "../noDataFallback/NoDataFallback";
import Avatar from "src/resources/svg/reuben_avatar_black.svg";

class QuestionCard extends PureComponent {
    static propTypes = {
        question: PropTypes.object,
        questionReport: PropTypes.object,
        questionFilter: PropTypes.object,
        onChoiceSelect: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        question: null,
        questionReport: null,
        questionFilter: {},
        onChoiceSelect: () => null,
        loading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            freeTextOrdered: [],
            loading: true,
            selectedChoice: null,
            isWordCloud: false,
        };

        this.questionMenuItems = [
            {
                text: "Download PNG",
                icon: CloudDownloadIcon,
                onPress: this.onDownloadPNGPress,
            },
            {
                text: "Download JSON",
                icon: CloudDownloadIcon,
                onPress: this.onDownloadJSONPress,
            },
            {
                text: "Download CSV",
                icon: CloudDownloadIcon,
                onPress: this.onDownloadCSVPress,
            },
        ];
        this.rankingChoiceDialog = null;
    }

    componentDidMount() {
        this.getQuestions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.questionReport !== this.props.questionReport) {
            this.getQuestions();
        }
    }

    getQuestions = () => {
        const props = this.props;
        if (!!props.question && props.question.type === "FREE_TEXT" && !props.loading) {
            if (!!props.questionReport) {
                const questionReport = props.questionReport;

                const answers = Object.keys(questionReport.answers).map((value) => {
                    const answers = questionReport.answers[value];
                    return {
                        value: value,
                        answers: answers,
                    };
                });

                const freeTextOrdered = orderBy(answers, ["answers"], ["desc"]);
                this.setState({ freeTextOrdered: freeTextOrdered });
                if (freeTextOrdered.length > 0) {
                    this.setState({
                        freeTextOrdered: [
                            ...freeTextOrdered,
                            {
                                value: "Skipped",
                                id: "skipped",
                                answers: this.props.questionReport.skipped,
                                question: this.props.question.id,
                            },
                        ],
                    });
                } else if (freeTextOrdered.length === 0) {
                    this.setState({
                        freeTextOrdered: [
                            ...freeTextOrdered,
                            {
                                value: "Skipped",
                                answers: this.props.questionReport.skipped,
                                id: "skipped",
                                question: this.props.question.id,
                            },
                        ],
                    });
                }
            }
        }
    };

    onDownloadQuestionReportPress = (e) => {
        this.popper.show(e.target);
    };

    onDownloadPNGPress = () => {
        const element = document.getElementById(this.props.question.id);
        html2canvas(element, {
            scale: 1.6,
            useCORS: true,
            allowTaint: true,
            imageTimeout: 2000,
            windowWidth: 1920,
        }).then((element) => {
            try {
                element.id = "screenshot";
                document.body.append(element);
                const result = document.getElementById("screenshot");
                const link = document.createElement("a");
                link.download = `${this.props.survey.name}-Question-${this.props.question.ordinal + 1}.png`;
                link.href = result.toDataURL("image/png");
                link.click();
                result.remove();
                this.props.dispatch(showSuccess("Successfully downloaded."));
            } catch (error) {
                this.props.dispatch(showSuccess(showError(error.message)));
            }
        });

        this.popper.hide();
    };

    onCopyPNGPress = () => {
        const element = document.getElementById(this.props.question.id);
        html2canvas(element, {
            scale: 1.6,
            useCORS: true,
            allowTaint: true,
            imageTimeout: 2000,
            windowWidth: 1920,
        }).then((element) => {
            try {
                element.id = "screenshot";
                document.body.appendChild(element);
                const result = document.getElementById("screenshot");
                result.toBlob((blob) => {
                    navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
                });
                result.remove();
                this.props.dispatch(showSuccess("Copied to Clipboard."));
            } catch (error) {
                this.props.dispatch(showSuccess(showError(error.message)));
            }
        });
    };

    onDownloadJSONPress = () => {
        const header = ((this.props.question.content || [])[0] || {}).text;
        const questionReport = this.props.questionReport;
        const isFreeText = this.props.question.type === "FREE_TEXT";
        const report = getQuestionJSONReport(questionReport, this.props.question.choices, isFreeText);
        const jsonReport = {
            [header]: report,
        };

        const json = "data:text/json;charset=utf-8," + JSON.stringify(jsonReport);
        saveAs(json, `${this.props.survey.name} - Question ${this.props.question.ordinal + 1}.json`);

        this.popper.hide();
    };

    onDownloadCSVPress = () => {
        const header = ((this.props.question.content || [])[0] || {}).text;
        const questionReport = this.props.questionReport;
        const isFreeText = this.props.question.type === "FREE_TEXT";
        const answers = getQuestionCSVReport(
            header,
            questionReport,
            this.props.question.choices,
            isFreeText,
            false,
            this.props.question,
        );

        const csv = "data:text/csv;charset=utf-8," + answers;
        saveAs(csv, `${this.props.survey.name} - Question ${this.props.question.ordinal + 1}`);

        this.popper.hide();
    };

    getQuestionContent = () => {
        const contentOrder = ["IMAGE", "QUESTION_TEXT"];
        const imageTextQuestion = this.props.question.content.sort((a, b) => {
            const indexOfA = contentOrder.indexOf(a.type);
            const indexOfB = contentOrder.indexOf(b.type);
            if (indexOfA > indexOfB) return 1;
            if (indexOfA < indexOfB) return -1;
            return 0;
        });
        const contentLastIndex = imageTextQuestion.length - 1;

        return (
            <>
                {imageTextQuestion.map((content, i) => {
                    if (!(this.props.question.type === "MULTI_ATTRIBUTE" && i > 0)) {
                        return (
                            <div className={styles.question_content} key={`question-${this.props.question.id}-content-${i}`}>
                                {content.type === "IMAGE" ? (
                                    <div className={styles.question_image_container}>
                                        <img src={content.imageURL} alt={"Question Image"} />
                                    </div>
                                ) : (
                                    <>
                                        {i === contentLastIndex ? (
                                            <div className={styles.final_question_container}>
                                                <div className={styles.final_question_img_container}>
                                                    <img src={Avatar} alt={"Reuben Avatar"} width={25} height={25} />
                                                </div>
                                                <div className={styles.question_text}>{content.text}</div>
                                            </div>
                                        ) : (
                                            <div className={styles.question_text}>{content.text}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    }
                })}
            </>
        );
    };

    getRankWeight = (rank) => {
        switch (rank) {
            case 1:
                return 6;
            case 2:
                return 5;
            case 3:
                return 4;
            case 4:
                return 3;
            case 5:
                return 2;
            case 6:
                return 1;
            default:
                return 0;
        }
    };

    renderRankingQuestion = () => {
        const topAnswers = [];
        const report = this.props.questionReport;

        if (report.answers) {
            Object.keys(report.answers).map((choiceId) => {
                const rankObject = report.answers[choiceId];
                let totalWeight = 0;
                Object.keys(rankObject).map((rank) => {
                    totalWeight += rankObject[rank] * this.getRankWeight(Number(rank));
                    return null;
                });

                const choice = find(this.props.question.choices, ["id", Number(choiceId)]);
                topAnswers.push({
                    ...choice,
                    rank: { ...rankObject },
                    totalWeight: totalWeight,
                });
                return null;
            });
        }
        const rankingAnswers = orderBy(topAnswers, ["totalWeight"], ["desc"]);
        if (this.props.question.canSkip) {
            rankingAnswers.push({
                value: "Skipped",
                answers: this.props.questionReport.skipped,
                rank: "skipped",
                id: "skipped",
                question: this.props.question.id,
            });
        }
        return (
            <div className={styles.choices}>
                <div className={styles.answers_container}>
                    {report.respondents === 0 ? <NoDataFallback /> : rankingAnswers.map(this.renderRankChoice)}
                </div>

                <div className={styles.card_footer}>
                    <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                    <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                </div>
            </div>
        );
    };

    onChoiceButtonPress = (choice) => {
        this.setState({ selectedChoice: choice });
        this.rankingChoiceDialog.show();
    };

    onRankingDialogClose = () => {
        this.setState({ selectedChoice: null });
    };

    renderRankChoice = (choice, choiceIndex) => {
        const questionReport = this.props.questionReport;
        let count = 0;
        const rankKeys = Object.keys(choice.rank);
        rankKeys.map((key) => (count += choice.rank[key]));
        const choiceVal = choice.value;
        const choiceNum = choiceIndex + 1;

        return (
            <div key={choice.id} className={styles.choice}>
                {questionReport.respondents === 0 ? (
                    <NoDataFallback />
                ) : (
                    <>
                        <div className={styles.choice_button} onClick={() => this.onChoiceButtonPress(choice)}>
                            {`#${choiceNum}`}
                        </div>
                        <QuestionCardAnswer
                            onPress={() => this.props.onChoiceSelect(this.props.question.id, choice.id)}
                            selected={!!this.props.questionFilter[choice.id]}
                            text={choiceVal}
                            hidePercentage
                        />
                    </>
                )}
            </div>
        );
    };

    renderQuestionChoice = () => {
        const questionReport = this.props.questionReport;
        const choices = (this.props.question.choices || []).map((choice) => {
            return {
                ...choice,
                answers: (questionReport.answers || {})[choice.id] || 0,
            };
        });
        const questionType = this.props.question.type;

        const numChoices = choices.length;
        const numRespondents = this.props.questionReport.respondents || 0;

        let choicesOrdered = orderBy(choices, ["answers"], ["desc"]);
        if (questionType === "EMOJI_RATING") {
            choicesOrdered = choices.reverse();
        }
        if (questionType === "RATING") {
            choicesOrdered = orderBy(choices, ["value"], ["desc"]);
        }
        if (this.props.question.canSkip) {
            choicesOrdered.push({
                value: "Skipped",
                answers: this.props.questionReport.skipped,
                id: "skipped",
                question: this.props.question.id,
            });
        }

        if (
            (questionType === "SINGLE_CHOICE" ||
                questionType === "MULTI_CHOICE" ||
                questionType === "EMOJI_RATING" ||
                questionType === "RATING" ||
                questionType === "LOVE_HATE") &&
            numChoices <= 2
        ) {
            return (
                <div className={styles.choices}>
                    <DonutChart
                        questionType={questionType}
                        choices={choices}
                        numRespondents={numRespondents}
                        onPress={(choiceId) => {
                            this.props.onChoiceSelect(this.props.question.id, choiceId);
                        }}
                        questionType={this.props.question.type}
                    />
                    <div className={styles.card_footer}>
                        <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                        <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.choices}>
                    <div className={styles.answers_container}>
                        {questionReport.respondents === 0 ? <NoDataFallback /> : choicesOrdered.map(this.renderChoice)}
                    </div>
                    <div className={styles.card_footer}>
                        <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                        <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                    </div>
                </div>
            );
        }
    };

    renderChoice = (choice, freeTextId) => {
        const choiceId = choice.id;
        const numRespondents = this.props.questionReport.respondents || 0;
        const answers = choice.answers || 0;
        const fill = Number((answers / numRespondents || 0) * 100);
        const percentage = Number(fill.toFixed(1));
        const numStars = this.props.question.type === "RATING" && toArray(choice.value).length;
        const value = this.props.question.type === "RATING" ? `${numStars} stars` : choice.value;
        return (
            <div key={choiceId || freeTextId} className={styles.question_answer_container}>
                <RatingView
                    questionType={this.props.question.type}
                    image={choice.imageUrl}
                    selectedImage={choice.selectedImageUrl}
                    numStars={numStars}
                />
                <QuestionCardAnswer
                    onPress={() => this.props.onChoiceSelect(this.props.question.id, choiceId)}
                    selected={!!this.props.questionFilter[choiceId]}
                    text={value}
                    freeText={this.props.question.type === "FREE_TEXT"}
                    number={choice.answers}
                    percentage={percentage}
                    fill={fill}
                />
            </div>
        );
    };

    getWordCloudData = () => {
        const questionReport = this.props.questionReport;
        const words = Object.keys(questionReport.answers).map((value) => {
            const numAnswers = questionReport.answers[value];
            const wordsSplit = value
                .toLowerCase()
                .split(/[, ]+/)
                .filter((word) => !englishStopWords.words.includes(word));

            const wordsByNumAnswers = new Array(numAnswers).fill(wordsSplit).flat();

            return wordsByNumAnswers;
        });

        const allFilteredWords = words.flat();
        const countWords = allFilteredWords.reduce((count, word) => {
            count[word] = count.hasOwnProperty(word) ? count[word] + 1 : 1;
            return count;
        }, {});

        const wordCloudData = Object.keys(countWords).map((word) => ({ value: word, count: countWords[word] }));

        return wordCloudData;
    };

    renderWordCloud = () => {
        const data = this.getWordCloudData();
        const colorOptions = {
            luminosity: "bright",
            hue: "#52A1FF",
        };
        const isDataEmpty = data.length === 0;
        return (
            <div className={styles.choices}>
                {isDataEmpty ? (
                    <NoDataFallback />
                ) : (
                    <div className={styles.word_cloud_container}>
                        <TagCloud
                            minSize={18}
                            maxSize={63}
                            tags={data}
                            colorOptions={colorOptions}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 18,
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        />
                    </div>
                )}
                <div className={styles.card_footer}>
                    <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                    <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                </div>
            </div>
        );
    };

    renderFreeText = (freeText, index) => {
        const numRespondents = this.props.questionReport.respondents || 0;
        const answers = freeText.answers || 0;
        const fill = Number((answers / numRespondents || 0) * 100);
        const percentage = Number(fill.toFixed(1));

        return (
            <div key={index} className={styles.question_answer_container}>
                <QuestionCardAnswer
                    text={freeText.value}
                    freeText={this.props.question.type === "FREE_TEXT"}
                    number={freeText.answers}
                    percentage={percentage}
                    fill={fill}
                    isDisabled={true}
                />
            </div>
        );
    };

    renderFreeTextAnswer = () => {
        return (
            <>
                {this.state.isWordCloud ? (
                    this.renderWordCloud()
                ) : (
                    <div className={styles.choices}>
                        <div className={styles.answers_container}>
                            {this.state.freeTextOrdered.map(this.renderFreeText)}
                        </div>
                        <div className={styles.card_footer}>
                            <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                            <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                        </div>
                    </div>
                )}
            </>
        );
    };

    renderNPSQuestion = () => {
        const questionReport = this.props.questionReport;
        const choices = this.props.question.choices;
        const answers = this.props.questionReport.answers;
        let total = 0;
        let orderedCount = [];
        let promoters = 0;
        let detractors = 0;
        choices.forEach((choice) => {
            orderedCount.push(answers[choice.id]);
            if (answers[choice.id]) {
                total += answers[choice.id];
            }
            const value = choice.ordinal;
            if (value >= 9 && answers[choice.id]) {
                promoters += answers[choice.id];
            } else if (value <= 6 && answers[choice.id]) {
                detractors += answers[choice.id];
            }
        });
        let netScore = Math.round(((promoters - detractors) / total) * 100);
        if (promoters === 0) {
            promoters = "0%";
            netScore = detractors;
        } else {
            promoters = `+${Math.round((promoters / total) * 100)}%`;
        }
        if (detractors === 0) {
            detractors = "0%";
            netScore = promoters;
        } else {
            detractors = `-${Math.round((detractors / total) * 100)}%`;
        }
        netScore = parseInt(netScore);
        if (!this.props.questionReport.respondents) {
            netScore = "No respondents yet";
        }

        return (
            <div className={styles.nps_content}>
                {questionReport.respondents === 0 ? (
                    <NoDataFallback />
                ) : (
                    <div className={styles.nps_container}>
                        <div className={styles.score_section}>
                            <h3>Net promoter score: {netScore}</h3>
                            <div className={styles.detail_row}>
                                <div className={styles.detail_label}>
                                    <div className={styles.detail} style={{ backgroundColor: "#8DD800" }} />
                                    <span>Promoters</span>
                                </div>
                                <span style={{ color: "grey" }}>{promoters}</span>
                            </div>
                            <div className={styles.detail_row} style={{ marginBottom: 0 }}>
                                <div className={styles.detail_label}>
                                    <div className={styles.detail} style={{ backgroundColor: "#FF5454" }} />
                                    <span>Detractors</span>
                                </div>
                                <span style={{ color: "grey" }}>{detractors}</span>
                            </div>
                        </div>
                        <div className={styles.graph_and_labels}>
                            <>
                                <div className={styles.nps_graph}>
                                    {orderedCount.map((count, index) => this.renderNPSCount(count, index, total))}
                                </div>
                            </>
                        </div>
                    </div>
                )}
                <div className={styles.card_footer}>
                    <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                    <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                </div>
            </div>
        );
    };

    renderNPSCount = (count = 0, index, total) => {
        const width = 38;
        const height = (count / total) * 100;
        const colors = {
            green: "#8DD800",
            yellow: "#FFDE54",
            red: "#FF5454",
        };
        let color;
        if (index >= 9) {
            color = colors["green"];
        } else if (index >= 7 && index < 9) {
            color = colors["yellow"];
        } else {
            color = colors["red"];
        }

        return (
            <div key={`nps${index}`} className={styles.bar_container}>
                <div className={styles.bar}>
                    <div className={styles.bar_value_wrapper}>
                        <span className={styles.bar_value}>{Math.floor((count / total) * 100) || 0}%</span>
                    </div>
                    <div
                        key={`npsCount${index}`}
                        style={{
                            backgroundColor: color,
                            margin: 0,
                            height: height,
                            width: width,
                            borderRadius: "0 0 9px 9px",
                        }}
                    />
                </div>
                <span key={`npsLabel${index}`} className={styles.label}>
                    {index}
                </span>
            </div>
        );
    };

    multiAttributeRow = (value, list, content) => {
        const colors = ["#FFBF57", "#FFDE54", "#FFF3C6", "#E5E8EA", "#CDD2D5"];
        let total = 0;
        list.forEach((v) => {
            total = total + v;
        });

        const { leftLabel: notImportant, rightLabel: veryImportant } = this.props.question.multiAttributeQuestion;
        return (
            <div key={content.id} className={styles.row}>
                <span>{value}</span>
                <div className={styles.attribute_row}>
                    {list.reverse().map((v, i) => {
                        const value = (v / total) * 100;
                        return (
                            <div
                                key={`${value}-${i}`}
                                style={{
                                    backgroundColor: colors[i],
                                    width: `${value > 0 ? value : 1.5}%`,
                                    height: "100%",
                                }}></div>
                        );
                    })}
                </div>
                <div className={styles.labels}>
                    <span>{veryImportant}</span>
                    <span>{notImportant}</span>
                </div>
            </div>
        );
    };

    renderMultiAttributeQuestion = () => {
        const questionReport = this.props.questionReport;
        let answerArray = [];
        const content = [...this.props.question.content].slice(1);
        const contentMaxIndex = content.length;
        this.props.question.content.forEach((c, i) => {
            if (i > 0) {
                answerArray.push([0, 0, 0, 0, 0]);
            }
        });
        Object.values(questionReport.answers).forEach((a, i) => {
            const cappedAnswers = a.slice(0, contentMaxIndex);
            (cappedAnswers || []).forEach((v, index) => {
                answerArray[index][v - 1] = answerArray[index][v - 1] + 1;
            });
        });

        return (
            <div className={styles.multi_attribute}>
                <div className={styles.multi_attribute_container}>
                    {questionReport.respondents === 0 ? (
                        <NoDataFallback />
                    ) : (
                        answerArray.map((a, i) => this.multiAttributeRow(this.props.question.content[i + 1].text, a, content[i]))
                    )}
                </div>

                <div className={styles.card_footer}>
                    <div className={styles.respondents}>n={this.props.questionReport.respondents}</div>
                    <img className={styles.logo} src={Logo} alt="Grey Horizontal Stickybeak Logo " />
                </div>
            </div>
        );
    };

    onFreeTextDisplayChange = () => {
        this.setState((prevState) => ({ ...prevState, isWordCloud: !prevState.isWordCloud }));
    };

    render() {
        if (!this.props.question || !this.props.questionReport) {
            return null;
        }

        const questionNumber = this.props.question.ordinal + 1;
        const questionType = this.props.question.type;
        const isFreeTextQuestion = questionType === "FREE_TEXT";
        const isChoiceQuestion =
            questionType === "SINGLE_CHOICE" ||
            questionType === "MULTI_CHOICE" ||
            questionType === "LONG_FORM_SINGLE_CHOICE" ||
            questionType === "LONG_FORM_MULTI_CHOICE" ||
            questionType === "EMOJI_RATING" ||
            questionType === "RATING" ||
            questionType === "LOVE_HATE";
        const isRankingQuestion = questionType === "RANKING";
        const isNPSQuestion = questionType === "NPS";
        const isMultiAttribute = questionType === "MULTI_ATTRIBUTE";
        const className = classnames(styles.question_card);
        return (
            <div className={className}>
                <div className={styles.card_container}>
                    <div className={styles.question_card_header}>
                        <span>{`Question ${questionNumber}`}</span>
                        <div className={styles.button_container}>
                            {isFreeTextQuestion ? (
                                <Button className={styles.free_text_button} onPress={this.onFreeTextDisplayChange}>
                                    {this.state.isWordCloud ? "Word List" : "Word Cloud"}
                                </Button>
                            ) : null}
                            <Button className={styles.question_card_button} onPress={this.onCopyPNGPress}>
                                Copy slide
                            </Button>
                            <Button className={styles.question_card_button} onPress={this.onDownloadPNGPress}>
                                Download slide
                            </Button>
                        </div>
                    </div>
                    <Card className={styles.card} id={this.props.question.id}>
                        <div className={styles.card_content}>
                            <div className={styles.header}>
                                <div className={styles.question_container}>{this.getQuestionContent()}</div>
                            </div>

                            {this.props.loading || !this.props.questionReport ? (
                                <div className={styles.loader}>
                                    <Loader />
                                </div>
                            ) : (
                                <Fragment>
                                    {isFreeTextQuestion ? this.renderFreeTextAnswer() : null}
                                    {isChoiceQuestion ? this.renderQuestionChoice() : null}
                                    {isRankingQuestion ? this.renderRankingQuestion() : null}
                                    {isNPSQuestion ? this.renderNPSQuestion() : null}
                                    {isMultiAttribute ? this.renderMultiAttributeQuestion() : null}
                                </Fragment>
                            )}
                        </div>
                    </Card>
                </div>

                <PopperMenu
                    ref={(el) => (this.popper = el)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    menuItems={this.questionMenuItems}
                />

                <RankingChoiceDialog
                    choice={this.state.selectedChoice || {}}
                    onClose={this.onRankingDialogClose}
                    dialogRef={(ref) => (this.rankingChoiceDialog = ref)}
                    question={this.props.question || {}}
                    questionReport={this.props.questionReport || {}}
                />
            </div>
        );
    }
}

export default QuestionCard = connect((state, props) => {
    return {
        questionReport: state.reports.questions[(props.question || {}).id],
    };
})(QuestionCard);
