import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";

export default class DeleteAudienceFormColDialog extends PureComponent {

	static propTypes = {
		dialogRef: PropTypes.func,
		onSubmit: PropTypes.func,
	};

	static defaultProps = {
		dialogRef: () => null,
		onSubmit: () => null,
	};

	constructor(props) {
		super(props);

		this.actions = [
			{
				text: "Cancel",
				onPress: this.hide,
				props: {
					plain: true
				}
			},
			{
				text: "Yes",
				onPress: this.onSubmit,
				props: {
					blue: true,
					loading: props.loading
				}
			}
		];
	}

	hide = () => {
		this.dialog.hide();
	};

	onSubmit = () => {
		this.props.onSubmit();
		this.dialog.hide();
	};

	render() {
		return (
			<Dialog title={"Delete Column"}
					text={"Are you sure you would like to delete this column?"}
					actions={this.actions}
					ref={(ref) => {
						this.dialog = ref;
						this.props.dialogRef(ref)
					}}/>
		)
	}
}