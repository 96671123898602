import React from "react";
import {connect} from "react-redux";
import Card from "../../../../../../../../../widgets/card/Card";
import styles from "../SummaryCard.module.scss";
import {v4 as uuid} from "uuid";
import {AccountType} from "../../../../../../../../../../types/model/StickybeakAccount";

class LinkSummary extends React.Component {

    renderRow = (label, value) => {
        return (
            <div className={styles.card_row}>
                <div className={styles.card_row_main}>
                    <h4>{label}</h4>
                    <div>
                        {
                            Array.isArray(value)
                                ? value.map((v) => <p key={uuid()}>{v}</p>)
                                : value
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderSaasDetails = () => {
        if (this.props.subscription && this.props.subscription.tier && !this.props.isEnterprise) {
            return (
                <>
                    {this.renderRow("Subscription", this.props.subscription.tier.name)}
                    {this.renderRow("Response limit", this.props.subscription.tier.maxRespondentsPerSurvey)}
                </>
            )
        }
    }

    render() {
        return (
            <>
                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Survey details</h3>
                    {this.renderRow("Name", this.props.survey.name)}
                    {this.renderRow("Questions", this.props.questions)}
                    {this.renderRow("Skip logic", this.props.skipLogicQuestions)}
                    {this.renderRow("Validating questions", this.props.validatingQuestionCount)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience details</h3>
                    {this.renderRow("Audience type", this.props.audienceType)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Account details</h3>
                    {this.renderRow("Account name", this.props.organisation.name)}
                    {this.renderSaasDetails()}
                </Card>
            </>
        );
    }
}

export default connect((state) => {
    const currentOrg = state.settings.organisation;
    const selectedAccount = (state.organisations.accounts[currentOrg] || {})

    return {
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
    }
})(LinkSummary);