import React from "react"
import Field from "redux-form/es/Field";
import ToggleSwitch from "./ToggleSwitch";


function ToggleSwitchField(props) {
	return (
		<Field {...props} component={ToggleSwitch}/>
	)
}

ToggleSwitchField.propTypes = ToggleSwitch.propTypes;

ToggleSwitchField.defaultProps = ToggleSwitch.defaultProps;

export default ToggleSwitchField