/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import fetchJSON from "./fetchJSON";
import qs from "qs";

export default function del(url, token, data, params = {}) {
    let query = "";
    if (!!Object.keys(params || {}).length) {
        query = "?" + qs.stringify(params);
    }

    return fetchJSON("delete", url + query, token, data);
}