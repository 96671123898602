import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import classnames from "classnames";
import omit from "lodash/omit";
import Loader from "../loader/Loader";
import MatButton from "@mui/material/Button";

export default function Button(props) {

    const disabled = (props.loading || props.disabled);
    const className = classnames(styles.button, {
        [styles.disabled]: disabled,
        [styles.red]: props.red,
        [styles.yellow]: props.yellow,
        [styles.plain]: props.plain,
        [styles.grey_dark]: props.greyDark,
        [styles.text_grey]: props.textGrey,
        [styles.text_white]: props.textWhite,
        [styles.border]: props.border,
        [styles.border_dark]: props.borderDark,
        [styles.border_white]: props.borderWhite,
        [styles.border_light]: props.borderLight,
        [styles.border_grey_mid]: props.borderGreyMid,
        [styles.border_blue]: props.borderBlue,
        [styles.green]: props.green,
        [styles.blue]: props.blue,
        [styles.blue_dark]: props.blueDark,
        [styles.pink]: props.pink,
        [styles.orange]: props.orange,
        [styles.peach]: props.peach,
        [styles.turquoise]: props.turquoise,
        [styles.active]: props.active,
        [styles.round]: props.round,
        [styles.white]: props.white,
    }, props.className);

    const newProps = omit(props, Object.keys(Button.defaultProps));

    const loaderColor = (props.plain) ? "#454545" : "#fff";

    const labelClassName = classnames(styles.label, props.labelClassName);

    return (
        <MatButton {...newProps}
                   className={className}
                   onClick={props.onPress}
                   disabled={!!disabled}
                   classes={{
                       label: labelClassName
                   }}>
            {props.loading ? <Loader color={loaderColor} /> : props.children}
        </MatButton>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    type: PropTypes.string,

    onPress: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    round: PropTypes.bool,

    red: PropTypes.bool,
    yellow: PropTypes.bool,
    plain: PropTypes.bool,
    greyDark: PropTypes.bool,
    textGrey: PropTypes.bool,
    textWhite: PropTypes.bool,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    borderWhite: PropTypes.bool,
    borderLight: PropTypes.bool,
    borderGreyMid: PropTypes.bool,
    borderBlue: PropTypes.bool,
    green: PropTypes.bool,
    blue: PropTypes.bool,
    blueDark: PropTypes.bool,
    pink: PropTypes.bool,
    orange: PropTypes.bool,
    peach: PropTypes.bool,
    turquoise: PropTypes.bool,
    white: PropTypes.bool,

    children: PropTypes.any,
};

Button.defaultProps = {
    className: null,
    labelClassName: null,

    onPress: null,
    active: false,
    disabled: false,
    loading: false,
    round: false,

    red: false,
    yellow: false,
    plain: false,
    greyDark: false,
    textGrey: false,
    textWhite: false,
    border: false,
    borderDark: false,
    borderWhite: false,
    borderLight: false,
    borderGreyMid: false,
    borderBlue: false,
    green: false,
    blue: false,
    blueDark: false,
    pink: false,
    orange: false,
    peach: false,
    turquoise: false,
    white: false,
};


