import React, {Component, Fragment} from "react";
import Dialog from "../../../../../../../../../../widgets/dialog/Dialog";
import Card from "../../../../../../../../../../widgets/card/Card";
import styles from "./CountryCard.module.scss"
import PropTypes from "prop-types";
import Button from "../../../../../../../../../../widgets/button/Button";
import classnames from "classnames";

export default class CountryCard extends Component {

    static propTypes = {
        top: PropTypes.string,
        header: PropTypes.string,
        summaryTemplate: PropTypes.object,
        infoDialogTitle: PropTypes.string,
        infoDialogText: PropTypes.string,

    };

    static defaultProps = {
        top: "",
        header: "",
        summaryTemplate: {},
        infoDialogTitle: "",
        infoDialogText: ""
    };

    render() {
        const className = classnames(styles.select_country_card, this.props.className, {
            [styles.selected]: this.props.isSelected,
        });

        const {audienceNumber, countryText} = this.props.summaryTemplate;

        return (
            <Fragment>
                <Card className={className}>
                    <div className={styles.top}>
                        <img src={this.props.top} alt={"Representative survey"}/>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.text}>
                            <h2>{this.props.header}</h2>
                            <p>{`Target ${audienceNumber} broadly representative people across ${countryText} by age, gender and geography.`}</p>
                        </div>

                        <div className={styles.buttons}>
                            {this.props.isSelected
                                ? <Button plain borderGreyMid disabled>Selected</Button>
                                : <Button green onPress={this.props.onSelectPress}>Select</Button>}
                            <Button plain onPress={() => this.dialog.show()}>Info</Button>
                        </div>
                    </div>
                </Card>
                <Dialog title={this.props.infoDialogTitle}
                        titleClassName={styles.info_dialog_title}
                        contentClassName={styles.info_dialog_content}
                    // text={this.props.infoDialogText}
                        actions={[{
                            text: "Okay",
                            onPress: () => this.dialog.hide(),
                            props: {
                                blue: true
                            }
                        }]}
                        actionsClassName={styles.info_dialog_button}
                        ref={ref => this.dialog = ref}>
                    {this.props.infoDialogContent}
                </Dialog>
            </Fragment>
        )
    }
}