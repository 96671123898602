/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import QuestionReport from "../../types/model/QuestionReport";
import SurveyReport from "../../types/model/SurveyReport";
import ReportAPI from "./api/reports";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import SurveyFiltering from "../../types/model/SurveyFiltering";

export enum Types {
    SET_SURVEY_REPORT = "reports:SET_SURVEY_REPORT",
    SET_QUESTION_REPORT = "reports:SET_QUESTION_REPORT",
    SET_SURVEY_FILTERING = "reports:SET_SURVEY_FILTERING",
}

export const setSurveyReport = createAction<{
    surveyId: number;
    report: SurveyReport | null;
}>(Types.SET_SURVEY_REPORT);
export const setQuestionReport = createAction<{
    questionId: number;
    report: QuestionReport | null;
}>(Types.SET_QUESTION_REPORT);
export const setSurveyFiltering = createAction<{
    surveyId: number;
    report: SurveyFiltering | null;
}>(Types.SET_SURVEY_FILTERING);

export const Actions = {
    [Types.SET_SURVEY_REPORT]: setSurveyReport,
    [Types.SET_QUESTION_REPORT]: setQuestionReport,
    [Types.SET_SURVEY_FILTERING]: setSurveyFiltering,
};
export type Actions = typeof Actions;

export const getSurveyReport = wrapper(ReportAPI.getSurveyReport, {
    onSuccess: (store, report, surveyId) => {
        store.dispatch(setSurveyReport({
            surveyId,
            report,
        }));
    }
});

export const getQuestionReport = wrapper(ReportAPI.getQuestionReport, {
    onSuccess: (store, report, questionId) => {
        store.dispatch(setQuestionReport({
            questionId,
            report,
        }));
    }
});

export const getSurveyFiltering = wrapper(ReportAPI.getSurveyFiltering, {
    onSuccess: (store, report, surveyId) => {
        store.dispatch(setSurveyFiltering({
            surveyId,
            report,
        }));
    }
});