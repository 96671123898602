import React, { Component } from "react";
import styles from "./QuotaPicker.module.scss";
import TextInputField from "../../../../../../../../../../../widgets/textInput/TextInputField";
import Error from "../../../../../../../../../../../widgets/error/Error";
import { change } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { v4 as uuid } from "uuid";
import {
    createOrUpdateEstimate,
    loadingPriceEstimate
} from "../../../../../../../../../../../../redux/actions/estimate";
import isEqual from "lodash/isEqual";
import { isAdmin } from "../../../../../../../../../../../../constants/isAdmin";
import Radio from "@mui/material/Radio";

class QuotaPicker extends Component {
    constructor(props) {
        super(props);
        this.getPriceEstimate = debounce(this.debouncedGetPriceEstimate, 250);
    }

    componentWillMount() {
        const formValues = this.props.formValues;
        if (!!formValues.genders.length && !!formValues.ageGroups.length && !!formValues.geoLocations.length) {
            this.getPriceEstimate(this.props);
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const formValues = nextProps.formValues;
        if (!isEqual(formValues, this.props.formValues)) {
            if (!!formValues.genders.length && !!formValues.ageGroups.length && !!formValues.geoLocations.length) {
                this.getPriceEstimate(nextProps);
            }
        }
    }

    onSampleSizeSelect = () => {
        this.props.dispatch(change("audience", "quotaType", "SAMPLE_SIZE"));
        this.props.dispatch(change("audience", "maxSpend", null));
    };

    onBudgetSelect = () => {
        this.props.dispatch(change("audience", "quotaType", "SPEND"));
        this.props.dispatch(change("audience", "desiredSampleSize", ""));
    };
    onSampleSizeRadioSelect = () => {
        this.props.dispatch(change("audience", "quotaType", "SAMPLE_SIZE"));
        this.props.dispatch(change("audience", "desiredSampleSize", "100"));
        this.props.dispatch(change("audience", "maxSpend", null));
    };
    onBudgetRadioSelect = () => {
        this.props.dispatch(change("audience", "quotaType", "SPEND"));
        this.props.dispatch(change("audience", "maxSpend", "100000"));
        this.props.dispatch(change("audience", "desiredSampleSize", ""));
        this.props.dispatch(change("audience", "genders", ["ALL"]));
        this.props.dispatch(change("audience", "ageGroups", [{ minAge: 18, maxAge: 65 }]));
    };

    debouncedGetPriceEstimate = (props) => {

        const formValues = props.formValues;

        const id = uuid();

        this.setState({
            loadingPricing: id,
        });

        const sanitisedAgeGroups = formValues.ageGroups.map((ageGroup) => {

            if (ageGroup.minAge > 65 || ageGroup.minAge === 65) {
                ageGroup.minAge = 65;
            }

            if (ageGroup.maxAge > 65 || ageGroup.maxAge === 65) {
                ageGroup.maxAge = 65;
            }

            return ageGroup;
        });

        const targeting = {
            maxSpend: formValues.maxSpend,
            desiredSampleSize: formValues.desiredSampleSize,
            ageGroups: sanitisedAgeGroups,
            genders: formValues.genders,
            geoLocations: formValues.geoLocations,
            detailed: formValues.detailed,
            representativePreset: null
        };

        props.dispatch(loadingPriceEstimate(true));
        props.dispatch(createOrUpdateEstimate(props.surveyId, targeting))
            .catch((e) => console.log(e))
            .finally(() => props.dispatch(loadingPriceEstimate(false)));
    };

    render() {
        return (
            <div className={styles.quota_picker}>
                <div className={styles.quota_select}>
                    <Radio className={styles.radio}
                           checked={this.props.quotaType === "SAMPLE_SIZE"}
                           disabled={!!(this.props.priceEstimate || {}).loading}
                           onChange={this.onSampleSizeRadioSelect} />
                    <span className={styles.radio_text}>I would like to hear from</span>
                    <TextInputField name={"desiredSampleSize"}
                                    className={styles.quota_select_input}
                                    onTextChange={this.onSampleSizeSelect}
                                    placeholder={"e.g. 100"}
                                    disabled={!!(this.props.priceEstimate || {}).loading}
                                    number />
                    <span className={styles.radio_text}>people</span>
                    <Error error={this.props.desiredSampleSizeError} />
                </div>
                <div className={styles.quota_select}>
                    <Radio className={styles.radio}
                           checked={this.props.quotaType === "SPEND"}
                           disabled={!!(this.props.priceEstimate || {}).loading}
                           onChange={this.onBudgetRadioSelect} />
                    <span
                        className={styles.radio_text}>Please find as many people as possible with my budget of $</span>
                    <TextInputField name={"maxSpend"}
                                    hideError
                                    className={styles.quota_select_input}
                                    onTextChange={this.onBudgetSelect}
                                    placeholder={"e.g. 2000"}
                                    disabled={!!(this.props.priceEstimate || {}).loading}
                                    dollar />
                    <span className={styles.radio_text}>USD</span>
                    <Error error={this.props.maxSpendError} />
                </div>
            </div>

        );
    }
}

QuotaPicker.propTypes = {
    className: PropTypes.string,
};

QuotaPicker.defaultProps = {
    className: null,
};

QuotaPicker = connect((state, props) => {
    const survey = state.surveys[props.surveyId];
    const formValues = ((state.form.audience || {}).values || {});
    const formValueErrors = ((state.form.audience || {}).syncErrors || {});


    return {
        orgId: state.settings.organisation,
        survey,
        formValues: formValues,
        quotaType: formValues.quotaType,
        desiredSampleSize: formValues.desiredSampleSize,
        maxSpend: formValues.maxSpend,

        desiredSampleSizeError: formValueErrors.desiredSampleSize,
        maxSpendError: formValueErrors.maxSpend,

        priceEstimate: state.estimate,
        isAdmin: isAdmin(state.user),
    };
})(QuotaPicker);

export default QuotaPicker;
