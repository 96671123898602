/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import createAction from "../helpers/v1/createAction";

export const SET_ACTIVE_ORGANISATION = "settings:SET_ACTIVE_ORGANISATION";
export const SET_CURRENCY = "settings:SET_CURRENCY";
export const SET_TIMEZONE = "settings:SET_TIMEZONE";
export const SET_SAAS_SIGN_UP = "settings:SET_SAAS_SIGN_UP";

export const PURGE_REDUX_STORE = "settings:PURGE_REDUX_STORE";

export const setActiveOrganisation = createAction(SET_ACTIVE_ORGANISATION, "orgId");
export const setCurrency = createAction(SET_CURRENCY, "currency");
export const setTimeZone = createAction(SET_TIMEZONE, "timezone");
export const setSaasSignUp = createAction(SET_SAAS_SIGN_UP, "saasSignUp")

export const purgeReduxStore = createAction(PURGE_REDUX_STORE);
