import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Dialog from "../../dialog/Dialog";

export default class DeleteSurveyRowDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
        isLive: PropTypes.bool,
    };

    static defaultProps = {
        dialogRef: () => null,
        onSubmit: () => null,
        loading: false,
    };

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.props.onSubmit();
    };

    render() {

        const actions = [
            {
                text: "Cancel",
                onPress: this.hide,
                props: {
                    plain: true
                }
            },
            {
                text: "Yes",
                onPress: this.onSubmit,
                props: {
                    blue: true,
                    loading: this.props.loading
                }
            }
        ];

        return (
            <Dialog title={"Delete question"}
                    actions={actions}
                    text={this.props.isLive ? "Are you sure you would like to delete this question? Answers for this question will not appear in the report." : "Are you sure you would like to delete this question?"}
                    disableDismiss={this.props.loading}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}/>
        );
    }
}
