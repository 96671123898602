/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import { updateUser } from "../../../redux/actions/user";
import styles from "./Profile.module.scss";
import Header from "../../widgets/header/Header";
import Button from "../../widgets/button/Button";
import {removeSnackbar, showError, showNotification, showSuccess} from "../../../redux/actions/v1/snackbars";
import {sendPasswordResetToEmail} from "../../../redux/actions/v1/user";
import TextInputField from "../../widgets/textInput/TextInputField";
import {reduxForm, reset} from "redux-form";
import validateEmail from "../../../constants/validateEmail";
import Content from "../../widgets/content/Content";

class Profile extends Component {

    state = {
        loading: false
    };

    onResetPasswordPress = () => {
        this.props.dispatch(showNotification("Sending reset link to email.", {id: "resetLinkId", duration: 1000}));
        this.props.dispatch(sendPasswordResetToEmail({
            email: this.props.user.email,
            onSuccess: () => {
                this.props.dispatch(removeSnackbar("resetLinkId"));
                this.props.dispatch(showSuccess("Reset link successfully sent to email!"));
            },
        }));
    };

    onSubmit = async (values) => {

        this.setState({
            loading: true,
        });

        this.props.dispatch(showNotification("Updating user.", {id: "updatingUser", duration: 1000}));

        try {
            await this.props.dispatch(updateUser({
                firstName: values.firstName,
                lastName: values.lastName,
                name: values.firstName + " " + values.lastName,
                email: values.email
            }));
            this.props.dispatch(removeSnackbar("updatingUser"));
            this.props.dispatch(showSuccess("User updated!"));
        } catch (err) {
            this.props.dispatch(removeSnackbar("updatingUser"));
            this.props.dispatch(showError(err.message));
        }

        this.setState({
            loading: false,
        });
    };

    onCancelPress = () => {
        this.props.dispatch(reset("profileForm"));
    };

    render() {
        return (
            <Content headerText={"Settings"}
            >
                <div className={styles.profile}>
                    <Header text={"My Profile"} medium noBorder/>
                    <div className={styles.profile_content}>
                        <form className={styles.profile_details} onSubmit={this.props.handleSubmit(this.onSubmit)}>

                            <div className={styles.row}>
                                <TextInputField name={"firstName"} label={"First Name"}/>
                                <TextInputField name={"lastName"} label={"Last Name"}/>

                            </div>
                            <div className={styles.row}>

                            <TextInputField name={"email"} label={"Email"} email/>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.row_column}>
                                    <label>Password</label>
                                    <div className={styles.password_input}>
                                        <Button plain className={styles.forgot_password_button} onPress={this.onResetPasswordPress}>Send reset password link</Button>
                                    </div>
                                </div>
                                <div className={styles.row_column}>

                                </div>
                            </div>

                            <div className={styles.row}>
                                <Button className={styles.button} plain border disabled={this.state.loading || !this.props.valid || this.props.pristine} type={"submit"}>Save</Button>
                                {/*<Button className={styles.button} plain border onPress={this.onCancelPress}>Cancel</Button>*/}
                            </div>
                        </form>
                    </div>
                </div>
            </Content>
        );
    }
}

Profile = reduxForm({
    form: "profileForm",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};


        if (!(values.firstName && values.lastName)) {
            errors.name = "Name cannot be empty"
        }

        if (!validateEmail(values.email)) {
            errors.email = "Email is badly formatted"
        }


        // if (!!props.user.phone || (!props.user.phone && !!values.phone)) {
        //
        //     const phoneValidation = isValidNumber(values.phone || "");
        //     if (!phoneValidation) {
        //         errors.phone = "Invalid phone number";
        //     }
        // }

        return errors;
    }
})(Profile);

export default Profile = connect((state) => {

    const user = state.user;

    return {
        user: user,
        initialValues: user,
        formValues: ((state.form.profileForm || {}).values || {}),
    };
})(Profile);
