import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./EditAdCopy.module.scss";
import RadioGroup from "../../../../../../../widgets/radioGroup/RadioGroup";
import FormikInput from "../../../../../../../widgets/input/FormikInput";
import { Form, Formik } from "formik";
import FormGroupheader from "../audience/components/findMyAudience/components/formGroupHeader/FormGroupheader";
import Header from "../../../../../../../widgets/header/Header";
import * as Yup from "yup";
import Button from "../../../../../../../widgets/button/Button";
import {
    createAdCreative,
    getSurveyAdCreatives,
    uploadCustomVideo
} from "../../../../../../../../redux/actions/api/surveys";
import { connect } from "react-redux";
import { showError, showSuccess } from "../../../../../../../../redux/actions/v1/snackbars";
import useDispatch from "../../../../../../../../hooks/useDispatch";
import { creativeContentTypes } from "../../../../../../../../constants/adCreativeContentTypes";
import adExample from "../../../../../../../../resources/png/ad-example.png"
import UploadImageDialog from "../../../../../../../widgets/dialogs/uploadImageDialog/UploadImageDialog";
import { UploadVideoDialog } from "../../../../../../../widgets/dialogs/uploadVideoDialog/UploadVideoDialog";

const radioGroupOptions = [
    {
        text: "1 ad",
        value: "1"
    },
    {
        text: "2 ads (A/B test)",
        value: "2"
    }
];

const contentRadioOptions = (otherSide) => {
    return (
        [
            {
                text: "Existing creative",
                value: "EXISTING"
            },
            {
                text: "Upload new",
                value: "CUSTOM_VIDEO"
            },
            {
                text: `Same creative as variant ${otherSide}`,
                value: `DUPLICATE`
            },
        ]
    )
};

const validationSchema = Yup.object().shape({
    textA: Yup.string().min(15, "Too short"),
    textB: Yup.string().min(15, "Too short"),
    creativeA: Yup.string(),
    creativeB: Yup.string(),
    headlineA: Yup.string(),
    headlineB: Yup.string(),
    descriptionA: Yup.string(),
    descriptionB: Yup.string()
});


function EditAdCopy (props, ref) {

    const [ads, setAds] = useState("1");

    const onRadioSelect = (value) => {
        setAds(value);
    };
    let dialogRef = useRef(null);
    const dispatch = useDispatch();
    const [uploadingVideo, setUploadingVideo] = useState("")
    const [urlA, setUrlA] = useState("")
    const [urlB, setUrlB] = useState("")
    const [radioA, setRadioA] = useState("EXISTING")
    const [radioB, setRadioB] = useState("EXISTING")
    const [adCreatives, setAdCreatives] = useState([]);
    const [initialValues, setInitialValues] = useState({
        textA: "",
        creativeA: "YELLOW",
        headlineA: "",
        descriptionA: "",
        textB: "",
        creativeB: "RUNNING_REUBEN",
        headlineB: "",
        descriptionB: ""

    });
    console.log(adCreatives)
    useEffect(() => {
        (async () => {
            const data = await getSurveyAdCreatives(props.surveyId);
            setAdCreatives(data);
            if ( data.length > 1 ) {
                setInitialValues({
                    textA: data[0].text,
                    creativeA: data[0].contentType,
                    headlineA: data[0].headline,
                    descriptionA: data[0].ctaText,
                    textB: data[1].text,
                    creativeB: data[1].contentType,
                    headlineB: data[1].headline,
                    descriptionB: data[1].ctaText

                });
                setAds("2");
                if ( !!data[0].video.length && data[0].contentType === "CUSTOM_VIDEO" ) {
                    setUrlA(data[0].video)
                }
                if ( !!data[1].video.length && data[1].contentType === "CUSTOM_VIDEO" ) {
                    setUrlB(data[1].video)
                }
            } else if ( data.length === 1 ) {
                setInitialValues({
                    textA: data[0].text,
                    creativeA: data[0].contentType,
                    headlineA: data[0].headline,
                    descriptionA: data[0].ctaText,
                    textB: "",
                    creativeB: "OLDIE_POLLING_STATION",
                    headlineB: "",
                    descriptionB: ""
                });
                if ( !!data[0].video.length && data[0].contentType === "CUSTOM_VIDEO" ) {
                    setUrlA(data[0].video)
                }
            } else {
                setInitialValues({
                    textA: "",
                    creativeA: "YELLOW",
                    headlineA: "",
                    descriptionA: "",
                    textB: "",
                    creativeB: "RUNNING_REUBEN",
                    headlineB: "",
                    descriptionB: ""
                });
            }
        })();
    }, []);

    const onSubmit = async (values) => {
        let dtos = [];
        switch ( adCreatives.length ) {

            case 0: {
                dtos.push({
                    text: values.textA.replaceAll("\n", "\\n"),
                    headline: values.headlineA,
                    ctaText: values.descriptionA,
                    contentType: values.creativeA,
                    survey: props.surveyId,
                    video: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                    storyVideo: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                    customStoryVideo:!!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : adCreatives[0].customStoryVideo
                });
                if ( ads === "2" ) {
                    dtos.push({
                        text: values.textB.replaceAll("\n", "\\n"),
                        headline: values.headlineB,
                        ctaText: values.descriptionB,
                        contentType: values.creativeB,
                        survey: props.surveyId,
                        video: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        storyVideo: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        customStoryVideo:!!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : adCreatives[1].customStoryVideo

                    });
                }
                break;
            }
            case 1: {
                if ( ads === "2" ) {
                    dtos.push({
                        text: values.textB.replaceAll("\n", "\\n"),
                        headline: values.headlineB,
                        ctaText: values.descriptionB,
                        contentType: values.creativeB,
                        survey: props.surveyId,
                        video: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        storyVideo: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        customStoryVideo:!!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : adCreatives[1].customStoryVideo
                    });
                    dtos.push({
                        text: values.textA.replaceAll("\n", "\\n"),
                        headline: values.headlineA,
                        ctaText: values.descriptionA,
                        contentType: values.creativeA,
                        id: adCreatives[0].id,
                        survey: props.surveyId,
                        video: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        storyVideo: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        customStoryVideo:!!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : adCreatives[0].customStoryVideo
                    });

                } else {
                    dtos.push({
                        text: values.textA.replaceAll("\n", "\\n"),
                        headline: values.headlineA,
                        ctaText: values.descriptionA,
                        contentType: values.creativeA,
                        id: adCreatives[0].id,
                        survey: props.surveyId,
                        video: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        storyVideo: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        customStoryVideo:!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : adCreatives[0].customStoryVideo
                    });
                }
                break;
            }
            case 2: {
                if ( ads === "2" ) {
                    dtos.push({
                        text: values.textB.replaceAll("\n", "\\n"),
                        headline: values.headlineB,
                        ctaText: values.descriptionB,
                        contentType: values.creativeB,
                        id: adCreatives[1].id,
                        survey: props.surveyId,
                        video: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        storyVideo: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        customStoryVideo:!!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : adCreatives[1].customStoryVideo

                    });
                    dtos.push({
                        text: values.textA.replaceAll("\n", "\\n"),
                        headline: values.headlineA,
                        ctaText: values.descriptionA,
                        contentType: values.creativeA,
                        id: adCreatives[0].id,
                        survey: props.surveyId,
                        video: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        storyVideo: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        customStoryVideo:!!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : adCreatives[0].customStoryVideo
                    });
                } else {
                    dtos.push({
                        text: values.textB.replaceAll("\n", "\\n"),
                        headline: values.headlineB,
                        ctaText: values.descriptionB,
                        contentType: values.creativeB,
                        id: adCreatives[1].id,
                        survey: props.surveyId,
                        status: "DELETED",
                        video: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        storyVideo: !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : null,
                        customStoryVideo:!!urlB.length && values.creativeB === "CUSTOM_VIDEO" ? urlB : adCreatives[1].customStoryVideo
                    });
                    dtos.push({
                        text: values.textA.replaceAll("\n", "\\n"),
                        headline: values.headlineA,
                        ctaText: values.descriptionA,
                        contentType: values.creativeA,
                        id: adCreatives[0].id,
                        survey: props.surveyId,
                        video: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        storyVideo: !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : null,
                        customStoryVideo:!!urlA.length && values.creativeA === "CUSTOM_VIDEO" ? urlA : adCreatives[0].customStoryVideo
                    });

                }
                break;
            }
            default: {
                break;
            }
        }
        console.log(dtos, "dtos")
        await createAdCreative(dtos, props.surveyId).then(
            (data) => {
                dispatch(showSuccess("Creative successfully updated."));
                setAdCreatives(data);
            },
            (e) => dispatch(showError(e.message))
        );

    };
    const isSurveyLive = (props.survey || {}).status === "LIVE";

    const setRadio = (v, side, setFieldValue, values) => {
        if ( side === "A" ) {
            if ( v === "DUPLICATE" ) {
                setFieldValue("creativeA", "CUSTOM_VIDEO")
                setUrlA(urlB)
            }
            if ( v === "EXISTING" && !!urlA.length ) {
                setFieldValue("creativeA", "YELLOW")
            }
            if ( v === "CUSTOM_VIDEO" ) {
                setFieldValue("creativeA", v)
            }
            setRadioA(v)
        } else {
            if ( v === "DUPLICATE" ) {
                setFieldValue("creativeB", "CUSTOM_VIDEO")
                setUrlB(urlA)
            }
            if ( v === "EXISTING" && !!urlB.length ) {
                setFieldValue("creativeB", "YELLOW")

            }
            if ( v === "CUSTOM_VIDEO" ) {
                setFieldValue("creativeB", v)
            }
            setRadioB(v)
        }

    }

    const showAdCreative = (id, side, values) => {
        if ( side === "A" && !!urlA.length && values.creativeA === "CUSTOM_VIDEO" ) {
            return (
                <video src={urlA} controls/>
            )
        } else if ( side === "B" && !!urlB.length && values.creativeB === "CUSTOM_VIDEO" ) {
            return (
                <video src={urlB} controls/>
            )
        }
        const creative = creativeContentTypes.find(c => c.value === id)


        if ( !!creative ) {
            if ( creative.creativeUrl.includes("mp4") ) {
                return (
                    <video src={creative.creativeUrl} controls/>
                )
            }
            return <img alt={""} src={creative.creativeUrl}/>
        }

    }

    const onUploadVideoPress = (side) => {
        setUploadingVideo(side)
        dialogRef.current.show()
    }
    const onVideoSubmit = async (formData) => {
        const fileSize = formData.get("inputFile").size / 1000 / 1000
        if ( fileSize >= 31 ) {
            dispatch(showError("File size limit is 30 MB"))
        } else {
            try{

            const url = await uploadCustomVideo(formData)
            if ( uploadingVideo === "A" ) {
                setUrlA(url)
            } else if ( uploadingVideo === "B" ) {
                setUrlB(url)
            }
            }catch(e){
                dispatch(showError(e.message()))
            }

        }

        dialogRef.current.hide();
    }


    return (
        <>

            <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit} innerRef={ref}
                    validationSchema={validationSchema}>
                {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
                    <Form>
                        <Header text={"Edit ad copy"} className={styles.header}/>
                        <div className={styles.container}>

                            {!isSurveyLive &&
                            <div className={styles.top}>
                                <FormGroupheader
                                    step={"01. "}
                                    heading={"Ad Settings"}
                                />
                                <div className={styles.settings_form}>
                                        <span>
                                            Select one:
                                        </span>
                                    <RadioGroup options={radioGroupOptions}
                                                onChange={(v) => onRadioSelect(v)} value={ads}/>
                                </div>
                            </div>
                            }
                            <div className={styles.page}>

                                <div className={styles.settings}>
                                    <FormGroupheader
                                        step={"02. "}
                                        heading={"Edit Ads"}
                                    />

                                    <div className={styles.copy_edit}>


                                        <div className={styles.ad_variant}>
                                            <h3>
                                                Ad variant A
                                            </h3>
                                            <span>
                                            Primary text
                                        </span>
                                            <FormikInput name={"textA"} className={styles.input} multiline
                                                         rows={4}
                                                         type={"text"}
                                            />
                                            <span>
                                                Ad creative
                                                </span>

                                            <RadioGroup
                                                options={radioB === "CUSTOM_VIDEO" ? contentRadioOptions("B") : contentRadioOptions("B").filter(v => v.value !== "DUPLICATE")}
                                                onChange={(v) => setRadio(v, "A", setFieldValue, values)}
                                                value={radioA}/>
                                            {radioA === "EXISTING" ?
                                                <FormikInput name={"creativeA"} className={styles.select} select
                                                             options={creativeContentTypes}/>
                                                : radioA === "CUSTOM_VIDEO" ?
                                                    <Button onPress={() => onUploadVideoPress("A")}
                                                            className={styles.upload_button}>
                                                        Upload Custom Video
                                                    </Button> : null
                                            }
                                            <div className={styles.creative_wrapper}>
                                                {showAdCreative(values.creativeA, "A", values)}
                                            </div>
                                            {values.creativeA === "CUSTOM_VIDEO" ?
                                                <>
                                                <span>
                                                    (30 MB file size limit)
                                                </span>
                                                    <span>
                                                    Video must be over 500 pixels wide for Facebook to generate story ads.
                                            </span></> : null
                                            }
                                            <span>
                                            Headline text
                                        </span>
                                            <FormikInput name={"headlineA"} className={styles.input}
                                                         rows={2}
                                                         type={"text"}
                                                         maxLength={"125"}/>
                                            <span>
                                                Description text
                                        </span>
                                            <FormikInput name={"descriptionA"} className={styles.input}
                                                         rows={2}
                                                         type={"text"}
                                                         maxLength={"125"}/>
                                        </div>

                                        <div className={styles.ad_variant}>
                                            {ads === "2" &&
                                            <>
                                                <h3>
                                                    Ad variant B
                                                </h3>
                                                <span>
                                                Primary text
                                                </span>
                                                <FormikInput name={"textB"} className={styles.input} multiline
                                                             rows={4}
                                                             type={"text"}
                                                             maxLength={"125"}/>
                                                <span>
                                                Ad creative
                                                </span>
                                                <RadioGroup
                                                    options={radioA === "CUSTOM_VIDEO" ? contentRadioOptions("A") : contentRadioOptions("A").filter(v => v.value !== "DUPLICATE")}
                                                    onChange={(v) => setRadio(v, "B", setFieldValue, values)}
                                                    value={radioB}/>
                                                {radioB === "EXISTING" ?
                                                    <FormikInput name={"creativeB"} className={styles.select} select
                                                                 options={creativeContentTypes}/>
                                                    : radioB === "CUSTOM_VIDEO" ?
                                                        <Button onPress={() => onUploadVideoPress("B")}
                                                                className={styles.upload_button}>
                                                            Upload Custom Video
                                                        </Button>
                                                        : null
                                                }
                                                <div className={styles.creative_wrapper}>
                                                    {showAdCreative(values.creativeB, "B", values)}
                                                </div>
                                                {values.creativeB === "CUSTOM_VIDEO" ?
                                                    <>
                                                <span>
                                                    (30 MB file size limit)
                                                </span>
                                                        <span>
                                                    Video must be over 500 pixels wide for Facebook to generate story ads.
                                            </span></> : null
                                                }
                                                <span>
                                                Headline text
                                        </span>
                                                <FormikInput name={"headlineB"} className={styles.input}
                                                             rows={2}
                                                             type={"text"}
                                                             maxLength={"125"}/>
                                                <span>
                                                Description text
                                        </span>
                                                <FormikInput name={"descriptionB"} className={styles.input}
                                                             rows={2}
                                                             type={"text"}
                                                             maxLength={"125"}/>
                                            </>
                                            }
                                        </div>


                                    </div>

                                </div>
                                <img src={adExample} className={styles.ad_example}/>

                            </div>
                        </div>
                        <div className={styles.button_wrapper}>
                            <Button type={"submit"} disabled={isSubmitting || !isValid} onPress={handleSubmit}>
                                Save Ad Creative
                            </Button>
                        </div>
                    </Form>


                )}
            </Formik>
            <UploadVideoDialog dialogRef={dialogRef}
                               onSubmit={onVideoSubmit}/>
        </>

    );
}

export default connect((state, props) => {
    const surveyId = props.match.params.surveyId;
    const survey = state.surveys[surveyId];
    return {
        surveyId,
        survey
    };
}, null, null, { forwardRef: true })(React.forwardRef(EditAdCopy));
