import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RenameSurveyDialog from "../../../../../../widgets/dialogs/renameSurveyDialog/RenameSurveyDialog";
import DuplicateSurveyDialog from "../../../../../../widgets/dialogs/duplicateSurveyDialog/DuplicateSurveyDialog";
import { completeSurvey } from "../../../../../../../redux/actions/v1/surveys";
import { showError, showSuccess } from "../../../../../../../redux/actions/v1/snackbars";
import styles from "./EnterpriseSurveyCard.module.scss";
import Dialog from "../../../../../../widgets/dialog/Dialog";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import GreenTickCircle from "src/resources/svg/green_tick_circle.svg";
import { SurveyStatus } from "../../../../../../../types/model/Survey";
import getSurveyCardDetails from "../../../../../../../util/getSurveyCardDetails";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

class ActiveSurveysCard extends Component {

    static propTypes = {
        onCardPress: PropTypes.func,
        index: PropTypes.number,
        survey: PropTypes.object,
        org: PropTypes.object,
    };

    static defaultProps = {
        onCardPress: () => null,
        index: null,
        survey: {},
        org: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            submitting: false,
        };
    }


    handleOptionsPress = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ anchorEl: e.target });
    };

    handleOptionsClose = () => {
        this.setState({ anchorEl: null });
    };

    onCardPress = () => {
        this.props.onCardPress(this.props.survey);
    };

    onCompletePress = () => {
        this.handleOptionsClose();
        this.completeSurveyDialog.show();
    };

    handleCompleteSurvey = () => {
        this.setState({ submitting: true });

        this.props.dispatch(completeSurvey({
            orgId: this.props.survey.account,
            surveyId: this.props.survey.id,
            onSuccess: () => {
                this.setState({ submitting: false });
                this.completeSurveyDialog && this.completeSurveyDialog.hide();
                this.props.dispatch(showSuccess("Survey marked as completed."))
            },
            onError: (err) => {
                this.setState({ submitting: false });
                this.props.dispatch(showError(err.message));
            }
        }));
    };

    onRenamePress = () => {
        this.handleOptionsClose();
        this.renameSurveyDialog.show();
    };

    onDuplicatePress = () => {
        this.handleOptionsClose();
        this.duplicateSurveyDialog.show();
    };

    renderTags = (cardDetails) => {

        let interests = "";
        (cardDetails.tags.interests || []).forEach((interest, index) => {
            if (index + 1 < cardDetails.tags.interests.length) {
                interests = interests + interest + ", ";
            } else {
                interests = interests + interest;
            }
        });

        let locations = "";
        if (cardDetails.surveyType === "National audience") {
            locations = cardDetails.representativeLocation;
        } else {
            (cardDetails.tags.locations || []).forEach((location, index) => {
                if (index + 1 < cardDetails.tags.locations.length) {
                    locations = locations + location + " ";
                } else {
                    locations = locations + location;
                }
            });
        }
        let ageGroups = "";
        (cardDetails.tags.age || []).forEach((ageGroup, index) => {
            if (index + 1 < cardDetails.tags.age.length) {
                ageGroups = ageGroups + ageGroup.replace(/\s/g, "") + ",";
            } else {
                ageGroups = ageGroups + ageGroup.replace(/\s/g, "");
            }
        });
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.tags}>
                    <span className={styles.tag_text}>
                        {locations}
                    </span>
                </div>
                <div style={{ display: "flex" }}>
                    <div className={styles.tags}>
                        <span className={styles.tag_text}>
                            {ageGroups}
                        </span>
                    </div>
                    <div className={styles.tags}>
                        <span className={styles.tag_text}>
                        {cardDetails.tags.gender}
                        </span>
                    </div>
                </div>
                {!!interests &&
                    <div className={styles.tags}>

                        <span className={styles.tag_text}>
                        Interests: {interests}
                    </span>

                    </div>
                }
            </div>
        );

    };

    render() {
        const { submitting, anchorEl } = this.state;
        const respondentCount = (this.props.survey.insights || {}).respondentsCompleted || 0;
        const popoverOpen = Boolean(this.state.anchorEl);
        const survey = this.props.survey || {};
        const { name: surveyName, startedAt, type, desiredSampleSize, status, targeting } = survey;
        const cardDetails = getSurveyCardDetails(survey, respondentCount);

        let tickStyle = {
            opacity: 0
        };
        if (status === SurveyStatus.COMPLETED) {
            tickStyle.opacity = 1;
        }

        const renderCardRow = (label, value) => (
            <div className={styles.info_row}>
                <span className={styles.label}>{label}</span>
                <span className={styles.value}>{value}</span>
            </div>
        );

        return (
            <>
                <div className={styles.active_survey_card} onClick={this.onCardPress}>

                    <div className={styles.active_survey_card_header}>
                        <div className={styles.title_wrapper}>
                            <div className={styles.title}>{surveyName}</div>
                            <div
                                className={styles.date_started}>
                                {startedAt && moment(startedAt).format("DD.MM.YYYY")}
                            </div>
                        </div>
                        <div className={styles.options_button}>
                            <MoreVertIcon onClick={this.handleOptionsPress} />
                        </div>
                    </div>

                    <div>
                        <div className={styles.progress_bar}>
                            <CircularProgressbar
                                value={cardDetails.progressBarValue}
                                strokeWidth={9}
                                text={null}
                                styles={buildStyles({
                                        textSize: "24px",
                                        pathTransitionDuration: 0.5,
                                        pathColor: `#8DD800`,
                                        textColor: `#444444`,
                                        trailColor: `rgba(205, 210, 213, 0.6)`,
                                        backgroundColor: `#ffffff`,
                                    }
                                )}
                            />

                            <div className={styles.progress_bar_content}>
                                <div className={styles.content_text}>
                                    <h2 className={styles.respondents}>
                                        {cardDetails.respondentsLine1}
                                    </h2>
                                    <span className={styles.progress_bar_bottom_text}>
                                {cardDetails.respondentsLine2}
                            </span>
                                </div>
                            </div>

                            <div className={styles.tick} style={tickStyle}>
                                <img src={GreenTickCircle} alt={""} />
                            </div>
                        </div>

                        <div className={styles.table}>
                            {renderCardRow("Survey type", cardDetails.surveyType)}
                            {renderCardRow("Time in field", cardDetails.timeElapsed)}
                            {/*{renderCardRow("Est. time remaining", cardDetails.timeRemaining)}*/}
                            {cardDetails.unchargedSurvey === true ?
                                <>{renderCardRow("Total charged", "Uncharged survey")}</> :
                                <>{renderCardRow("Total charged", cardDetails.budget)}</>}

                        </div>
                        {survey.type !== "LINK" &&
                            <div className={styles.tag_list}>
                                {this.renderTags(cardDetails)}
                            </div>
                        }
                    </div>
                </div>

                <Popover open={popoverOpen}
                         onClose={this.handleOptionsClose}
                         anchorEl={anchorEl}
                         elevation={2}>
                    {status !== SurveyStatus.COMPLETED &&
                        <MenuItem onClick={this.onCompletePress}>Mark as Completed</MenuItem>}
                    <MenuItem onClick={this.onRenamePress}>Rename</MenuItem>
                    <MenuItem onClick={this.onDuplicatePress}>Duplicate</MenuItem>
                </Popover>

                <Dialog ref={ref => this.completeSurveyDialog = ref}
                        title={"Move to Completed"}
                        text={"You're about to complete this survey. You will stop collecting responses and the results will be finalised, are you sure you want to proceed?"}
                        disableDismiss={submitting}
                        actions={[
                            {
                                text: "Cancel",
                                props: {
                                    plain: true,
                                    disabled: submitting
                                },
                                onPress: () => this.completeSurveyDialog.hide()
                            },
                            {
                                text: "Move",
                                props: {
                                    blue: true,
                                    loading: submitting
                                },
                                onPress: this.handleCompleteSurvey
                            }
                        ]} />
                <RenameSurveyDialog survey={this.props.survey}
                                    dialogRef={ref => this.renameSurveyDialog = ref} />
                <DuplicateSurveyDialog survey={this.props.survey}
                                       dialogRef={ref => this.duplicateSurveyDialog = ref} />
            </>
        );
    }
}

export default connect()(withRouter(ActiveSurveysCard));

