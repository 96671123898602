import React from "react";

export default function SettingsIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			<defs>
				<style>
					{`.settings_icon_a{fill:#4d73ed}.settings_icon_b{fill:#fff}`}
				</style>
			</defs>
			<g id="Group_16541" data-name="Group 16541" transform="translate(-1035 -281)">
				<g id="Group_16504" data-name="Group 16504" transform="translate(170 -405)">
					<g id="Group_16503" data-name="Group 16503" transform="translate(865 686)">
						<g id="Group_15230" data-name="Group 15230">
							<circle id="Ellipse_1196" cx="18" cy="18" r="18" className="settings_icon_a" data-name="Ellipse 1196"
									fillOpacity="0.0"/>
						</g>
					</g>
				</g>
				<path id="Path_6863"
					  d="M6.277 1.873a6.519 6.519 0 0 0-1.377.569L3.73 1.6a.505.505 0 0 0-.674.03l-.7.7-.7.7a.5.5 0 0 0-.03.674l.839 1.169a6.519 6.519 0 0 0-.569 1.378l-1.447.266A.5.5 0 0 0 0 7.011v2.008a.491.491 0 0 0 .449.494l1.423.24a5.928 5.928 0 0 0 .569 1.378L1.6 12.285a.5.5 0 0 0 .03.674l.7.7.7.7a.5.5 0 0 0 .674.03l1.169-.839a6.519 6.519 0 0 0 1.378.569l.24 1.408a.5.5 0 0 0 .509.458h2a.491.491 0 0 0 .494-.449l.24-1.408a6.519 6.519 0 0 0 1.378-.569l1.169.839a.505.505 0 0 0 .674-.03l.7-.7.7-.7a.5.5 0 0 0 .03-.674l-.839-1.169a6.519 6.519 0 0 0 .569-1.378l1.423-.24A.5.5 0 0 0 16 9V7a.491.491 0 0 0-.449-.494l-1.423-.24a6.519 6.519 0 0 0-.569-1.378l.841-1.173a.505.505 0 0 0-.03-.674l-.7-.7-.7-.7a.5.5 0 0 0-.674-.03l-1.169.839a6.519 6.519 0 0 0-1.378-.569L9.513.449A.5.5 0 0 0 9.019 0H7.011a.491.491 0 0 0-.494.449l-.24 1.423zM8.015 5a3 3 0 1 1-3 3 3 3 0 0 1 3-3z"
					  className="settings_icon_b" data-name="Path 6863" transform="translate(1045 291)"/>
			</g>
		</svg>
	)
}