/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import AdSet from "../../types/model/AdSet";
import { Actions, Types } from "../actions/adSets";

type State = AdSet[] | null;

const initialState: State = null;

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function adSets(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_AD_SETS: {
            const { payload } = action as ActionValueTypes<Types.SET_AD_SETS>;
            return payload!;
        }
        default: {
            return state;
        }
    }
}