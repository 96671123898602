/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Kyle on 21/01/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import {Route, Switch} from "react-router-dom"
import Overview from "./routes/overview/Overview";
import Routes from "../../../constants/routes";
import Lists from "./routes/list/List";
import AddManually from "./routes/addManually/AddManually";
import PricingDetail from "./routes/pricingDetail/PricingDetail";
import UpdateAudienceMembers from "./routes/update/UpdateAudienceMembers";

export default class Audiences extends Component {

    render() {
        return (
            <Switch location={this.props.location}>
                <Route path={Routes.Audiences.AddManually} component={AddManually}/>
                <Route path={Routes.Audiences.Update} component={UpdateAudienceMembers}/>
                <Route path={Routes.Audiences.Lists} component={Lists}/>
                <Route path={Routes.Audiences.Pricing} component={PricingDetail}/>
                <Route path={Routes.Audiences.root} component={Overview}/>
            </Switch>
        );
    }
}
