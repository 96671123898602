/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import {Currency} from "dinero.js";
import Targeting from "./Targeting";

export enum SurveyType {
    FACEBOOK = "FACEBOOK",
    AUDIENCE = "AUDIENCE",
    LINK = "LINK"
}

export enum SurveyStatus {
    TEMPLATE = "TEMPLATE",
    DRAFT = "DRAFT",
    PENDING = "PENDING",
    LIVE = "LIVE",
    COMPLETED = "COMPLETED",
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED",
}

export enum SurveyState {
    INACTIVE = "INACTIVE",// Doing nothing
    PENDING = "PENDING",
    CREATING_CAMPAIGN = "CREATING_CAMPAIGN",// Currently campaign in facebook batch pull queue
    CREATING_ADS = "CREATING_ADS",// Currently creating ads in facebook batch pull queue
    ERROR_CAMPAIGN = "ERROR_CAMPAIGN",// Error creating campaign
    ERROR_ADS = "ERROR_ADS",// Error creating ad sets, ads or ad creative.
    COMPLETED = "COMPLETED",// Finished creating facebook ads.
}

export enum RewardType {
    OPTIONAL_OXFAM_DONATION = "OPTIONAL_OXFAM_DONATION",
    PRIZE_MONEY = "PRIZE_MONEY",
}


export default interface Survey {
    id: number;
    account: number;
    name: number;
    description: string;
    imageURL: string;
    maxSpend: number;
    facebookMaxSpend: number;
    desiredSampleSize: number;
    currentCostPerRespondent: number;
    currency: Currency;
    facebookCampaignId: string;
    audienceListId: number;
    targeting: Targeting;
    type: SurveyType;
    status: SurveyStatus;
    state: SurveyState;
    insights: object;
    rewardType: number;
    freeSurveyLaunch?: boolean;
}
