import React, { Component, useRef, useState } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./SurveyRows.module.scss";
import QuestionBlock from "../questionBlock/QuestionBlock";
import { change, Field, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import CommentBlock from "../commentBlock/CommentBlock";
import {
    arrayMove,
    useSortable,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    DndContext,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay, closestCorners, MouseSensor
} from '@dnd-kit/core';
import cloneDeep from "lodash/cloneDeep";
import Dialog from "../../../../../../../../../widgets/dialog/Dialog";
import { showWarning } from "../../../../../../../../../../redux/actions/v1/snackbars";
import { QUESTION_LIMIT } from "../../../../../../../../../../constants/surveyBuilderConstants";
import { deleteCustomSurveyEnding, getSurveyEnding, } from "../../../../../../../../../../redux/actions/v1/questions";
import UpgradePromptDialog from "../../../../../../../../../widgets/dialogs/upgradePromptDialog/UpgradePromptDialog";
import RenderCustomEndingButtons from "../renderCustomEndingButtons/RenderCustomEndingButtons";
import SurveyEnding from "../surveyEnding/SurveyEnding";
import values from "lodash/values";
import Button from "../../../../../../../../../widgets/button/Button";
import classnames from "classnames";
import { updateSurveyLanguage } from "../../../../../../../../../../redux/actions/surveys";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isAdmin } from "../../../../../../../../../../constants/isAdmin";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

const SurveyRow = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging,
        isOver,
        transition,
        over,
        active
    } = useSortable({ id: props.id });

    let isComment;
    if ( !!props.question ) {
        isComment = props.question.type === "COMMENT";
    }
    const style = {
        // position: "relative",
        transform: CSS.Translate.toString(transform),
        transition: transition,
        easing: {
            duration: 1000
        }
    };
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

            {isComment ? (
                <CommentBlock
                    key={props.name}
                    name={props.name}
                    index={props.rowIndex}
                    formName={props.form}
                    survey={props.survey}
                    onAddNewRowSubmit={props.onAddNewRowSubmit}
                    onDeleteRowPress={props.onDeleteRowSubmit}/>

            ) : (

                <QuestionBlock
                    key={props.name}
                    name={props.name}
                    index={props.rowIndex}
                    formName={props.form}
                    survey={props.survey}
                    onAddNewRowSubmit={props.onAddNewRowSubmit}
                    onDeleteRowPress={props.onDeleteRowSubmit}
                    surveySaveRequired={props.surveySaveRequired}/>
            )}
        </div>
    )
}

connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const surveyEnding = state.surveyEnding.defaultEndings;
    return {
        question: selector(state, props.name),
        surveyEnding: surveyEnding
    };

})(SurveyRow);


function SurveyRows (props) {

    const confirmSortDialog = useRef();

    const [activeId, setActiveId] = useState(null)
    const [style, setStyle] = useState({})
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 60
            }

        })
    );
    const [indexes, setIndexes] = useState({ oldIndex: null, newIndex: null })
    const onCancelRowMove = () => {
        setIndexes({
            oldIndex: null,
            newIndex: null
        });
        confirmSortDialog.current.hide();
    };

    const dialogActions = [
        {
            text: "Cancel",
            onPress: onCancelRowMove,
            props: {
                plain: true
            }
        },
        {
            text: "Confirm",
            onPress: () => onRowMoveSubmit(indexes),
            props: {}
        }
    ];
    const onRowMoveSubmit = ({ oldIndex, newIndex }) => {
        // loop through questions between oldIndex & newIndex to clear skip logic
        const questions = [...props.input.value];
        const startIndex = oldIndex < newIndex ? oldIndex : newIndex;
        const endIndex = oldIndex < newIndex ? newIndex : oldIndex;
        for ( let i = startIndex ; i <= endIndex ; i++ ) {
            questions[i].choicesHaveSkipLogic = null;
            questions[i].nextQuestion = null;
            questions[i].terminatingQuestion = null;
            (questions[i].choices || []).forEach((choice) => {
                choice.nextQuestion = null;
                choice.terminatingChoice = null;
                choice.qualifyingChoice = null;
            });
        }
        const shiftedQuestions = arrayMove(questions, oldIndex, newIndex);
        confirmSortDialog.current.hide();
        props.dispatch(change("editSurvey", "questions", shiftedQuestions));
        setActiveId(null)
        return onNewOrderSubmit();
    };

    function handleDragEnd (event) {
        const { active, over } = event;
        const questions = props.questions
        if ( active.id !== over.id ) {
            const oldIndex = questions.map(q => q.id).indexOf(active.id);
            const newIndex = questions.map(q => q.id).indexOf(over.id);
            setIndexes({
                oldIndex: oldIndex,
                newIndex: newIndex
            })
            confirmSortDialog.current.show();
        }
        setActiveId(null)

    }

    function handleDragStart (event) {
        setActiveId(event.active.id)
    }

    function onNewOrderSubmit () {
        setIndexes({
            oldIndex: null,
            newIndex: null
        });
        props.onNewOrderSubmit()
    }

    return (
        <>
            <DndContext sensors={sensors} collisionDetection={rectIntersection} onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}>
                <SortableContext items={props.questions} strategy={verticalListSortingStrategy}>
                    {props.questions.map((q, i) => <SurveyRow id={q.id} key={q.id} survey={props.survey}
                                                              name={`questions[${i}]`}
                                                              formName={props.form}
                                                              rowIndex={i}
                                                              surveySaveRequired={props.surveyUnsaved}
                                                              onAddNewRowSubmit={props.onAddNewRowSubmit}
                                                              onDeleteRowSubmit={props.onDeleteRowSubmit}
                    />)}
                </SortableContext>
                <DragOverlay style={{ opacity: 0.8 }}>
                    {
                        activeId ? (
                            <QuestionBlock
                                style={{ position: "fixed" }}
                                key={activeId}
                                name={`questions[${props.questions.map(q => q.id).indexOf(activeId)}]`}
                                index={props.questions.map(q => q.id).indexOf(activeId)}
                                formName={props.form}
                                id={`dragging${activeId}`}
                                survey={props.survey}
                                onAddNewRowSubmit={props.onAddNewRowSubmit}
                                onDeleteRowPress={props.onDeleteRowSubmit}
                                surveySaveRequired={props.surveySaveRequired}/>
                        ) : null
                    }
                </DragOverlay>
            </DndContext>

            <Dialog title={"Confirm question move"}
                    text={"Any skip logic on questions affected by this will be cleared"}
                    actions={dialogActions}
                    ref={confirmSortDialog}/>
        </>
    );
};

connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const surveyEnding = state.surveyEnding.defaultEndings;
    return {
        question: selector(state, props.name),
        surveyEnding: surveyEnding
    };

})(SurveyRows);

class SortableSurveyRows extends Component {
    state = {
        editingEnding: false,
        deletingRow: false,
        loadingEnding: false,
        langMenuOpen: false,
        langMenuAnchor: null,
    };

    componentDidMount () {
        if ( this.props.isCustomEnding && this.props.upgradeRequired ) {
            this.props.dispatch(deleteCustomSurveyEnding({
                surveyId: this.props.surveyId,
                customEnding: [this.props.customEnding]
            }));
        }
    }

    onAddNewRowSubmit = (index, row) => {
        const questions = cloneDeep(this.props.input.value);

        if ( this.props.survey.type === "FACEBOOK" && questions.length >= QUESTION_LIMIT ) {
            return this.props.dispatch(showWarning(`This type of survey has a limit of ${QUESTION_LIMIT} questions, this helps keep your completion rate high`));
        }

        questions.splice((index + 1), 0, row);

        this.props.dispatch(change("editSurvey", "questions", questions));
    };

    onDeleteRowSubmit = (index) => {
        const questions = cloneDeep(this.props.input.value);
        const questionId = questions[index].id;

        // clear skip logic related to deleted question
        (questions || []).forEach(question => {
            if ( question.nextQuestion === questionId ) question.nextQuestion = null;

            if ( question.choices ) {
                let choiceSkipLogicRemains = false;
                question.choices.forEach(choice => {
                    if ( choice.nextQuestion === questionId ) choice.nextQuestion = null;
                    if ( choice.terminatingChoice || choice.qualifyingChoice ) choiceSkipLogicRemains = true;
                });

                question.choicesHaveSkipLogic = choiceSkipLogicRemains;
            }
        });

        questions.splice(index, 1);
        this.props.dispatch(change("editSurvey", "questions", questions));
    };


    onUpgradePress = () => {
        this.upgradePromptDialog.show();
    };

    onCustomiseEndingPress = () => {
        this.setState({ editingEnding: true });
    };

    onRevertToDefaultPress = async () => {
        this.setState({ loadingEnding: true });
        if ( this.props.isCustomEnding ) {
            await this.props.dispatch(deleteCustomSurveyEnding({
                surveyId: this.props.surveyId,
                customEnding: [this.props.customEnding]
            }));
        } else {
            await this.props.dispatch(change("customEnding", "values", null));
        }
        this.setState({ loadingEnding: false });
        this.setState({ editingEnding: false });
    };
    deleteContentRow = () => {
        this.setState({ deletingRow: true });
    };
    afterContentRowDelete = () => {
        this.setState({ deletingRow: false });
    };


    langOptionsOnPress = (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        this.setState({
            langMenuAnchor: target,
            langMenuOpen: true,
        });
    };

    onLangMenuClose = () => {
        this.setState({
            langMenuOpen: false,
        });
    };

    onLangChange = (language) => {
        if ( language.code === this.props.surveyLanguage ) {
            return () => {
                this.onLangMenuClose();
            };
        } else {
            return async () => {
                this.onLangMenuClose();
                await this.props.dispatch(updateSurveyLanguage(this.props.orgId, this.props.surveyId, language.language));
                this.props.dispatch(getSurveyEnding({
                    surveyId: this.props.surveyId
                }));
            };
        }
    };

    renderLangMenuItem = (language) => {
        return (
            <MenuItem key={language.id}
                      onClick={this.onLangChange(language)}
            >
                {language.language}
            </MenuItem>
        );
    };

    render () {
        const questions = this.props.input.value;
        let surveyUnsaved = false;
        const filteredEndingQuestions = (this.props.surveyEnding || []).filter((ending) => {
            if ( ending.id === -1001 || ending.id === -1002 || ending.id === -1006 || ending.id === -1007 ) {
                return ending;
            }
        });
        (questions || []).forEach(question => {
            if ( !question.id ) surveyUnsaved = true;
            question.type !== "NPS" && question.type !=="MULTI_ATTRIBUTE" && question.choices && question.choices.forEach(choice => {
                if ( !choice.id ) surveyUnsaved = true;
            });
        });


        const languageMenuClassName = classnames(styles.language_menu);

        const languageMenuButtonClassName = classnames(styles.language_filter, {
            [styles.language_filter_open]: this.state.langMenuOpen,
        });

        const languageName = (this.props.languages.find(language => language.code === this.props.surveyLanguage) || {}).language;
        const langFilterIconClassName = classnames(styles.language_filter_icon);
        return (
            <div className={styles.survey_questions}>
                <SurveyRows questions={questions}
                            {...this.props}
                            surveyUnsaved={surveyUnsaved}
                            helperClass={styles.helper}
                            distance={60}
                            lockAxis={"y"}
                            axis={"y"}
                            survey={this.props.survey}
                            onNewOrderSubmit={this.props.onNewOrderSubmit}
                            onAddNewRowSubmit={this.onAddNewRowSubmit}
                            onDeleteRowSubmit={this.onDeleteRowSubmit}
                />
                {!!questions.length && !this.props.loadingEnding && !this.state.loadingEnding &&
                <div className={styles.survey_ending}>
                    <h2 className={styles.survey_ending_header}>Survey ending</h2>
                    <div className={styles.survey_ending_inner}>
                        <div>
                            {filteredEndingQuestions.map((ending, i) => {
                                if ( ending.id === -1001 ) {
                                    return (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            {!this.props.upgradeRequired && (this.props.customEnding || this.state.editingEnding) &&
                                            <SurveyEnding
                                                {...this.props}
                                                name={`customEnding`}
                                                key={`customEnding`}
                                                rowIndex={0} index={0}
                                                survey={this.props.survey}
                                                editable={this.state.editingEnding}
                                                deletingContentRow={this.state.deletingRow}
                                                afterDelete={this.afterContentRowDelete}
                                                isCustomEnding/>
                                            }
                                            {!(this.props.surveyEnding.length === 1 && (this.props.customEnding || this.state.editingEnding)) &&
                                            <>
                                                {this.props.isAdmin && <div style={{
                                                    paddingTop: "18px",
                                                }}>
                                                    <h4>Language Selection(Admin only)</h4>
                                                    <Button
                                                        className={languageMenuButtonClassName}
                                                        labelClassName={styles.language_filter_label}
                                                        onPress={this.langOptionsOnPress}
                                                        plain
                                                        borderLight>
                                                        {languageName}
                                                        <KeyboardArrowDownIcon
                                                            className={langFilterIconClassName}/>
                                                    </Button>
                                                    <Popover
                                                        anchorEl={this.state.langMenuAnchor}
                                                        classes={{
                                                            paper: languageMenuClassName,
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        }}
                                                        onClose={this.onLangMenuClose}
                                                        open={!!this.state.langMenuOpen}
                                                    >
                                                        {this.props.languages.map(
                                                            this.renderLangMenuItem
                                                        )}
                                                    </Popover>
                                                </div>}
                                                <SurveyEnding
                                                    {...this.props}
                                                    endingQuestions={filteredEndingQuestions}
                                                    key={`${this.props.surveyId}.surveyEnding[${i}]`}
                                                    name={`${this.props.surveyId}.surveyEnding[${i}]`}
                                                    rowIndex={i}
                                                    index={i}
                                                    survey={this.props.survey}
                                                    hideFirstLine={!this.props.upgradeRequired && (this.props.customEnding || this.state.editingEnding)}
                                                />
                                            </>
                                            }
                                        </div>

                                    );
                                } else {
                                    return (
                                        <SurveyEnding
                                            {...this.props}
                                            endingQuestions={filteredEndingQuestions}
                                            key={`${this.props.surveyId}.surveyEnding[${i}]`}
                                            name={`${this.props.surveyId}.surveyEnding[${i}]`}
                                            rowIndex={i}
                                            index={i}
                                            survey={this.props.survey}
                                        />
                                    );
                                }
                            })}
                            <RenderCustomEndingButtons
                                customEndingFormValues={this.props.customEndingFormValues}
                                upgradeRequired={this.props.upgradeRequired}
                                onUpgradePress={this.onUpgradePress}
                                onCustomiseEndingPress={this.onCustomiseEndingPress}
                                onRevertToDefaultPress={this.onRevertToDefaultPress}
                                deleteContentRow={this.deleteContentRow}
                                editingEnding={this.state.editingEnding}/>
                        </div>
                    </div>
                </div>
                }
                <UpgradePromptDialog dialogRef={(ref) => this.upgradePromptDialog = ref}/>
            </div>
        );
    }
}

SortableSurveyRows = connect((state, props) => {
    const survey = state.form.editSurvey;
    const orgId = state.settings.organisation;
    const surveyId = (props.survey || {}).id;
    const surveyLanguage = (props.survey || {}).language;
    const surveyType = props.survey.type;
    const customEnding = (state.surveyEnding.customEndings || {})[props.survey.id];
    let customEndingFormValues = {};
    if ( !!state.form.customEnding ) {
        customEndingFormValues = state.form.customEnding.values;
    }
    let tier = state.organisations.accounts[orgId].subscription.tier.name;
    const isEnterprise = state.organisations.accounts[orgId].accountType === "ENTERPRISE";
    // const isEnterprise = false
    // let tier = "FREE"
    const upgradeRequired = (tier === "FREE" && surveyType !== "FACEBOOK" && !isEnterprise);
    const isCustomEnding = !!(state.surveyEnding.customEndings || {})[props.survey.id];
    return {
        surveyEnding: state.surveyEnding.defaultEndings,
        surveyId: surveyId,
        upgradeRequired: upgradeRequired,
        customEnding: customEnding,
        isCustomEnding: isCustomEnding,
        customEndingFormValues: customEndingFormValues,
        surveyType,
        surveyLanguage: surveyLanguage,
        orgId: orgId,
        isAdmin: isAdmin(state.user),
    };
})(SortableSurveyRows);

export default class SortableSurveyRowsField extends Component {

    render () {
        return (
            <Field {...this.props} component={SortableSurveyRows}/>
        );
    }
}
