import React from "react";
import styles from "./RatingView.module.scss";
import starOutline from "../../../../../../../../../resources/png/rating/starBlueOutline@2x.png";
import star from "../../../../../../../../../resources/png/rating/starFilledBlue@2x.png";

interface RatingViewProps {
    questionType: string;
    image: string;
    selectedImage?: string;
    numStars: number;
}

const RatingView = ({ questionType, image, selectedImage, numStars }: RatingViewProps) => {
    if (questionType === "RATING") {
        return (
            <div className={styles.starContainer}>
                {Array.from({ length: 5 }, (_, currentNum) => {
                    if (currentNum < numStars) {
                        return <img key={`${currentNum}${selectedImage}`} src={star} alt="Blue Star" width={27} height={27} />;
                    } else {
                        return <img key={`${currentNum}${image}`} src={starOutline} alt="Blank Star" width={27} height={27} />;
                    }
                })}
            </div>
        );
    } else if (questionType === "EMOJI_RATING") {
        const splitUrl = image ? image?.split("/") : undefined;
        const emojiImgSrc = splitUrl?.[splitUrl?.length - 1];
        return (
            <div className={styles.emoji_container}>
                <img src={image ?? emojiImgSrc} alt="Emoji" width={50} />
            </div>
        );
    }

    return null;
};

export default RatingView;
