import clone from "lodash/clone";
import orderBy from "lodash/orderBy";
import values from "lodash/values";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Routes from "../../../../../../../constants/routes";
import {
    getQuestionReport, getSurveyFiltering,
    getSurveyReport,
    setQuestionReport
} from "../../../../../../../redux/actions/reports";
import {showError} from "../../../../../../../redux/actions/v1/snackbars";
import ShareableLinkSurveyDialog
    from "../../../../../../widgets/dialogs/shareableLinkSurveyDialog/ShareableLinkSurveyDialog";
import Header from "../../../../../../widgets/header/Header";
import Loader from "../../../../../../widgets/loader/Loader";
import SurveyToolbar from "./components/surveyToolbar/SurveyToolbar";
import QuestionCard from "./components/questionCard/QuestionCard";
import styles from "./Report.module.scss";
import ReportDownload from "./ReportDownload";
import UpgradeSaasPlan from "./components/upgradeSaasPlan/UpgradeSaasPlan";
import ReportSideBar from "./components/sideBar/ReportSideBar";
import Dialog from "../../../../../../widgets/dialog/Dialog";

class Report extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingSurveyReport: {},
            loadingDemographicFiltering: true,
            filter: {},
            showCompletedOnly: true,
            respondents: 0,
            demographicSelection: {}
        };
    }

    componentWillMount() {
        this.getSurveyFilteringOnMount();
        this.getQuestionsReport();
        this.props.dispatch(getSurveyReport(this.props.surveyId))
            .then((response) => {
                this.setState({respondents: response.respondentsCompleted});
            });
    }

    onDownloadReportPress = (e) => {
        this.reportDownload.onDownloadReportPress(e.target);
    };

    getQuestionsReport = (showCompletedOnly, demographicResponse) => {

        if (showCompletedOnly === undefined) {
            showCompletedOnly = this.state.showCompletedOnly;
        }

        let respondentsPerDemographicFilter = (demographicResponse || {}).respondentsList
        if (respondentsPerDemographicFilter !== undefined && respondentsPerDemographicFilter.length === 0) {
            respondentsPerDemographicFilter = [0]
        }

        const params = {
            showCompletedOnly: showCompletedOnly,
            respondentsPerDemographicFilter: respondentsPerDemographicFilter
        };

        Object.keys(this.state.filter)
            .map((questionId) => {
                const qId = `q-${questionId}`;
                const choiceIds = Object.keys((this.state.filter[questionId] || {}))
                    .join(",");

                if (choiceIds.length !== 0) {
                    params[qId] = choiceIds;
                }
                return null;
            });

        const activeQuestions = (this.props.activeQuestions || []);

        if (activeQuestions.length) {
            this.setState({
                showCompletedOnly: showCompletedOnly,
                loadingSurveyReport: activeQuestions.length,
            }, () => {
                activeQuestions.forEach((question) => {
                    this.props.dispatch(setQuestionReport({
                        questionId: question.id,
                        report: null,
                    }));
                    this.props.dispatch(getQuestionReport(question.id, params))
                        .then(this.onSurveyReportSuccess)
                        .catch(this.onError);
                });
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    };

    onSurveyReportSuccess = () => {
        this.setState((state) => {
            const loadingSurveyReport = clone(state.loadingSurveyReport) - 1;
            return {
                loading: false,
                loadingSurveyReport: loadingSurveyReport,
                loadingDemographicFiltering: false
            };
        });
    };

    onError = (err) => {
        this.props.dispatch(showError(err.message));
    };

    onClosePress = () => {
        this.props.history.push(Routes.Surveys.root);
    };
    getRespondents = () => {
        this.props.dispatch(getSurveyReport(this.props.surveyId))
            .then((response) => {
            });
    };

    getSurveyFilteringOnMount = () => {
        this.props.dispatch(getSurveyFiltering(this.props.surveyId))
            .then((response) => {
                this.setState({
                    loadingDemographicFiltering: false,
                });
            });
    };

    getSurveyFiltering = () => {
        let selection = this.state.demographicSelection

        const genderChoices = Object.keys(selection["Genders"] || {}).join(",");
        const ageChoices = Object.keys(selection["Ages"] || {}).join(",");
        let params = {
            genders: genderChoices,
            ages: ageChoices
        }
        for (const key of Object.keys(params)) {
            if (params[key] === "") {
                delete params[key];
            }
        }
        this.props.dispatch(getSurveyFiltering(this.props.surveyId, params))
            .then((response) => {
                this.getQuestionsReport(undefined, response);
            });
    };

    renderReport = () => {
        const buttons = [];
        if (this.props.survey && (this.props.survey.type === "AUDIENCE" || this.props.survey.type === "LINK") && this.props.survey.status !== "COMPLETED") {
            buttons.push(
                {
                    text: "Share",
                    onPress: this.onShareSurveyPress,
                    props: {
                        green: true,
                    },
                },
            );
        }

        const questions = orderBy((this.props.activeQuestions || []), ["ordinal"], ["asc"])
            .map(this.renderQuestionCard);

        let tier = this.props.organisation.subscription.tier.name;
        let maxRespondents = this.props.organisation.subscription.tier.maxRespondentsPerSurvey;
        // let maxRespondents;
        // const tier = "Unlimited";
        if (tier === "Unlimited") {
            maxRespondents = 100000;
        }

        let respondents = this.state.respondents;
        return (
            <div className={styles.main}>
                <div className={styles.content}>
                    <Header text={this.props.survey.name} className={styles.header}
                            buttons={buttons}/>
                    {this.props.survey.type !== "FACEBOOK" && respondents > maxRespondents && !this.props.isEnterprise && (
                        <UpgradeSaasPlan respondents={respondents} activeQuestions={this.props.activeQuestions || []}
                                         tier={tier}/>
                    )}
                    <div className={styles.question_cards_wrapper} id={"report"}>
                        <div className={styles.question_cards}>
                            {questions}
                        </div>
                    </div>
                </div>

                <ReportSideBar
                    surveyId={this.props.surveyId}
                    enterprise={this.props.isEnterprise}
                    respondents={respondents}
                    getQuestionsReport={this.getQuestionsReport}
                    onFilterSelect={this.onFilterSelect}
                    onFilterClear={this.onFilterClear}
                    demographicSelection={this.state.demographicSelection}
                    loading={this.state.loadingDemographicFiltering}
                    showCompletedOnly={this.state.showCompletedOnly}/>

            </div>
        );
    };

    onFilterClear = (filter) => {
        let state = {...this.state.demographicSelection};
        delete state[filter];
        this.setState({
            demographicSelection: state,
            loadingDemographicFiltering: true
        }, this.getSurveyFiltering);
    }

    onFilterSelect = (demographicName, demographic) => {
        const demographicFilter = ({...this.state.demographicSelection[demographicName]} || {});
        if (!!demographicFilter[demographic]) {
            delete demographicFilter[demographic];
        } else {
            demographicFilter[demographic] = true;
        }
        this.setState({
            filter: {},
            demographicSelection: {
                ...this.state.demographicSelection,
                [demographicName]: demographicFilter,
            },
            loadingDemographicFiltering: true
        }, this.getSurveyFiltering);
    }

    onShareSurveyPress = () => {
        if (this.shareSurveyDialog) {
            this.shareSurveyDialog.show();
        }
    };

    renderQuestionCard = (question) => {
        return (
            <QuestionCard key={question.id}
                          loading={this.state.loading || !!this.state.loadingSurveyReport[question.id]}
                          survey={this.props.survey}
                          question={question}
                          questionFilter={(this.state.filter[question.id] || {})}
                          onChoiceSelect={this.onChoiceSelect}/>
        );
    };

    onChoiceSelect = (questionId, choiceId) => {
        this.setState({
            demographicSelection: {}
        }, this.getSurveyFilteringOnMount);

        const questionFilter = ({...this.state.filter[questionId]} || {});
        if (!!questionFilter[choiceId]) {
            delete questionFilter[choiceId];
        } else {
            questionFilter[choiceId] = true;
        }

        this.setState({
            filter: {
                ...this.state.filter,
                [questionId]: questionFilter,
            },
        }, this.getQuestionsReport);
    };

    onEditSurveyPress = () => {
        this.props.history.push(Routes.Surveys.Survey.root.replace(":surveyId", this.props.surveyId));
    };

    render() {
        const loading = this.state.loadingSurveyReport !== 0;
        const buttons = [
            {
                text: "Edit Survey",
                onPress: this.onEditSurveyPress,
                props: {
                    plain: true,
                    borderDark: true
                },
            },
            {
                text: "Download Report",
                onPress: this.onDownloadReportPress,
                props: {
                    plain: true,
                    borderDark: true,
                    loading: loading,
                },
            },
            {
                text: "Close",
                onPress: this.onClosePress,
                props: {
                    greyDark: true,
                },
            }
        ];

        return (
            <div className={styles.report}>

                <SurveyToolbar survey={this.props.survey}
                               organisation={this.props.organisation}
                               buttons={buttons}/>

                {this.state.loading || !this.props.survey ?
                    <div className={styles.loader_wrapper}>
                        <Loader large/>
                    </div> : this.renderReport()}

                <ReportDownload wrappedComponentRef={(ref) => this.reportDownload = ref}
                                loading={loading}/>

                <ShareableLinkSurveyDialog
                    dialogRef={(ref) => this.shareSurveyDialog = ref}
                    accountId={this.props.orgId}
                    surveyId={this.props.surveyId}
                />
            </div>
        );
    }
}

export default Report = connect((state, props) => {

    const orgId = state.settings.organisation;
    const surveyId = props.match.params.surveyId;
    const survey = state.surveys[surveyId];
    const isEnterprise = state.organisations.accounts[orgId].accountType === "ENTERPRISE"
    const questions = state.surveyQuestions[surveyId];
    const activeQuestions = values(questions)
        .filter((question) => {
            return question.status === "ACTIVE";
        });
    const filtering = state.reports.filtering[surveyId];
    return {
        user: state.user,
        orgId: orgId,
        organisation: state.organisations.accounts[orgId],
        surveyId: surveyId,
        survey: survey,
        isEnterprise: isEnterprise,
        questions: questions,
        activeQuestions: activeQuestions,
        filtering: filtering
    };
})(Report);
