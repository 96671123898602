/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-05-14.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import PropTypes from "prop-types";
import styles from "./HollyBallSpin.module.scss";
import classnames from "classnames";

export default function HollyBallSpin(props) {
    const className = classnames(styles.holly_ball_spin, props.className);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="91.594" height="138.578" viewBox="0 0 91.594 138.578" className={className}>
            <defs>
                <clipPath id="a">
                    <path d="M35.135,59.854C22.778,64.6,8.05,56.405,2.3,41.462s-.359-30.893,12-35.635c27.876-10.7,51.8-6.035,57.548,8.909S65.669,48.143,35.135,59.854Z"/>
                </clipPath>
                <clipPath id="d">
                    <circle className={styles.a} cx="26.496" cy="26.496" r="26.496" transform="translate(0 0)"/>
                </clipPath>
            </defs>
            <g transform="translate(-820.933 -1039.098)">
                <g transform="translate(816.835 1089.036)">
                    <path className={styles.b}
                          d="M.721,19.147a.713.713,0,0,1-.148-.04.85.85,0,0,1-.524-1.087,8.437,8.437,0,0,0,.108-3.483.846.846,0,0,1,.655-.979l12.112-2.749L11.107,1.468A.853.853,0,1,1,12.291.238l2.955,10.44a.843.843,0,0,1,.228.842.859.859,0,0,1-.632.6l-12.9,2.926a9,9,0,0,1-.279,3.529A.862.862,0,0,1,.721,19.147Z"
                          transform="translate(79.345 18.342) rotate(129)"/>
                    <g transform="translate(87.039 97.782) rotate(-169)">
                        <path className={styles.c}
                              d="M65.091,5.6c0,1.581-3.161,2.946-8.334,4.023-6.107,1.293-15.087,2.084-25.074,2.084-8.765,0-16.165-1.221-21.913-1.652C3.449,9.627,0,7.328,0,5.6c0-1.8,5.245-3.377,11.783-4.6C17.243,0,24,0,32.546,0,42.82,0,49.142.862,57.62,1.581,61.715,2.012,65.091,4.1,65.091,5.6Z"
                              transform="matrix(0.978, -0.208, 0.208, 0.978, 0, 13.533)"/>
                        <g transform="matrix(0.819, -0.574, 0.574, 0.819, 53.435, 53.657)">
                            <path className={styles.b}
                                  d="M6.466,35.06h0a1.167,1.167,0,0,1-1.078-1.15C6.682,13.363.216,1.724.144,1.581A1.011,1.011,0,0,1,.575.144,1.011,1.011,0,0,1,2.012.575c.287.5,6.825,12.285,5.532,33.552A1.036,1.036,0,0,1,6.466,35.06Z"
                                  transform="translate(0 0)"/>
                        </g>
                        <path className={styles.b} d="M35.135,59.854C22.778,64.6,8.05,56.405,2.3,41.462s-.359-30.893,12-35.635c27.876-10.7,51.8-6.035,57.548,8.909S65.669,48.143,35.135,59.854Z"
                              transform="translate(4.713 8.395)"/>
                        <path className={styles.d} d="M13.722,1.243l.216.287a2.837,2.837,0,0,1-.359,3.664L5.819,12.954,0,4.4,10.274.165A3.035,3.035,0,0,1,13.722,1.243Z"
                              transform="translate(49.188 46.818)"/>
                        <path className={styles.e} d="M1.078,5.317,5.819,8.55,0,0Z" transform="translate(49.188 51.294)"/>
                        <path className={styles.f}
                              d="M0,.5V.359A.4.4,0,0,1,.431,0a3.757,3.757,0,0,1,2.8,1.365,3.669,3.669,0,0,1,.431,2.8.447.447,0,0,1-.431.287.447.447,0,0,1-.287-.431h0a2.8,2.8,0,0,0-.287-2.155A3.133,3.133,0,0,0,.431.79C.144.718,0,.647,0,.5Z"
                              transform="translate(61.761 36.637)"/>
                        <g transform="translate(73.256 11.501)">
                            <path className={styles.a} d="M1.724,0s5.6,1.724,7.328,6.394c1.724,4.6-3.951,3.3-4.526,3.592C3.88,10.2,1.078,12.429.647,9.7S0,1.581,0,1.581Z"
                                  transform="translate(6.035 9.977)"/>
                            <path className={styles.a} d="M7.687,8.971s2.012-3.592.79-6.753S5.1-.01,4.023.709C2.946,1.5,0,1.068,0,2.72S4.957,8.612,5.891,10.48C6.9,12.348,7.687,8.971,7.687,8.971Z"
                                  transform="translate(0 0)"/>
                            <path className={styles.a} d="M2.375,3.8s0-3.377-.934-3.736S-.642.926.435,2.219A15.055,15.055,0,0,1,2.016,4.446Z" transform="translate(4.594 4.309)"/>
                            <path className={styles.a} d="M0,0A19.12,19.12,0,0,1,2.155,3.736C2.371,4.814.647,3.664.359,2.155.072.575,0,0,0,0Z" transform="translate(7.903 10.911)"/>
                            <path className={styles.a} d="M2.608,3.735C1.458,4.166.6,4.095.165,2.945A2.114,2.114,0,0,1,1.243.143,2,2,0,0,1,3.9,1.436C4.4,2.586,3.757,3.3,2.608,3.735Z"
                                  transform="translate(5.223 7.894)"/>
                        </g>
                        <g transform="translate(56.516 39.008)">
                            <g transform="translate(0 0)">
                                <path className={styles.g} d="M0,1.581A2.209,2.209,0,0,1,1.006,0" transform="translate(0)"/>
                            </g>
                            <path className={styles.g} d="M1.15,0A2.524,2.524,0,0,0,0,1.078" transform="translate(1.006 0.431)"/>
                        </g>
                        <path className={styles.h} d="M.069,4.257C-.362,3.251,1.29,1.311,3.158.521c1.509-.647,3.736-.79,4.167.144S6.248,3.754,4.308,4.544C2.656,5.191.428,5.191.069,4.257Z"
                              transform="translate(51.059 41.146)"/>
                        <g transform="translate(4.713 8.395)">
                            <g className={styles.i} transform="translate(0 0)">
                                <path className={styles.h} d="M.033,3.307c.359,1.006,2.874,1.365,4.742.718,1.581-.5,3.233-1.94,3.017-2.874C7.5.146,4.918-.357,2.978.289,1.4.792-.255,2.3.033,3.307Z"
                                      transform="translate(55.147 46.345)"/>
                            </g>
                            <g className={styles.i} transform="translate(0 0)">
                                <path className={styles.f}
                                      d="M3.017,4.749a.545.545,0,0,1-.144-.287,3.743,3.743,0,0,0-.647-3.017A2.546,2.546,0,0,0,.431.8.455.455,0,0,1,0,.438.455.455,0,0,1,.359.007,3.225,3.225,0,0,1,2.8.869a4.182,4.182,0,0,1,.862,3.664.4.4,0,0,1-.431.359C3.089,4.893,3.017,4.821,3.017,4.749Z"
                                      transform="translate(62.436 41.742)"/>
                            </g>
                        </g>
                        <path className={styles.e} d="M14.01,1.57,2.946,8.467,0,4.516S9.986.421,10.274.277A2.792,2.792,0,0,1,14.01,1.57Z" transform="translate(49.116 46.85)"/>
                        <g transform="translate(4.713 8.395)">
                            <g className={styles.i} transform="translate(0 0)">
                                <path className={styles.f} d="M7.835,0s.287,4.383-2.8,4.239A9.518,9.518,0,0,1,.292,2.874s-2.084,9.7,4.885,15.231S29.03,17.1,29.03,17.1Z"
                                      transform="translate(60.708 3.671)"/>
                            </g>
                        </g>
                        <g transform="translate(40.506 43.055) rotate(25)">
                            <path className={styles.j}
                                  d="M4.489,35.456h0a1.121,1.121,0,0,0,.79-1.365C-.4,14.262,3.411,1.545,3.483,1.4A1.138,1.138,0,0,0,2.764.037,1.138,1.138,0,0,0,1.4.755c-.144.575-4.023,13.507,1.8,33.911A1.221,1.221,0,0,0,4.489,35.456Z"
                                  transform="translate(0 0)"/>
                        </g>
                        <path className={styles.h} d="M1.132.071c.862-.359.431.718.79,1.652.359.862,1.293,1.293.431,1.581A1.755,1.755,0,0,1,.126,2.3,1.7,1.7,0,0,1,1.132.071Z"
                              transform="translate(46.332 55.39)"/>
                        <path className={styles.e} d="M.546,0S.331,2.658,1.839,3.52C3.42,4.383-1.609,3.449.546,0Z" transform="translate(44.619 56.323)"/>
                    </g>
                    <path className={styles.b}
                          d="M.721,17.887a.714.714,0,0,1-.148-.04A.85.85,0,0,1,.049,16.76a8.437,8.437,0,0,0,.108-3.483A.846.846,0,0,1,.812,12.3L12.924,9.549,7.343,1.468A.853.853,0,1,1,8.527.238l6.719,9.18a.843.843,0,0,1,.228.842.859.859,0,0,1-.632.6l-12.9,2.926a9,9,0,0,1-.279,3.529A.862.862,0,0,1,.721,17.887Z"
                          transform="translate(84.867 35.794) rotate(-155)"/>
                </g>
                <g transform="translate(837.618 1039.098)">
                    <circle className={styles.a} cx="26.496" cy="26.496" r="26.496" transform="translate(52.019 62.13) rotate(-169)"/>
                    <g className={styles.k} transform="translate(52.019 62.13) rotate(-169)">
                        <g transform="translate(-10.351 -5.468)">
                            <path className={styles.l}
                                  d="M28.872,50.041s-12,8.144-22.948,6.261-3.6-8.347-2.4-12.349-.507-9.51.7-9.816,5.8,3.933,5.8,3.933,2.339-1.525,2.746-3.633,1.122-7.9-1.536-7.9-8.095,1.373-8.731-1.228-3.792-7.828,1.538-9.143S8.043,0,8.043,0L29.184,20.122,14.831,28.151s-.007,6.822,1.683,8.658,8.849-4.82,8.849-4.82-3.839,19.568-8.745,22.234S28.872,50.041,28.872,50.041Z"
                                  transform="translate(40.73 0)"/>
                            <path className={styles.l} d="M3.759,10.722,5.217,1.376,1.675,0,0,8.32Z" transform="translate(17.509 44.645)"/>
                            <path className={styles.l}
                                  d="M14.595,45.28s-.235-7.227,3.138-7.7a11.9,11.9,0,0,0,5.591-2.363L19.6,30.553l-4.918-.37L14.329,34.8,9.307,32.553l1.42-7.175s8.676,2.482,11.064,1.95,6.035-5.274,6.035-5.274l-5.165-7.2s-.685-5.621-1.249-6.424S23.539,0,23.539,0L0,24.039,2.165,47.7,17.35,59.456Z"
                                  transform="translate(0 3.388)"/>
                        </g>
                    </g>
                </g>
                <path className={styles.m} d="M3238.862,67.456c4.289-3.693,7.893-6.061,11.288-17.966C3249.739,61.181,3243.561,66.137,3238.862,67.456Z" transform="translate(-2348.221 1028.428)"/>
                <path className={styles.m} d="M0,10.276C4.522,7.573,4.514,6.809,6.457,0,6.221,6.687,5.194,9.2,0,10.276Z" transform="translate(841.298 1052.934) rotate(-173)"/>
                <path className={styles.m} d="M3111.337-8.753s2.389-11.925,12.894-16.371a14.44,14.44,0,0,0-12.093,10.465A30.208,30.208,0,0,0,3111.337-8.753Z"
                      transform="translate(-2275.255 1067.939)"/>
                <path className={styles.m} d="M.115,8.942S1.641,1.853,8,0C4.325.459,2.927,1.12,1.408,3.02A7.975,7.975,0,0,0,.115,8.942Z" transform="translate(904.445 1095.785) rotate(177)"/>
            </g>
        </svg>
    );
}

HollyBallSpin.propTypes = {
    className: PropTypes.string,
};

HollyBallSpin.defaultProps = {
    className: null,
};