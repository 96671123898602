import classnames from "classnames";
import values from "lodash/values";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import isPath from "../../../../../constants/isPath";
import Routes from "../../../../../constants/routes";
import { getAudiencesForOrganisation } from "../../../../../redux/actions/v1/audiences";
import { getSurveyQuestions } from "../../../../../redux/actions/v1/questions";
import { showError } from "../../../../../redux/actions/v1/snackbars";
import { getSurvey } from "../../../../../redux/actions/v1/surveys";
import Button from "../../../../widgets/button/Button";
import Dialog from "../../../../widgets/dialog/Dialog";
import Loader from "../../../../widgets/loader/Loader";
import Audience from "./routes/editor/audience/Audience";
import Score from "./routes/editor/score/Score";
import Report from "./routes/report/Report";
import Summary from "./routes/editor/summary/Summary";
import SurveyForm from "./routes/editor/surveyForm/SurveyForm";
import styles from "./Survey.module.scss";
import { QUESTION_LIMIT } from "../../../../../constants/surveyBuilderConstants";
import validateSurveyQuestions from "../../../../../util/validateSurveyQuestions";
import SurveyScorePriceChangeDialog
    from "../../../../widgets/dialogs/surveyScorePriceChangeDialog/SurveyScorePriceChangeDialog";
import { getEstimate } from "../../../../../redux/actions/estimate";
import isRepresentativeSurvey from "../../../../../util/isRepresentativeSurvey";
import ReportPdf from "./routes/report/ReportPdf"
import { getSaasTiers } from "../../../../../redux/actions/v1/saas";
import { getOrganisationCards } from "../../../../../redux/actions/v1/organisations";
import sendEventToActiveCampaign from "../../../../../redux/actions/api/v1/eventTracking";
import EditAdCopy from "./routes/editor/editAdCopy/EditAdCopy";

class Survey extends Component {

    constructor (props) {
        super(props);

        const hasSurvey = !!props.survey;
        this.dialogRef = React.createRef();
        this.editAdCopyForm = React.createRef();

        this.state = {
            loadingSurvey: !hasSurvey,
            loadingQuestions: true,
            loadingAudiences: false,
            saving: false,
            requestedPath: null
        };

        if ( !hasSurvey ) {
            this.props.dispatch(getSurvey({
                orgId: props.orgId,
                surveyId: props.surveyId,
                onSuccess: (survey) => {
                    if ( survey.type === "AUDIENCE" ) {
                        this.getAudiences();
                    }

                    if ( !isRepresentativeSurvey(survey) && survey.type === "FACEBOOK" ) {
                        this.props.dispatch(getEstimate(props.orgId, props.surveyId)).then((r) => {
                            this.setState({
                                loadingSurvey: false
                            });
                        }).catch((e) => {
                            this.setState({
                                loadingSurvey: false
                            });
                        });
                    } else {
                        this.setState({
                            loadingSurvey: false
                        });
                    }
                },
                onError: this.onError,
            }));
        }

        props.dispatch(getSurveyQuestions({
            surveyId: props.surveyId,
            onSuccess: () => {
                this.setState({
                    loadingQuestions: false,
                });
            },
            onError: this.onError,
        }));

        if ( hasSurvey ) {
            if ( props.survey.type === "AUDIENCE" ) {
                this.getAudiences();
            }
        }
    }

    getAudiences = () => {
        this.props.dispatch(getAudiencesForOrganisation({
            orgId: this.props.orgId,
            onSuccess: () => {
                this.setState({
                    loadingAudiences: false,
                });
            },
            onError: this.onError,
        }));
    };
    componentDidMount = async () => {
        await this.props.dispatch(getSaasTiers())
        await this.props.dispatch(getOrganisationCards({ orgId: this.props.orgId }));

    }

    onError = (err) => {
        this.props.dispatch(showError(err.message));
        this.setState({
            loadingSurvey: false,
            loadingQuestions: false,
            saving: false,
        });
    };

    renderToolbarButton = (text, path) => {
        const selected = isPath(this.props.location.pathname, path);

        const className = classnames(styles.toolbar_button, {
            [styles.toolbar_button_selected]: selected,
        });
        const exceedingQuestionLimit = (this.props.survey.type === "FACEBOOK" && (this.props.activeQuestions.length > QUESTION_LIMIT));
        let isDisabled = (exceedingQuestionLimit && (path.includes("score") || path.includes("summary")));
        return (
            <Button plain className={className} disabled={isDisabled}
                    onPress={this.onToolbarButtonPress(path)}>{text}</Button>
        );
    };


    onSaveSurvey = () => {
        //todo cleaner way of accessing these class methods from the ref

        return this.surveyForm.ref.current.wrapped.current.onSavePress();
    };

    onSaveAdCreative = () => {
        return this.editAdCopyForm.handleSubmit();

    }

    onSaveButtonPress = () => {
        if ( validateSurveyQuestions(this.surveyForm.ref.current.wrapped.current.props.formQuestions) ) {
            return this.onSaveSurvey();
        } else if ( !validateSurveyQuestions(this.surveyForm.ref.current.wrapped.current.props.formQuestions) ) {
            this.props.dispatch(showError("Your survey has incomplete questions."));
        }
    };

    onToolbarButtonPress = (path) => {
        const location = this.props.history.location.pathname;
        const saveSurvey = !(location.includes("audience") || location.includes("score") || location.includes("summary") || location.includes("ads"));
        const saveAdCreative = location.includes("ads");
        return async () => {
            this.setState({ requestedPath: path });
            if ( path.includes("summary") && !!this.props.estimate.prevEstCostPerRespondent && this.props.survey.type === "FACEBOOK" && !isRepresentativeSurvey(this.props.survey) ) {
                if ( saveAdCreative ) {
                    await this.onSaveAdCreative()
                }
                return this.priceDialog.show();
            }

            if ( saveSurvey ) {
                if ( !validateSurveyQuestions(this.surveyForm.ref.current.wrapped.current.props.formQuestions) ) {
                    this.dialogRef.show();

                } else if ( validateSurveyQuestions(this.surveyForm.ref.current.wrapped.current.props.formQuestions) ) {
                    await this.onSaveSurvey();
                    this.props.history.push(path);
                }
            } else if ( saveAdCreative ) {
                await this.onSaveAdCreative();
                this.props.history.push(path);
            } else {
                if ( location.includes("score") ) {
                    sendEventToActiveCampaign(this.props.user.email, "Continue_from_score", "Pressed continue from score");
                } else {
                    sendEventToActiveCampaign(this.props.user.email, "Continue_from_survey_builder", "Pressed continue from survey builder");
                }
                this.props.history.push(path);
            }
        };
    };

    onClosePress = () => {
        const isSurveyLive = (this.props.survey || {}).status === "LIVE";
        if ( isSurveyLive ) {
            this.props.history.push(Routes.Surveys.Survey.Report.replace(":surveyId", this.props.surveyId));
        } else {
            const location = this.props.history.location.pathname;
            const saveSurvey = !(location.includes("audience") || location.includes("score") || location.includes("summary") || location.includes("ads"));
            const saveAdCreative = location.includes("ads");
            if ( saveSurvey ) {
                if ( !validateSurveyQuestions(this.surveyForm.ref.current.wrapped.current.props.formQuestions) ) {
                    this.dialogRef.show();
                    this.setState({ requestedPath: "/surveys" });
                } else {
                    this.onSaveSurvey();
                    this.props.history.push("/surveys");
                }
            } else if ( saveAdCreative ) {
                this.onSaveAdCreative();
                this.props.history.push("/surveys");
            } else {
                this.props.history.push("/surveys");
            }
        }
    };

    onPreviewPress = () => {
        this.surveyForm.ref.current.wrapped.current.onPreviewPress();
    };

    renderEditor = () => {
        return (
            <Switch location={this.props.location}>
                <Route path={Routes.Surveys.Survey.Audience} component={Audience}/>
                <Route path={Routes.Surveys.Survey.Score} component={Score}/>
                {this.props.user.role === "SUPER_ADMIN" &&
                <Route path={Routes.Surveys.Survey.Ads}
                       render={(props) => <EditAdCopy {...props}
                                                      ref={ref => this.editAdCopyForm = ref}/>} exact/>}
                <Route path={Routes.Surveys.Survey.Summary} component={Summary}/>
                <Route path={Routes.Surveys.Survey.root}
                       render={(props) => <SurveyForm {...props}
                                                      ref={ref => this.surveyForm = ref}/>} exact/>
            </Switch>
        );
    };

    renderReport = () => {
        return (
            <Switch location={this.props.location}>
                <Route exact path={Routes.Surveys.Survey.ReportPdf} component={ReportPdf}/>

                <Route path={Routes.Surveys.Survey.Report}
                       render={(props) => (
                           <Report {...props} surveyId={this.props.surveyId}
                                   survey={this.props.survey}/>
                       )}/>

            </Switch>
        );
    };

    onEditBannerPress = () => {
        if ( !this.editDialog ) {
            return;
        }

        this.editDialog.show();
    };
    onUnderstandPress = () => {
        this.dialogRef.hide();
        this.onSaveSurvey();
        this.props.history.push(this.state.requestedPath);


    };
    onFixPress = () => {
        this.dialogRef.hide();
    };

    render () {
        const loading = (this.state.loadingSurvey || this.state.loadingQuestions || this.state.loadingAudiences);
        const mainClassName = classnames(styles.main, {
            [styles.survey_editor]: isPath(this.props.location.pathname, `/surveys/${this.props.surveyId}`)
        });

        const location = this.props.history.location.pathname;
        const showEditorButtons = !(location.includes("audience") || location.includes("summary"));
        const nextPage = (location) => {
            if ( location.includes("score") ) {
                if ( this.props.user.role === "SUPER_ADMIN" ) {
                    return "ads"
                }
                return "summary";
            } else if ( location.includes("ads") ) {
                return "summary"
            } else {
                if ( this.props.survey.type === "LINK" ) {
                    return "summary"
                }
                return "score";
            }
        };
        const isSurveyLive = (this.props.survey || {}).status === "LIVE";
        const exceedingQuestionLimit = ((this.props.survey || {}).type === "FACEBOOK" && (this.props.activeQuestions.length > QUESTION_LIMIT));
        return (
            <div className={styles.survey}>
                {loading ? (
                    <div className={styles.loader_wrapper}>
                        <Loader large/>
                    </div>
                ) : (
                    <>
                        <nav className={styles.survey_toolbar}>
                            <div className={styles.left}>
                                <Button className={styles.toolbar_button} plain border
                                        onPress={this.onClosePress}>{isSurveyLive ? "Back to report" : "Back"}</Button>
                            </div>

                            <div className={styles.center}>
                                {isSurveyLive ? (
                                    <>
                                        {!this.props.location.pathname.includes("ads") && this.props.survey.type !== "LINK" && this.props.user.role === "SUPER_ADMIN" && this.renderToolbarButton("Edit ad copy", `/surveys/${this.props.surveyId}/ads`)}
                                        {this.props.location.pathname.includes("ads") && this.renderToolbarButton("Edit survey", `/surveys/${this.props.surveyId}`)}

                                        <span className={styles.edit_warning}
                                              onClick={this.onEditBannerPress}>Changing your live survey may effect your results. <span>Learn more</span></span>

                                        <Dialog ref={(ref) => this.editDialog = ref}
                                                className={styles.edit_dialog}
                                                contentClassName={styles.edit_dialog_content}
                                                actions={[
                                                    {
                                                        text: "Done",
                                                        props: {
                                                            greyDark: true,
                                                        },
                                                        onPress: () => this.editDialog.hide(),
                                                    }
                                                ]}>
                                            <h2>FAQs</h2>
                                            <h3><strong>What happens if I edit the question text?</strong>
                                            </h3>
                                            <p>Any previous responses will be kept and reported under the
                                                new question text.</p>

                                            <h3><strong>What happens if I edit multi-choice
                                                options?</strong></h3>
                                            <p>Any options that are deleted will no longer appear in the
                                                report. Any options
                                                that are edited (i.e. “Ture” to “True”) will appear in the
                                                report under the new
                                                option text.</p>

                                            <h3><strong>What happens if I delete a question?</strong></h3>
                                            <p>The question will no longer display in the report and data
                                                will be deleted</p>

                                            <h3><strong>What happens if I change the question type?</strong>
                                            </h3>
                                            <p>Multi-choice and Long list questions can be interchanged
                                                without losing any data.
                                                Freetext and Ranking questions cannot be changed. </p>
                                        </Dialog>
                                    </>
                                ) : (
                                    <>
                                        {this.renderToolbarButton(`${!isRepresentativeSurvey(this.props.survey) && this.props.estimate && this.props.estimate.estCostPerRespondent === 0 ? "⚠️" : ""} 1. Choose audience`, `/surveys/${this.props.surveyId}/audience`)}
                                        {this.renderToolbarButton("2. Edit survey", `/surveys/${this.props.surveyId}`)}
                                        {this.props.survey.type !== "LINK" && this.renderToolbarButton("3. Survey score", `/surveys/${this.props.surveyId}/score`)}
                                        {this.props.survey.type !== "LINK" && this.props.user.role === "SUPER_ADMIN" && this.renderToolbarButton("4. Edit ad copy", `/surveys/${this.props.surveyId}/ads`)}
                                        {this.renderToolbarButton(`${this.props.survey.type === "LINK" ? 3 : this.props.user.role === "SUPER_ADMIN" ? 5 : 4}. View summary`, `/surveys/${this.props.surveyId}/summary`)}
                                    </>
                                )}
                            </div>

                            <div className={styles.right}>
                                {showEditorButtons
                                    ? <Fragment>
                                        {/*<Button className={styles.toolbar_button} plain border onPress={this.onPreviewPress}>Preview*/}
                                        {/*survey</Button>*/}
                                        {!(this.props.location.pathname.includes("score") || this.props.location.pathname.includes("ads")) && (
                                            <Button className={styles.toolbar_button} plain border
                                                    onPress={this.onSaveButtonPress}>Save</Button>
                                        )}

                                        {!isSurveyLive && (
                                            <Button green className={styles.toolbar_button}
                                                    disabled={exceedingQuestionLimit}
                                                    onPress={this.onToolbarButtonPress(`/surveys/${this.props.surveyId}/${nextPage(location)}`)}>Continue</Button>
                                        )}
                                    </Fragment>
                                    : null
                                }
                            </div>
                        </nav>
                        <div className={mainClassName}>
                            {this.renderEditor()}
                            {this.renderReport()}
                        </div>
                    </>
                )}

                <Dialog titleClassName={styles.dialog_title}
                        className={styles.lose_changes_dialog}
                        contentClassName={styles.dialog_content_style}

                        text={"One or more of your questions has incomplete text fields or missing response options. Close this window to fix and save"}
                        ref={(ref) => this.dialogRef = ref} title={"Unsaved changes may be lost"}>
                    <div style={{ display: "flex", justifyContent: "space-evenly", }}>
                        <Button onPress={this.onUnderstandPress} white labelClassName={styles.understand_button}>
                            I understand
                        </Button>
                        <Button onPress={this.onFixPress} white>Close and fix</Button>
                    </div>
                </Dialog>

                <SurveyScorePriceChangeDialog dialogRef={(ref) => this.priceDialog = ref}
                                              onSubmit={() => {
                                                  this.props.history.push(this.state.requestedPath);
                                                  this.priceDialog.hide();
                                              }}
                                              estimate={this.props.estimate}/>
            </div>
        );
    }
}

export default Survey = connect((state, props) => {

    const orgId = state.settings.organisation;
    const surveyId = props.match.params.surveyId;
    const survey = state.surveys[surveyId];

    const questions = state.surveyQuestions[surveyId];
    const activeQuestions = values(questions)
        .filter((question) => {
            return question.status === "ACTIVE";
        });

    return {
        user: state.user,
        orgId: orgId,
        organisation: state.organisations.accounts[orgId],
        surveyId: surveyId,
        survey: survey,
        estimate: state.estimate,

        questions: questions,
        activeQuestions: activeQuestions
    };
})(Survey);
