import Dinero from "dinero.js";

export default function fromCents(amount = 0, currency, pretty = false, format = "0") {
    const params = {
        amount: amount,
    };

    if (!!currency) {
        params.currency = currency;
    }

    if (!!pretty) {
        format = "$0,0.00";
    }

    let formatted = Dinero(params).toFormat(format);

    if (!!pretty) {
        formatted = `${currency === "USD" ? "US" : ""}${formatted}`;
    }


    return formatted;
};
