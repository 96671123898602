import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import DraftSurveyRow from "./draftSurveyRow/DraftSurveyRow";
import Loader from "../../../../../widgets/loader/Loader";
import styles from "./DraftSurveyContent.module.scss";
import { orderBy } from "lodash/collection";

class DraftSurveyContent extends Component {

    onDraftPress = (surveyId) => {
        this.props.history.push(`/surveys/${surveyId}/audience`);
    };

    render() {
        const { loading, draftSurveys } = this.props;

        if (loading) {
            return (
                <div className={styles.loader_wrapper}><Loader /></div>
            );
        } else if (draftSurveys.length) {
            const sortedDrafts = orderBy(draftSurveys, ["updatedAt"], ["desc"]);

            return sortedDrafts.map((survey) => {
                return (
                    <DraftSurveyRow
                        key={survey.id}
                        survey={survey}
                        org={this.props.org}
                        onDraftPress={this.onDraftPress} />
                );
            });
        }
        return (
            <p>No draft surveys here</p>
        );
    }
}

DraftSurveyContent.propTypes = {
    orgId: PropTypes.number,
    organisations: PropTypes.array,
    draftSurveys: PropTypes.array,
    loading: PropTypes.bool,
};

DraftSurveyContent.defaultProps = {
    orgId: null,
    organisations: [],
    draftSurveys: [],
    loading: false,
};

export default withRouter(DraftSurveyContent);