import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "../../dialog/Dialog";
import styles from "./RankingChoiceDialog.module.scss";

class RankingChoiceDialog extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
    };

    state = {
        loading: false,
        error: null,
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.props.onClose();
    };

    render() {
        const choice = this.props.choice;
        const rankObject = choice.rank;
        let rankKeys = [];

        if (rankObject) {
            rankKeys = Object.keys(rankObject);
        }
        
        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                title={this.props.choice.value}
                titleClassName={styles.header}
                onClose={this.onClose}
                disableDismiss={this.state.loading}
                error={this.state.error}
                actions={[
                    {
                        text: "Close", onPress: this.hide, props: { plain: true }
                    }
                ]}
            >
                <div className={styles.ranking_choice_dialog}>
                    <div className={styles.fill_container}>
                        {rankKeys.map((key, keyIndex) => {
                            let percentage = (choice.rank[key] / this.props.questionReport.respondents);

                            return (
                                <div className={styles.fill_wrapper}>
                                    <div key={`fill.${choice.id}.${key}`}
                                         className={styles.fill}
                                         style={{ width: `${percentage * 100}%` }}/>
                                    <div className={styles.answer_content}>
                                        <div className={styles.answer_text}>
                                            #{keyIndex + 1}
                                        </div>
                                        <div className={styles.answer_value}>
                                            {choice.rank[key]} {`${choice.rank[key] === 1 ? "person" : "people"}`}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect()(RankingChoiceDialog);