/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Kyle on 21/01/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component, Fragment } from "react";
import Header from "../../../../widgets/header/Header";
import Content from "../../../../widgets/content/Content";
import { connect } from "react-redux";
import styles from "./List.module.scss";
import AddContactsToAudienceDialog
    from "../../../../widgets/dialogs/addContactsToAudienceDialog/AddContactsToAudienceDialog";
import {
    clearAudience,
    clearAudienceMembers,
    createAudienceMembersFromCSV,
    deleteAudienceMembers,
    getAudienceMembers,
    setUpdateAudienceMembers
} from "../../../../../redux/actions/v1/audienceMembers";
import { deleteAudience, getAudiencesForOrganisation } from "../../../../../redux/actions/v1/audiences";
import Button from "../../../../widgets/button/Button";
import PopperMenu from "../../../../widgets/popperMenu/PopperMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import values from "lodash/values";
import Loader from "../../../../widgets/loader/Loader";
import TextInput from "../../../../widgets/textInput/TextInput";
import SearchIcon from "@mui/icons-material/Search";
import { find, orderBy } from "lodash/collection";
import ConfirmDeleteAudienceMemberDialog
    from "../../../../widgets/dialogs/confirmDeleteAudienceMemberDialog/ConfirmDeleteAudienceMemberDialog";
import { showError } from "../../../../../redux/actions/v1/snackbars";
import Dialog from "../../../../widgets/dialog/Dialog";
import Routes from "../../../../../constants/routes";
import AudienceCreationChoices from "../../../../widgets/audienceCreationChoices/AudienceCreationChoices";
import IconButton from "@mui/material/IconButton";

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingAudience: true,
            loadingAudienceMembers: true,
            selectedMembers: [],
            page: 0,
            searchText: "",
        };
    }

    componentDidMount() {
        this.getAudience();
        this.getAudienceMembers();
    }

    componentWillUnmount() {
        this.props.dispatch(clearAudienceMembers(this.props.orgId));
    }

    getAudience = () => {
        this.setState({
            loadingAudience: true
        });
        this.props.dispatch(getAudiencesForOrganisation({
            orgId: this.props.orgId,
            onSuccess: () => {
                this.setState({
                    loadingAudience: false
                });
            },
        }));
    };

    getAudienceMembers = (cursor = null) => {

        this.setState({
            loadingAudienceMembers: true,
        });

        this.props.dispatch(getAudienceMembers({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            search: this.state.searchText,
            cursor: cursor,
            onSuccess: () => {
                this.setState({
                    loadingAudienceMembers: false,
                });
            },
            onError: (err) => {
                this.setState({
                    loadingAudienceMembers: false
                });
            },
        }));
    };

    onAddManualPress = () => {

        if (!!this.props.onAddManualPress) {
            this.props.onAddManualPress();
        } else {
            this.props.history.push(`/audiences/${this.props.audienceId}/add/manually`);
        }

    };

    onUploadRespondentsPress = () => {
        !!this.uploadRespondentsDialog && this.uploadRespondentsDialog.show();
    };

    onUpload = (data) => {
        this.props.dispatch(createAudienceMembersFromCSV({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            csv: data,
            onSuccess: () => {
                !!this.uploadRespondentsDialog && this.uploadRespondentsDialog.hide();
                this.props.dispatch(clearAudienceMembers(this.props.orgId));
                this.getAudience();
                this.getAudienceMembers();
            },
            onError: (err) => {
                this.props.dispatch(showError(err.message));
            },
        }));
    };

    onOptionsPress = () => {
        !!this.popper && this.popper.show(document.getElementById("options"));
    };

    optionsMenu = () => {
        return [
            { text: "Edit contacts", icon: SettingsIcon, onPress: this.editPress },
            { text: "Delete contacts", icon: DeleteIcon, onPress: this.deletePress }
        ];
    };

    editPress = () => {
        this.props.dispatch(setUpdateAudienceMembers(this.state.selectedMembers));
        if (this.props.onEditMemberPress) {
            this.props.onEditMemberPress();
        } else {
            this.props.history.push(`/audiences/update/${this.props.audienceId}`);
        }
    };

    deletePress = () => {
        !!this.deleteDialog && this.deleteDialog.show();
    };

    onSearchTextChange = (text) => {
        if (!text.length) {
            this.onSearchPress();
        }
        this.setState({
            searchText: text,
        });
    };

    onSearchPress = () => {
        this.props.dispatch(clearAudience(this.props.audienceId));

        this.setState({
            page: 0,
        }, this.getAudienceMembers);
    };


    onNextPress = () => {

        this.setState({
            loadingAudienceMembers: true,
            page: 1 + this.state.page,
            selectedMembers: [],
        });

        const currentPage = (this.props.audienceMemberPages[this.state.page] || { items: [], cursor: null });
        const nextPage = (this.props.audienceMemberPages[1 + this.state.page]);

        const cursor = currentPage.cursor;
        if (!!cursor && !nextPage) {
            this.getAudienceMembers(cursor);
        } else {
            this.setState({ loadingAudienceMembers: false });
        }
    };

    onBackPress = () => {
        this.setState((prevState) => {
            return {
                page: prevState.page - 1,
                selectedMembers: [],
            };
        });
    };

    renderHeader = () => {

        const audience = this.props.audience;

        let columns = [];

        if (!!audience.columns) {
            columns = orderBy(audience.columns, ["ordinal"], ["asc"]);
        }

        return (
            <tr>
                <th width="30" className={styles.checkbox}>
                    <input id={"selectAll"}
                           type="checkbox"
                           onChange={this.onSelectAllMembersCheckboxSelect} />
                </th>
                <th>Email</th>
                {columns.map(this.renderColumnHeader)}
            </tr>
        );
    };

    renderColumnHeader = (column) => {
        return (
            <th key={column.id}>{column.name}</th>
        );
    };

    renderRows = (currentPage) => {

        if (this.state.loadingAudienceMembers) {
            return (
                <tr>
                    <td><Loader large /></td>
                </tr>
            );
        }

        return currentPage.items.map(this.renderMemberRow);
    };

    renderMemberRow = (memberId) => {
        const member = this.props.audienceMembers[memberId];
        const isSelected = !!find((this.state.selectedMembers || []), { id: member.id });

        let columns = [];

        if (!!this.props.audience.columns) {
            columns = orderBy(this.props.audience.columns, ["ordinal"], ["asc"]);
        }

        return (
            <tr key={memberId}>
                <th width="30" className={styles.checkbox}>
                    <input checked={isSelected}
                           onChange={this.onMemberCheckboxSelect(member)}
                           type="checkbox" />
                </th>
                <td>{member.email}</td>
                {columns.map(this.renderMemberColumnItem(member))}
                {/*Don't delete td below*/}
                <td width="100%">&nbsp;</td>
            </tr>
        );
    };

    renderMemberColumnItem = (member) => {
        return (column) => {
            const tag = (member.tags[column.id] || {});
            const value = tag.value;
            return (
                <td key={column.id}>{!!value ? value : "..."}</td>
            );
        };
    };

    onMemberCheckboxSelect = (member) => {
        return (e) => {
            const isChecked = e.target.checked;
            const selectedMembers = cloneDeep(this.state.selectedMembers);
            const index = findIndex(selectedMembers, member.id);
            if (isChecked && index === -1) {
                selectedMembers.push(member);
            } else {
                selectedMembers.splice(index, 1);
            }
            this.setState({
                selectedMembers: selectedMembers
            });
        };
    };

    onSelectAllMembersCheckboxSelect = (e) => {
        const isChecked = e.target.checked;
        const currentPage = (this.props.audienceMemberPages[this.state.page] || { items: [], cursor: null });
        if (isChecked) {
            let selectedMembers = [];
            currentPage.items.map((item) => {
                if (!!this.props.audienceMembers[item]) {
                    selectedMembers.push(this.props.audienceMembers[item]);
                }
                return null;
            });

            this.setState({
                selectedMembers: selectedMembers,
            });
        } else {
            this.setState({
                selectedMembers: []
            });
        }
    };

    onConfirmDeletePress = () => {
        this.setState({ loadingAudienceMembers: true });
        this.props.dispatch(deleteAudienceMembers({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            data: this.state.selectedMembers,
            onSuccess: () => {
                !!this.deleteDialog && this.deleteDialog.hide();
                this.props.dispatch(clearAudienceMembers(this.props.orgId));
                this.getAudience();
                this.getAudienceMembers();
                this.setState({ selectedMembers: [], loadingAudienceMembers: false });
            },
            onError: (e) => {
                this.setState({ loadingAudience: false });
                this.props.dispatch(showError(e.message));
            }
        }));
    };

    onDeleteAudiencePress = () => {
        this.deleteAudienceDialog.show();
    };

    onConfirmDeleteAudiencePress = () => {
        this.setState({ deletingAudience: true });

        this.props.dispatch(deleteAudience({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            onSuccess: () => {
                this.setState({ deletingAudience: false });
                this.deleteAudienceDialog.hide();
                if (this.props.onDeleteAudiencePress) {
                    this.props.onDeleteAudiencePress();
                } else {
                    this.props.history.push(Routes.Audiences.root);
                }
            },
            onError: (e) => {
                this.setState({ deletingAudience: false });
                this.props.dispatch(showError(e.message));
            }
        }));
    };

    render() {
        const isNotEmpty = (object) => values(object).some(i => i !== undefined);

        let headerButtons = [{ text: "Delete Audience", onPress: this.onDeleteAudiencePress, props: { plain: true } }];

        if (!!this.props.audience && isNotEmpty(this.props.audienceMembers)) headerButtons = [
            ...headerButtons,
            { text: "Add Manually", onPress: this.onAddManualPress, props: { plain: true, border: true } },
            { text: "Upload Respondents", onPress: this.onUploadRespondentsPress, props: { green: true } },
        ];

        const optionButtons = [{
            text: "Options",
            onPress: this.onOptionsPress,
            props: {
                white: true,
                id: "options",
                disabled: !this.state.selectedMembers.length
            }
        }];

        const deleteAudienceActions = [
            {
                text: "Cancel",
                onPress: () => this.deleteAudienceDialog.hide(),
                props: { plain: true, disabled: this.state.deletingAudience }
            },
            {
                text: "Delete audience",
                onPress: () => this.onConfirmDeleteAudiencePress(),
                props: { loading: this.state.deletingAudience }
            }
        ];

        const currentPage = (this.props.audienceMemberPages[this.state.page] || { items: [], cursor: null });

        return (
            <React.Fragment>

                <Content className={styles.list}
                         headerText={"Audiences"}
                         headerButtons={headerButtons}
                         loading={this.state.loadingAudience}>
                    {
                        !!this.props.audience && isNotEmpty(this.props.audienceMembers)
                            ? <Fragment>
                                <Header text={`List: ${this.props.audience.name}`} medium noBorder
                                        buttons={optionButtons} />
                                < div className={styles.list_search}>
                                    <TextInput placeholder={"Enter search text here"}
                                               text={this.state.searchText}
                                               onTextChange={this.onSearchTextChange} />
                                    <IconButton onClick={this.onSearchPress}>
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                                <div className={styles.list_container}>
                                    <table>
                                        <thead>
                                        {this.renderHeader()}
                                        </thead>
                                        <tbody>
                                        {this.renderRows(currentPage)}
                                        </tbody>
                                    </table>

                                    <div className={styles.pager_buttons}>
                                        <Button plain
                                                disabled={this.state.page === 0}
                                                onPress={this.onBackPress}>Previous</Button>
                                        <Button plain
                                                disabled={!currentPage.cursor}
                                                onPress={this.onNextPress}>Next</Button>
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <AudienceCreationChoices
                                onAddManualPress={this.onAddManualPress}
                                onUploadRespondentsPress={this.onUploadRespondentsPress}
                            />
                    }
                    <PopperMenu ref={(el) => this.popper = el}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                menuItems={this.optionsMenu} />

                    <ConfirmDeleteAudienceMemberDialog
                        dialogRef={(ref) => this.deleteDialog = ref}
                        onSubmit={this.onConfirmDeletePress}
                    />

                    <Dialog
                        title={"Delete Audience"}
                        text={"Are you sure you would like to delete this audience?"}
                        actions={deleteAudienceActions}
                        disableDismiss={this.state.deletingAudience}
                        ref={(ref) => this.deleteAudienceDialog = ref} />

                </Content>

                <AddContactsToAudienceDialog
                    onUpload={this.onUpload}
                    disableDismiss={this.props.keepUploadDialogOpen}
                    dialogRef={(ref) => {
                        this.uploadRespondentsDialog = ref;
                        if (this.props.getUploadDialogRef) {
                            this.props.getUploadDialogRef(ref);
                        }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default List = connect((state, props) => {

    const orgId = state.settings.organisation;
    const organisation = (state.organisations.accounts[orgId] || {});
    const audienceId = Number(((props.match || {}).params || {}).audienceId) || props.audienceId;
    const audience = ((state.audiences[orgId] || {})[audienceId] || {});
    const audienceMembers = (state.audienceMembers[audienceId] || { members: {}, pages: [] });

    return {
        orgId: orgId,
        organisation: organisation,
        audienceId: audienceId,
        audience: audience,
        audienceMembers: audienceMembers.members,
        audienceMemberPages: audienceMembers.pages,
        cursor: audienceMembers.cursor,
        audienceUploadComplete: state.audienceMembers.audienceUploadComplete
    };
})(List);
