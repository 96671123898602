export default function fireTagEvent(event, params) {
    let dataLayer = window.dataLayer || [];
    let eventdata = {
        'event': event,
    };

    if (params !== null && params !== undefined) {
        eventdata = Object.assign(eventdata, params);
    }

    dataLayer.push(eventdata);

    if (process.env.REACT_APP_ENV !== "production") {
        console.log("pushing event to gtm: " + event);
    }
}