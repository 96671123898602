import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { change, formValueSelector } from "redux-form";
import FieldArray from "redux-form/es/FieldArray";
import { EMOJI_RATING, FREE_TEXT, LOVE_HATE, NPS, QuestionText, RATING } from "../../../../../../../../../../constants/QuestionTypes";
import DeleteTrashcanIcon from "../../../../../../../../../../resources/js/DeleteTrashcanIcon";
import RandomIcon from "../../../../../../../../../../resources/js/RandomIcon";
import RandomIconActive from "../../../../../../../../../../resources/js/RandomIconActive";
import SettingsIcon from "../../../../../../../../../../resources/js/SettingsIcon";
import SkipIcon from "../../../../../../../../../../resources/js/SkipIcon";
import { SurveyStatus } from "../../../../../../../../../../types/model/Survey";
import AddLogicDialog from "../../../../../../../../../widgets/dialogs/addLogicDialog/AddLogicDialog";
import CreateSurveyRowDialog from "../../../../../../../../../widgets/dialogs/createSurveyRowDialog/CreateSurveyRowDialog";
import DeleteSurveyRowDialog from "../../../../../../../../../widgets/dialogs/deleteSurveyRowDialog/DeleteSurveyRowDialog";
import QuestionRandomiseDialog from "../../../../../../../../../widgets/dialogs/questionRandomiseDialog/QuestionRandomiseDialog";
import QuestionSettingsDialog from "../../../../../../../../../widgets/dialogs/questionSettingsDialog/QuestionSettingsDialog";
import IconButton from "../../../../../../../../../widgets/iconButton/IconButton";
import AddRow from "../addRow/AddRow";
import QuestionChoices from "../questionChoices/QuestionChoices";
import QuestionContent from "../questionContent/QuestionContent";
import styles from "./QuestionBlock.module.scss";
import yellowPadlock from "../../../../../../../../../../resources/svg/padlock-yellow-circle.svg";
import UpgradeSaasPlan from "../../../../report/components/upgradeSaasPlan/UpgradeSaasPlan";
import UpdateSubscriptionPlanDialog from "../../../../../../../../../widgets/dialogs/updateSubscriptionPlanDialog/UpdateSubscriptionPlanDialog";
import UpgradePromptDialog from "../../../../../../../../../widgets/dialogs/upgradePromptDialog/UpgradePromptDialog";

class QuestionBlock extends Component {
    static propTypes = {
        formName: PropTypes.string,
        index: PropTypes.number,
        name: PropTypes.string,
        surveySaveRequired: PropTypes.bool,
        onAddNewRowSubmit: PropTypes.func,
        onDeleteRowPress: PropTypes.func,
        onConfirmDeleteSurveyRow: PropTypes.func,
    };

    static defaultProps = {
        formName: null,
        index: null,
        name: null,
        surveySaveRequired: false,
        onAddNewRowSubmit: () => null,
        onDeleteRowPress: () => null,
    };

    state = {
        deleteRow: false,
        deleteRows: [],
    };

    onDeleteRowPress = () => {
        this.deleteSurveyRowDialog.show();
    };

    onQuestionSettingsPress = () => {
        this.questionSettingsDialog.show();
    };

    onAddNewRowPress = () => {
        this.createRowDialog.show();
    };

    onSkipLogicPress = () => {
        if (!this.props.upgradeRequired) {
            this.skipLogicDialog.show();
        } else {
            this.upgradePromptDialog.show();
        }
    };

    onRandomiseChoicesPress = () => {
        this.questionRandomiseDialog.show();
    };

    onConfirmDeleteSurveyRow = () => {
        this.props.onDeleteRowPress(this.props.index);
        this.deleteSurveyRowDialog.hide();
    };

    onDeleteQuestionContentRowPress = () => {
        if (!this.state.deleteRow) {
            this.setState({ deleteRow: true }, () => {
                const deleteRows = JSON.parse(JSON.stringify(this.state.deleteRows));
                const questionToBeDeleted = this.props.index;

                if (deleteRows.indexOf(questionToBeDeleted) === -1) {
                    deleteRows.push(questionToBeDeleted);
                    this.setState({ deleteRows: deleteRows });
                }
            });
        } else {
            this.setState({ deleteRow: false }, () => {
                if (this.state.deleteRows.indexOf(this.props.index) > -1) {
                    const deleteRows = JSON.parse(JSON.stringify(this.state.deleteRows));
                    deleteRows.splice(deleteRows.indexOf(this.props.index), 1);
                    this.setState({ deleteRows: deleteRows });
                }
            });
        }
    };

    afterQuestionContentRowDelete = () => this.setState({ deleteRows: [], deleteRow: false });
    onUpgradePromptClick = () => {
        this.createRowDialog.hide();
        this.upgradePromptDialog.show();
    };
    renderEditorTools = () => {
        const isFinalQuestion = this.props.index === this.props.surveyQuestions.length - 1;
        const { question } = this.props;
        const skipLogicText =
            question.choicesHaveSkipLogic || question.nextQuestion || question.terminatingQuestion ? "View logic" : "Add logic";

        const hasRandomChoices = question.choices && question.choices.some((choice) => choice.randomiseChoice === true);
        const randomiseAndSkipLogic =
            question.type === "SINGLE_CHOICE" ||
            question.type === "LONG_FORM_SINGLE_CHOICE" ||
            question.type === "MULTI_CHOICE" ||
            question.type === "LONG_FORM_MULTI_CHOICE";

        const { status } = this.props.survey;
        const isLive = status === SurveyStatus.LIVE;
        return (
            <Fragment>
                <IconButton text={"Settings"} textGrey blueDark onPress={this.onQuestionSettingsPress} className={styles.button}>
                    <SettingsIcon />
                </IconButton>
                {!this.props.surveySaveRequired && !isFinalQuestion && randomiseAndSkipLogic ? (
                    <div className={styles.upgrade_prompt}>
                        {this.props.upgradeRequired && (
                            <img src={yellowPadlock} alt={"feature-locked"} className={styles.yellow_padlock} />
                        )}
                        <IconButton text={skipLogicText} textGrey blueDark onPress={this.onSkipLogicPress} className={styles.button}>
                            <SkipIcon />
                        </IconButton>
                    </div>
                ) : !isFinalQuestion && randomiseAndSkipLogic ? (
                    <IconButton text={"Save survey to add skip logic"} textGrey className={styles.button_disabled}>
                        <SkipIcon />
                    </IconButton>
                ) : null}

                {randomiseAndSkipLogic ? (
                    <IconButton
                        text={"Randomise"}
                        textGrey
                        blueDark={!hasRandomChoices}
                        greenLight={hasRandomChoices}
                        onPress={this.onRandomiseChoicesPress}
                        className={styles.button}>
                        {hasRandomChoices ? <RandomIconActive /> : <RandomIcon />}
                    </IconButton>
                ) : null}

                {question.content.length > 1 && question.type !== "MULTI_ATTRIBUTE" ? (
                    <IconButton
                        text={this.state.deleteRow ? "Cancel" : "Delete a row"}
                        textGrey
                        onPress={this.onDeleteQuestionContentRowPress}
                        className={styles.button}>
                        <DeleteTrashcanIcon />
                    </IconButton>
                ) : null}

                {question.content.length > 1 && !isLive && question.type === "MULTI_ATTRIBUTE" ? (
                    <IconButton
                        text={this.state.deleteRow ? "Cancel" : "Delete a row"}
                        textGrey
                        onPress={this.onDeleteQuestionContentRowPress}
                        className={styles.button}>
                        <DeleteTrashcanIcon />
                    </IconButton>
                ) : null}

                <IconButton text={"Delete question"} textGrey onPress={this.onDeleteRowPress} className={styles.button}>
                    <DeleteTrashcanIcon />
                </IconButton>
            </Fragment>
        );
    };

    render() {
        const questionNumber = `Question ${this.props.index + 1}`;
        const hideAddContent =
            this.props.question.type === "LONG_FORM_SINGLE_CHOICE" ||
            this.props.question.type === "LONG_FORM_MULTI_CHOICE" ||
            this.props.question.type === "RANKING" ||
            (this.props.question.type === "MULTI_ATTRIBUTE" && this.props.question.content.length > 5);

        const survey = this.props.survey;
        const { status } = survey;
        const isLive = status === SurveyStatus.LIVE;
        return (
            <div className={styles.question_container}>
                <div className={styles.question_content}>
                    <div className={styles.left}>{questionNumber}</div>
                    <div className={styles.right}>
                        <h4>{QuestionText[this.props.question.type]}</h4>
                        <div className={styles.question_block}>
                            <div className={styles.messages_wrapper}>
                                <FieldArray
                                    name={`${this.props.name}.content`}
                                    component={QuestionContent}
                                    props={{
                                        showDelete: this.state.deleteRows.indexOf(this.props.index) > -1,
                                        afterDelete: this.afterQuestionContentRowDelete,
                                        hideAddContent: hideAddContent,
                                        question: this.props.question,
                                        questionName: this.props.name,
                                    }}
                                />
                                <QuestionChoices
                                    survey={survey}
                                    question={this.props.question}
                                    dispatch={this.props.dispatch}
                                    responseType={this.props.question.type}
                                    choices={this.props.question.choices}
                                    name={this.props.name}
                                />
                            </div>
                            <div className={styles.tools_wrapper}>{this.renderEditorTools()}</div>
                        </div>
                        <AddRow text={"Add Question"} onAddNewQuestionPress={this.onAddNewRowPress} />
                    </div>
                </div>

                <CreateSurveyRowDialog
                    dialogRef={(ref) => (this.createRowDialog = ref)}
                    rowIndex={this.props.index}
                    onSubmit={this.props.onAddNewRowSubmit}
                />

                <QuestionSettingsDialog
                    dialogRef={(ref) => (this.questionSettingsDialog = ref)}
                    survey={this.props.survey}
                    fieldName={this.props.name}
                />

                <AddLogicDialog
                    dialogRef={(ref) => (this.skipLogicDialog = ref)}
                    fieldName={this.props.name}
                    questionIndex={this.props.index}
                    question={this.props.question}
                    dispatch={this.props.dispatch}
                    surveyQuestions={this.props.surveyQuestions}
                />
                <UpgradePromptDialog dialogRef={(ref) => (this.upgradePromptDialog = ref)} />

                {this.props.question.choices && (
                    <QuestionRandomiseDialog
                        dialogRef={(ref) => (this.questionRandomiseDialog = ref)}
                        question={this.props.question}
                        fieldName={this.props.name}
                    />
                )}

                <DeleteSurveyRowDialog
                    isLive={isLive}
                    dialogRef={(ref) => (this.deleteSurveyRowDialog = ref)}
                    onSubmit={this.onConfirmDeleteSurveyRow}
                />
            </div>
        );
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const org = state.organisations.accounts[state.settings.organisation];
    const survey = state.form.editSurvey;
    const surveyId = props.survey.id;
    const surveyType = props.survey.type;
    let tier = org.subscription.tier.name;
    const isEnterprise = state.organisations.accounts[state.settings.organisation].accountType === "ENTERPRISE";
    // let tier = "FREE"
    const upgradeRequired = tier === "FREE" && surveyType !== "FACEBOOK" && !isEnterprise;
    return {
        question: selector(state, props.name) || state.surveyEnding[props.index],
        surveyQuestions: selector(state, "questions"),
        org: org,
        surveyType: selector(state, "type"),
        upgradeRequired: upgradeRequired,
    };
})(QuestionBlock);
