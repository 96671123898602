import * as Types from "../../actions/v1/surveyFormState";

const initialState = {
    isFocused: false,
    isSaving: false
};


export default function surveyFormState(state=initialState, action) {
    switch(action.type) {
        case Types.SET_SURVEY_FORM_FOCUSED: {
            return{
            isFocused: action.isFocused
        }}


        case Types.SET_SURVEY_FORM_SAVING: {
            return{
                isSaving: action.isSaving
            }}
        default: {
            return state
        }
    }
}
