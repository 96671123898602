/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 27/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./Footer.module.scss";
import classnames from "classnames";

class Footer extends Component {

    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const className = classnames(styles.footer, this.props.className);
        return (
            <div className={className}>
                <div className={styles.legal_line}>
                    <p>Hey we are in beta, any problems we would love to hear <a href={"mailto:support@stickybeak.co"}> your feedback</a>.</p>
                </div>
            </div>
        );
    }
}

export default Footer;