import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
import styles from "./DatePicker.module.scss";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";

export default class DatePicker extends Component {

    static propTypes = {
        className: PropTypes.string,
        datePickerClassName: PropTypes.string,

        onChange: PropTypes.func,
        label: PropTypes.string,
        localDate: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        datePickerClassName: null,

        onChange: () => null,
        label: "",
        localDate: false,
    };

    onChange = (date) => {
        if (this.props.localDate) {
            date = date.format("YYYY-MM-DD");
        } else {
            date = date.format();
        }

        this.props.onChange(date);
    };

    render() {
        const className = classnames(styles.date_picker, this.props.className);

        let error = this.props.error;
        if (!!this.props.meta && !!this.props.meta.error) {
            error = this.props.meta.error;
        }
        const hasErrorText = !!error && error !== " " ? error : false;

        const datePickerClassName = classnames(styles.date_picker_input, {
            [styles.disabled]: this.props.disabled,
            [styles.error]: !!error,
        }, this.props.datePickerClassName);

        let value = moment(this.props.value);

        if (!value || !value.isValid || !value.isValid()) {
            value = moment();
        }

        const minDate = moment(this.props.minDate) || moment();

        return (
            <div className={className}>
                {!!this.props.label && <label className={styles.label}>{this.props.label}</label>}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        {...DatePicker.defaultProps}
                        onChange={this.onChange}
                        value={value.toDate()}
                        inputFormat={"DD/MM/yyyy"}
                        minDate={minDate}
                        mask={"__/__/____"}
                        renderInput={(params) => <TextField {...params} />}
                        className={datePickerClassName} />
                </LocalizationProvider>
                {!!hasErrorText ?
                    <label className={styles.error_text}>{error}</label> : !!this.props.helperText ?
                        <label
                            className={styles.helper_text}>{this.props.helperText}</label> : null}
            </div>
        );
    }
}
