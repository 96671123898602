/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import axios, { AxiosRequestConfig } from "axios";
import qs from "../../../../util/qs";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    paramsSerializer: (params) => {
        return qs.stringify(params);
    },
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (config.headers.Authorization) {
            return config;
        }

        const newConfig = { ...config };
        newConfig.headers.Authorization = await window?.firebase?.auth?.()?.currentUser?.getIdToken();

        return config;
    },
    (err: any) => {
        return Promise.reject(err);
    },
);

export default axiosInstance;