import styles from "../../ReportSideBar.module.scss";
import Dinero from "dinero.js";
import TableRow from "../TableRow";
import UpdateMaxSpendDialog
    from "../../../../../../../../../../widgets/dialogs/updateMaxSpendDialog/UpdateMaxSpendDialog";
import UpdateDailyCampaignSpendDialog
    from "../../../../../../../../../../widgets/dialogs/updateDailyAdSpendDialog/UpdateDailyCampaignSpendDialog";
import React, {useRef, useState} from "react";
import {DateTime} from "luxon";
import useSelector from "../../../../../../../../../../../hooks/useSelector";
import useAdminState from "../../../../../../../../../../../hooks/useAdminState";
import { CardDetails } from "../cardDetails/CardDetails";

export default function PerformanceTab(props) {

    const {surveyId, respondents, cardDetails} = props;
    const report = useSelector((state => state.reports.surveys[surveyId]));
    const [, isAdmin] = useAdminState();
    const survey = useSelector((state => state.surveys[surveyId]));
    const estimate = useSelector((state => state.estimate))
    const {currency} = survey;
    const [showSpendLimitReached, setShowSpendLimitReached] = useState(true)
    const updateMaxSpendDialogRef = useRef();
    const updateDailySpendRef = useRef();
    const now = DateTime.local();
    const {respondentsCompleted, insights, respondentsStarted} = report;
    const latestInsight = insights[insights.length - 1] || {
        costPerRespondent: 0,
        costPerRespondentConverted: 0,
        currency: "USD",
        clicks: 0,
        reach: 0,
        conversionRate: 1,
        impressions: 0,
        costPer1000Impression: 0,
        costPer1000ImpressionConverted: 0,
        inlineLinkClickCtr: 0,
        spend: 0,
        dateStarted: now.toISO()
    };
    const conversionRate = 1/Number(latestInsight.conversionRate)
    const dailyAdSetSpend = Dinero({amount: survey.targeting.dailyAdSetSpend || 0, currency});
    const dailyAdSetSpendNz = Dinero({amount: Number(((survey.targeting.dailyAdSetSpend || 0)*conversionRate).toFixed(0))})
    const {dateStarted, dateEnded} = latestInsight;
    const insightSpend = Dinero({amount: latestInsight.spend, currency});
    const insightSpendNz  = Number((latestInsight.spend*conversionRate).toFixed(0))
    console.log(latestInsight.spend, conversionRate, latestInsight.spend*conversionRate)
    const remaining = survey.maxSpend - latestInsight.spend;
    const remainingNz=Dinero({amount: survey.facebookMaxSpend - insightSpendNz});
    console.log(remaining, conversionRate, remaining*conversionRate)

    const budgetLeft = Dinero({amount: remaining, currency});
    const started = DateTime.fromISO(dateStarted);
    const ended = dateEnded ? DateTime.fromISO(dateEnded) : now;
    const numberOfDays = Math.round(ended.diff(started, "days").as("days"));

    const variance = Dinero({
        amount: (estimate.estCostPerRespondent || 0) - (latestInsight.costPerRespondentConverted || 0),
        currency: currency
    }).toFormat("0,0.00")
    const variancePercentage = (((estimate.estCostPerRespondent || 0) - (latestInsight.costPerRespondentConverted || 0)) / estimate.estCostPerRespondent * 100).toFixed(3);
    const completionRate = respondentsCompleted / respondentsStarted * 100;
    const cpr= Number(((insightSpendNz/respondentsStarted)||0).toFixed(0))
    const genders = {
        MALE: {
            text: "Male",
            className: styles.male
        },
        FEMALE: {
            text: "Female",
            className: styles.female
        },
        ALL: {
            text: "Any",
            className: styles.bubble
        }
    }
    return (
        <div>
            <div className={styles.block}>
                {latestInsight.spend >= survey.maxSpend && showSpendLimitReached &&
                    <div className={styles.spend_limit_reached}>
                            <span>
                                Your survey has been paused.
                            </span>
                        <span>
                                Add funds to reactivate.
                            </span>
                        <span className={styles.dismiss_button} onClick={() => setShowSpendLimitReached(false)}>
                                Dismiss
                            </span>
                    </div>
                }
                <h3>Survey spend details</h3>
                <div className={styles.spend_limit}>
                    <div className={styles.spend_limit_span}>
                        <span className={styles.label}>Survey spend limit</span>
                        {isAdmin && <span className={styles.label}>Ads manager campaign limit</span>}
                    </div>
                    <div className={styles.limit}>
                                <span>
                                    {survey.maxSpend && `USD$${Dinero({
                                        amount: survey.maxSpend,
                                        currency: currency
                                    }).toFormat("0,0.00")}`}
                                </span>
                        <span>
                                    {isAdmin && survey.facebookMaxSpend && `NZD$${Dinero({
                                        amount: survey.facebookMaxSpend,
                                        currency: currency
                                    }).toFormat("0,0.00")}`}
                                </span>
                        <span className={styles.edit_limit}
                              onClick={() => updateMaxSpendDialogRef.current.show()}>
                                      Add funds
                                </span>
                    </div>
                </div>
                <TableRow left={"Daily ad set limit"} right={`NZD$${dailyAdSetSpendNz.toFormat("0,0.00")}`}/>
                <TableRow left={"Time in the field"} right={`${numberOfDays} days`}/>
                <TableRow left={"Cost per respondent"} right={`NZD$${Dinero({amount:cpr}).toFormat("0,0.00")}`}/>
                <TableRow left={"Total spent"} right={`NZD$${Dinero({amount:insightSpendNz}).toFormat("0,0.00")}`}/>
                <TableRow left={"Total remaining"} right={`NZD$${remainingNz.toFormat("0,0.00")}`}/>


            </div>
            <div className={styles.enterprise_demo_data}>
                <div className={styles.report_sidebar_bottom}>
                    {isAdmin && respondentsCompleted > 0 &&
                        <div className={styles.survey_detail_section}>
                            <div className={styles.facebook_data}>

                                <TableRow left={`Variance (${variancePercentage}%)`} right={`USD$${variance}`}/>
                                <TableRow left={"CPM"} right={`USD$${Dinero({
                                    amount: Number(latestInsight.costPer1000ImpressionConverted || 0),
                                    currency: currency
                                }).toFormat("0,0.00")}`}/>
                                <TableRow left={"% click through"}
                                          right={`${Number(latestInsight.inlineLinkClickCtr || 0).toFixed(3)}%`}/>
                                <TableRow left={`Completion rate (${respondentsCompleted}/${respondentsStarted})`}
                                          right={`${completionRate.toFixed(3)}%`}/>
                            </div>

                        </div>}
                    <div className={styles.survey_detail_section}>
                        <h3>
                            Daily spend details
                        </h3>
                        <div className={styles.detail_container}>
                            <div  className={styles.spend_details_label}>
                                    <span className={styles.label}>
                                        Daily ad set spend details
                                    </span>
                                {isAdmin && <span className={styles.label}>
                                        Ads manager daily ad set spend
                                    </span>}
                            </div>
                            <div className={styles.spend_details}>
                                        <span>
                                           {`USD$${dailyAdSetSpend.toFormat("0,0.00")}/day`}
                                        </span>
                                <span>
                                           {isAdmin && `NZD$${dailyAdSetSpend.toFormat("0,0.00")}/day`}
                                        </span>
                                <span className={styles.edit_spend}
                                      onClick={() => updateDailySpendRef.current.show()}>
                                            Edit daily ad set limit
                                        </span>
                            </div>
                        </div>
                        {/*<h4>*/}
                        {/*        Daily budget per audience segment*/}
                        {/*    </h4>*/}
                        {/*<div className={styles.detail_container}>*/}
                        {/*    {*/}
                        {/*        survey.targeting.genders.map((gender) => {*/}
                        {/*            return survey.targeting.ageGroups.map((ageGroup) => (*/}
                        {/*                <div className={styles.targeting}>*/}
                        {/*                    <div>*/}
                        {/*                        <span>*/}
                        {/*                            Age*/}
                        {/*                        </span>*/}
                        {/*                        <span className={styles.bubble}>*/}
                        {/*                            {`${ageGroup.minAge} - ${ageGroup.maxAge}`}*/}
                        {/*                        </span>*/}
                        {/*                    </div>*/}
                        {/*                    <div>*/}
                        {/*                        <span>*/}
                        {/*                            Gender*/}
                        {/*                        </span>*/}
                        {/*                        <span className={genders[gender].className}>*/}
                        {/*                            {genders[gender].text}*/}
                        {/*                        </span>*/}
                        {/*                    </div>*/}
                        {/*                    <div>*/}
                        {/*                        <span>*/}
                        {/*                            Daily limit*/}
                        {/*                        </span>*/}
                        {/*                        <input className={styles.limit}*/}
                        {/*                               placeholder={`$${Dinero({amount: survey.targeting.dailyAdSetSpend, currency: currency }).toFormat(("0,0.00"))}`}>*/}

                        {/*                        </input>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            ))*/}

                        {/*        })*/}
                        {/*    }*/}


                        {/*</div>*/}
                    </div>
                    {survey.type === "FACEBOOK" &&
                        <CardDetails cardDetails={cardDetails}/>
                    }
                </div>
                <UpdateMaxSpendDialog
                    dialogRef={updateMaxSpendDialogRef}
                    disableDismiss={false}
                    currency={currency}
                    isAdmin={isAdmin}
                    surveyId={surveyId}
                    currentMaxSpend={survey.maxSpend}
                />
                <UpdateDailyCampaignSpendDialog
                    dialogRef={updateDailySpendRef}
                    disableDismiss={false}
                    surveyId={surveyId}
                    currentMaxSpend={survey.maxSpend}
                    currentDailySpend={dailyAdSetSpend.toFormat("0,0.00")}
                />

            </div>
            {/*{survey.status === SurveyStatus.LIVE && (*/}
            {/*    <>*/}
            {/*        <AdSpend surveyId={surveyId} survey={survey}/>*/}
            {/*        <AdCreative surveyId={surveyId}/>*/}
            {/*    </>*/}
            {/*)}*/}

        </div>
    )
}