import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import Input from "../../input/Input";
import Button from "../../button/Button";
import React, { Component } from "react";
import styles from "./CreditCardSelectDialog.module.scss";
import values from "lodash/values";
import { withRouter } from "react-router";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";

class CreditCardSelectDialog extends Component {

    state = {
        cardId: "",
    };

    static propTypes = {
        initialValue: PropTypes.string,
        dialogRef: PropTypes.func,
        cards: PropTypes.object,
        submitCard: PropTypes.func,
        onAddCard: PropTypes.func,
    };

    static defaultProps = {
        dialogRef: () => null,
        cards: {},
        submitCard: () => null,
    };

    onAddCardPress = () => {
        this.props.onAddCard();
    };

    onSubmit = () => {
        this.props.submitCard(this.state.cardId);
        this.dialog.hide();
    };

    onClose = () => {
        if (this.props.initialValue) {
            this.setState({ cardId: this.props.initialValue });
        }
    };

    render() {
        const cardOptions = values(this.props.cards).map((card) => {
            return { label: (!!card.name ? card.name : "Card ending in " + card.last4), value: card.id };
        });

        return (
            <Dialog ref={(ref) => {
                this.dialog = ref;
                this.props.dialogRef(ref);
            }}
                    className={styles.dialog}
                    custom
                    width={"md"}
                    onClose={this.onClose}>
                <div className={styles.dialog_header}>
                    <span className={styles.header_text}>Select card</span>
                    <div className={styles.close_wrapper}>
                        <IconButton onClick={() => this.dialog.hide()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <div className={styles.dialog_content}>
                    <Input options={cardOptions}
                           onChange={(e) => this.setState({ cardId: e.target.value })}
                           initialValue={this.props.initialValue}
                           value={this.state.cardId} />
                    <Button green
                            onPress={this.onSubmit}
                            disabled={!this.state.cardId}>Select card</Button>
                    <Button border plain onPress={this.onAddCardPress}>Add card</Button>
                </div>
            </Dialog>
        );
    }
}

export default withRouter(CreditCardSelectDialog);
