import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import * as SurveysAPI from "../api/v1/surveys";
import {removeSurvey, setCompletedPaginatedSurveys, setPaginatedSurveys, setSurvey, setDraftSurveys, duplicateSurveyAction, completeSurveyAction} from "../surveys";
import {showError, showSuccess} from "./snackbars";

export const SET_SURVEY_AGENTS = "surveys:SET_SURVEY_AGENTS";
export const SET_SURVEY_TEMPLATES = "surveys:SET_SURVEY_TEMPLATES";
export const SET_ORGANISATION_SURVEYS = "surveys:SET_ORGANISATION_SURVEYS";
export const SET_ORGANISATION_SURVEY = "surveys:SET_ORGANISATION_SURVEY";
export const REMOVE_ORGANISATION_SURVEY = "surveys:REMOVE_ORGANISATION_SURVEY";
export const SET_SHARE_SURVEY_LINK = "surveys:SET_SHARE_SURVEY_LINK";

export const setSurveyAgents = createAction(SET_SURVEY_AGENTS, "agents");
export const setSurveyTemplates = createAction(SET_SURVEY_TEMPLATES, "templates");

export const getSurvey = wrapper(SurveysAPI.getSurveyAPI, (dispatch, agents) => {
    dispatch(setSurvey(agents))
});

export const createSurvey = wrapper(SurveysAPI.createOrganisationSurveyAPI, (dispatch, survey) => {
    dispatch(setSurvey(survey))
});

export const createTemplateSurvey = wrapper(SurveysAPI.createOrganisationTemplateSurveyAPI, (dispatch, survey) => {
    dispatch(setSurvey(survey))
});

export const updateSurvey = wrapper(SurveysAPI.updateOrganisationSurveyAPI, (dispatch, survey) => {
    dispatch(showSuccess("Survey successfully saved"));
    dispatch(setSurvey(survey))
}, (dispatch, err) => {
    dispatch(showError(err.message));
});

export const startFreeSurveyFacebook = wrapper(SurveysAPI.startOrganisationFreeSurveyFacebookAPI, (dispatch, data) => {
    dispatch(showSuccess("Your survey is live!"));
    dispatch(setSurvey(data))
});

export const startSurveyFacebook = wrapper(SurveysAPI.startOrganisationSurveyFacebookAPI, (dispatch, data) => {
    dispatch(showSuccess("Your survey is live!"));
    dispatch(setSurvey(data))
});

export const startSurveyAudience = wrapper(SurveysAPI.startOrganisationSurveyAudienceAPI, (dispatch, data) => {
    dispatch(showSuccess("Your survey is live!"));
    dispatch(setSurvey(data))
});

export const startShareableLinkSurvey = wrapper(SurveysAPI.startOrganisationShareableLinkSurveyAPI, (dispatch, data) => {
    dispatch(showSuccess("Your survey is live!"));
    dispatch(setSurvey(data));
});

export const deleteSurvey = wrapper(SurveysAPI.deleteOrganisationSurveyAPI, (dispatch, r, orgId, surveyId) => {
    dispatch(removeSurvey(surveyId))
});

export const duplicateSurvey = wrapper(SurveysAPI.duplicateOrganisationSurveyAPI, (dispatch, survey) => {
    dispatch(duplicateSurveyAction(survey))
});

export const completeSurvey = wrapper(SurveysAPI.completeOrganisationSurveyAPI, (dispatch, survey) => {
    dispatch(completeSurveyAction(survey))
});

export const getOrganisationLiveSurveys = wrapper(SurveysAPI.getOrganisationLiveSurveysAPI, (dispatch, surveys) => {
    dispatch(setPaginatedSurveys(surveys));
});

export const getOrganisationDraftSurveys = wrapper(SurveysAPI.getOrganisationDraftSurveysAPI, (dispatch, surveys) => {
    dispatch(setDraftSurveys(surveys))
});

export const getOrganisationCompletedSurveys = wrapper(SurveysAPI.getOrganisationCompletedSurveysAPI, (dispatch, surveys) => {
    dispatch(setCompletedPaginatedSurveys(surveys))
});

export const getSurveyAgents = wrapper(SurveysAPI.getSurveyAgentsAPI, (dispatch, agents) => {
    dispatch(setSurveyAgents(agents))
});

export const getSurveyTemplates = wrapper(SurveysAPI.getSurveyTemplatesAPI, (dispatch, templates) => {
    dispatch(setSurveyTemplates(templates))
});

export const getShareSurveyLink = wrapper(SurveysAPI.getShareSurveyLinkAPI);
export const emailShareSurveyLink = wrapper(SurveysAPI.emailShareSurveyLinkAPI);

