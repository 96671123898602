import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import values from "lodash/values";
import getQuestionJSONReport from "../../../../../../../constants/report/getQuestionJSONReport";
import getQuestionCSVReport from "../../../../../../../constants/report/getQuestionCSVReport";
import { saveAs } from "file-saver";
import PopperMenu from "../../../../../../widgets/popperMenu/PopperMenu";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

class ReportDownload extends Component {


    constructor(props) {
        super(props);

        this.surveyReportMenuItems = [
            { text: "Download JSON", icon: CloudDownloadIcon, onPress: this.onDownloadJSONPress },
            { text: "Download CSV", icon: CloudDownloadIcon, onPress: this.onDownloadCSVPress },
        ];
    }


    onDownloadReportPress = (target) => {
        this.popper.show(target);
        // this.onDownloadCSVPress();
        // this.onDownloadJSONPress();
    };

    onDownloadJSONPress = () => {

        const report = {};
        (this.props.activeQuestions || []).map((question) => {
            const header = ((question.content || [])[0] || {}).text;
            const questionReport = this.props.questionReports[question.id];
            const isFreeText = (question.type === "FREE_TEXT");
            report[header] = getQuestionJSONReport(questionReport, question.choices, isFreeText);
            return null;
        });

        const json = "data:text/json;charset=utf-8," + JSON.stringify(report);
        saveAs(json, `${this.props.survey.name}.json`);

        this.popper.hide();
    };

    onDownloadCSVPress = () => {
        let report = [["question", "response", "count"]];
        (this.props.activeQuestions || []).map((question) => {
            let header
            if(((question.content || [])[0] || {}).text){
                header = ((question.content || [])[0] || {}).text;
            }else if(question.content.length>1 && ((question.content || [])[1] || {}).text){
                header = ((question.content || [])[1] || {}).text
            }
            const questionReport = this.props.questionReports[question.id];
            const isFreeText = (question.type === "FREE_TEXT");
            if (!!header) {
                const items = getQuestionCSVReport(header.replaceAll("\n", " ").split(",").join(" ").trim(), questionReport, question.choices, isFreeText, false, question);
                report = report.concat(items);
            }

            return null;
        });

        const csv = report.map((r) => {
            return r.join(",");
        }).join("\n");
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `${this.props.survey.name}.csv`);
        this.popper.hide();
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !nextProps.loading;
    }

    render() {
        return (
            <PopperMenu ref={(el) => this.popper = el}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        menuItems={this.surveyReportMenuItems} />
        );
    }
}

ReportDownload = connect((state, props) => {

    const orgId = state.settings.organisation;
    const surveyId = props.match.params.surveyId;
    const survey = state.surveys[surveyId];

    const questions = state.surveyQuestions[surveyId];
    const activeQuestions = values(questions).filter((question) => {
        return question.status === "ACTIVE";
    });

    return {
        orgId: orgId,
        surveyId: surveyId,
        survey: survey,
        questions,
        activeQuestions: activeQuestions,
        questionReports: state.reports.questions,
    };
}, null, null, { forwardRef: true })(ReportDownload);

ReportDownload = withRouter(ReportDownload);
export default ReportDownload;
