import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import styles from "./CreditCardDialog.module.scss";
import classnames from "classnames";
import Dialog from "../../dialog/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { addOrganisationCard, updateOrganisationCard } from "../../../../redux/actions/v1/organisations";
import CreditCardForm from "../../creditCardForm/CreditCardForm";
import Button from "../../button/Button";
import Error from "../../error/Error";
import TextInput from "../../textInput/TextInput";
import { AccountType } from "../../../../types/model/StickybeakAccount";
import sendEventToActiveCampaign from "../../../../redux/actions/api/v1/eventTracking";
import IconButton from "@mui/material/IconButton";

class CreditCardDialog extends Component {

    static propTypes = {
        className: PropTypes.string,

        shown: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onCardSuccess: PropTypes.func,

        orgId: PropTypes.number,
        cardId: PropTypes.string,
    };

    static defaultProps = {
        className: null,

        shown: false,
        onClose: () => null,
        onCardSuccess: () => null,

        orgId: null,
        cardId: null,
    };

    constructor (props) {
        super(props);


        this.state = {
            loading: false,
            name: "",
            error: null,
            touched: false,
        };
    }


    onSubmit = () => {

        this.setState({
            loading: true,
            error: null,
        });

        if ( this.props.isUpdating ) {
            this.onUpdateCardPress();
        } else {
            if ( this.props.isEnterprise && !this.state.name ) {
                // Enterprise customers must add a card nickname
                return this.onError("Card name is required");
            }
            this.onCreateNewCard();
        }
    };

    onUpdateCardPress = () => {
        const name = this.state.name;

        this.props.dispatch(updateOrganisationCard({
            orgId: this.props.orgId,
            stripeCardId: this.props.cardId,
            data: {
                name: name,
            },
            onSuccess: this.hide,
            onError: (err) => this.onError(err.message),
        }));
    };


    onCreateNewCard = () => {
        this.creditCardForm.getCreditCardToken();
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err,
        });
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.setState({
            loading: false,
            name: "",
            error: null,
            touched: false,
        });
        console.log("CC dialog on close");
        this.props.onClose();
    };

    onFocus = () => {
        this.setState({
            focused: true
        });
    };

    onCreditCardSuccess = (token) => {
        this.props.dispatch(addOrganisationCard({
            orgId: this.props.orgId,
            token: token,
            onSuccess: (card) => {
                this.props.onCardSuccess();
                sendEventToActiveCampaign(this.props.user.email, "Card_added", `country: ${card.country}`);
                if ( this.props.isEnterprise ) {
                    this.props.dispatch(updateOrganisationCard({
                        orgId: this.props.orgId,
                        stripeCardId: card.id,
                        data: {
                            name: this.state.name,
                        },
                        onSuccess: this.hide,
                        onError: (err) => this.onError(err.message),
                    }));
                } else {
                    this.hide();
                }
            },
            onError: (err) => this.onError(err.message),
        }));
    };

    onChange = (key, override) => {
        return (value) => {
            this.setState({
                [key]: !!override ? override : value,
            });
        };
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if ( !prevProps.isUpdating && this.props.isUpdating ) {
            this.onChange("name")((this.props.card || { name: "" }).name);
        }
    }

    render () {
        const className = classnames(styles.credit_card_dialog, this.props.className);

        let text = (this.props.card || { name: "" }).name;

        if ( this.state.hasFocused ) {
            text = this.state.name;
        }

        return (
            <Dialog className={className}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}
                    onClose={this.onClose}
                    custom
                    disableDismiss={this.state.loading}>

                <div className={styles.header}>
                    <span
                        className={styles.header_text}>{this.props.isUpdating ? "Update Card" : "Add Payment Method"}</span>
                    <div className={styles.close_wrapper}>
                        <IconButton onClick={this.hide} disabled={this.state.loading}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>

                <div className={styles.form}>

                    {this.props.isUpdating ?
                        <TextInput className={styles.card_name}
                                   onTextChange={this.onChange("name")}
                                   onFocus={this.onChange("hasFocused", true)}
                                   text={text || ""}
                                   label={"Card nickname"}/> :
                        (
                            <>
                                {this.props.isEnterprise && <TextInput className={styles.card_name}
                                                                       onTextChange={this.onChange("name")}
                                                                       onFocus={this.onChange("hasFocused", true)}
                                                                       text={text || ""}
                                                                       label={"Card nickname"}/>}

                                <ElementsConsumer>
                                    {({ stripe, elements }) => (
                                        <CreditCardForm ccRef={(ref) => this.creditCardForm = ref} stripe={stripe}
                                                        elements={elements}
                                                        onSuccess={this.onCreditCardSuccess}
                                                        onError={this.onError}/>
                                    )}
                                </ElementsConsumer>

                            </>
                        )}

                </div>


                <div className={styles.footer}>
                    <Error error={this.state.error}/>

                    <Button className={styles.submit_button}
                            turquoise
                            onPress={this.onSubmit}
                            disabled={this.props.isUpdating ? !text || text === (this.props.card || { name: "" }).name : false}
                            loading={this.state.loading}>{this.props.isUpdating ? "Update Card" : "Add Payment Method"}</Button>

                </div>
            </Dialog>
        );
    }
}


export default CreditCardDialog = connect((state, props) => {
    const orgId = props.orgId;
    const selectedAccount = state.organisations.accounts[orgId];
    const cardId = props.cardId;
    const card = (state.organisations.cards[orgId] || {})[cardId];
    const user = state.user;
    return {
        orgId: orgId,
        org: selectedAccount,
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
        cardId: cardId,
        card: card,
        isUpdating: !!cardId,
        user
    };
})(CreditCardDialog);


