import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./QuestionContent.module.scss";
import classnames from "classnames";
import MessageItemField from "../../../../../../../../../widgets/messageItem/MessageItemField";
import { IMAGE, QUESTION_TEXT, VIDEO } from "../../../../../../../../../../constants/contentTypes";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import MediaItemField from "./mediaItem/MediaItemField";
import { QUESTION_CHAR_LIMIT } from "../../../../../../../../../../constants/surveyBuilderConstants";
import AddRow from "../addRow/AddRow";
import Button from "../../../../../../../../../widgets/button/Button";
import DeleteBubbleIcon from "../../../../../../../../../../resources/js/DeleteBubbleIcon";
import UploadImageDialog from "../../../../../../../../../widgets/dialogs/uploadImageDialog/UploadImageDialog";
import { setSurveyFormFocused } from "../../../../../../../../../../redux/actions/v1/surveyFormState";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

class Content extends Component {

    static propTypes = {
        className: PropTypes.string,
        onDeleteQuestionContentPress: PropTypes.func,
        index: PropTypes.number,
        isComment: PropTypes.bool,
        isCustomEnding: PropTypes.bool,
        question: PropTypes.any,
        questionName: PropTypes.string
    };

    static defaultProps = {
        className: null,
        onDeleteQuestionContentPress: () => null,
        index: null,
        isComment: false,
    };

    onBlur = () => {
        this.props.dispatch(setSurveyFormFocused(false));
    };
    onFocus = () => {
        this.props.dispatch(setSurveyFormFocused(true));
    };

    onDeleteQuestionContentPress = () => {
        this.props.onDeleteQuestionContentPress(this.props.index);
    };

    getPlaceholderText = () => {
        if ( !!this.props.placeholder ) {
            return this.props.placeholder;
        } else if ( !!this.props.content.placeholder ) {
            return this.props.content.placeholder;
        } else {
            return "Tap to edit";
        }
    };

    renderContentItem = (contentType) => {
        switch ( contentType ) {
            case IMAGE: {
                return (
                    <MediaItemField
                        name={`${this.props.name}.imageURL`}
                        initialValue={this.props.content.value}
                        contentType={contentType}/>
                );
            }
            case VIDEO: {
                return (
                    <MediaItemField
                        name={`${this.props.name}.video`}
                        contentType={contentType}/>
                );
            }
            case QUESTION_TEXT: {
                return (
                    <div className={styles.content_message_wrapper}>
                        <MessageItemField
                            containerClassName={styles.question_bubble}
                            name={`${this.props.name}.text`}
                            editable={this.props.editable}
                            elementId={this.props.name}
                            isCustomEnding={this.props.isCustomEnding}
                            placeholder={this.getPlaceholderText()}
                            characterLimit={QUESTION_CHAR_LIMIT}
                            classNameText={styles.content_bubble}
                            autoFocus={this.props.content.autoFocus}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            // onKeyDown={this.props.onKeyDown}
                        />
                        {(this.props.index>0 && (this.props.question||{}).type==="MUTLI_ATTRIBUTE") &&
                            <Button round white
                                    className={classnames(styles.delete_button)}
                                    onPress={this.props.onDeleteQuestionContentPress}>
                                <DeleteBubbleIcon/>
                            </Button>
                        }
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    render () {
        return (
            <div key={this.props.name} className={styles.content_item}>
                {this.renderContentItem(this.props.content.type)}
                {this.props.showDelete && (
                    <Button round white
                            className={classnames(styles.delete_button, { [styles.center]: this.props.content.type === "QUESTION_TEXT" })}
                            onPress={this.props.onDeleteQuestionContentPress}>
                        <DeleteBubbleIcon/>
                    </Button>
                )}
            </div>
        );
    }
}

Content = connect((state, props) => {

    let selector = formValueSelector("editSurvey");
    if ( props.isCustomEnding ) {
        selector = formValueSelector("customEnding");
    }
    return {
        content: selector(state, `${props.name}`)
    };
})(Content);


export default class QuestionContent extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string,
        isComment: PropTypes.bool,
        isCustomEnding: PropTypes.bool,
        placeholder: PropTypes.string,
        editable: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        name: null,
        isComment: false,
        isCustomEnding: false,
        placeholder: null,
        editable: true

    };

    constructor (props) {
        super(props);

        this.state = {
            openPopover: false,
            anchorEl: null
        };

        this.uploadImageDialog = null;
    }

    onAddQuestionContentPress = () => {
        this.setState({ openPopover: true });
    };

    setAnchorEl = (elRef) => {
        this.setState({ anchorEl: elRef });
    };

    onDeleteQuestionContentPress = (index) => {
        if(this.props.question.type==="MULTI_ATTRIBUTE"){
            const newQuestion = this.props.question;
            newQuestion.multiAttributeQuestion.attributes = this.props.content.length - 2;
            this.props.dispatch(change("editSurvey", this.props.questionName, newQuestion))
        }
        this.props.fields.remove(index);
        this.props.afterDelete();
    };

    onAddQuestionContentSubmit = (contentType) => {
        if ( this.props.question.type === "MULTI_ATTRIBUTE" ) {
            const newQuestion = this.props.question;
            newQuestion.multiAttributeQuestion.attributes = this.props.content.length;
            this.props.dispatch(change("editSurvey", this.props.questionName, newQuestion))
        }
        this.props.fields.push({
            type: contentType,
            autoFocus: true,
            placeholder: "Start typing"
        });

    };

    onAddImageQuestionContentSubmit = (url) => {
        this.props.fields.push({
            type: "IMAGE",
            value: url,
            url: url
        });
    };

    closePopover = () => {
        this.setState({ openPopover: false });
    };

    render () {
        const className = classnames(styles.question_content, this.props.className);
        return (
            <div className={styles.question_content_wrapper}>
                <div className={className}>
                    <div className={styles.agent_icon}/>
                    <div className={styles.content_messages}>
                        {this.props.fields.map((name, i) => {
                            return (
                                <div key={`${i}_${this.props.fields.length}`} className={styles.content_wrapper}>
                                    <Content key={`${i}_${this.props.fields.length}`}
                                             name={name}
                                             index={i}
                                             survey={this.props.survey}
                                             onDeleteQuestionContentPress={() => this.onDeleteQuestionContentPress(i)}
                                             placeholder={this.props.placeholder}
                                        // onKeyDown={this.onKeyDown}
                                             showDelete={this.props.showDelete && i > 0}
                                             isCustomEnding={this.props.isCustomEnding}
                                             editable={this.props.editable}
                                             isComment={this.props.isComment}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {!this.props.hideAddContent && (
                    <div className={styles.add_row}>

                        {(this.props.question||{}).type==="MULTI_ATTRIBUTE"?
                            <AddRow text={"Add attribute row"}
                                    setAnchorEl={this.setAnchorEl}
                                    onAddNewQuestionPress={()=>this.onAddQuestionContentSubmit("QUESTION_TEXT")}/>
                            :
                            <AddRow text={"Add image or text"}
                                    setAnchorEl={this.setAnchorEl}
                                    onAddNewQuestionPress={this.onAddQuestionContentPress}/>
                        }
                        <Popover anchorEl={this.state.anchorEl}
                                 open={this.state.openPopover}
                                 classes={{
                                     paper: styles.popover_paper
                                 }}
                                 onClose={this.closePopover}
                                 anchorOrigin={{
                                     vertical: "center",
                                     horizontal: "right",
                                 }}
                                 transformOrigin={{
                                     vertical: "center",
                                     horizontal: "left",
                                 }}
                        >
                            <MenuItem classes={{ root: styles.popover_menu_item }} onClick={() => {
                                this.onAddQuestionContentSubmit("QUESTION_TEXT");
                                this.setState({ openPopover: false });
                            }}>
                                Add text
                            </MenuItem>
                            <MenuItem classes={{ root: styles.popover_menu_item }} onClick={() => {
                                this.uploadImageDialog.show();
                                this.setState({ openPopover: false });
                            }}>
                                Upload image
                            </MenuItem>
                        </Popover>
                    </div>
                )}
                <UploadImageDialog dialogRef={(ref) => this.uploadImageDialog = ref}
                                   onSubmit={this.onAddImageQuestionContentSubmit}
                />
            </div>
        );
    }
}

QuestionContent = connect((state, props) => {
    const selector = formValueSelector("editSurvey");

    return {
        content: selector(state, props.fields.name)
    };
})(QuestionContent);
