import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./UpdateSubscriptionPlanDialog.module.scss";
import classnames from "classnames";
import {reduxForm, reset} from "redux-form";
import connect from "react-redux/es/connect/connect";
import Dialog from "../../dialog/Dialog";
import {
    calculateSubscriptionDifference,
    createUpdateSaaSSubscription,
    setSubscriptionDifference
} from "../../../../redux/actions/v1/organisations";
import fromCents from "../../../../constants/localization/currencies/fromCents";
import fireTagEvent from "../../../../constants/tagmanager";
import AnalyticsAPI from "../../../../util/Analytics";
import sendEventToActiveCampaign from "../../../../redux/actions/api/v1/eventTracking";

class UpdateSubscriptionPlanDialog extends Component {

    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.newPrice !== this.props.newPrice && this.props.newPrice) {
            this.props.dispatch(calculateSubscriptionDifference({
                accountId: this.props.org.id,
                newPricingId: this.props.newPrice.id,
                onSuccess: () => this.setState({loading: false})
            }));
        }
    }

    onConfirmPress = (isUpgrade, upgradeLater = false) => {

        const price = this.dialog.getParams();
        let previousPlan = this.props.currentSub.tier.name;
        let eventName = `${isUpgrade ? "Upgrade" : "Downgrade"} to ${this.props.newTier.name}`;
        let upgradeTime = upgradeLater ? "later" : "now";

        this.setState({
            loading: true,
            error: null,
        });

        this.props.dispatch(createUpdateSaaSSubscription({
            orgId: this.props.orgId,
            subId: price.id,
            upgradeLater: upgradeLater,
            onSuccess: () => this.onSuccess(eventName, previousPlan, upgradeTime, isUpgrade),
            onError: this.onError,
        }));

    };

    onSuccess = (eventName, previousPlan, upgradeTime, isUpgrade) => {
        fireTagEvent(eventName, {
            previousPlan: previousPlan,
            upgradeTime: upgradeTime
        });
        if(isUpgrade){
            sendEventToActiveCampaign(this.props.user.email, "Tier_upgrade", `Upgraded from ${previousPlan.toUpperCase()} to ${this.props.newTier.name.toUpperCase()}`)
        }else {
            sendEventToActiveCampaign(this.props.user.email, "Tier_downgrade", `Downgraded from ${previousPlan.toUpperCase()} to ${this.props.newTier.name.toUpperCase()}`)

        }
        AnalyticsAPI.accountUpgradeEvent(this.props.newTier.name.toUpperCase())
        this.setState({
            loading: false,
            error: null,
        }, this.hide)
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        })
    };

    hide = () => {

        if (this.state.loading) {
            return;
        }

        this.dialog.hide();
        this.props.dispatch(setSubscriptionDifference(null));
    };

    onClose = () => {
        this.props.onClose();
        this.setState({
            error: null
        })
        this.props.dispatch(reset(this.props.form));
        this.props.dispatch(setSubscriptionDifference(null));
    };

    checkIfUpgrade = () => {
        if (!this.props.currentSub || !this.props.newTier) {
            return false;
        }

        if (this.props.currentSub && this.props.newTier) {
            return this.getMaxRespondents(this.props.currentSub.tier) < this.getMaxRespondents(this.props.newTier) || this.props.currentSub.pricing.interval !== this.props.pricingInterval;
        }

        return true;
    };

    getMaxRespondents = (tier) => {
        return tier.maxRespondentsPerSurvey || Number.MAX_SAFE_INTEGER;
    }

    getTierDetail = (newTier, price) => {

        if (!price) {
            return null;
        }

        const subtitle = `${fromCents(price.amount, price.currency, true)}/${price.interval}`;

        const maxUsers = newTier.maxUsers === 1000000 ? "Unlimited" : newTier.maxUsers;
        const maxRespondentsPerSurvey = !newTier.maxRespondentsPerSurvey ? "Unlimited" : newTier.maxRespondentsPerSurvey;
        const maxAudiences = !newTier.maxAudiences ? "Unlimited" : newTier.maxAudiences;

        return (
            <p>
                {maxUsers === "Unlimited" ? "Unlimited Users" : `Up to ${maxUsers} User${maxUsers > 1 ? "s": ""}`}
                <br/>
                {maxRespondentsPerSurvey} responses per survey
                <br/>
                {maxAudiences} contact lists
                <br/><br/>
                {this.props.currentSub && this.props.currentSub.status !== "trialing" ? subtitle : null}
            </p>
        );
    };

    render() {
        const className = classnames(styles.create_update_audience, this.props.className);

        const newTier = (this.props.newTier || {});
        let price = null;
        (newTier.pricing || []).map((pricing) => {
            if (pricing.interval === this.props.pricingInterval) {
                price = pricing;
            }
            return null;
        });


        let isUpgrade = this.checkIfUpgrade();
        let title = "";

        if (this.props.currentSub && this.props.newTier) {
            title = `${isUpgrade ? "Upgrade" : "Downgrade"} to ${this.props.newTier.name}`
        }

        let difference = 0;

        if (this.props.difference) {
            difference = fromCents(this.props.difference, price.currency, true);
        }

        const currentSub = this.props.currentSub || {};
        const subscriptionPricing = currentSub.pricing || {};

        let actions = [
            {
                text: `${isUpgrade ? `Upgrade now (${difference})` : "Keep current plan"}`,
                onPress: () => isUpgrade ? this.onConfirmPress(isUpgrade) : this.hide(),
                props: {loading: this.state.loading, green: true}
            }
        ];

        if(subscriptionPricing.amount) {
            actions.push({
                text: `${isUpgrade ? "Upgrade at end of term" : "Downgrade plan"}`,
                onPress: () => this.onConfirmPress(isUpgrade, true),
                props: {loading: this.state.loading, white: true, borderLight: true}
            });
        }
        actions.push({
                text: "Cancel",
                onPress: this.hide,
                props: {plain: true, borderLight: true, disabled: this.state.loading}
            });

        if (this.props.currentSub && this.props.currentSub.status === "trialing") {
            actions = [
                {
                    text: `${isUpgrade ? `Upgrade now` : "Downgrade now"}`,
                    onPress: () => this.onConfirmPress(true),
                    props: {loading: this.state.loading, green: true}
                },
                {
                    text: "Cancel",
                    onPress: this.hide,
                    props: {plain: true, borderLight: true, disabled: this.state.loading}
                },
            ]
        }


        return (
            <Dialog
                title={title}
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                className={className}
                disableDismiss={this.state.loading}
                onClose={this.onClose}
                error={this.state.error}
                actionsClassName={styles.dialog_actions}
                actions={actions}
            >
                <div className={styles.tier_detail}>
                    {this.getTierDetail(newTier, price)}
                </div>
            </Dialog>
        );
    }
}

UpdateSubscriptionPlanDialog = reduxForm({
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        return errors;
    }
})(UpdateSubscriptionPlanDialog);

export default connect((state, props) => {
    const orgId = props.orgId;
    const user = state.user
    return {
        form: `createUpdateAudience${orgId}`,
        orgId: orgId,
        difference: state.organisations.difference,
        user
    };
})(UpdateSubscriptionPlanDialog);
