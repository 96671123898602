/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-05.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


export default function getQuestionJSONReport(questionReport = {}, choices = [], isFreeText = false) {


    let questionReportAnswers = {};
    if (isFreeText) {
        questionReportAnswers = questionReport.answers
    } else {
        choices.map((choice) => (questionReportAnswers[choice.value] = (questionReport.answers || {})[choice.id] || 0));
    }

    return questionReportAnswers;
}