import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from "../../../../../../../../../widgets/dialog/Dialog";
import Loader from "../../../../../../../../../widgets/loader/Loader";
import Button from "../../../../../../../../../widgets/button/Button";
import styles from "./previewDialog.module.scss";
import FlyingKick from "../../../../../../../../../../resources/gif/rueben/flyingkick.gif";

class PreviewDialog extends Component {
    state = {
        loading: this.props.loading
    };

    hide = () => {
        this.dialog.hide();
    };

    render() {
        return (
            <Dialog
                className={styles.preview_survey_dialog}
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                custom>
                {this.props.loading ?
                    <div className={styles.preview_dialog_container}>
                        <h3>Loading preview</h3>
                        <Loader/>
                    </div>
                    :
                    <div className={styles.preview_survey_dialog}>
                        <div className={styles.gif_container}>
                            <img src={FlyingKick} alt={""}/>
                        </div>
                        <div className={styles.preview_content}>
                            <h2>Your preview is ready!</h2>
                            <Button onPress={this.props.onPress}>
                                Let's go
                            </Button>
                        </div>
                    </div>
                }
            </Dialog>
        );
    }
}

export default PreviewDialog = connect()(PreviewDialog);