import React, {Component, Fragment} from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types"
import styles from "./SelectAgent.module.scss"
import Content from "../../../../../../widgets/content/Content"
import Loader from "../../../../../../widgets/loader/Loader"
import Button from "../../../../../../widgets/button/Button"
import noAgent from "../../../../../../../resources/png/noAgent.png"
import values from "lodash/values"

class SelectAgent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    static propTypes = {
        agents: PropTypes.object,
    };

    static defaultProps = {
        agents: {}
    };

    // componentDidMount() {
    //     this.props.dispatch(getSurveyAgents({
    //         onSuccess: () => {
    //             this.setState({loading: false})
    //         }
    //     }));
    // }

    onContinuePress = () => {
        this.props.history.push("/surveys/create/template")
    };

    render() {
        const agents = values(this.props.agents);
        return (
            <Content headerText={"Create new survey"} className={styles.agent_container}>
                <h3 className={styles.select_agent_title}>1. Select an agent</h3>
                {this.state.loading
                    ? <div className={styles.loading_container}><Loader/></div>
                    : <Fragment>
                        <div className={styles.agents_wrapper}>
                            {agents.map((agent) => {
                                return (
                                    <div className={styles.agent} key={agent.id}>
                                        <img src={agent.image} className={styles.agent_image} alt={""}/>
                                        <h3 className={styles.agent_name}>{agent.name}</h3>
                                        <a href={"localhost:3000/"}><h4
                                            className={styles.agent_link}>Meet {agent.name}</h4></a>
                                    </div>
                                )
                            })}
                            <div className={styles.no_agent}>
                                <img className={styles.no_agent_image} src={noAgent} alt={""}/>
                                <h3 className={styles.no_agent_text}>Run the survey without an agent</h3>
                            </div>
                        </div>
                        < div
                            className={styles.continue_button}>
                            <Button
                                greyDark
                                onPress={this.onContinuePress}>Continue</Button>
                        </div>
                    </Fragment>
                }
            </Content>
        )
    }
}

export default SelectAgent = connect((state) => {
        return {agents: state.surveyAgents.agents}
    }
)(SelectAgent);