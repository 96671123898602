import React from "react"

import styles from "./Template.module.scss"
import Button from "../../../../../../../widgets/button/Button"

export default function Template(props) {
    return (
        <div className={styles.template_container}>
            <div className={styles.template_image_background}>
                <h3 className={styles.template_title}>{props.title}</h3>
                <img className={styles.template_image} src={props.image} alt={""}/>
            </div>
            <div className={styles.template_body_container}>
                {!!props.description && <div className={styles.template_body_inner}>
                    <h3>Survey summary</h3>
                    <p className={styles.template_body_text}>{props.description}</p>
                </div>}
                <div className={styles.continue_button_wrapper}>
                    <div className={styles.continue_button_inner}>
                        <Button green
                                onPress={props.onCreateSurveyPress}>Continue</Button>
                    </div>
                </div>
            </div>
            <div className={styles.template_background_wrapper}>
                <div className={styles.template_layer2}/>
                <div className={styles.template_layer3}/>
            </div>
        </div>
    )
}