import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Genders.module.scss";
import classnames from "classnames";
import Button from "../../../../../../../../../../../widgets/button/Button";
import isEqual from "lodash/isEqual";
import Radio from "@mui/material/Radio";

class Genders extends Component {

    ALL = ["ALL"];
    MALE = ["MALE"];
    FEMALE = ["FEMALE"];
    MALE_FEMALE_SPLIT = ["MALE", "FEMALE"];


    onMaleFemaleSplitClick = () => {
        this.props.input.onChange(this.MALE_FEMALE_SPLIT);
    };

    onMalePress = () => {
        this.props.input.onChange(this.MALE);
    };

    onFemalePress = () => {
        this.props.input.onChange(this.FEMALE);
    };

    onRemoveButtonPress = () => {
        this.props.input.onChange(this.ALL);
        this.props.onRemoveClick();
    };


    render() {
        const className = classnames(styles.genders, this.props.className);

        const maleFemaleSplitSelected = isEqual(this.props.input.value, this.MALE_FEMALE_SPLIT);
        const maleSelected = isEqual(this.props.input.value, this.MALE);
        const femaleSelected = isEqual(this.props.input.value, this.FEMALE);

        return (
            <div className={className}>
                <p className={styles.warning_message}>Please note that this may affect pricing.</p>
                <div className={styles.radio_select}>
                    <div>
                        <Radio className={styles.radio} onChange={this.onMaleFemaleSplitClick} checked={maleFemaleSplitSelected} />
                        50% women / 50% men
                    </div>
                    <div>
                        <Radio className={styles.radio} onChange={this.onFemalePress} checked={femaleSelected} />
                        Only women
                    </div>
                    <div>
                        <Radio className={styles.radio} onChange={this.onMalePress} checked={maleSelected} />
                        Only men
                    </div>
                </div>
                <Button borderDark plain onPress={this.onRemoveButtonPress}>Remove</Button>
            </div>
        );
    }
}

Genders.propTypes = {
    className: PropTypes.string,
};

Genders.defaultProps = {
    className: null,
};


export default Genders;
