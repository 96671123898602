import React from "react";

export default function KeyboardImage() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="135.57" height="58.226" viewBox="0 0 135.57 58.226">
            <g id="keyboard" transform="translate(-130.215 -295)">
                <rect id="Rectangle_3564" data-name="Rectangle 3564" width="135.57" height="58.226" rx="2" transform="translate(130.215 295)" fill="#cdd2d5"/>
                <g id="Group_17219" data-name="Group 17219" transform="translate(136.847 300.566)">
                    <rect id="Rectangle_3526" data-name="Rectangle 3526" width="7.66" height="7.96" transform="translate(0 0)" fill="#fff"/>
                    <rect id="Rectangle_3527" data-name="Rectangle 3527" width="7.656" height="7.96" transform="translate(11.458 0)" fill="#fff"/>
                    <rect id="Rectangle_3528" data-name="Rectangle 3528" width="7.657" height="7.96" transform="translate(22.915 0)" fill="#fff"/>
                    <rect id="Rectangle_3529" data-name="Rectangle 3529" width="7.659" height="7.96" transform="translate(34.369 0)" fill="#fff"/>
                    <rect id="Rectangle_3530" data-name="Rectangle 3530" width="7.655" height="7.96" transform="translate(45.827 0)" fill="#fff"/>
                    <rect id="Rectangle_3531" data-name="Rectangle 3531" width="7.659" height="7.96" transform="translate(57.283 0)" fill="#fff"/>
                    <rect id="Rectangle_3532" data-name="Rectangle 3532" width="7.658" height="7.96" transform="translate(68.738 0)" fill="#fff"/>
                    <rect id="Rectangle_3533" data-name="Rectangle 3533" width="7.656" height="7.96" transform="translate(80.196 0)" fill="#fff"/>
                    <rect id="Rectangle_3534" data-name="Rectangle 3534" width="7.66" height="7.96" transform="translate(91.65 0)" fill="#fff"/>
                    <rect id="Rectangle_3535" data-name="Rectangle 3535" width="7.657" height="7.96" transform="translate(103.109 0)" fill="#fff"/>
                    <rect id="Rectangle_3536" data-name="Rectangle 3536" width="7.657" height="7.96" transform="translate(114.565 0)" fill="#fff"/>
                    <rect id="Rectangle_3537" data-name="Rectangle 3537" width="7.66" height="7.957" transform="translate(0 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3538" data-name="Rectangle 3538" width="7.656" height="7.957" transform="translate(11.458 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3539" data-name="Rectangle 3539" width="7.657" height="7.957" transform="translate(22.915 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3540" data-name="Rectangle 3540" width="7.659" height="7.957" transform="translate(34.369 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3541" data-name="Rectangle 3541" width="7.655" height="7.957" transform="translate(45.827 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3542" data-name="Rectangle 3542" width="7.659" height="7.957" transform="translate(57.283 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3543" data-name="Rectangle 3543" width="7.658" height="7.957" transform="translate(68.738 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3544" data-name="Rectangle 3544" width="7.656" height="7.957" transform="translate(80.196 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3545" data-name="Rectangle 3545" width="7.66" height="7.957" transform="translate(91.65 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3546" data-name="Rectangle 3546" width="7.657" height="7.957" transform="translate(103.109 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3547" data-name="Rectangle 3547" width="7.657" height="7.957" transform="translate(114.565 12.839)" fill="#fff"/>
                    <rect id="Rectangle_3548" data-name="Rectangle 3548" width="15.285" height="7.96" transform="translate(0 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3549" data-name="Rectangle 3549" width="15.285" height="7.96" transform="translate(106.921 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3550" data-name="Rectangle 3550" width="7.656" height="7.96" transform="translate(17.801 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3551" data-name="Rectangle 3551" width="7.656" height="7.96" transform="translate(29.075 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3552" data-name="Rectangle 3552" width="7.657" height="7.96" transform="translate(40.346 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3553" data-name="Rectangle 3553" width="7.657" height="7.96" transform="translate(51.622 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3554" data-name="Rectangle 3554" width="7.66" height="7.96" transform="translate(62.892 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3555" data-name="Rectangle 3555" width="7.657" height="7.96" transform="translate(74.168 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3556" data-name="Rectangle 3556" width="7.657" height="7.96" transform="translate(85.443 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3557" data-name="Rectangle 3557" width="7.66" height="7.96" transform="translate(96.713 25.675)" fill="#fff"/>
                    <rect id="Rectangle_3558" data-name="Rectangle 3558" width="7.66" height="7.96" transform="translate(0 38.513)" fill="#fff"/>
                    <rect id="Rectangle_3559" data-name="Rectangle 3559" width="7.656" height="7.96" transform="translate(11.458 38.513)" fill="#fff"/>
                    <rect id="Rectangle_3560" data-name="Rectangle 3560" width="7.657" height="7.96" transform="translate(22.915 38.513)" fill="#fff"/>
                    <path id="Path_7445" data-name="Path 7445" d="M426.4,660.721H391.3v7.96h54.213v-7.96H426.4Z" transform="translate(-357.663 -622.208)" fill="#fff"/>
                    <rect id="Rectangle_3561" data-name="Rectangle 3561" width="7.66" height="7.96" transform="translate(91.65 38.513)" fill="#fff"/>
                    <rect id="Rectangle_3562" data-name="Rectangle 3562" width="7.657" height="7.96" transform="translate(103.109 38.513)" fill="#fff"/>
                    <rect id="Rectangle_3563" data-name="Rectangle 3563" width="7.657" height="7.96" transform="translate(114.565 38.513)" fill="#fff"/>
                </g>
            </g>
        </svg>

    )
}