import { Actions, Types } from "../actions/estimate";

const initialState: any = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function estimate(state = initialState, action: ActionTypes): any {
    switch (action.type) {
        case Types.SET_ESTIMATE: {
            const { payload } = action as ActionValueTypes<Types.SET_ESTIMATE>;
            return payload!;
        }
        case Types.SET_LOADING: {
            const {payload} = action as ActionValueTypes<Types.SET_LOADING>;
            return {
                ...state,
                loading: payload
            }
        }
        default: {
            return state;
        }
    }
}
