export const SINGLE_CHOICE = "SINGLE_CHOICE";
export const MULTI_CHOICE = "MULTI_CHOICE";
export const FREE_TEXT = "FREE_TEXT";
export const LONG_FORM_SINGLE_CHOICE = "LONG_FORM_SINGLE_CHOICE";
export const LONG_FORM_MULTI_CHOICE = "LONG_FORM_MULTI_CHOICE";
export const EMAIL_CAPTURE = "EMAIL_CAPTURE";
export const IMAGE_COMPARE = "IMAGE_COMPARE";
export const NEW_PROFIT = "NEW_PROFIT";
export const COMMENT = "COMMENT";
export const RANKING = "RANKING";
export const RATING = "RATING";
export const EMOJI_RATING="EMOJI_RATING";
export const LOVE_HATE="LOVE_HATE";
export const NPS="NPS";
export const MULTI_ATTRIBUTE="MULTI_ATTRIBUTE";

export const QuestionText = {
    SINGLE_CHOICE: "Multiple choice (single answer only)",
    MULTI_CHOICE: "Multiple choice (multiple answers allowed)",
    FREE_TEXT: "Free text",
    LONG_FORM_SINGLE_CHOICE: "Long list (single answer only)",
    LONG_FORM_MULTI_CHOICE: "Long list (multiple answers allowed)",
    RANKING: "Ranking question",
    RATING: "Rating",
    EMOJI_RATING:"Emoji rating",
    LOVE_HATE: "Thumbs up/down",
    NPS:"Net promoter score (NPS)",
    MULTI_ATTRIBUTE: "Multi-attribute scale"
};

export const QuestionTypes = [
    { id: SINGLE_CHOICE, value: SINGLE_CHOICE, text: QuestionText[SINGLE_CHOICE] },
    { id: MULTI_CHOICE, value: MULTI_CHOICE, text: QuestionText[MULTI_CHOICE] },
    { id: FREE_TEXT, value: FREE_TEXT, text: QuestionText[FREE_TEXT] },
    { id: LONG_FORM_SINGLE_CHOICE, value: LONG_FORM_SINGLE_CHOICE, text: QuestionText[LONG_FORM_SINGLE_CHOICE] },
    { id: LONG_FORM_MULTI_CHOICE, value: LONG_FORM_MULTI_CHOICE, text: QuestionText[LONG_FORM_MULTI_CHOICE] },
    { id: RANKING, value: RANKING, text: QuestionText[RANKING] },
    {id: RATING, value: RATING, text: QuestionText[RATING]},
    {id: EMOJI_RATING, value: EMOJI_RATING, text: QuestionText[EMOJI_RATING]},
    {id: NPS, value: NPS, text: QuestionText[NPS]},
    {id: LOVE_HATE, value: EMOJI_RATING, text: QuestionText[LOVE_HATE]},
    {id: MULTI_ATTRIBUTE, value: MULTI_ATTRIBUTE, text: QuestionText[MULTI_ATTRIBUTE]},


];

export default QuestionTypes;


