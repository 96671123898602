/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 6/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import TextInputField from "../../textInput/TextInputField";
import {formValueSelector, reduxForm, reset} from "redux-form";
import Dialog from "../../dialog/Dialog";
import SelectField from "../../select/SelectField";
import {addUserToOrganisation, updateUserInOrganisation} from "../../../../redux/actions/v1/organisations";
import roles from "../../../../constants/roles";
import styles from "./UserAccountDialog.module.scss"

const INITIAL_STATE = {
    loading: false,
    error: null,
};

class UserAccountDialog extends Component {

    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,

        orgId: PropTypes.number,
        userId: PropTypes.number,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,

        orgId: null,
        userId: null,
    };

    state = INITIAL_STATE;

    onSubmit = (values) => {
        values.email = values.email.trim()
        this.setState({
            loading: true,
        });

        const payload = {
            accountId: this.props.orgId,
            data: values,
            onSuccess: this.hide,
            onError: this.onError,
        };

        if (this.props.updating) {
            this.props.dispatch(updateUserInOrganisation(payload, this.props.orgId));
        } else {
            this.props.dispatch(addUserToOrganisation(payload, this.props.orgId));
        }
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        });
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.setState(INITIAL_STATE);
        this.props.onClose();
        this.props.dispatch(reset(this.props.form));
    };

    getDescription = () => {
        switch (this.props.role) {

            case "USER": {
                return "This User will have read and write access to surveys, reports and audience’s. They will not have access to Account Settings and Billing.";
            }

            case "SUPER_ADMIN": {
                return "This User will have full access to this Organisation’s account, including Account Settings and Billing.";
            }

            default: {
                return "";
            }
        }
    };

    render() {

        const actions = [
            {
                text: "Cancel",
                onPress: this.hide,
                props: {
                    plain: true,
                    disabled: this.state.loading
                }
            },
            {
                text: this.props.updating ? "Update" : "Add",
                props: {
                    type: "submit",
                    loading: this.state.loading,
                    disabled: (!this.props.valid || this.props.pristine)
                }
            }
        ];

        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                onClose={this.onClose}
                actions={actions}
                error={this.state.error}
                title={!!this.props.updating ? "Edit user" : "Add user"}
                onSubmit={this.props.handleSubmit(this.onSubmit)}
                disableDismiss={this.state.loading}>

                {this.props.updating ?
                    <TextInputField
                        disabled
                        name={"name"}
                        label={"Name"}
                        autoFocus/> : null}

                <TextInputField
                    disabled={this.props.updating}
                    name={"email"}
                    label={"Email"}
                    autoFocus/>

                <SelectField name={"role"}
                             label={"Role"}
                             options={roles}/>

                <p className={styles.permission_description}>{this.getDescription()}</p>

            </Dialog>
        );
    }
}

UserAccountDialog = reduxForm({
    form: "userAccount",
    enableReinitialize: true,
    validate: (values, props) => {
        const errors = {};

        if (!props.pristine) {
            if (!values.email || values.email === "") {
                errors.email = "Required"
            }

            if (!values.role) {
                errors.role = "Required"
            }
        }

        return errors;
    }
})(UserAccountDialog);


const selector = formValueSelector("userAccount");

export default UserAccountDialog = connect((state, props) => {

    const orgId = Number(props.orgId);
    const userId = props.userId;
    const user = (state.organisations.users[orgId] || {})[userId];

    const initialValues = (user || {role: "USER"});

    return {
        updating: !!user,
        orgId: orgId,
        org: state.organisations.accounts[orgId],
        user: user,

        initialValues: initialValues,

        role: selector(state, "role"),
    };
})(UserAccountDialog);
