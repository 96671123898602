import post from "./util/post";

function validateRecaptcha(token, recaptcha) {
    return post("/v1/recaptcha/validate", "", recaptcha);
}

const RecaptchaAPI = {
    validateRecaptcha
};
export default RecaptchaAPI;
