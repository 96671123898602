/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-05.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Types from "../../actions/v1/facebook";

export const placeholderPriceEstimate = {
    currency: "USD",
    estBudget: 0,
    estCostPerRespondent: 0,
    estNumberRespondents: 0,
    numberOfDays: 0,
};

const initialState = {
    priceEstimate: null,
};

export default function facebook(state = initialState, action = {type: null, payload: null}) {
    switch (action.type) {

        case Types.SET_PRICE_ESTIMATE: {
            return {
                ...state,
                priceEstimate: action.priceEstimate,
            };
        }

        default: {
            return state;
        }
    }
}
