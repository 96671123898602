/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import { combineReducers } from "redux";
import formReducer from "redux-form/lib/reducer";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { Action } from "../actions/helpers/createAction";
import * as SettingTypes from "../actions/v1/settings";
import adSets from "./adSets";
import reports from "./reports";
import surveys from "./surveys";
import user from "./user";
import audienceMembers from "./v1/audienceMembers";
import audiences from "./v1/audiences";
import auth from "./v1/auth";
import facebook from "./v1/facebook";
import organisations from "./v1/organisations";
import saas from "./v1/saas";
import settings from "./v1/settings";
import snackbars from "./v1/snackbars";
import surveyAgents from "./v1/surveyAgents";
import surveyQuestions from "./v1/surveyQuestions";
import surveyTemplates from "./v1/surveyTemplates";
import surveyFormState from "./v1/surveyFormState";
import estimate from "./estimate";

import emojiTemplates from "./v1/emojiTemplates";
import surveyEnding from "./v1/surveyEnding";
const reducers = {
    form: formReducer,

    // Typescript converted Reducers
    adSets,
    estimate,
    reports,
    surveys,
    user,

    // JS Reducers
    surveyEnding,
    snackbars,
    organisations,
    surveyQuestions,
    surveyAgents,
    surveyTemplates,
    surveyFormState,
    audiences,
    audienceMembers,
    emojiTemplates,
    saas,
    settings,
    auth,
    facebook,
};

export const PERSIST_CONFIG: PersistConfig<unknown> = {
    key: "root",
    storage,
    whitelist: ["settings"],
};

const root = combineReducers(reducers);
const persistedReducer = persistReducer(PERSIST_CONFIG, (state: ApplicationState, action: Action<unknown>) => {

    if (action.type === SettingTypes.PURGE_REDUX_STORE) {
        return root(undefined, action);
    }

    if (action.type === SettingTypes.SET_ACTIVE_ORGANISATION) {
        const newState = {
            ...state,
            surveys: undefined,
            surveyQuestions: undefined,
            audiences: undefined,
            audienceMembers: undefined,
        };
        // @ts-ignore
        return root(newState, action);
    }
    return root(state, action);
});

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
