/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export function getSaasTiersAPI(token, params) {
    return window.get(`/v1/saas/tiers`, token, params);
}