/*eslint-disable @typescript-eslint/no-explicit-any*/
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../../reducers/root";
import { Action } from "./createAction";

type StoreProps = {
    dispatch: ThunkDispatch<ApplicationState, unknown, AnyAction>;
    getState: () => ApplicationState;
};

export type DispatchFunctionSuccess<T, P extends Parameters<any>> = (store: StoreProps, data: T, ...params: P) => void;

export type DispatchFunctionFailure<P extends Parameters<any>> = (store: StoreProps, data: unknown, ...params: P) => void;

type WrapperOptions<T, P extends Parameters<any>> = {
    action?: (payload: T) => Action<T>;
    onSuccess?: DispatchFunctionSuccess<T, P>;
    onFailure?: DispatchFunctionFailure<P>;
};

// Called at build time
export default function wrapper<T, P extends Parameters<any> = []>(
    promise: (...args: P) => Promise<T>,
    { action, onSuccess, onFailure }: WrapperOptions<T, P> = {},
): (...params: Parameters<typeof promise>) => ThunkAction<Promise<T>, ApplicationState, T, AnyAction> {
    // Called when creating the action with the params during runtime
    return (...params: Parameters<typeof promise>): ThunkAction<Promise<T>, ApplicationState, T, AnyAction> => {
        // Thunk action
        return async (dispatch, getState): Promise<T> => {
            try {
                const data = await promise(...params);
                if (action) {
                    dispatch(action(data));
                }

                if (process.env.NODE_ENV === "development") {
                    console.log("API SUCCESS: ", data);
                }

                if (onSuccess) {
                    onSuccess({ dispatch, getState }, data, ...params);
                }

                return data;
            } catch (err) {
                let error = err;
                if (!!err.response && !!err.response.data) {
                    error = err.response.data;
                }

                if (process.env.NODE_ENV === "development") {
                    console.log("API FAILURE: ", error.message);
                }

                if (onFailure) {
                    onFailure({ dispatch, getState }, error, ...params);
                }

                throw error;
            }
        };
    };
}
