import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import fromCents from "../../../../../../../../../../constants/localization/currencies/fromCents";
import AustraliaMap from "../../../../../../../../../../resources/svg/australia_map.svg";
import NewZealandMap from "../../../../../../../../../../resources/svg/new_zealand_map.svg";
import UnitedKingdomMap from "../../../../../../../../../../resources/svg/uk_map.svg";
import UnitedStatesMap from "../../../../../../../../../../resources/svg/united_states_map.svg";
import Button from "../../../../../../../../../widgets/button/Button";
import Card from "../../../../../../../../../widgets/card/Card";
import Header from "../../../../../../../../../widgets/header/Header";
import CountryCard from "./card/CountryCard";
import styles from "./RepresentationAudience.module.scss";

class RepresentationAudience extends Component {
    static propTypes = {
        onSubmitRepAudience: PropTypes.func,
    };

    static defaultProps = {
        onSubmitRepAudience: () => null,
    };

    componentDidMount() {
        const repAudienceContainer = document.getElementById("rep-audience-container");
        if (!!repAudienceContainer) {
            repAudienceContainer.scrollIntoView({
                behavior: "smooth",
            });
        }
    }

    onSelectCountryPress = (key) => {
        this.props.dispatch(change("audience", "representativeAudience", key));
        const continueButton = document.getElementById("continue-button");
        if (!!continueButton) {
            continueButton.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    renderCountryCard = (countryProps, i) => {
        return <CountryCard key={i} {...countryProps} />;
    };

    render() {
        const className = classnames(styles.representation_audience, this.props.className);

        const representativeCountries = [
            {
                top: UnitedStatesMap,
                header: "USA",
                summaryTemplate: {
                    audienceNumber: 1000,
                    totalCost: 500000,
                    currency: "USD",
                    countryText: "the USA",
                },
                infoDialogTitle: "About your sample",
                infoDialogContent: (
                    <p>
                        The sample is made up of n=1000 with interlocking quotas across genders, ages (18-39 , 40-59, 60+), and
                        geography across nine division as defined{" "}
                        <a
                            href={"https://en.wikipedia.org/wiki/List_of_regions_of_the_United_States"}
                            target={"_blank"}
                            rel="noopener noreferrer">
                            here
                        </a>
                        . Quotas were based on population figures from the US Census bureau for 2017.
                    </p>
                ),
                isSelected: this.props.selectedRepAudience === "US",
                onSelectPress: () => this.onSelectCountryPress("US"),
            },
            {
                top: UnitedKingdomMap,
                header: "UK",
                summaryTemplate: {
                    audienceNumber: 1000,
                    totalCost: 500000,
                    currency: "USD",
                    countryText: "the UK",
                },
                infoDialogTitle: "About your sample",
                infoDialogContent: (
                    <p>
                        The sample is made up of n=1000 with interlocking quotas across genders, ages (18-39 , 40-59, 60+), and
                        geography across nine regions of England (as defined{" "}
                        <a href={"https://en.wikipedia.org/wiki/Regions_of_England"} target={"_blank"} rel="noopener noreferrer">
                            here
                        </a>
                        ) and Scotland, Wales and Northern Ireland. Quotas were based on population figures from respective Censuses
                        from 2011.
                    </p>
                ),
                isSelected: this.props.selectedRepAudience === "UK",
                onSelectPress: () => this.onSelectCountryPress("UK"),
            },
            {
                top: AustraliaMap,
                header: "Australia",
                summaryTemplate: {
                    audienceNumber: 1000,
                    totalCost: 500000,
                    currency: "USD",
                    countryText: "Australia",
                },
                infoDialogTitle: "About your sample",
                infoDialogContent: (
                    <p>
                        The sample is made up of n=1000 with interlocking quotas across genders, ages (18-39 , 40-59, 60+), and
                        geography across the six states (New South Wales, Queensland, South Australia, Tasmania, Victoria, Western
                        Australia). Quotas were based on population figures from the Australian Bureau of Statistics Census from 2016.
                    </p>
                ),
                isSelected: this.props.selectedRepAudience === "AU",
                onSelectPress: () => this.onSelectCountryPress("AU"),
            },
            {
                top: NewZealandMap,
                header: "New Zealand",
                summaryTemplate: {
                    audienceNumber: 600,
                    totalCost: 300000,
                    currency: "USD",
                    countryText: "New Zealand",
                },
                infoDialogTitle: "About your sample",
                infoDialogContent: (
                    <p>
                        The sample is made up of n=1000 with interlocking quotas across genders, ages (18-39 , 40-59, 60+), and
                        geography across six areas (Auckland, Provincial upper North Island, Wellington, Provincial lower North Island,
                        Canterbury, Provincial South Island). Quotas were based on population figures from the StatsNZ Census from
                        2013.
                    </p>
                ),
                isSelected: this.props.selectedRepAudience === "NZ",
                onSelectPress: () => this.onSelectCountryPress("NZ"),
            },
        ];
        const selectedCountry = representativeCountries.filter((country) => country.isSelected)[0];

        return (
            <div id={"rep-audience-container"} className={className}>
                <Header text={"2. Select your target country"} medium noBorder />
                <div className={styles.content}>{representativeCountries.map(this.renderCountryCard)}</div>
                <Header text={"3. Survey summary"} medium noBorder />
                <Card className={classnames(styles.quota, styles.content_final)}>
                    <div className={styles.quota_summary}>
                        <div className={styles.quota_summary_item}>
                            <span>TARGET RESPONSES</span>
                            <h2>{!!selectedCountry ? selectedCountry.summaryTemplate.audienceNumber : "-"}</h2>
                        </div>

                        <div className={styles.quota_summary_item}>
                            <span>TOTAL COST</span>
                            <h2>
                                {!!selectedCountry
                                    ? fromCents(
                                          selectedCountry.summaryTemplate.totalCost,
                                          selectedCountry.summaryTemplate.currency,
                                          true,
                                      )
                                    : "-"}
                            </h2>
                        </div>

                        <div className={styles.quota_summary_item}>
                            <span>ESTIMATED COMPLETION TIME</span>
                            <h2>3-5 Days</h2>
                        </div>
                    </div>
                </Card>
                <Button
                    id={"continue-button"}
                    className={styles.continue_button}
                    green
                    loading={this.props.submittingRepAudience}
                    onPress={() => this.props.onSubmitRepAudience(this.props.selectedRepAudience)}
                    disabled={this.props.selectedRepAudience === "null"}>
                    Next: Edit Survey
                </Button>
            </div>
        );
    }
}

export default RepresentationAudience = connect((state, props) => {
    return {
        selectedRepAudience: ((state.form.audience || {}).values || {}).representativeAudience,
    };
})(RepresentationAudience);
