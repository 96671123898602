import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import styles from "./Toolbar.module.scss";
import Button from "../button/Button";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import Routes from "../../../constants/routes";
import { signOut } from "../../../redux/actions/v1/user";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setActiveOrganisation } from "../../../redux/actions/v1/settings";
import NoOrganisationDialog from "../dialogs/noOrganisationDialog/NoOrganisationDialog";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import Avatar from "../../../resources/svg/reuben_avatar.svg";
import CreateOrganisationDialog from "../dialogs/createOrganisationDialog/CreateOrganisationDialog";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";

class Toolbar extends Component {

    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        id: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        classes: [],
    };

    state = {
        menuOpen: false,
        orgMenuOpen: false,
        orgMenuAnchor: null,
        avatar: null,
        avatarMenuOpen: false,
    };

    constructor(props) {
        super(props);
        window.addEventListener("resize", this.resizeListener);
    }

    resizeListener = () => {
        if (window.outerWidth <= 800 && this.state.menuOpen) {
            this.setState({
                menuOpen: false,
            });
        }
    };

    onNavButtonPress = (path) => {
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.props.history.push(path);
            this.onMenuClose();
            this.onAvatarMenuClose();
        };
    };

    onMenuButtonPress = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    };

    onMenuClose = () => {
        this.setState({
            menuOpen: false,
        });
    };

    onLogOutPress = () => {
        this.props.dispatch(signOut());
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeListener);
    }

    renderNavButton = (name, path) => {
        const isSelected = this.props.location.pathname === path;
        const navButton = classnames({
            [styles.nav_button_selected]: isSelected
        });

        return (
            <Button plain className={navButton} key={name} onPress={this.onNavButtonPress(path)}
                    href={path}>{name}</Button>
        );
    };

    onNoOrgSurveyPress = () => {
        this.noOrgDialog.show();
    };

    onOrgChange = (orgId) => {
        if (orgId === this.props.organisation.id) {
            return () => {
                this.onOrgMenuClose();
            };
        } else {
            return () => {
                this.onOrgMenuClose();
                this.props.dispatch(setActiveOrganisation(orgId));
                this.props.history.push(Routes.Surveys.root);
            };
        }
    };

    onOrgMenuButtonPress = (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        this.setState({
            orgMenuAnchor: target,
            orgMenuOpen: true,
        });
    };

    onOrgMenuClose = () => {
        this.setState({
            orgMenuOpen: false,
        });
    };

    renderOrgMenuItem = (org) => {
        return (
            <MenuItem key={org.id} onClick={this.onOrgChange(org.id)}>{org.name}</MenuItem>
        );
    };

    renderProfileMenuItem = (name, path) => {
        return (
            <MenuItem key={name + path} onClick={this.onNavButtonPress(path)}>{name}</MenuItem>
        );
    };

    onCreateNewOrgPress = () => {
        this.onOrgMenuClose();
        this.createOrgDialog.show();
    };

    onAvatarPress = () => {
        const target = document.getElementById("avatar");
        this.setState({
            avatar: target,
            avatarMenuOpen: true,
        });
    };

    onAvatarMenuClose = () => {
        this.setState({
            avatarMenuOpen: false,
        });
    };

    render() {
        const className = classnames(styles.toolbar, this.props.className, this.props.classes);

        const avatarDropdownClassName = classnames(styles.avatar_dropdown, this.props.className, this.props.classes);

        const orgMenuClassName = classnames(styles.org_menu);

        const orgMenuButtonClassName = classnames(styles.organisation_filter, {
            [styles.organisation_filter_open]: this.state.orgMenuOpen,
        });

        const orgFilterIconClassName = classnames(styles.organisation_filter_icon);

        return (
            <header id={this.props.id} className={className}>
                <nav>
                    <Paper className={styles.nav} elevation={1}>
                        <div className={styles.inner}>
                            <Button className={orgMenuButtonClassName}
                                    labelClassName={styles.organisation_filter_label}
                                    onPress={this.onOrgMenuButtonPress}
                                    plain
                                    borderLight>
                                {this.props.organisation.name}
                                <KeyboardArrowDownIcon className={orgFilterIconClassName} />
                            </Button>

                            {this.props.organisation.logo &&
                                <img className={styles.logo} src={this.props.organisation.logo}
                                     alt={this.props.organisation.name || ""} />}

                            <div className={styles.nav_buttons}>
                                {this.renderNavButton("Surveys", Routes.Surveys.root)}
                                {/*{this.renderNavButton("Audiences", Routes.Audiences.root)}*/}
                                {this.renderNavButton("Settings", Routes.Settings.root)}
                                {/*{this.renderNavButton("Help", Routes.Help.root)}*/}
                                <div id={"avatar"} onClick={this.onAvatarPress} className={styles.nav_avatar}>
                                    <img src={Avatar} alt={""} />
                                </div>
                            </div>
                            <div className={styles.hamburger_button_wrapper}>
                                <IconButton
                                    aria-label={"Menu"}
                                    aria-owns={this.state.menuOpen ? "menu-appbar" : null}
                                    aria-haspopup="true"
                                    onClick={this.onMenuButtonPress}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                    </Paper>
                </nav>

                <Menu id={"menu-appbar"}
                      disableAutoFocusItem
                      PaperProps={{
                          className: styles.menu,
                          elevation: 1
                      }}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      onClose={this.onMenuClose}
                      open={this.state.menuOpen}
                >

                </Menu>

                <NoOrganisationDialog dialogRef={(ref) => this.noOrgDialog = ref} />

                <Popover anchorEl={this.state.orgMenuAnchor}
                         classes={{
                             paper: orgMenuClassName,
                         }}
                         anchorOrigin={{
                             vertical: "bottom",
                             horizontal: "left",
                         }}
                         transformOrigin={{
                             vertical: "top",
                             horizontal: "left",
                         }}
                         onClose={this.onOrgMenuClose}
                         open={!!this.state.orgMenuOpen}
                >
                    {orderBy(values(this.props.organisations), (org) => org.name).map(this.renderOrgMenuItem)}
                    <MenuItem onClick={this.onCreateNewOrgPress}>+ Add Organisation</MenuItem>
                </Popover>

                <Popover anchorEl={this.state.avatar}
                         classes={{
                             paper: avatarDropdownClassName,
                         }}
                         anchorOrigin={{
                             vertical: "bottom",
                             horizontal: "left",
                         }}
                         transformOrigin={{
                             vertical: "top",
                             horizontal: "left",
                         }}
                         onClose={this.onAvatarMenuClose}
                         open={!!this.state.avatarMenuOpen}
                >
                    <h4><strong>{this.props.user.name}</strong></h4>
                    {this.renderProfileMenuItem("My Profile", Routes.Profile.root)}
                    <MenuItem onClick={this.onLogOutPress}>Log out</MenuItem>
                </Popover>

                <CreateOrganisationDialog dialogRef={(ref) => this.createOrgDialog = ref} />

            </header>
        );
    }
}

Toolbar = withRouter(Toolbar);

export default Toolbar = connect((state) => {
    const organisations = state.organisations.accounts;
    return {
        user: state.user,
        organisations: organisations,
        organisation: organisations[(state.settings.organisation || "")] || {},
    };
})(Toolbar);
