import React, {Component} from "react";
import styles from "./PricingDetail.module.scss";
import Content from "../../../../widgets/content/Content";
import PricingCard from "../../../../widgets/pricingCard/PricingCard";
import tick from "../../../../../resources/svg/pricing_detail_tick.svg";
import audienceCharacter from "../../../../../resources/png/agentSWorld.png";

class PricingDetail extends Component {

    onSelectPlanPress = () => {
        alert("Plan selected")
    };

    render() {
        return (
            <Content headerText={"Pricing"}>
                <div className={styles.pricing_detail_page}>
                    <h2>I have my own audience</h2>
                    <h4>14 day free trial. No credit card required. Cancel anytime.</h4>
                    <div className={styles.pricing_detail_options_container}>
                        <PricingCard className={styles.option_basic}
                                     title={"Basic"}
                                     subtitle={true ? "$25/month" : "$19/month"}
                                     body={true ? "Billed monthly" : "Billed annually"}
                                     onPress={this.onSelectPlanPress}
                                     buttonProps={{
                                         white: true,
                                     }}
                                     buttonText={"Try for free"}
                                     content={
                                         <div className={styles.pricing_features_content_container}>
                                             <div><img src={tick} alt={""}/><span>1 Account</span></div>
                                             <div><img src={tick} alt={""}/><span>3 Audiences per account</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents per account</span>
                                             </div>
                                         </div>
                                     }/>
                        <PricingCard className={styles.option_pro}
                                     title={"Pro"}
                                     subtitle={true ? "$99/month" : "$79/month"}
                                     body={true ? "Billed monthly" : "Billed annually"}
                                     onPress={this.onSelectPlanPress}
                                     buttonProps={{
                                         white: true
                                     }}
                                     buttonText={"Try for free"}
                                     content={
                                         <div className={styles.pricing_features_content_container}>
                                             <div><img src={tick} alt={""}/><span>1 Account</span></div>
                                             <div><img src={tick} alt={""}/><span>3 Audiences per account</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents per account</span>
                                             </div>
                                         </div>
                                     }/>
                        <PricingCard className={styles.option_unlimited}
                                     title={"Unlimited"}
                                     subtitle={true ? "$199/month" : "$159/month"}
                                     body={true ? "Billed monthly" : "Billed annually"}
                                     onPress={this.onSelectPlanPress}
                                     buttonProps={{
                                         white: true
                                     }}
                                     buttonText={"Try for free"}
                                     content={
                                         <div className={styles.pricing_features_content_container}>
                                             <div><img src={tick} alt={""}/><span>1 Account</span></div>
                                             <div><img src={tick} alt={""}/><span>3 Audiences per account</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents per account</span>
                                             </div>
                                         </div>
                                     }/>
                        <PricingCard className={styles.option_advanced}
                                     title={"Advanced"}
                                     subtitle={"Price on request"}
                                     onPress={this.onContactPress}
                                     buttonProps={{
                                         white: true
                                     }}
                                     buttonText={"Try for free"}
                                     content={
                                         <div className={styles.pricing_features_content_container}>
                                             <div><img src={tick} alt={""}/><span>1 Account</span></div>
                                             <div><img src={tick} alt={""}/><span>3 Audiences per account</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents</span></div>
                                             <div><img src={tick} alt={""}/><span>1000 Respondents per account</span>
                                             </div>
                                         </div>
                                     }/>


                    </div>
                    <div className={styles.find_audience_container}>
                        <h2>I’d like Stickybeak to find me an audience (anywhere in the world)</h2>
                        <div className={styles.audience_info_container}>
                            <div className={styles.audience_info_content}>
                                <h1>Find me an audience</h1>
                                <h2>From $1 per response, no subscription required</h2>
                                <h3>Target responses from people anywhere in the world.</h3>
                                <p>Customise the audience response you need such as getting an even representation from
                                    multiple age brackets.
                                    Stickybeak will do the rest; returning valuable insights in real-time.
                                    Perfect for testing ideas before developing further or getting nationwide polling.
                                </p>
                                <span className={styles.service_available_tick}><img src={tick} alt={""}/> Service available</span>
                            </div>
                            <div className={styles.audience_info_content_image}>
                                <img src={audienceCharacter} alt={""}/>
                            </div>

                        </div>
                    </div>

                    <div className={styles.pricing_faq_section}>
                        <h1>Frequently asked questions</h1>
                        <div className={styles.pricing_faq_content}>

                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>
                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>
                            <div className={styles.faq_question}>
                            <h3>Frequently asked question?</h3>
                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                dolor
                                sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                consectetur. usce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                dolor</p>
                        </div>
                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>
                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>
                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>
                            <div className={styles.faq_question}>
                                <h3>Frequently asked question?</h3>
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum
                                    massa justo sit amet risus. Sed posuere consectetur est at lobortis. Lorem ipsum
                                    dolor
                                    sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed
                                    consectetur.</p>
                            </div>

                        </div>

                    </div>

                </div>
            </Content>
        );
    }
}

export default PricingDetail;