/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import Field from "redux-form/es/Field";
import DatePicker from "./DatePicker";

export default class DatePickerField extends Component {

    // eslint-disable-next-line
    static propTypes = DatePicker.propTypes;

    static defaultProps = DatePicker.defaultProps;

    render() {
        return (
            <Field {...this.props} component={DatePicker} />
        );
    }
}
