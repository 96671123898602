import React, {Component} from "react"
import PropTypes from "prop-types"
import FieldArray from "redux-form/es/FieldArray";
import QuestionContent from "../questionContent/QuestionContent";
import {connect} from "react-redux"
import styles from "./CustomEndingBlock.module.scss"
import {Animated} from "react-animated-css/lib/index";
import {reduxForm, formValueSelector} from "redux-form";
import getSurveyProps from "../../../../../../../../../../constants/getSurveyProps";


class CustomEndingBlock extends Component{

    static propTypes ={
        name: PropTypes.string,
        formName: PropTypes.string
    }

    static defaultProps={
        name: null,
        formName: null
    }

    state = {
        deleteRow: false,
        deleteRows: []
    };

    onDeleteQuestionContentRowPress = () => {
        if (!this.state.deleteRow) {
            this.setState({deleteRow: true}, () => {
                const deleteRows = JSON.parse(JSON.stringify(this.state.deleteRows));
                const questionToBeDeleted = this.props.index;

                if (deleteRows.indexOf(questionToBeDeleted) === -1) {
                    deleteRows.push(questionToBeDeleted);
                    this.setState({deleteRows: deleteRows});
                }
            });
        } else {
            this.setState({deleteRow: false}, () => {
                if (this.state.deleteRows.indexOf(this.props.index) > -1) {
                    const deleteRows = JSON.parse(JSON.stringify(this.state.deleteRows));
                    deleteRows.splice(deleteRows.indexOf(this.props.index), 1);
                    this.setState({deleteRows: deleteRows});
                }
            });
        }

    };


    render() {
        return(
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <div className={styles.question_container}>
                <div className={styles.question_content}>
                    <div className={styles.left}>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.question_block}>
                            <div className={styles.messages_wrapper}>
                                <FieldArray name={`content`}
                                            props={{editable: this.props.editable, showDelete: this.props.deletingContentRow, afterDelete: this.props.afterDelete, hideAddContent: (!this.props.editable||this.props.upgradeRequired)}}
                                            component={QuestionContent}
                                            placeholder={"Start typing..."}
                                            isCustomEnding/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Animated>
        )
    }
}

CustomEndingBlock = reduxForm({
    enableReinitialize: true,
    form: "customEnding",
    validate: (values)=>{
        const errors= {};
        if(!!values.content){
            values.content.forEach((content, index)=>{
                if (content.type === "QUESTION_TEXT" && (!content || !content.text || content.text === "" || content.text === "<br/>" || content.text === "<div><br/></div>" || content.text===" ")) {
                    errors[index] = "One of your custom ending rows is empty"
                }
            })
        }
        return errors
    }
})(CustomEndingBlock)

export default CustomEndingBlock = connect((state,props)=>{
    const selector = formValueSelector("customEnding")
    const ending = selector(state, "content")
    const surveyId = (props.survey||{}).id
    return{
        ending:ending,
        initialValues: (state.surveyEnding.customEndings||{})[surveyId]||{content: [{text: "", type: "QUESTION_TEXT"}]}
    }
})(CustomEndingBlock)