/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum Roles {
    USER = "USER",
    SUPER_ADMIN = "SUPER_ADMIN",
}

export default interface User {
    id: string;
    name: string;
    firstName?: string;
    lastName?: string;
    email: string;
    avatar: string;
    role: Roles;
    emailVerified: boolean;
}
