/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-01-21.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreateUpdateSurveyDialog.module.scss";
import Dialog from "../../dialog/Dialog";
import connect from "react-redux/es/connect/connect";
import {createSurvey} from "../../../../redux/actions/v1/surveys";
import Routes from "../../../../constants/routes";
import {withRouter} from "react-router-dom";
import TextInput from "../../textInput/TextInput";

class CreateUpdateSurveyDialog extends Component {

    static propTypes = {
        dialogRef: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dialogRef: () => null,
    };

    state = {
        loading: false,
        error: null,
        surveyName: ""
    };

    onClose = () => {
        this.setState({
            error: null,
            surveyName: "",
        });
    };

    hide = () => {
        this.dialog.hide();
    };

    onTextInput = (text) => {
        this.setState({
            surveyName: text
        })
    };

    onSurveySubmit = () => {
        this.setState({
            loading: true,
            error: null,
        });

        this.props.dispatch(createSurvey({
            orgId: this.props.orgId,
            survey: {
                name: this.state.surveyName
            },
            onSuccess: (survey) => {
                this.setState({
                    loading: false
                });
                
                this.props.history.push(`${Routes.Surveys.root}/${survey.id}`);
                this.hide()
            },
            onError: (err) => {
                this.setState({
                    loading: false,
                    error: err
                })
            }
        }));
    };

    render() {

        const actions = [
            {
                text: "Cancel",
                onPress: this.hide,
                props: {
                    plain: true,
                    disabled: this.state.loading
                }
            },
            {
                text: "Create",
                onPress: this.onSurveySubmit,
                props: {
                    greyDark: true,
                    loading: this.state.loading
                }
            }
        ];

        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                actions={actions}
                className={styles.create_update_survey_dialog}
                onClose={this.onClose}
                title={"Let's give your survey a name"}
                error={this.state.error}
                disableDismiss={this.state.loading}>

                <TextInput label={"Survey Name"}
                           placeholder={"Customer Satisfaction Survey"}
                           text={this.state.surveyName}
                           onTextChange={this.onTextInput}/>

            </Dialog>
        );
    }
}

CreateUpdateSurveyDialog = withRouter(CreateUpdateSurveyDialog);

export default CreateUpdateSurveyDialog = connect((state) => {
    return {
        orgId: state.settings.organisation,
    }
})(CreateUpdateSurveyDialog)