const ACTIVE_CAMPAIGN_EVENTS_KEY="89a6267bd44bbef9cab2def212f93cdfecbc947b";
const ACTIVE_CAMPAIGN_ACT_ID="1001268838";

export default function sendEventToActiveCampaign(email, event, eventData){
    // ENCODE THE VARIABLES
    const visitObject = {
        email: email
    }
    const encodedVisit = encodeURIComponent(JSON.stringify(visitObject));
    const encodedEvent = encodeURIComponent(event);
    const encodedEventData = encodeURIComponent(eventData);

    const url = "https://trackcmp.net/event"
    const header = {
        "accept" : "application/json; charset=utf-8",
        "content-type": "application/x-www-form-urlencoded"
    }
    const data = `actid=${ACTIVE_CAMPAIGN_ACT_ID}&key=${ACTIVE_CAMPAIGN_EVENTS_KEY}&event=${encodedEvent}&eventdata=${encodedEventData}&visit=${encodedVisit}`
    fetch(url, {
        method: "POST",
        headers: header,
        mode:"no-cors",
        body: data,
    }).catch((e)=>console.log(e))
}
