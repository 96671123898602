import React, { Component } from "react";
import styles from "./RatingQuestion.module.scss";
import { connect } from "react-redux";
import { setSurveyFormFocused } from "../../../../../../../../../../redux/actions/v1/surveyFormState";
import { change, formValueSelector } from "redux-form";

const responseTypeOptions = [
    {
        label: "5 options",
        value: 5,
    },
    {
        label: "4 options",
        value: 4
    },
    {
        label: "3 options",
        value: 3
    }
];

const starBlueOutline="https://storage.googleapis.com/stickybeak-test-uploads/images/rating/starBlueOutline%402x.png";
const starFilledBlue="https://storage.googleapis.com/stickybeak-test-uploads/images/rating/starFilledBlue%402x.png";
const starFilledWhite = "https://storage.googleapis.com/stickybeak-test-uploads/images/rating/starFilledWhite%402x.png";
const starWhiteOutline ="https://storage.googleapis.com/stickybeak-test-uploads/images/rating/starWhiteOutline%402x.png";

class RatingBubble extends Component {
    static defaultProps = {};
    state = {
        hideError: true,
    };
    onFocus = () => {
        this.props.dispatch(setSurveyFormFocused(true));
        this.setState({ hideError: true });
    };
    onBlur = (e) => {
        this.props.dispatch(setSurveyFormFocused(false));
    };

    render() {
        const iconFilled = starFilledWhite;
        const iconNoFill = starWhiteOutline
        let icons = [this.props.responseType];
        for (let i = 0; i < this.props.responseType; i++) {
            if(i+1>Math.ceil(this.props.responseType/2)) {
                icons[i] = iconNoFill;
            }else{
                icons[i] = iconFilled
            }
        }
        return (
            <div className={styles.rating_bubble_wrapper}>
                <div
                    className={styles.rating_bubble}
                >
                    <div className={styles.rating_icons}>
                        {icons.map((icon) => {

                            return (
                                <div className={styles.icon_wrapper}>
                                <img width={40} src={icon}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

class RatingQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableAutoFocus: false,
        };
    }

    componentDidMount() {
        this.setState({ enableAutoFocus: true });
        let value = "";
        if (!this.props.question.choices) {
            let choices = [5];
            for (let i = 0; i < 5; i++) {
                value = value + "⭐️";
                choices[i] = { value: value, imageUrl: starBlueOutline, selectedImageUrl: starFilledBlue };
            }
            this.props.dispatch(change("editSurvey", `${this.props.name}.ratingOptions.numberOfResponses`, 5));
            this.props.dispatch(change("editSurvey", `${this.props.name}.ratingOptions.icon`, "⭐️"));
            this.props.dispatch(change("editSurvey", `${this.props.name}.choices`, choices));
        }
    }

    onResponseSelect = (e) => {
        this.props.dispatch(change("editSurvey", `${this.props.name}.ratingOptions.numberOfResponses`, parseInt(e.target.value)));
        let choices = [e.target.value];
        let value = "";
        for (let i = 0; i < e.target.value; i++) {
            value = value + this.props.question.ratingOptions.icon;
            choices[i] = { value: value, imageUrl: starBlueOutline, selectedImageUrl: starFilledBlue};
        }
        this.props.dispatch(change("editSurvey", `${this.props.name}.choices`, choices));
    };


    render() {
        let ratingOptions = this.props.question.ratingOptions;
        if (!ratingOptions) {
            ratingOptions = { numberOfResponses: 5, icon: "⭐️" };
        }


        return (
            <div className={styles.rating_bubble_container}>
                <div className={styles.option_row}>
                    {/*<div className={styles.option_row_half}>*/}
                    <div className={styles.select_container}>
                        <span className={styles.option_label}>Response type</span>
                        <select className={styles.option_select}
                                onChange={this.onResponseSelect}
                                value={ratingOptions.numberOfResponses}>
                            {responseTypeOptions.map((responseType) => <option
                                value={responseType.value}
                                key={responseType.label}>{responseType.label}</option>)}
                        </select>
                    </div>
                </div>
                <RatingBubble responseType={ratingOptions.numberOfResponses} />
            </div>
        );
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const question = selector(state, `${props.name}`);
    return {
        question
    };
})(RatingQuestion);
