/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Kyle on 21/01/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import Content from "../../../../widgets/content/Content";
import styles from "./AddManually.module.scss";
import { arrayPush, FieldArray, formValueSelector, reduxForm, reset } from "redux-form";
import validateEmail from "../../../../../constants/validateEmail";
import Button from "../../../../widgets/button/Button";
import connect from "react-redux/es/connect/connect";
import AudienceFormRow from "./audienceFormRow/AudienceFormRow";
import CreateUpdateAudienceColumnDialog
    from "../../../../widgets/dialogs/createUpdateAudienceColumnDialog/CreateUpdateAudienceColumnDialog";
import DeleteAudienceFormColDialog
    from "../../../../widgets/dialogs/deleteAudienceFormColDialog/DeleteAudienceFormColDialog";
import { clearAudienceMembers, createAudienceMembers, } from "../../../../../redux/actions/v1/audienceMembers";
import keys from "lodash/keys";
import { deleteAudienceColumn, getAudiencesForOrganisation } from "../../../../../redux/actions/v1/audiences";
import CloseIcon from "@mui/icons-material/Close";
import { orderBy } from "lodash/collection";
import IconButton from "../../../../widgets/iconButton/IconButton";

class AddManually extends Component {

    constructor(props) {
        super(props);

        this.headerButtons = [
            {
                text: "Add table column",
                onPress: () => this.createAudienceColDialog.show(),
                props: { white: true, border: true }
            },
        ];

        this.state = {
            selectedColumnId: null,
            loadingAudience: false
        };

        if (!keys(props.audience).length) {
            props.dispatch(getAudiencesForOrganisation({
                orgId: props.orgId
            }));
        }
    }

    addRow = () => {
        this.props.dispatch(arrayPush("addManually", "respondents", {
            email: "",
            tags: {},
        }));
    };

    onEditColPress = (dataType, title) => {
        this.updateAudienceColDialog.show();
    };

    editColSubmit = (col) => {

    };

    deleteColPress = (columnId) => {
        this.deleteAudienceColDialog.show();
        this.setState({ deleteColumnId: columnId });
    };

    deleteColSubmit = () => {
        this.setState({ loadingAudience: true });
        this.props.dispatch(deleteAudienceColumn({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            attributeId: this.state.deleteColumnId,
            onSuccess: () => {
                !!this.deleteAudienceColDialog && this.deleteAudienceColDialog.hide();
                this.setState({ loadingAudience: false });
            }
        }));
    };

    onCancelPress = () => {
        this.props.dispatch(reset("addManually"));

        if (this.props.onCancelPress) {
            this.props.onCancelPress();
        } else {
            this.props.history.push(`/audiences/${this.props.audienceId}`);
        }
    };

    onSubmit = (values) => {
        let newValues = values.respondents.map((respondent) => {
            Object.keys(respondent.tags).map((respondentKey) => {
                let respondentTag = respondent.tags[respondentKey];
                if (respondentTag) {
                    respondentTag.value = respondentTag.value.join("");
                }
                return respondentTag;
            });
            return respondent;
        });

        if (this.props.onSubmit) {
            this.props.onSubmit();
        }

        this.props.dispatch(createAudienceMembers({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            data: newValues,
            // audience: audience,
            onSuccess: () => {
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                } else {
                    this.props.dispatch(clearAudienceMembers(this.props.orgId));
                    this.props.history.push(`/audiences/${this.props.audienceId}`);
                }
            },
        }));
    };


    renderColumnHeadings = () => {

        let columns = [];
        if (!!this.props.audience.columns) {
            columns = orderBy(this.props.audience.columns, ["ordinal"], ["asc"]);
        }

        return columns.map((column) => {
            return (
                <th key={column.id} className={styles.delete_row_button}>
                    {column.name}
                    <IconButton plain round
                                iconClassName={styles.delete_button_icon}
                                onPress={() => this.deleteColPress(column.id)}>
                        <CloseIcon color={"action"} fontSize={"small"} />

                    </IconButton>
                </th>

            );
        });
    };

    render() {
        return (
            <Content className={styles.form_content}
                     headerText={"Add Contacts"}
                     loading={this.state.loadingAudience || this.state.loadingAudienceMembers}
                     headerButtons={this.headerButtons}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <div className={styles.form_row_content}>
                        <table>
                            <thead>
                            <tr>
                                <th>Email</th>
                                {this.renderColumnHeadings()}
                                <th width="100%">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>


                            <FieldArray name={"respondents"}
                                        component={AudienceFormRow}
                                        audience={this.props.audience} />

                            <tr className={styles.add_row_link}>
                                <td onClick={this.addRow}>+ Add Row</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <div>
                        <Button green type="submit">Save</Button>
                        <Button plain onPress={this.onCancelPress}>Cancel</Button>
                    </div>
                </form>

                <CreateUpdateAudienceColumnDialog dialogRef={(ref) => this.createAudienceColDialog = ref}
                                                  orgId={this.props.orgId}
                                                  audienceId={this.props.audienceId} />

                <CreateUpdateAudienceColumnDialog dialogRef={(ref) => this.updateAudienceColDialog = ref}
                                                  audienceColumnId={this.state.audienceColumnId}
                                                  orgId={this.props.orgId}
                                                  audienceId={this.props.audienceId} />

                <DeleteAudienceFormColDialog dialogRef={(ref) => this.deleteAudienceColDialog = ref}
                                             loading={this.state.loadingAudience}
                                             onSubmit={this.deleteColSubmit} />
            </Content>
        );
    }
}

AddManually = reduxForm({
    form: "addManually",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.respondents || !values.respondents.length) {
            errors.respondents = { _error: "At least one respondent is required" };
        } else {
            errors.respondents = values.respondents.map((respondent) => {
                const respondentErrors = {};
                if (!respondent.email) {
                    respondentErrors.email = "Required";
                }
                if (!!respondent.email && !validateEmail(respondent.email)) {
                    respondentErrors.email = "Please enter a valid email address.";
                }
                return respondentErrors;
            });
        }

        return errors;
    }
})(AddManually);

const valueSelector = formValueSelector("addManually");

export default AddManually = connect((state, props) => {
    const orgId = state.settings.organisation;
    const audienceId = Number(((props.match || {}).params || {}).audienceId) || props.audienceId;
    const audience = ((state.audiences[orgId] || {})[audienceId] || {});

    return {
        orgId: orgId,
        organisation: (state.organisations.accounts[orgId] || {}),
        audienceId: audienceId,
        audience: audience,
        respondents: valueSelector(state, "respondents"),

        initialValues: {
            respondents: [
                {
                    email: "",
                    gender: "NOT_SUPPLIED",
                    tags: {},
                }
            ]
        }
    };
})(AddManually);
