/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import wrapper from "../helpers/v1/wrapper";
import * as SaaSAPI from "../api/v1/saas";
import createAction from "../helpers/v1/createAction";

export const SET_SAAS_TIERS = "saas:SET_SAAS_TIERS";

export const setSaaSTiers = createAction(SET_SAAS_TIERS, "tiers");

export const getSaasTiers = wrapper(SaaSAPI.getSaasTiersAPI, (dispatch, tiers) => {
    dispatch(setSaaSTiers(tiers));
});