import React from "react"
import styles from "./NoDataFallback.module.scss"
import ReubenPool from "../../../../../../../../../resources/mp4/reuben_pool.mp4"


const NoDataFallback = () => {
  return (
    <div className={styles.container}>
        <video className={styles.video} autoPlay muted loop playsInline width={300} height={300}>
            <source src={ReubenPool} type="video/mp4" />
        </video>
        <div>
            <p>No results yet!</p>
            <p>Check back soon</p>
        </div>
    </div>
  )
}
export default NoDataFallback