

export function getAudienceSizeAPI(token, params) {
	return window.get(`/v1/facebook/targeting/search/audience`, token, params);
}

export function getAudienceSizeForSurveyAPI(token, surveyId) {
	return window.get(`/v1/facebook/targeting/search/survey/${surveyId}/audience`, token);
}

export function searchLocationsAPI(token, params) {
	return window.get(`/v1/facebook/targeting/search`, token, params);
}

export function searchDetailedAPI(token, params) {
	return window.get(`/v1/facebook/targeting/search/detailed`, token, params)
}

export function getPriceEstimateAPI(token, surveyId, data, params) {
	return window.post(`/v1/facebook/pricing/estimate/survey/${surveyId}`, token, data, params)
}