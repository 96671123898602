/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Audience.module.scss";
import Header from "../../../../../../../widgets/header/Header";
import getSurveyProps from "../../../../../../../../constants/getSurveyProps";
import SelectAudiences from "./components/selectAudiences/SelectAudiences";
import { Field, reduxForm } from "redux-form";
import FindMyAudience from "./components/findMyAudience/FindMyAudience";
import { updateSurvey } from "../../../../../../../../redux/actions/v1/surveys";
import RepresentationAudience from "./components/representationAudience/RepresentationAudience";
import Routes from "../../../../../../../../constants/routes";
import Button from "../../../../../../../widgets/button/Button";
import UpgradePromptDialog
    from "../../../../../../../widgets/dialogs/upgradePromptDialog/UpgradePromptDialog";
import sendEventToActiveCampaign from "../../../../../../../../redux/actions/api/v1/eventTracking";

class Audience extends Component {

    constructor (props) {
        super(props);
        this.dialogRef = React.createRef();
    }

    state = {
        submittingRepAudience: false,
        submittingAudienceList: false,
        submittingLinkSurvey: false
    };

    onSubmitTargeting = () => {

        const {
            ageGroups,
            genders,
            geoLocations,
            detailed,
            maxSpend,
            desiredSampleSize,
            quotaType,
            type
        } = this.props.formValues;

        const sanitisedAgeGroups = ageGroups.map((ageGroup) => {

            if ( ageGroup.minAge > 65 || ageGroup.minAge === "65+" ) {
                ageGroup.minAge = 65;
            }

            if ( ageGroup.maxAge > 65 || ageGroup.maxAge === "65+" ) {
                ageGroup.maxAge = 65;
            }

            return ageGroup;
        });

        const targeting = {
            maxSpend: maxSpend,
            desiredSampleSize: desiredSampleSize,
            ageGroups: sanitisedAgeGroups,
            genders: genders,
            geoLocations: geoLocations,
            detailed: detailed,
            representativePreset: null
        };

        const survey = {
            ...this.props.survey,
            targeting: targeting,
            type: type,
            audienceListId: null,
        };

        if ( quotaType === "SPEND" ) {
            survey.maxSpend = Number(maxSpend);
            survey.desiredSampleSize = null;
        }

        if ( quotaType === "SAMPLE_SIZE" ) {
            survey.maxSpend = null;
            survey.desiredSampleSize = Number(desiredSampleSize);
        }

        this.props.dispatch(updateSurvey({
            accountId: this.props.orgId,
            surveyId: this.props.surveyId,
            survey: survey,
            onSuccess: () => {
                sendEventToActiveCampaign(this.props.user.email, "Submitted_targeting", "Submitted targeting");
                this.continueToBuilder();
            },
        }));
    };

    onSubmitAudience = (audienceId) => {
        this.setState({ submittingAudienceList: true });
        this.props.dispatch(updateSurvey({
            orgId: this.props.orgId,
            surveyId: this.props.surveyId,
            survey: {
                ...this.props.survey,
                targeting: {
                    maxSpend: null,
                    desiredSampleSize: null,
                    ageGroups: [],
                    genders: [],
                    geoLocations: [],
                    detailed: [],
                    representativePreset: null
                },
                desiredSampleSize: null,
                maxSpend: null,
                audienceListId: audienceId,
                type: "AUDIENCE"
            },
            onSuccess: () => {
                this.setState({ submittingAudienceList: false });
                this.props.history.push(`${Routes.Surveys.root}/${this.props.surveyId}`);
            },
        }));
    };

    onSubmitShareableLink = () => {
        this.setState({ submittingLinkSurvey: true });
        this.props.dispatch(updateSurvey({
            orgId: this.props.orgId,
            surveyId: this.props.surveyId,
            survey: {
                ...this.props.survey,
                targeting: {
                    maxSpend: null,
                    desiredSampleSize: null,
                    ageGroups: [],
                    genders: [],
                    geoLocations: [],
                    detailed: [],
                    representativePreset: null
                },
                desiredSampleSize: null,
                maxSpend: null,
                audienceListId: null,
                type: "LINK"
            },
            onSuccess: () => {
                this.setState({ submittingLinkSurvey: false });
                sendEventToActiveCampaign(this.props.user.email, "Submitted_link_audience", "Submitted link audience");
                this.continueToBuilder();
            },
        }));
    };

    onSubmitRepAudience = (country) => {
        this.setState({
            submittingRepAudience: true
        });
        this.props.dispatch(updateSurvey({
            accountId: this.props.orgId,
            surveyId: this.props.surveyId,
            survey: {
                ...this.props.survey,
                targeting: {
                    representativePreset: country
                },
                type: "FACEBOOK",
                facebookType: "NAT_REP"
            },
            onSuccess: () => {
                this.setState({
                    submittingRepAudience: false
                });
                sendEventToActiveCampaign(this.props.user.email, "Submitted_rep_audience", "Submitted rep audience");
                this.continueToBuilder();
            },
        }));
    };

    onUpgradeTierPress = () => {
        this.dialogRef.show();
    };

    onAudienceChange = () => {
    };

    continueToBuilder = () => {
        this.props.history.push(`${Routes.Surveys.root}/${this.props.surveyId}`);
        sendEventToActiveCampaign(this.props.user.email, "Entered_survey_builder", "Entered the survey builder");
    };

    render () {

        const type = this.props.type;

        return (
            <div className={styles.audience}>
                {/*<Header text={"Audience"}/>*/}

                <Field name={"type"}
                       component={SelectAudiences}
                       props={{
                           hideBorder: type === "LINK",
                           onUpgradeTierPress: this.onUpgradeTierPress,
                           onShareableLinkClick: this.onSubmitShareableLink,
                           survey: this.props.survey
                       }}/>

                {type === "FACEBOOK"
                    ? <FindMyAudience orgId={this.props.orgId}
                                      surveyId={this.props.surveyId}
                                      onSubmitTargeting={this.onSubmitTargeting}/>
                    : null}
                {type === "REPRESENTATION"
                    ? <RepresentationAudience
                        onSubmitRepAudience={this.onSubmitRepAudience}
                        submittingRepAudience={this.state.submittingRepAudience}/>
                    : null}

                {type === "LINK" && (
                    <div className={styles.link_button}>
                        <Button green onPress={this.onSubmitShareableLink}
                                loading={this.state.submittingLinkSurvey}>Continue</Button>
                    </div>
                )}
                <UpgradePromptDialog dialogRef={(ref) => this.dialogRef = ref}/>
            </div>
        );
    }
}


Audience = reduxForm({
    form: "audience",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};


        if ( values.quotaType === "SAMPLE_SIZE" ) {

        }

        if ( values.quotaType === "SPEND" ) {
            if ( !values.maxSpend || values.maxSpend === "" || values.maxSpend < 30000 ) {
                errors.maxSpend = "Minimum amount is $300 USD.";
            }
        }

        return errors;
    },
})(Audience);

export default Audience = connect((state, props) => {
    const surveyProps = getSurveyProps(state, props);
    const survey = surveyProps.survey;

    let initialValues = {
        type: "",
        audience: "null",
        representativeAudience: "null",
        quotaType: "SAMPLE_SIZE",
        desiredSampleSize: 100,
        maxSpend: "",
        dailyAdBudget: 500,
        geoLocations: [],
        ageGroups: [
            {
                minAge: 18,
                maxAge: 65
            }
        ],
        genders: ["ALL"],
        detailed: []
    };

    if ( survey && survey.type === "FACEBOOK" ) {
        let isRepresentativeSurvey = !!survey.targeting.representativePreset;
        let targeting = survey.targeting;

        initialValues = {
            ...initialValues,
            type: isRepresentativeSurvey ? "REPRESENTATION" : survey.type,
            quotaType: !!targeting.desiredSampleSize || !targeting.maxSpend ? "SAMPLE_SIZE" : "SPEND",
            desiredSampleSize: !!targeting.desiredSampleSize && !targeting.maxSpend ? targeting.desiredSampleSize : 100,
            maxSpend: !!targeting.maxSpend ? targeting.maxSpend : null,
            representativeAudience: survey.targeting.representativePreset,
        };

        if ( !!targeting ) {
            Object.keys(targeting).map((key) => {
                if ( targeting[key] ) {
                    if ( Array.isArray(targeting[key]) && targeting[key].length > 0 ) {
                        initialValues[key] = targeting[key];
                    }

                    if ( targeting[key] && !initialValues[key] ) {
                        initialValues[key] = targeting[key];
                    }
                }
                return null;
            });
        }
    } else if ( survey && survey.type === "AUDIENCE" ) {
        const { orgId, survey: { audienceListId } } = surveyProps;
        const audienceIsValid = !!((state.audiences || {})[orgId] || {})[audienceListId];
        initialValues = {
            ...initialValues,
            type: "EMAIL",
            audience: audienceIsValid ? survey.audienceListId : "null"
        };
    } else if ( survey && survey.type === "LINK" ) {
        initialValues = {
            ...initialValues,
            type: "LINK",
        };
    }

    return {
        ...surveyProps,
        initialValues: initialValues,
        formValues: ((state.form.audience || {}).values || {}),
        type: ((state.form.audience || {}).values || {}).type,
        user: state.user,
        priceEstimate: state.estimate,
    };
})(Audience);
