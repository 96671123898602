import { useEffect } from "react";

export default function useMountEffect(effect: () => void, onError?: (err: any) => void) {
    useEffect(() => {
        try {
            const asyncFunc = async () => {
                await effect();
            };

            asyncFunc();
        } catch (err) {
            onError?.(err);
        }
    }, []);
}
