/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-01-07.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import Routes from "../../../../../constants/routes";
import {Route, Switch} from "react-router-dom";
import SelectAgent from "./routes/selectAgent/SelectAgent";
import SelectTemplate from "./routes/selectTemplate/SelectTemplate";

export default class CreateSurvey extends Component {

    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <Switch location={this.props.location}>
                <Route path={Routes.Surveys.Create.SelectAgent} component={SelectAgent}/>
                <Route path={Routes.Surveys.Create.SelectTemplate} component={SelectTemplate}/>
            </Switch>
        );
    }
}