import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./IconButton.module.scss";
import MatButton from "@mui/material/Button";

function IconButton(props) {

    const className = classnames(styles.icon_button, props.className);

    const iconClass = classnames(styles.icon, {
        [styles.blue_dark]: props.blueDark,
        [styles.text_grey]: props.textGrey,
        [styles.red]: props.red,
        [styles.yellow]: props.yellow,
        [styles.plain]: props.plain,
        [styles.border]: props.border,
        [styles.green]: props.green,
        [styles.green_light]: props.greenLight,
        [styles.blue]: props.blue,
        [styles.pink]: props.pink,
        [styles.orange]: props.orange,
        [styles.peach]: props.peach,
        [styles.turquoise]: props.turquoise,
        [styles.active]: props.active,
        [styles.round]: props.round,
    }, props.iconClassName);

    return (
        <MatButton onClick={props.onPress} className={className}>
            <div className={styles.button_contents}>
                <span className={iconClass}>{props.children}</span>
                {!!props.text ? <div className={styles.text_wrapper}>
                    <span className={styles.text}>{props.text}</span>
                </div> : null}
            </div>
        </MatButton>
    );
}

IconButton.propTypes = {
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    text: PropTypes.string,
    onPress: PropTypes.func,

    red: PropTypes.bool,
    yellow: PropTypes.bool,
    plain: PropTypes.bool,
    greyDark: PropTypes.bool,
    textGrey: PropTypes.bool,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    green: PropTypes.bool,
    greenLight: PropTypes.bool,
    blue: PropTypes.bool,
    blueDark: PropTypes.bool,
    pink: PropTypes.bool,
    orange: PropTypes.bool,
    peach: PropTypes.bool,
    turquoise: PropTypes.bool,
};

IconButton.defaultProps = {
    className: null,
    iconClassName: null,
    text: null,

    red: false,
    yellow: false,
    plain: false,
    greyDark: false,
    textGrey: false,
    border: false,
    borderDark: false,
    green: false,
    greenLight: false,
    blue: false,
    blueDark: false,
    pink: false,
    orange: false,
    peach: false,
    turquoise: false,

    settings: false,
    skip: false,
    add: false,
    delete: false,
};

export default IconButton;
