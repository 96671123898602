import React from "react";
import TextInput from "../textInput/TextInput";
import { SearchOutlined } from "@mui/icons-material";
import Button from "../button/Button";
import styles from "./SearchSurvey.module.scss";

export default function SearchSurvey(props: { value: string, onChange: (text: string) => void, onClearPress: () => void, title: string }) {
    const { value, onChange, onClearPress, title } = props;

    return (
        <div className={styles.search_container}>
            <span>{title}</span>
            <div className={styles.search_input}>
                <TextInput placeholder={"Search..."} text={value} onTextChange={onChange} icon={<SearchOutlined />} />
                <Button plain disabled={value === ""} onPress={onClearPress}>Clear</Button>
            </div>
        </div>
    );
}
