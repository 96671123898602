import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Select.module.scss";
import classnames from "classnames";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import MatSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default class Select extends Component {

    static propTypes = {
        className: PropTypes.string,
        labelClassName: PropTypes.string,
        wrapperClassName: PropTypes.string,
        onChange: PropTypes.func,
        label: PropTypes.any,
        value: PropTypes.string,
        defaultValue: PropTypes.string,
        options: PropTypes.array,
        native: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        labelClassName: null,
        wrapperClassName: null,
        onChange: () => null,
        label: null,
        value: "null",
        defaultValue: "null",
        options: [],
        native: false,
    };

    state = {
        value: this.props.value,
        focused: false,
    };


    onChange = (e) => {
        const value = e.target.value;

        if ( !!this.props.onChange ) {
            this.props.onChange(value);
        }
        if ( !!this.props.input ) {
            this.props.input.onChange(value);
        }
    };

    onFocus = () => {
        if ( !!this.props.onFocus ) {
            this.props.onFocus();
        }

        if ( !!this.props.input ) {
            this.props.input.onFocus();
        }

        this.setState({
            focused: true,
        });
    };

    onBlur = () => {
        if ( !!this.props.onBlur ) {
            this.props.onBlur();
        }

        if ( !!this.props.input ) {
            this.props.input.onBlur();
        }
        this.setState({
            focused: false,
        });
    };

    renderSelectedValue = (value) => {
        if ( value === null ) {
            if ( !!this.props.options[0] ) {
                if ( !this.props.options[0].description ) {
                    return (
                        <div className={styles.selected_text}>
                            {this.props.options[0].label}
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.selected_text}>
                            {this.props.options[0].description}
                        </div>
                    );
                }
            }


        }
        const item = (this.props.options.filter((opt) => isEqual(opt.value, value))[0] || {});
        return (
            <div className={styles.selected_text}>
                {item.label}
            </div>
        );
    };

    render () {

        const className = classnames(styles.select, this.props.className);
        const labelClassName = classnames(styles.label, this.props.labelClassName);
        const props = omit({ ...this.props }, [...Object.keys(Select.defaultProps), "input", "meta"]);

        let value = (this.props.value || this.props.defaultValue);
        if ( !!this.props.input ) {
            value = this.props.input.value;
        }
        let error = this.props.error;
        if ( !!this.props.meta ) {
            error = this.props.meta.error;
        }

        const selectWrapperClassName = classnames(styles.select_wrapper, {
            [styles.focused]: this.state.focused,
            [styles.disabled]: this.props.disabled,
            [styles.error]: !!error,
        }, this.props.wrapperClassName);

        return (
            <div className={className}>
                {!!this.props.label ? <label className={labelClassName}>{this.props.label}</label> : null}
                <MatSelect
                    {...props}
                    classes={{
                        root: styles.root,
                        select: styles.select_text,
                    }}
                    className={selectWrapperClassName}
                    displayEmpty={true}
                    disableUnderline
                    value={value}
                    native={this.props.native}
                    // open={this.state.focused}
                    renderValue={this.renderSelectedValue}
                    onOpen={this.onFocus}
                    onClose={this.onBlur}
                    onChange={this.onChange}
                >
                    {this.props.options.map((option) => (
                        <MenuItem key={option.value} value={option.value} disabled={!!option.disabled}>
                            {option.label}
                        </MenuItem>
                    ))}
                </MatSelect>

                {!!error ? <label className={styles.error_text}>{error}</label> : !!this.props.helperText ?
                    <label className={styles.helper_text}>{this.props.helperText}</label> : null}
            </div>
        );
    }
}
