import ReactGA from "react-ga";

declare global {
    interface Window {
        dataLayer: any[]
    }
}

function logSurveyEvent(action: string, label?: string, value?: number): void {
    if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
            category: "Survey",
            action,
            label,
            value,
        })
    }
}

function tierSignupEvent(tier: string) {
    window.dataLayer.push({
        "event": "userSignup",
        "userTier": tier
    })
}



function surveyPaymentEvent(price: number, tier: string, surveyType: string) {
    window.dataLayer.push({
        "event": "surveyPayment",
        "userTier": tier,
        "surveyType": surveyType,
        "price": price
    })
}

function accountUpgradeEvent(tier: string) {
    window.dataLayer.push({
        "event": "userUpgrade",
        "selectedTier": tier,
    })
}

function loginSuccess(email: string) {
    window.dataLayer.push({
        "event": "userLogin",
        "userEmail": email,
    })
}



const AnalyticsAPI = {
    logSurveyEvent,
    accountUpgradeEvent,
    surveyPaymentEvent,
    tierSignupEvent,
    loginSuccess,
};

export default AnalyticsAPI;