import React, { Fragment, PureComponent } from "react"
import PropTypes from "prop-types"
import Dialog from "../../dialog/Dialog"
import styles from "./AddLogicDialog.module.scss";
import Select from "../../select/Select";
import connect from "react-redux/es/connect/connect";
import { change, formValueSelector } from "redux-form";
import { v4 as uuid } from "uuid";
import {
    FREE_TEXT,
    LONG_FORM_MULTI_CHOICE,
    LONG_FORM_SINGLE_CHOICE,
    MULTI_CHOICE,
    SINGLE_CHOICE
} from "../../../../constants/QuestionTypes";
import CheckBox from "../../checkBox/CheckBox";
import RadioGroup from "../../radioGroup/RadioGroup"
import { isAdmin } from "../../../../constants/isAdmin";

class AddLogicDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        fieldName: PropTypes.string,
        questionIndex: PropTypes.number,
        question: PropTypes.object,
        surveyQuestions: PropTypes.array,
        isAdmin: PropTypes.bool
    };

    static defaultProps = {
        dialogRef: () => null,
        fieldName: null,
        questionIndex: null,
        question: {},
        surveyQuestions: [],
        isAdmin: false
    };

    constructor (props) {
        super(props);

        this.state = {
            choices: {}
        };


    }

    onChangeTargetResponse = (value, choiceIndex) => {
        const choice = this.props.question.choices[choiceIndex];
        choice.terminatingChoice = null
        choice.qualifyingChoice = null
        choice.completingChoice = null
        choice.nextQuestion = null
        if ( value === "terminate" ) {
            choice.terminatingChoice = true
            choice.nextQuestion = null
        } else if ( value === "complete" ) {
            choice.completingChoice = true
            choice.nextQuestion = null
        } else if ( value !== "placeholder" ) {
            choice.nextQuestion = value;
        }
        this.setState({ choices: { ...this.state.choices, [choice.id]: choice } })
    };

    onCheckBoxChange = (value, choiceIndex) => {
        const choice = this.props.question.choices[choiceIndex];
        choice.terminatingChoice = null
        choice.qualifyingChoice = null
        choice.completingChoice = null
        choice.nextQuestion = null
        if ( value === "qualify" ) {
            choice.qualifyingChoice = true;
        }

        this.setState({ choices: { ...this.state.choices, [choice.id]: choice } })
    }


    onCancelPress = () => {
        this.setState({ choices: {} })
        this.dialog.actionToHide()
    };

    actionToHide = () => {

    };

    onSubmit = async () => {
        const newQuestion = { ...this.props.question };
        const questionType = this.props.question.type;

        if ( questionType === SINGLE_CHOICE || questionType === MULTI_CHOICE || questionType === LONG_FORM_SINGLE_CHOICE || questionType === LONG_FORM_MULTI_CHOICE ) {
            const newChoices = newQuestion.choices;

            newChoices.map((choice) => {

                if ( !!this.state.choices[choice.id] ) {
                    choice = this.state.choices[choice.id];
                    newQuestion.choicesHaveSkipLogic = true
                }


                return choice
            });

            newQuestion.choices = newChoices;
        }


        this.props.dispatch(change("editSurvey", `questions[${this.props.question.ordinal}]`, newQuestion));
        this.setState({ choices: {} })
        this.dialog.hide()
    };

    onClearSkipLogic = (hide = true) => {
        const newQuestion = { ...this.props.question };
        const newChoices = newQuestion.choices;

        newQuestion.terminatingQuestion = null;
        newQuestion.nextQuestion = null;
        newQuestion.choicesHaveSkipLogic = null;

        newChoices.map((choice) => {
            choice.nextQuestion = null;
            choice.terminatingChoice = null;
            choice.qualifyingChoice = null;
            return choice
        });
        newQuestion.choices = newChoices

        this.props.dispatch(change("editSurvey", `questions[${this.props.question.ordinal}]`, newQuestion));
        hide && this.dialog.hide()
    };

    renderQuestionRows = (questionType, selectChoiceOptions, targetQuestionOptions) => {

        return (
            selectChoiceOptions.map((choice, choiceIndex) => {
                return (
                    <div key={`${choice.id}-${choiceIndex}`} className={styles.logic_row}>
                        <div className={styles.logic_column}>
                            <p>{choice.label}</p>
                        </div>
                        <div className={styles.logic_column}>
                            {this.renderActionOptions(this.props.question.type, selectChoiceOptions, targetQuestionOptions, choiceIndex)}
                        </div>
                    </div>
                )
            })
        )
    }

    renderActionOptions = (questionType, selectChoiceOptions, targetQuestionOptions, choiceIndex) => {

        // const skipLogicType = [{label: "If answer is equal to", value: "placeholder"}];
        const choice = this.props.question.choices[choiceIndex]
        const value = () => {
            if ( choice.nextQuestion ) {
                return this.props.question.choices[choiceIndex].nextQuestion
            } else if ( choice.terminatingChoice ) {
                return "terminate"
            } else if ( choice.completingChoice ) {
                return "complete"
            } else {
                return "placeholder"
            }

        }

        switch ( questionType ) {
            case SINGLE_CHOICE:
            case LONG_FORM_SINGLE_CHOICE: {
                return (
                    <Fragment>
                        <Select
                            className={styles.select}
                            options={targetQuestionOptions}
                            value={value().toString()}
                            onChange={(e) => this.onChangeTargetResponse(e, choiceIndex)}/>
                    </Fragment>
                )
            }

            case MULTI_CHOICE:
            case LONG_FORM_MULTI_CHOICE: {
                const checked = this.props.question.choices[choiceIndex].qualifyingChoice ? "qualify" : "terminate"
                const radioOptions = [{
                    text: "Go to next question (Qualifies)",
                    value: "qualify"
                },
                    {
                        text: "End survey",
                        value: "terminate"
                    }]
                return (
                    <Fragment>
                        <RadioGroup options={radioOptions} onChange={(e) => this.onCheckBoxChange(e, choiceIndex)}
                                    value={checked}/>
                        {/*<CheckBox onChange={(e) => this.onCheckBoxChange(e, choiceIndex)} checked={checked}/>*/}
                        {/*<span>*/}
                        {/*    Choice qualifies respondent to continue survey*/}
                        {/*</span>*/}
                    </Fragment>
                )
            }

            default:
                return null;
        }
    };

    render () {
        let selectChoiceOptions = [];
        if ( !!this.props.question.choices && !!this.props.question.choices.length ) {
            this.props.question.choices.map((choice) => {
                    if ( choice.hasOwnProperty("id") ) {
                        return selectChoiceOptions.push({
                            label: choice.value,
                            value: choice.id.toString()
                        })
                    } else {
                        return selectChoiceOptions.push({
                            label: `${choice.value} -  Save survey to select`,
                            value: uuid(),
                            disabled: true,
                        })
                    }
                }
            );
        }


        let targetQuestionOptions = [
            { label: "Go to next question (Default)", value: "placeholder" },
            { label: "Finish survey (Completion)", value: "complete" },
        ];
        if ( this.props.isAdmin ) {
            targetQuestionOptions.push({ label: "Finish survey (Terminate)", value: "terminate" })
        }
        for ( let i = 1 ; i <= this.props.surveyQuestions.length ; i++ ) {

            if ( (i - 2) > this.props.questionIndex && this.props.surveyQuestions[i - 1].hasOwnProperty("id") ) {

                targetQuestionOptions.push({
                    label: "Question " + i,
                    value: this.props.surveyQuestions[i - 1].id.toString()
                })
            } else if ( (i - 2) > this.props.questionIndex ) {
                targetQuestionOptions.push({
                    label: `Question ${i} -  Save survey to select`,
                    value: uuid(),
                    disabled: true,
                })
            }
        }

        let disableDialog;

        if ( (this.props.question.type === MULTI_CHOICE || this.props.question.type === LONG_FORM_MULTI_CHOICE) && (this.props.question.choices || []).length ) {
            const choices = this.state.choices
            if ( !((Object.values(choices) || []).find(c => c.qualifyingChoice) || this.props.question.choices.find(c => c.qualifyingChoice)) ) {
                disableDialog = true
            }
        }


        this.dialogActions = [
            {
                text: "Clear",
                onPress: this.onClearSkipLogic,
                props: {
                    plain: true,
                }
            },
            {
                text: "Cancel",
                onPress: this.onCancelPress,
                props: {
                    plain: true,
                }
            },
            {
                text: "Done",
                onPress: this.onSubmit,
                props: {
                    blue: true,
                    disabled: disableDialog
                }
            }
        ];


        return (
            <Dialog title={"Add logic"}
                    className={styles.logic_dialog}
                // titleClassName={styles.title}
                    actions={this.dialogActions}
                    actionToHide={this.actionToHide}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>

                <div className={styles.logic_table}>
                    <div className={styles.logic_row}>
                        <div className={styles.logic_column}>
                            <label>If answer is equal to...</label>
                        </div>
                        <div className={styles.logic_column}>
                            <label>Then</label>
                        </div>
                    </div>

                    {this.renderQuestionRows(this.props.question.type, selectChoiceOptions, targetQuestionOptions)}

                </div>
                {(this.props.question.type === SINGLE_CHOICE || this.props.question.type === LONG_FORM_SINGLE_CHOICE) &&
                    <label>
                        Tip: to replicate qualification logic, set disqualifying choices to finish survey.
                    </label>
                }
            </Dialog>
        )
    }
}

export default AddLogicDialog = connect((state, props) => {

        const selector = formValueSelector("editSurvey");

        const skipLogicChoice = ((selector(state, `${props.fieldName}.choices`) || []).filter((choice) => {
            return choice.nextQuestion || choice.terminatingChoice || choice.qualifyingChoice
        })[0] || {});

        const skipTargetFromChoice = skipLogicChoice.nextQuestion || (skipLogicChoice.terminatingChoice ? "terminate" : null);
        const skipTargetFromQuestion = props.question.nextQuestion;
        const qualifyingChoice = skipLogicChoice.qualifyingChoice && skipLogicChoice.id;

        return {
            isAdmin: isAdmin(state.user),
            targetResponse: skipLogicChoice.id || "placeholder",
            targetQuestion: skipTargetFromChoice || skipTargetFromQuestion || "placeholder",
            canSkipQuestion: selector(state, `${props.fieldName}.skipQuestion`),
            terminatingQuestion: selector(state, `${props.fieldName}.terminatingQuestion`),
            qualifyingChoice: qualifyingChoice
        }
    }
)(AddLogicDialog)
