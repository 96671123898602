
export function getOrganisationsAPI(token) {
    return window.get(`/v1/accounts`, token);
}

export function createOrganisationAPI(token, account) {
    return window.post(`/v1/accounts`, token, account);
}

export function getOrganisationAPI(token, accountId) {
    return window.get(`/v1/accounts/${accountId}`, token);
}

export function updateOrganisationAPI(token, accountId, account) {
    return window.put(`/v1/accounts/${accountId}`, token, account);
}

export function deleteOrganisationAPI(token, accountId) {
    return window.del(`/v1/accounts/${accountId}`, token);
}

export function getUsersForOrganisationAPI(token, accountId) {
    return window.get(`/v1/accounts/${accountId}/users`, token);
}

export function addUserToOrganisationAPI(token, accountId, data) {
    return window.post(`/v1/accounts/${accountId}/users`, token, data);
}

export function updateUserInOrganisationAPI(token, accountId, data) {
    return window.put(`/v1/accounts/${accountId}/users`, token, data);
}

export function removeUserFromOrganisationAPI(token, accountId, userId) {
    return window.del(`/v1/accounts/${accountId}/users/${userId}`, token);
}

export function getOrganisationCardsAPI(token, accountId) {
    return window.get(`/v1/accounts/${accountId}/cards`, token);
}

export function getOrganisationTransactions(token, accountId){
    return window.get(`/v1/accounts/${accountId}/transactions`, token);
}

export function addOrganisationCardAPI(token, accountId, stripeCardToken) {
    const data = {
        token: stripeCardToken,
    };
    return window.put(`/v1/accounts/${accountId}/cards`, token, data);
}

export function setOrganisationDefaultCardAPI(token, accountId, stripeCardId) {
    const data = {
        token: stripeCardId,
    };
    return window.put(`/v1/accounts/${accountId}/cards/default`, token, data);
}

export function updateOrganisationCardAPI(token, accountId, stripeCardId, data) {
    return window.put(`/v1/accounts/${accountId}/cards/${stripeCardId}`, token, data);
}

export function deleteOrganisationCardAPI(token, accountId, stripeCardId) {
    return window.del(`/v1/accounts/${accountId}/cards/${stripeCardId}`, token);
}

export function createUpdateSaaSSubscriptionAPI(token, accountId, subscriptionId, upgradeLater, card = "") {
    return window.put(`/v1/accounts/${accountId}/subscription/${subscriptionId}?upgrade_later=${upgradeLater}&card=${card}`, token);
}

export function cancelSaaSSubscriptionAPI(token, accountId) {
    return window.del(`/v1/accounts/${accountId}/subscription`, token);
}

export function calculateSubscriptionDifference(token, accountId, newPricingId) {
    return window.get(`/v1/accounts/${accountId}/subscription/calculate_difference`, token, {new_pricing_id: newPricingId});
}

export function cancelSaaSSubscriptionUpgradeAPI(token, accountId, subId) {
    return window.put(`/v1/accounts/${accountId}/subscription/cancel_upgrade`, token);
}
