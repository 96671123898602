import React, { PureComponent } from "react";
import styles from "./QuestionCardAnswer.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "@mui/material/Button";

export default class QuestionCardAnswer extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        onPress: PropTypes.func,
        style: PropTypes.object,
        selected: PropTypes.bool,
        text: PropTypes.string,
        number: PropTypes.number,
        percentage: PropTypes.number,
        fill: PropTypes.number,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        wrapperClassName: null,
        onPress: () => null,
        style: {},
        selected: false,
        text: "",
        number: 0,
        percentage: 0,
        fill: 0,
        isDisabled: false,
    };

    render() {
        const wrapperClassName = classnames(styles.question_card_answer_wrapper, this.props.wrapperClassName);

        let className = classnames(
            styles.question_card_answer,
            {
                [styles.answer_selected]: !!this.props.selected,
            },
            this.props.className,
            this.props.style,
        );
        if (this.props.text === "Skipped") {
            className = classnames(styles.question_card_answer_skipped, {
                [styles.answer_selected]: !!this.props.selected,
            });
        }
        const freeTextSkipped = this.props.freeText && this.props.text === "Skipped";
        let freeTextStyle = null;
        if (this.props.freeText && !freeTextSkipped) {
            freeTextStyle = { display: "inline-block" };
        }
        return (
            <div className={wrapperClassName} style={freeTextStyle}>
                <Button className={className} onClick={this.props.onPress} disabled={this.props.isDisabled}>
                    <div className={styles.fill_container}>
                        {!(this.props.freeText && !freeTextSkipped) && (
                            <div className={styles.fill} style={{ width: `${this.props.fill}%` }} />
                        )}
                    </div>
                    <div className={styles.answer_content}>
                        <span className={styles.answer_text} dangerouslySetInnerHTML={{ __html: this.props.text }} />
                        {this.props.hidePercentage || (this.props.freeText && this.props.text !== "Skipped") ? null : (
                            <span className={styles.answer_value}>{this.props.percentage}%</span>
                        )}
                    </div>
                </Button>
            </div>
        );
    }
}
