import React, { PureComponent } from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import { uploadImage } from "../../../../redux/actions/v1/images";
import connect from "react-redux/es/connect/connect";
import styles from "./UploadImageDialog.module.scss";
import uploadIcon from "../../../../resources/svg/upload_icon.svg";
import { showError } from "../../../../redux/actions/v1/snackbars";

export default class UploadImageDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        dialogRef: () => null,
    };

    constructor (props) {
        super(props);

        this.state = {
            files: [],
            loading: false
        };
    }

    onCancelPress = () => {
        this.setState({
            files: []
        });

        this.hide();
    };

    hide = () => {
        this.dialog.hide();
    };

    onImageSubmit = () => {
        this.setState({ loading: true });
        const files = this.state.files;

        const formData = new FormData();
        formData.isformdata = true;
        for ( let i = 0 ; i < files.length ; i++ ) {
            formData.append("files", files[i]);

        }


        this.props.dispatch(uploadImage({
            orgId: this.props.orgId,
            formData: formData,
            onSuccess: (f) => {
                this.setState({ preview: null, files: [], loading: false });
                this.props.onSubmit(f[0].url);
                this.hide();
            },
            onError: (err) => {
                showError(err.message);
                this.setState({ loading: false });
            }
        }));
    };

    onFileChange = (e) => {
        const files = [];

        for ( let i = 0 ; i < e.target.files.length ; i++ ) {
            files.push(e.target.files[i]);
        }

        this.setState({
            files: files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        });
    };

    render () {
        const actions = [
            {
                text: "Done",
                onPress: this.onImageSubmit,
                props: {
                    greyDark: true,
                    disabled: this.state.loading
                }
            },
            {
                text: "Cancel",
                onPress: this.onCancelPress,
                props: {
                    plain: true,
                    disabled: this.state.loading
                }
            },
        ];

        return (
            <Dialog title={this.props.title ? this.props.title : "Add media object"}
                    rootClassName={styles.dialog}
                    actions={actions}
                    actionToHide={this.onCancelPress}
                    loading={this.state.loading}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}>
                <div className={styles.file_input_label}>Upload image</div>
                <label className={styles.file_input}>
                    Click here to upload image
                    <input type={"file"} onChange={this.onFileChange} accept={"image/*"} multiple={false}/>
                    <img src={uploadIcon} alt={""}/>
                </label>
                <div className={styles.thumbnail_container}>
                    <img className={styles.thumbnail_image} src={((this.state.files || [])[0] || {}).preview} alt={""}/>
                </div>
            </Dialog>
        );
    }
}

UploadImageDialog = connect((state) => {
    return {
        orgId: state.settings.organisation
    };
})(UploadImageDialog);