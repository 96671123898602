import React, {Component} from "react";
import bluePadlock from "../../../../../../../../../../resources/svg/padlock-blue-circle.svg";
import yellowPadlock from "../../../../../../../../../../resources/svg/padlock-yellow-circle.svg";
import bluePencilButton from "../../../../../../../../../../resources/svg/bluePencilButton.svg";
import blueCrossButton from "../../../../../../../../../../resources/svg/blueCrossButton.svg";
import IconButton from "../../../../../../../../../widgets/iconButton/IconButton";
import styles from "./RenderCustomEndingButtons.module.scss";
import DeleteTrashcanIcon from "../../../../../../../../../../resources/js/DeleteTrashcanIcon";


export default class RenderCustomEndingButtons extends Component{

    getCustomEndingButton = () => {
        let button = {}
        if (this.props.upgradeRequired) {
            button = {
                icon: (<div className={styles.icon_button_wrapper}>
                    <img alt={"blue padlock"} src={bluePadlock}/>
                    <img alt={"yellow padlock"} src={yellowPadlock} className={styles.yellow_padlock}/>
                </div>),
                text: "Upgrade plan to unlock custom endings",
                onPress: this.props.onUpgradePress
            }
        } else if (!this.props.editingEnding) {
            button = {
                icon: (<div className={styles.icon_button_wrapper}>
                    <img alt={"edit ending"} src={bluePencilButton}/>
                </div>),
                text: "Edit survey ending",
                onPress: this.props.onCustomiseEndingPress
            }
        } else if (this.props.editingEnding) {
            button = {
                icon: (<div className={styles.icon_button_wrapper}>
                    <img style={{left: 3}} alt={"blue cross"} src={blueCrossButton}/>
                </div>),
                text: "Revert back to default ending",
                onPress: this.props.onRevertToDefaultPress
            }
        }
        return button
    }

    render() {
        return (
            <div className={styles.ending_buttons}>
                <div className={styles.ending_buttons_container}>
                    <IconButton text={this.getCustomEndingButton().text}
                                textGrey
                                blueDark
                                onPress={this.getCustomEndingButton().onPress}
                                className={styles.button}>
                        {this.getCustomEndingButton().icon}
                    </IconButton>
                    {(((this.props.customEnding || {}).content || []).length > 1 || ((this.props.customEndingFormValues || {}).content || []).length > 1) && this.props.editingEnding &&
                    <IconButton text={"Delete a row"}
                                textGrey
                                blueDark
                                onPress={this.props.deleteContentRow}
                                className={styles.button}>
                        <DeleteTrashcanIcon/>
                    </IconButton>
                    }
                </div>

            </div>
        )
    }
}