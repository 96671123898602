import React, { Component } from "react";
import PropTypes from "prop-types";
import { searchDetailed } from "../../../../../../../../../../../../../../redux/actions/v1/facebook";
import { connect } from "react-redux";
import AutoCompleteInput from "../../../../../../../../../../../../../widgets/autoCompleteInput/AutoCompleteInput";
import debounce from "lodash/debounce";
import styles from "./InterestInput.module.scss";
import MenuItem from "@mui/material/MenuItem";

class InterestInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            suggestions: [],
        };

        this.onTextChangeDebounce = debounce(this.debouncedSuggestionTextChange, 15);
    }

    onSuggestionTextChange = (text) => {
        this.props.onSuggestionTextChange(text);
        this.onTextChangeDebounce(text);
    };

    debouncedSuggestionTextChange = (text) => {

        if (text.length <= 0) {

            this.setState({
                suggestions: []
            });

            return;
        }

        this.setState({
            loading: true
        });

        this.props.dispatch(searchDetailed({
            params: {
                q: text
            },
            onSuccess: (suggestions) => {
                this.setState({
                    loading: false,
                    suggestions: suggestions,//sortBy(suggestions, ["name"]),
                });
            }
        }));
    };

    onSuggestionPress = (suggestion) => {

        this.setState({
            suggestions: [],
        });

        this.props.onSuggestionPress(suggestion);
    };

    renderSuggestion = (suggestion, onSuggestionPress, i) => {
        const suggestionType = suggestion.type;//.replace(/_/g, " ");

        return (
            <MenuItem key={i}
                      onClick={onSuggestionPress(suggestion)}
                      className={styles.suggestion}>
                <div className={styles.chip}>{suggestionType}</div>
                {suggestion.name}
            </MenuItem>
        );
    };

    render() {
        return (
            <AutoCompleteInput className={styles.interest_input}
                               suggestions={this.state.suggestions}
                               placeholder={"Search"}
                               text={this.props.text}
                               loading={this.state.loading}
                               disabled={this.props.disabled}
                               renderSuggestion={this.renderSuggestion}
                               onSuggestionTextChange={this.onSuggestionTextChange}
                               onSuggestionPress={this.onSuggestionPress} />
        );
    }
}

InterestInput.propTypes = {
    onSuggestionTextChange: PropTypes.func,
    onSuggestionPress: PropTypes.func,
    text: PropTypes.string,
};

InterestInput.defaultProps = {
    onSuggestionTextChange: () => null,
    onSuggestionPress: () => null,
    text: "",
};

InterestInput = connect(() => {
    return {};
})(InterestInput);

export default InterestInput;
