import React from "react";

export default function RandomIconActive() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="Group_17223" data-name="Group 17223" transform="translate(-1035 -2434)">
                <g id="Group_16541" data-name="Group 16541" transform="translate(0 2153)">
                    <g id="Group_16504" data-name="Group 16504" transform="translate(170 -405)">
                        <g id="Group_16503" data-name="Group 16503" transform="translate(865 686)">
                            <g id="Group_15230" data-name="Group 15230">
                                <g id="Ellipse_1196" data-name="Ellipse 1196" fill="rgba(141,216,0,0.21)" stroke="#8dd800" strokeWidth="1">
                                    <circle cx="18" cy="18" r="18" stroke="none"/>
                                    <circle cx="18" cy="18" r="17.5" fill="none"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Group_17049" data-name="Group 17049" transform="translate(743.461 2898.668)">
                    <path id="Path_7207" data-name="Path 7207" d="M315.692-452.934c0-.676,0-1.312,0-1.948,0-.193-.014-.4.205-.487s.378.035.527.184q1.644,1.647,3.291,3.292c.29.291.288.447-.009.745q-1.645,1.647-3.291,3.292c-.142.143-.291.26-.5.177s-.223-.28-.222-.479c0-.637,0-1.275,0-1.9-.029-.024-.044-.047-.058-.047-1.084.01-2.172-.028-3.251.05a2.333,2.333,0,0,0-1.86,1.406c-.622,1.184-1.164,2.411-1.745,3.617a12.39,12.39,0,0,1-1.915,3.091,5.117,5.117,0,0,1-3.9,1.7c-.78.008-1.561,0-2.342,0-.354,0-.46-.108-.462-.459q0-.957,0-1.913c0-.333.11-.439.45-.441.723,0,1.447-.019,2.17,0a2.606,2.606,0,0,0,2.543-1.472c.629-1.191,1.172-2.428,1.757-3.643a11.722,11.722,0,0,1,2.041-3.209,4.93,4.93,0,0,1,3.422-1.543c.931-.043,1.865-.016,2.8-.02Z" transform="translate(0)" fill="#8dd800"/>
                    <path id="Path_7208" data-name="Path 7208" d="M356.238-392.348c-.367,0-.717,0-1.067,0a11.759,11.759,0,0,1-3.347-.246,5.224,5.224,0,0,1-2.831-2.144.235.235,0,0,1-.012-.2c.465-.946.937-1.888,1.409-2.831,0-.005.014-.007.03-.014l.7,1.2a2.708,2.708,0,0,0,2.581,1.416c.836-.018,1.673,0,2.542,0,0-.108.012-.2.013-.289,0-.571-.013-1.142.01-1.713.006-.147.1-.379.2-.413a.567.567,0,0,1,.473.13c.688.657,1.354,1.338,2.027,2.011.444.444.89.886,1.332,1.333.259.261.265.432.015.683-1.122,1.126-2.243,2.253-3.378,3.366a.524.524,0,0,1-.445.13.522.522,0,0,1-.221-.4c-.025-.57-.01-1.142-.011-1.713C356.252-392.14,356.244-392.233,356.238-392.348Z" transform="translate(-40.56 -47.88)" fill="#8dd800"/>
                    <path id="Path_7209" data-name="Path 7209" d="M305.977-435.379c-.228-.39-.465-.8-.706-1.2a2.673,2.673,0,0,0-2.513-1.382c-.714.012-1.428,0-2.141,0-.342,0-.453-.107-.455-.436,0-.647,0-1.294,0-1.942,0-.238.075-.433.343-.43,1.112.012,2.233-.039,3.335.078a5.082,5.082,0,0,1,3.572,2.278.283.283,0,0,1,.011.245C306.946-437.239,306.463-436.313,305.977-435.379Z" transform="translate(-0.004 -12.156)" fill="#8dd800"/>
                </g>
            </g>
        </svg>
    )
}