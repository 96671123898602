import React, {Component} from "react";
import styles from "./NoOrganisationDialog.module.scss";
import PropTypes from "prop-types";
import Routes from "../../../../constants/routes";
import Dialog from "../../dialog/Dialog";
import {withRouter} from "react-router-dom";

class NoOrganisationDialog extends Component {

    static propTypes = {
        dialogRef: PropTypes.func,
    };

    static defaultProps = {
        dialogRef: () => null,
    };

    onCreateOrgPress = () => {
        this.props.history.push(`${Routes.Settings.Organisations}`);
        this.hide()
    };

    hide = () => {
        this.dialog.hide()
    };

    render() {
        return (
            <Dialog title={"Add an organisation"}
                    className={styles.dialog_container}
                    actionsClassName={styles.action_buttons}
                    text={"Oops, you haven't created an organisation yet, create one now."}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}
                    actions={[
                        {text: "Create organisation", onPress: this.onCreateOrgPress, props: {blueDark: true}}
                    ]}/>
        )
    }
}

export default NoOrganisationDialog = withRouter(NoOrganisationDialog)