import React, {Component} from "react"
import connect from "react-redux/es/connect/connect";
import values from "../../../../../../../../node_modules/lodash-es/values"
import keys from "../../../../../../../../node_modules/lodash-es/keys"
import PropTypes from "prop-types"
import styles from "./SelectTemplate.module.scss"
import Template from "./template/Template"
import {createSurvey, createTemplateSurvey, getSurveyTemplates} from "../../../../../../../redux/actions/v1/surveys"
import Content from "../../../../../../widgets/content/Content";
import Routes from "../../../../../../../constants/routes";
import Dialog from "../../../../../../widgets/dialog/Dialog";
import TextInput from "../../../../../../widgets/textInput/TextInput";
import AnalyticsAPI from "../../../../../../../util/Analytics";
import sendEventToActiveCampaign from "../../../../../../../redux/actions/api/v1/eventTracking";
import MenuItem from "@mui/material/MenuItem";

class SelectTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectedTemplate: "scratch",
            surveyName: "",
            loadingSurvey: false,
        };
    }

    static propTypes = {
        templates: PropTypes.object,
    };

    static defaultProps = {
        templates: {},
    };

    componentDidMount() {
        this.props.dispatch(getSurveyTemplates({
            onSuccess: () => {
                this.setState({
                    loading: false,
                    selectedTemplate: isNaN(this.state.selectedTemplate) ? "scratch" : Number(keys(this.props.templates)[0])
                });
            }
        }));
    }

    renderSideContent = () => {
        const templates = (values(this.props.templates) || []);

        return (
            <div className={styles.template_button_wrapper}>
                {templates.map((template) => {
                    return (
                        <MenuItem key={template.id}
                                  className={styles.template_menu_text}
                                  style={{whiteSpace: 'normal', paddingTop: 36}}
                                  selected={this.state.selectedTemplate === template.id}
                                  onClick={() => this.onTemplatePress(template.id)}>
                            <p>{template.name}</p>
                        </MenuItem>
                    )
                })}
            </div>

        )
    };

    onTemplatePress = (templateId) => {
        this.setState({
            selectedTemplate: templateId
        });
    };

    onCreateSurveyPress = () => {
        this.nameDialog.show()
    };

    onCreateSurveySubmit = () => {
        this.setState({
            loadingSurvey: true
        });

        this.state.selectedTemplate === "scratch"
            ? this.props.dispatch(createSurvey({
                orgId: this.props.orgId,
                survey: {
                    name: this.state.surveyName
                },
                onSuccess: (survey) => {
                    this.props.history.push(`${Routes.Surveys.root}/${survey.id}/audience`)
                    sendEventToActiveCampaign(this.props.user.email, "Draft_started", "Draft started")
                }
            }))
            : this.props.dispatch(createTemplateSurvey({
                accountId: this.props.orgId,
                templateId: this.state.selectedTemplate,
                survey: {
                    name: this.state.surveyName
                },
                onSuccess: (res) => {
                    AnalyticsAPI.logSurveyEvent("Selected template", (this.props.templates[this.state.selectedTemplate] || {}).name);
                    this.setState({
                        loadingSurvey: false
                    });
                    this.props.history.push(`${Routes.Surveys.root}/${res.id}/audience`)
                    sendEventToActiveCampaign(this.props.user.email, "Draft_started", "Draft started")

                },
                onError: (err) => {
                    this.setState({
                        loadingSurvey: false,
                    });
                }
            }))
    };

    onNameChange = (text) => {
        this.setState({surveyName: text})
    };

    render() {
        const selectedTemplate = this.props.templates[this.state.selectedTemplate];
        const {
            id,
            name,
            imageURL,
            description,
            checkListItems
        } = (selectedTemplate || {});

        const dialogActions = [
            {
                text: "Cancel",
                onPress: () => this.nameDialog.hide(),
                props: {
                    plain: true,
                    disabled: this.state.loadingSurvey
                },
            },
            {
                text: "Next: select your audience",
                onPress: this.onCreateSurveySubmit,
                props: {
                    disabled: !this.state.surveyName || this.state.loadingSurvey,
                    loading: this.state.loadingSurvey,
                },
            }
        ];
        return (
            <Content
                containerClassName={styles.select_template_container}
                className={styles.select_template_inner}
                headerText={"Templates"}
                loading={this.state.loading}
                sideContentTitle={"Select a template"}
                sideContentClass={styles.template_menu}
                sideContent={this.renderSideContent}
                sideContentLoading={this.state.loading}>
                <div className={styles.template_container}>
                    {selectedTemplate && Object.keys(selectedTemplate).length > 0 &&
                    <Template id={id}
                              title={name}
                              image={imageURL}
                              onCreateSurveyPress={() => this.onCreateSurveyPress(id)}
                              description={description}
                              checkListItems={checkListItems}/>}

                </div>
                <Dialog title={"Enter a name for your survey"}
                        actions={dialogActions}
                        ref={(ref) => this.nameDialog = ref}
                        disableDismiss={this.state.loadingSurvey}>
                    <TextInput onTextChange={this.onNameChange}
                               text={this.state.surveyName}/>
                </Dialog>
            </Content>
        )
    }
}

export default SelectTemplate = connect((state) => {
    const user = state.user;
    return {
        templates: {
            "scratch": {
                id: "scratch",
                name: "Start from scratch",
                imageURL: "https://storage.googleapis.com/stickybeak-test-uploads/images/template-start-from-scratch-1.png",
                description: "Jump straight into our editor and create a survey just how you'd like it."
            },
            ...state.surveyTemplates,
        },
        orgId: state.settings.organisation,
        user
    }
})(SelectTemplate)
