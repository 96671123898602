import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import connect from "react-redux/es/connect/connect";
import { change } from "redux-form"
import styles from "./QuestionSettingsDialog.module.scss"
import Dialog from "../../dialog/Dialog"
import Select from "../../select/Select"
import * as QuestionTypes from "../../../../constants/QuestionTypes"
import formValueSelector from "redux-form/es/formValueSelector";
import CheckBox from "../../checkBox/CheckBox";

class QuestionSettingsDialog extends PureComponent {

    static propTypes = {
        onSubmit: PropTypes.func,
        fieldName: PropTypes.string,
        dialogRef: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: () => null,
        fieldName: null,
        dialogRef: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            disableDismiss: false,
            questionType: props.questionType,
            canSkip: props.canSkip
        };

        this.actions = [
            { text: "Cancel", onPress: this.onCancelPress, props: { plain: true } },
            { text: "Done", onPress: this.onSubmit, props: { blue: true } }
        ];

        this.selectOptions = QuestionTypes.default.map((questionType) => {

                return {
                    label: questionType.text,
                    value: questionType.value
                }

        });
    }

    handleTypeChange = (questionType) => {
        this.setState({
            questionType: questionType
        })
    };

    handleSkipChange = () => {
        this.setState((prevState) => {
            return {
                canSkip: !prevState.canSkip
            }
        })
    };

    actionToHide = () => {
        this.setState({
            questionType: this.props.questionType,
            canSkip: this.props.canSkip
        })
    };

    onCancelPress = () => {
        this.dialog.actionToHide()
    };

    onSubmit = () => {
        this.props.dispatch(change("editSurvey", `${this.props.fieldName}.type`, this.state.questionType));
        this.props.dispatch(change("editSurvey", `${this.props.fieldName}.canSkip`, this.state.canSkip));

        this.dialog.hide()
    };

    render() {
        const isSurveyLive = (this.props.survey || {}).status === "LIVE";
        const isNewQuestion = !this.props.questionId;
        const isQuestionFreeTextOrRanking = this.props.questionType === "FREE_TEXT" || this.props.questionType === "RANKING";

        let options = this.selectOptions;
        if (isSurveyLive) {
            options = options.filter(({value}) => value !== "FREE_TEXT" && value !== "RANKING" && value!=="RATING" && value!== "EMOJI_RATING" && value!=="LOVE_HATE" && value!=="NPS")
        }
        options = options.filter((option)=> {
            return (option.id !== "LOVE_HATE")
        })


        const targeting = this.props.survey.targeting || {};
        //TD-2053 Hide the "Can be skipped" checkbox if it's a nat rep survey
        const showCanSkip = !targeting.representativePreset && !(this.state.questionType==="EMOJI_RATING")&&!(this.state.questionType==="SINGLE_CHOICE");

        return (
            <Dialog
                title={"Settings"}
                actions={this.actions}
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref)
                }}
                actionToHide={this.actionToHide}
                className={styles.dialog_container}
            >
                <div className={styles.options_wrapper}>
                    <label>Question type</label>
                    <Select value={this.state.questionType}
                            className={styles.select}
                            options={options}
                            onChange={this.handleTypeChange}
                            autoWidth={false}
                            disabled={!isNewQuestion && isSurveyLive && isQuestionFreeTextOrRanking}
                    />
                    {showCanSkip && <CheckBox
                        label={"Can be skipped"}
                        onChange={this.handleSkipChange}
                        checked={this.state.canSkip}/>}
                </div>
            </Dialog>
        )
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    return {
        questionId: selector(state, `${props.fieldName}.id`),
        questionType: selector(state, `${props.fieldName}.type`),
        canSkip: selector(state, `${props.fieldName}.canSkip`)
    }
})(QuestionSettingsDialog);
