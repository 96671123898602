/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {store} from "../components/App";
import {Roles} from "../types/model/User";

export function isAdmin(user = null) {

    if (!user) {
        user = store.getState().user
    }


    if (!user) {
        return false;
    }

    return (user || {role: ""}).role === Roles.SUPER_ADMIN;
}
