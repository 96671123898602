

import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Interests.module.scss";
import classnames from "classnames";
import InterestInput from "./components/interestInput/InterestInput";
import DeleteCircle from "../../../../../../../../../../../../resources/js/DeleteCircle";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IconButton from "@mui/material/IconButton";

class Interests extends Component {

    state = {
        suggestionText: "",
        searching: true
    };

    onSuggestionTextChange = (text) => {
        this.setState({
            suggestionText: text,
        });
    };

    onSuggestionPress = (suggestion) => {

        this.setState({
            suggestionText: "",
            searching: false
        });

        this.props.fields.push(suggestion);
    };

    onRemoveInterestPress = (index) => {
        return () => {
            this.props.fields.remove(index);
        };
    };

    onAddInterestPress = () => {
        this.setState({
            searching: true
        })
    }

    renderInterest = (name, i) => {

        const interest = this.props.detailed[i];

        return (
            <div className={styles.interest} key={i}>
                <span>{interest.name}</span>
                <IconButton onClick={this.onRemoveInterestPress(i)} className={styles.delete_button}>
                    <DeleteCircle />
                </IconButton>
            </div>
        );
    };

    render() {
        const className = classnames(styles.interests, this.props.className);
        return (
            <div className={className}>
                {this.props.fields.getAll().length !== 0 &&
                <div className={styles.table_list}>
                    {this.props.fields.map(this.renderInterest)}
                </div> }

                {this.state.searching ?


                <div data-tip={"You can add up to 5 interests"} data-for={"interest-tooltip"} className={styles.seach_field}>
                    <span>Search interests and behaviours</span>
                    <InterestInput className={styles.interest_input}
                                   text={this.state.suggestionText}
                                   disabled={this.props.fields && this.props.fields.length >= 5}
                                   onSuggestionTextChange={this.onSuggestionTextChange}
                                   onSuggestionPress={this.onSuggestionPress} />
                    {this.props.fields.length === 5 && (
                        <ReactTooltip textColor={"black"}
                                      backgroundColor={"white"}
                                      place={"right"}
                                      id={"interest-tooltip"}
                                      className={styles.tooltip}
                                      border
                                      borderColor={"#CDD2D5"} />
                    )}
                </div> :
                    <div className={styles.add_new}>
                        <a onClick={this.onAddInterestPress}>+ Add interest</a>
                    </div> }
            </div>
        );
    }
}

Interests.propTypes = {
    className: PropTypes.string,
};

Interests.defaultProps = {
    className: null,
};

Interests = connect((state) => {
    return {
        detailed: ((state.form.audience || {}).values || {}).detailed || [],
    };
})(Interests);

export default Interests;
