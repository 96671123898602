import React, {useEffect, useState} from "react";
import {AccountType} from "../../../types/model/StickybeakAccount";
import {getOrganisation} from "../../../redux/actions/v1/organisations";
import {showError} from "../../../redux/actions/v1/snackbars";
import Routes from "../../../constants/routes";
import {Route, Switch} from "react-router-dom";
import CompletedSurveys from "../surveys/routes/completedSurveys/CompletedSurveys";
import SurveyEnterpriseDashboard from "../surveys/routes/surveyDashboard/SurveyEnterpriseDashboard";
import SurveyDashboard from "../surveys/routes/surveyDashboard/SurveyDashboard";
import connect from "react-redux/es/connect/connect";

function Accounts(props) {
    const [isEnterprise, setIsEnterprise] = useState(false);
    const selectedAccount = props.selectedAccount

    useEffect(() => {
        if (selectedAccount) {
            setIsEnterprise(selectedAccount.accountType === AccountType.ENTERPRISE)
        }
    }, [selectedAccount]);

    useEffect(() => {
        let accountId = props.accountId;
        (async function fetchData() {
            try {
                await props.dispatch(getOrganisation({
                    accountId: accountId,
                    onSuccess: () => {
                        console.log("Saved organisation details for!", accountId)
                    },
                    onError: (err) => {
                        showError(err.message);
                        props.history.push(Routes.Surveys.root)
                    },
                }))
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <Switch location={props.location}>
            <Route path={Routes.Accounts.Completed} component={CompletedSurveys}/>
            <Route path={Routes.Accounts.root}
                   component={isEnterprise ? SurveyEnterpriseDashboard : SurveyDashboard}/>
        </Switch>

    )
}

export default connect((state, props) => {
    const currentOrg = state.settings.organisation;
    const selectedAccount = (state.organisations.accounts || {})[currentOrg];
    const accountId = props.match.params.accountId || selectedAccount.id;

    return {
        selectedAccount,
        accountId,
        currentOrg
    }
})(Accounts);