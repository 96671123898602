import React from "react";
import { connect } from "react-redux";
import { getSaasTiers } from "../../../../redux/actions/v1/saas";
import {
    addOrganisationCard, createUpdateSaaSSubscription,
    getOrganisationCards, setOrganisationDefaultCard
} from "../../../../redux/actions/v1/organisations";
import { showError, showSuccess } from "../../../../redux/actions/v1/snackbars";
import styles
    from "../../../routes/surveys/routes/survey/routes/report/components/upgradeSaasPlan/UpgradeSaasPlan.module.scss";
import infoIcon from "../../../../resources/svg/info.svg";
import ReactTooltip from "react-tooltip";
import Dialog from "../../dialog/Dialog";
import close from "../../../../resources/svg/close.svg";
import Select from "../../select/Select";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CreditCardForm from "../../creditCardForm/CreditCardForm";
import Button from "../../button/Button";
import unlock from "../../../../resources/svg/open-padlock.svg";
import PoweredByStripe from "../../../../resources/js/PoweredByStripe";
import PropTypes from "prop-types";
import { getQuestionReport, setQuestionReport } from "../../../../redux/actions/reports";
import AnalyticsAPI from "../../../../util/Analytics";
import { loadStripe } from "@stripe/stripe-js";

class UpgradePromptDialog extends React.Component {
    static propTypes = {
        dialogRef: PropTypes.func,
    };

    static defaultProps = {
        dialogRef: () => null,
    };

    constructor (props) {
        super(props);
        this.state = {
            selectedPlan: null,
            loading: false,
            error: null,
            selectedCard: null
        }

        this.dialogRef = this.props.dialogRef;
        this.ccRef = React.createRef();

    };

    componentDidMount = async () => {
        this.setState({ loading: true })
        if ( !!this.props.cards ) {
            this.setState({ selectedCard: Object.keys(this.props.cards)[0] });
        }
        this.setState({ loading: false })
        // let tierPrice = this.props.account.subscription.pricing.amount;
        let tierPrice = 0;
        this.setState({
            selectedPlan: Object.values(this.props.saasPlans)
                .filter(tier => tier.pricing.find((pricing) => pricing.interval === "month").amount > tierPrice)[0]
        })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if ( prevProps.cards !== this.props.cards && !!this.props.cards ) {

        }
    }

    onCCSuccess = (token) => {
        if ( !this.props.cards ) {
            this.props.dispatch(addOrganisationCard({
                accountId: this.props.orgId,
                stripeCardToken: token,
                onError: this.onCCError,
                onSuccess: () => {

                    this.props.dispatch(createUpdateSaaSSubscription({
                        accountId: this.props.orgId,
                        subscriptionId: this.state.selectedPlan.pricing.find((pricing) => pricing.interval === "month").id,
                        upgradeLater: false,
                        onSuccess: this.onUpgradeSuccess,
                        onError: this.onCCError
                    }));

                }
            }));
        } else {
            this.props.dispatch(createUpdateSaaSSubscription({
                accountId: this.props.orgId,
                subscriptionId: this.state.selectedPlan.pricing.find((pricing) => pricing.interval === "month").id,
                upgradeLater: false,
                onSuccess: this.onUpgradeSuccess,
                onError: this.onCCError
            }));
        }


    };


    onUpgradeSuccess = () => {

        this.props.dispatch(showSuccess(
            `Upgrade successful! You're now on our ${(this.state.selectedPlan === null ? "FREE" : this.state.selectedPlan.name)} tier`
        ));
        this.setState({ loading: false })

        AnalyticsAPI.accountUpgradeEvent(this.state.selectedPlan.name.toUpperCase())
        this.dialogRef.hide();
    };

    onCCError = (error) => {
        this.setState({
            error: error.message,
            loading: false
        });
        this.props.dispatch(showError(error.message));
    };

    onCCSubmit = () => {

        this.setState({ loading: true })
        if ( !this.state.selectedCard && this.ccRef ) {
            this.ccRef.getCreditCardToken();
        } else {
            this.props.dispatch(createUpdateSaaSSubscription({
                accountId: this.props.orgId,
                subscriptionId: this.state.selectedPlan.pricing.find((pricing) => pricing.interval === "month").id,
                upgradeLater: false,
                card: this.state.selectedCard,
                onSuccess: this.onUpgradeSuccess,
                onError: this.onCCError
            }));
        }
    };
    onSelect = (value) => {
        this.setState({ selectedPlan: value });
    };

    getPlanText = (plan) => {
        let price = (plan.pricing.find((pricing) => pricing.interval === "month").amount) / 100;
        let text = "";

        text = plan.name + " - $" + price.toString()
            + "USD/m";
        return text;
    };
    onCCSelect = (card) => {
        this.setState({ selectedCard: card });
    };


    render () {
        let cards;
        // const tierPrice = this.props.account.subscription.pricing.amount;

        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        let tierPrice = 0;
        const tiers = Object.values(this.props.saasPlans)
            .filter(tier => tier.pricing.find((pricing) => pricing.interval === "month").amount > tierPrice)
            .map(tier => {
                return {
                    value: tier,
                    label: <div className={styles.tier_container}>
                        <div className={styles.tier_description}>{this.getPlanText(tier)}</div>
                    </div>,
                    description: this.getPlanText(tier)
                };
            });


        if ( !!this.props.cards ) {
            cards = Object.values(this.props.cards)
                .map(card => {
                    return {
                        value: card.id,
                        label: (!!card.name ? card.name : "Card ending in " + card.last4),
                        description: (!!card.name ? card.name : "Card ending in " + card.last4)
                    };
                });
        }
        let tooltip = "For more information about our <br/> subscription tiers, visit our pricing page";
        let selectLabel = (
            <div className={styles.select_header}>
                <div>
                    Select subscription
                </div>
                <div data-tip={tooltip}>
                    <img src={infoIcon} className={styles.info_icon} height={22} alt={"info"}/>
                </div>
                <ReactTooltip clickable={true} type={"info"} class={styles.tooltip} delayHide={500}
                              backgroundColor={"white"} textColor={"black"} insecure={true}
                              effect={"solid"} place={"right"} multiline={true}>
                    For more information about our <br/> subscription tiers, visit our <a
                    href="http://stickybeak.co/pricing" target="_blank" rel={"noopener noreferrer"}>pricing
                    page</a>
                </ReactTooltip>
            </div>
        );
        return (
            <div>
                <Dialog custom
                        ref={(ref) => {
                            this.dialogRef = ref;
                            this.props.dialogRef(ref);
                        }
                        }
                        className={styles.cc_dialog}>
                    <div className={styles.dialog_title}>
                        <h3>
                            Upgrade plan
                        </h3>
                        <img alt={"close"} src={close} onClick={() => {
                            this.dialogRef.hide();
                        }} height={16} width={16} className={styles.dialog_cross}/>
                    </div>
                    <Select label={selectLabel}
                            onChange={this.onSelect} labelClassName={styles.select_label}
                            value={this.state.selectedPlan}
                            className={styles.select} options={tiers}/>

                    {!cards && (
                        <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => (
                                    <CreditCardForm onSuccess={this.onCCSuccess}
                                                    onError={this.onCCError}
                                                    ccRef={(ref) => this.ccRef = ref}
                                                    className={styles.cc_form} shown
                                                    stripe={stripe}
                                                    elements={elements}
                                                    selectedAccount={this.props.account}
                                                    orgId={this.props.orgId} showPoweredBy={false}/>
                                )}
                            </ElementsConsumer>
                        </Elements>
                    )}

                    {cards && !this.state.loading && (
                        <Select label={"Select Card"}
                                onChange={(card) => this.onCCSelect(card)}
                                labelClassName={styles.card_select_label}
                                value={this.state.selectedCard}
                                className={styles.card_select} options={cards}
                        />
                    )}

                    <Button green textWhite className={styles.button}
                            loading={this.state.loading}
                            onPress={this.onCCSubmit}>
                        <img src={unlock} height={20} alt={"lock"}/> Upgrade and unlock
                    </Button>

                    <div className={styles.power_by_stripe_wrapper}>
                        <a href={"http://stripe.com"} target={"_blank"}
                           rel={"noopener noreferrer"}>
                            <PoweredByStripe/>
                        </a>
                    </div>
                </Dialog>

            </div>
        )

    }
}

export default connect((state, props) => {
        const selectedOrg = state.settings.organisation;
        return {
            orgId: selectedOrg,
            account: state.organisations.accounts[selectedOrg],
            saasPlans: state.saas.tiers,
            cards: state.organisations.cards[selectedOrg]
        };
    }
)(UpgradePromptDialog)
