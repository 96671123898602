/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.scss";
import classnames from "classnames";
import Loader from "../loader/Loader";
import parsePhoneNumberFromString from "libphonenumber-js/es6/parsePhoneNumberFromString";
import fromCents from "../../../constants/localization/currencies/fromCents";
import toCents from "../../../constants/localization/currencies/toCents";

export default class TextInput extends Component {

    static propTypes = {
        className: PropTypes.string,
        classNameWrapper: PropTypes.string,
        classNameFocused: PropTypes.string,
        classNameDisabled: PropTypes.string,
        classNameError: PropTypes.string,

        inputComponent: PropTypes.any,
        extraProps: PropTypes.object,

        error: PropTypes.string,
        hideError: PropTypes.bool,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        text: PropTypes.any,
        helperText: PropTypes.string,
        maxLength: PropTypes.number,
        autoComplete: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        dollar: PropTypes.bool,
        currency: PropTypes.string,
        number: PropTypes.bool,
        password: PropTypes.bool,
        onTextChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onEnterPress: PropTypes.func,
        selectOnFocus: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,

        inputRef: PropTypes.func,
        formatOutput: PropTypes.func,
        formatInput: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        classNameWrapper: null,
        classNameFocused: null,
        classNameDisabled: null,
        classNameError: null,

        inputComponent: null,
        extraProps: {},

        error: null,
        hideError: false,
        label: null,
        placeholder: "",
        text: "",
        helperText: null,
        maxLength: null,
        autoComplete: null,
        loading: false,
        disabled: false,
        readOnly: false,
        dollar: false,
        currency: "USD",
        number: false,
        password: false,
        onTextChange: () => null,
        onFocus: () => null,
        onBlur: () => null,
        onEnterPress: () => null,
        selectOnFocus: false,
        min: 0,
        max: null,

        inputRef: (t) => t,
        formatOutput: (t) => t,
        formatInput: (t) => t,
    };

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }


    onFocus = (e) => {
        this.props.onFocus();
        if (!!this.props.input) {
            this.props.input.onFocus();
        }

        this.setState({
            focused: true,
        });

        if (!!this.props.selectOnFocus) {
            e.target.select()
        }
    };

    onBlur = () => {
        this.props.onBlur();
        if (!!this.props.input) {
            this.props.input.onBlur();
        }

        this.setState({
            focused: false,
        });
    };

    onTextChange = (e) => {

        const target = e.target;
        let text = "";
        if (!!target) {
            text = target.value;
        }

        if (this.props.number) {
            text = text.replace(/\D+/g, "");
        }

        if (this.props.dollar) {
            text = toCents(Number(text || 0), this.props.currency);
            if (text === 0 || text === "0") {
                text = "";
            }
        }

        if (!!this.props.phone) {
            const number = parsePhoneNumberFromString(text);
            if (!!number) {
                text = number.formatInternational();
            }
        }

        if (!!this.props.formatOutput) {
            text = this.props.formatOutput(text);
        }

        if (!!this.props.input) {
            this.props.input.onChange(text);
        }

        if (!!this.props.onTextChange) {
            this.props.onTextChange(text);
        }
    };

    focus = () => {
        !!this.input && this.input.focus();
    };

    blur = () => {
        !!this.input && this.input.blur();
    };

    formatText = (text) => {
        if (!!this.props.input) {
            text = this.props.input.value;
        }

        if (!!this.props.formatInput) {
            text = this.props.formatInput(text);
        }

        if (this.props.dollar) {
            text = fromCents(Number(text || 0), this.props.currency);

            if (text === 0 || text === "0") {
                text = 0;
            }
        }

        return text;
    };

    onRef = (ref) => {
        this.input = ref;
        this.props.inputRef(ref);
    };

    onKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
            this.props.onEnterPress();
        }
    };

    render() {

        const className = classnames(styles.text_input, this.props.className);

        const text = this.formatText(this.props.text);

        let error = this.props.error;
        if (!!this.props.meta && (!this.props.meta.pristine || this.props.meta.submitFailed) && !!this.props.meta.error) {
            error = this.props.meta.error;
        }

        const inputWrapperClassName = classnames(styles.input_wrapper, this.props.classNameWrapper, {
            [classnames(styles.focused, this.props.classNameFocused)]: this.state.focused,
            [classnames(styles.disabled, this.props.classNameDisabled)]: this.props.disabled,
            [classnames(styles.error, this.props.classNameError)]: !!error && !this.props.hideError/*&& this.props.meta.visited*/,
            [styles.icon]: !!this.props.icon
        });

        const hasErrorText = !!error && error !== " " ? error : false;
        const Component = !!this.props.inputComponent ? this.props.inputComponent : `input`;
        const extraProps = !!this.props.inputComponent ? {...this.extraProps} : {type: this.props.password ? "password" : undefined, ...this.extraProps};

        if (this.props.number) {
            extraProps.min = this.props.min;
            extraProps.max = this.props.max;
        }

        return (
            <div className={className}>
                {!!this.props.label ? <label className={styles.label}>{this.props.label}</label> : null}

                <div className={inputWrapperClassName}>
                    <Component  {...extraProps}
                                placeholder={this.props.placeholder}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onChange={this.onTextChange}
                                onKeyDown={this.onKeyDown}
                                value={text}
                                disabled={this.props.disabled}
                                ref={this.onRef}
                                autoComplete={"invalid"}
                    />

                    {!!this.props.icon && this.props.icon}

                    {this.props.loading ?
                        <div className={styles.loading}>
                            <Loader height={24}
                                    width={24}
                                    color={"#454545"}
                                    type={"spinningBubbles"}/>
                        </div> : null}
                </div>

                {!!hasErrorText && !this.props.hideError ? <label className={classnames(styles.error_text, this.props.labelErrorClassName)}>{error}</label> : !!this.props.helperText ?
                    <label className={styles.helper_text}>{this.props.helperText}</label> : null}
            </div>
        );
    }
}
