import React, { Component } from "react";
import styles from "./RenderEmojiRatingBubbles.module.scss";
import { connect } from "react-redux";
import { setSurveyFormFocused } from "../../../../../../../../../../redux/actions/v1/surveyFormState";
import { change } from "redux-form";
import Button from "../../../../../../../../../widgets/button/Button";

class EmojiRatingBubble extends Component {
    static defaultProps = {};
    state = {
        hideError: true,
    };

    onFocus = () => {
        this.props.dispatch(setSurveyFormFocused(true));
        this.setState({ hideError: true });
    };

    //if custom response, update choice to new value on blur
    onBlur = (e) => {
        this.props.dispatch(setSurveyFormFocused(false));
        this.setState({ hideError: false });
        if (e.target.value !== "") {
            this.props.dispatch(change("editSurvey", this.props.name, {
                value: e.target.value,
                imageUrl: this.props.icon,
            }));
        }
    };

    render() {
        return (
            <div className={styles.choice_wrapper}>
                <div
                    className={styles.choice_bubble}
                    name={`${this.props.name}.value`}>
                    <div className={styles.choice_icon}>
                        <img src={this.props.icon} width={"100%"} alt={""} />
                    </div>
                    <div className={styles.text_container}>
                        {!this.props.custom && (
                            <span className={styles.choice_text}>
                                {this.props.text}
                            </span>
                        )}
                        {
                            this.props.custom && (
                                <textarea onBlur={this.onBlur} name={`${this.props.name}.value`}
                                          maxLength={25}
                                          placeholder={this.props.text}
                                          className={styles.choice_text_input} />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class RenderEmojiRatingBubbles extends Component {
    constructor(props) {
        super(props);
        let emojiFilter = ["EMOJI", "EMOJI_FUN"];
        let responseType = "Satisfaction (5)";

        if (this.props.loveHate) {
            emojiFilter.push("THUMBS");
            responseType = "Love/hate";
        }
        if (!!this.props.fields.getAll()) {
            if (this.props.fields.getAll().length === 2) {
                emojiFilter.push("THUMBS");
                responseType = "Love/hate";
            }
        }
        this.state = {
            enableAutoFocus: false,
            emojiFilter: emojiFilter,
            iconType: "EMOJI",
            responseType: responseType,
            flipped: false
        };
        // if the question is being added set the choices on the question form to default values
        const templates = Object.values(this.props.emojiTemplates);
        if (!this.props.fields.getAll() && !this.props.loveHate) {
            this.props.dispatch(change("editSurvey", this.props.fields.name, templates[0].choices));
        }
        if (!this.props.fields.getAll() && this.props.loveHate) {
            this.props.dispatch(change("editSurvey", this.props.fields.name, templates
                .find(element => element.iconType === "THUMBS").choices));
        }
    }

    componentDidMount() {
        this.setState({ enableAutoFocus: true });
    }

    onResponseSelect = (e) => {
        let emojiFilter = [];
        const templates = Object.values(this.props.emojiTemplates);
        templates.forEach((template) => {
            if (template.responseType === e.target.value) {
                emojiFilter.push(template.iconType);
            }
        });
        let template;
        template = templates.find(element => (element.responseType === e.target.value && element.iconType === this.state.iconType));
        if (!template) {
            template = templates.find(element => (element.responseType === e.target.value));
        }
        this.setState({ iconType: template.iconType, responseType: e.target.value, emojiFilter: emojiFilter });
        this.props.dispatch(change("editSurvey", this.props.fields.name, template.choices));
    };

    onIconSelect = (e) => {
        const templates = Object.values(this.props.emojiTemplates);
        let template = templates.find(element => (element.iconType === e.target.value && element.responseType === this.state.responseType));
        if (!template) {
            template = templates.find(element => (element.iconType === e.target.value));
        }
        if (!template) {
            template = template.find(element => (element.choices.length === this.props.fields.getAll().length));
        }
        this.setState({ responseType: template.responseType, iconType: e.target.value });
        this.props.dispatch(change("editSurvey", this.props.fields.name, template.choices));
    };

    onFlipPress = () => {
        //get choices, reverse array and then set choices to flipped array - setState to make it re-render??
        let choices = this.props.fields.getAll();
        this.props.dispatch(change("editSurvey", this.props.fields.name, choices.reverse()));
        this.setState({ flipped: true });
    };

    findCurrentTemplate = () => {
        //choices are populated from redux as they will have been set in constructor
        const choices = this.props.fields.getAll();
        const templates = Object.values(this.props.emojiTemplates);
        let choiceValues, choiceTexts, choicesFlipped, currentTemplate;
        //create array of choice texts and flipped array without mutating choices object
        if (!!choices) {
            choiceValues = choices.map((choice) => {
                return { imageUrl: choice.imageUrl, value: choice.value };
            });
            choicesFlipped = [choices.length];
            choiceTexts = [choices.length];
            choices.forEach((choice, i) => {
                choicesFlipped[i] = choices[choices.length - 1 - i];
                choiceTexts[i] = choice.value;
            });
        }
        if (!!choices) {
            currentTemplate = templates.find(({ choices }) => this.compare(choices, choiceValues) || this.compare(choices, choicesFlipped));
        }
        let iconTypeValue;
        let responseTypeValue;
        //if it is a custom response, set current template to custom and match by length
        if (!currentTemplate) {
            if (!!this.props.fields.getAll()) {
                currentTemplate = templates.find(element => element.responseType.includes("Custom") && element.iconType === this.state.iconType && element.choices.length === choices.length);
            }
        }
        //getting current response type and icon type being used to show in select boxes
        if (!!currentTemplate) {
            iconTypeValue = currentTemplate.iconType;
            responseTypeValue = currentTemplate.responseType;
            if (this.state.iconType !== iconTypeValue) {
                this.setState({ iconType: iconTypeValue });
            }
            if (this.state.responseType !== responseTypeValue) {
                this.setState({ responseType: responseTypeValue });
            }
        }
        return (currentTemplate);
    };
    getUniqueResponseLabels = () => {
        const templates = Object.values(this.props.emojiTemplates);

        let uniqueResponseLabels = [];
        templates.forEach((template) => {
            if (!uniqueResponseLabels.includes(template.responseType)) {
                uniqueResponseLabels.push(
                    template.responseType
                );
            }
        });
        return uniqueResponseLabels;
    };

    compare(choicesA, choicesB) {
        let isEqual = false;
        if (choicesA.length !== choicesB.length) {
            return false;
        }
        for (let i = 0; i < choicesA.length; i++) {
            if (choicesA[i].value === choicesB[i].value && choicesA[i].imageUrl === choicesB[i].imageUrl) {
                isEqual = true;
            } else {
                break;
            }
        }
        return isEqual;
    }

    getFilteredIconOptions = () => {
        const iconOptions = [
            {
                label: "Thumbs up/Thumbs down",
                value: "THUMBS"
            },
            {
                label: "Emoji",
                value: "EMOJI"
            },

            {
                label: "Fun emojis",
                value: "EMOJI_FUN"
            }
        ];


        return iconOptions.filter((option) => this.state.emojiFilter.includes(option.value));
    };

    render() {

        //find the current template being used by checking for match in choices or flipped choices
        const choices = this.props.fields.getAll();
        const currentTemplate = this.findCurrentTemplate();
        const uniqueResponseLabels = this.getUniqueResponseLabels();
        const filteredIconOptions = this.getFilteredIconOptions();
        let responseTypeValue;
        let iconTypeValue;
        if (!!currentTemplate) {
            responseTypeValue = currentTemplate.responseType;
            iconTypeValue = currentTemplate.iconType;
        } else {
            responseTypeValue = this.state.responseType;
            iconTypeValue = this.state.iconType;
        }

        return (
            <div className={styles.rating_bubble_container}>
                <div className={styles.option_row}>
                    <div className={styles.select_container}>
                        <span className={styles.option_label}>Response type</span>
                        <select className={styles.option_select}
                                onChange={this.onResponseSelect}
                                value={responseTypeValue}>
                            {uniqueResponseLabels.map((responseType) => <option
                                value={responseType} className={styles.option}
                                key={responseType}>{responseType}</option>)}
                        </select>
                    </div>

                    <div className={styles.select_container}>
                        <span className={styles.option_label}>Icons</span>
                        <select className={styles.option_select} onChange={this.onIconSelect}
                                value={iconTypeValue}
                        >{filteredIconOptions.map((icon) => <option
                            value={icon.value} className={styles.option}
                            key={icon.label}>{icon.label}</option>)}</select>
                    </div>
                    <Button borderLight={true} className={styles.flip_button} onPress={this.onFlipPress}>Flip</Button>
                </div>
                <div style={{
                    display: "flex",
                }}>
                    {
                        this.props.fields.map((name, i) => {
                            const choice = choices[i];
                            if (!!choice) {
                                return (
                                    <EmojiRatingBubble key={i}
                                                       name={`${name}`}
                                                       index={i}
                                                       icon={choice.imageUrl}
                                                       numChoices={choices.length}
                                                       fields={this.props.fields}
                                                       text={choice.value}
                                                       autoFocus={this.state.enableAutoFocus}
                                                       dispatch={this.props.dispatch}
                                                       custom={!!currentTemplate && currentTemplate.responseType.includes("Custom")}
                                    />
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        );
    }
}

export default connect((state, props) => {
    const emojiTemplates = state.emojiTemplates;
    return {
        emojiTemplates: emojiTemplates
    };
})(RenderEmojiRatingBubbles);
