import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteSurvey } from "../../../../../../../redux/actions/v1/surveys";
import styles from "./DraftSurveyRow.module.scss";
import { showError, showSuccess } from "../../../../../../../redux/actions/v1/snackbars";
import DeleteSurveyDialog from "../../../../../../widgets/dialogs/deleteSurveyDialog/DeleteSurveyDialog";
import DuplicateSurveyDialog from "../../../../../../widgets/dialogs/duplicateSurveyDialog/DuplicateSurveyDialog";
import RenameSurveyDialog from "../../../../../../widgets/dialogs/renameSurveyDialog/RenameSurveyDialog";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

class DraftSurveyRow extends Component {

    initialState = {
        anchorEl: null,
        submitting: false
    };

    state = {
        ...this.initialState
    };

    handleDraftPress = () => {
        this.props.onDraftPress(this.props.survey.id);
    };

    handleOptionsPress = (e) => {
        this.setState({ anchorEl: e.target });
    };

    handleOptionsClose = () => {
        this.setState({ anchorEl: null });
    };

    onRenamePress = () => {
        this.handleOptionsClose();
        this.renameSurveyDialog.show();
    };

    onDuplicatePress = () => {
        this.handleOptionsClose();
        this.duplicateSurveyDialog.show();
    };

    onDeletePress = () => {
        this.handleOptionsClose();
        this.deleteSurveyDialog.show();
    };

    handleSurveyDelete = () => {
        this.setState({ submitting: true });
        this.deleteSurveyDialog.hide();

        this.props.dispatch(deleteSurvey({
            orgId: this.props.survey.account,
            surveyId: this.props.survey.id,
            onSuccess: ()=>{
                this.setState({submitting: false})
                this.props.dispatch(showSuccess("Survey deleted"))
            },
            onError: (err) => {
                this.setState({ submitting: false });
                this.props.dispatch(showError(err.message));
            }
        }));

    };

    onCancelPress = () => {
        this.setState({
            ...this.initialState
        });
    };

    render() {
        const { anchorEl, submitting } = this.state;
        const popoverOpen = Boolean(this.state.anchorEl);

        return (
            <div className={styles.draft_survey_container}>
                <MenuItem className={styles.draft_survey_button}
                          onClick={this.handleDraftPress}>
                    <div className={styles.draft_survey_chip}>Draft</div>
                    <div className={styles.draft_survey_details}>
                        <span className={styles.draft_survey_title}>{this.props.survey.name}</span>
                    </div>
                </MenuItem>
                <IconButton className={styles.draft_survey_options_button}
                            onClick={this.handleOptionsPress}>
                    <MoreVertIcon />
                </IconButton>
                <Popover open={popoverOpen}
                         onClose={this.handleOptionsClose}
                         anchorEl={anchorEl}
                         elevation={1}>
                    <MenuItem onClick={this.onRenamePress}>Rename</MenuItem>
                    <MenuItem onClick={this.onDuplicatePress}>Duplicate</MenuItem>
                    <MenuItem onClick={this.onDeletePress}>Delete</MenuItem>
                </Popover>

                <RenameSurveyDialog survey={this.props.survey}
                                    dialogRef={ref => this.renameSurveyDialog = ref} />

                <DuplicateSurveyDialog survey={this.props.survey}
                                       dialogRef={ref => this.duplicateSurveyDialog = ref} />

                <DeleteSurveyDialog dialogRef={ref => this.deleteSurveyDialog = ref}
                                    onSubmit={this.handleSurveyDelete}
                                    loading={submitting} />
            </div>
        );
    }
}

export default connect()(withRouter(DraftSurveyRow));

DraftSurveyRow.propTypes = {
    onDraftPress: PropTypes.func,
    title: PropTypes.string,
    org: PropTypes.string
};

DraftSurveyRow.defaultProps = {
    onDraftPress: () => null,
    title: "Survey Title",
    org: "Edit",
};
