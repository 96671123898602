

export function getSurveyQuestionsAPI(token, surveyId) {
    return window.get(`/v1/surveys/${surveyId}/questions`, token)
}

export function getSurveyEndingAPI(token, surveyId) {
    return window.get(`/v1/surveys/${surveyId}/questions/survey-ending`, token)
}

export function getSurveyLanguageOptionsAPI(token, surveyId) {
    return window.get(`/v1/surveys/${surveyId}/questions/survey-ending/languages`, token)
}

export function createQuestionAPI(token, surveyId, question) {
    return window.post(`/v1/surveys/${surveyId}/questions`, token, question)
}

export function deleteQuestionAPI(token, surveyId, questionId) {
    return window.del(`/v1/surveys/${surveyId}/questions/${questionId}`, token)
}

export function updateQuestionAPI(token, surveyId, questionId, question) {
    return window.put(`/v1/surveys/${surveyId}/questions/${questionId}`, token, question)
}

export function updateQuestionsAPI(token, surveyId, questions) {
    return window.put(`/v1/surveys/${surveyId}/questions`, token, questions)
}

export function getCustomSurveyEndingAPI(token, surveyId) {
    return window.get(`/v1/surveys/${surveyId}/customSurveyEndings`, token)
}

export function createUpdateCustomSurveyEndingAPI(token, surveyId, customEnding) {
    return window.put(`/v1/surveys/${surveyId}/customSurveyEndings`, token, customEnding)
}

export function deleteCustomSurveyEndingAPI(token, surveyId, customEnding) {
    return window.del(`/v1/surveys/${surveyId}/customSurveyEndings/${customEnding[0].id}`, token)
}
