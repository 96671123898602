import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./SurveyScorePriceChangeDialog.module.scss"
import fromCents from "../../../../constants/localization/currencies/fromCents";

export default class SurveyScorePriceChangeDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        formErrors: PropTypes.object,
        estimate: PropTypes.object,
    };

    static defaultProps = {
        dialogRef: () => null,
        formErrors: {},
    };

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.props.onSubmit();
    };

    render() {
        const actions = [
            {
                text: "Go back",
                onPress: this.hide,
                props: {
                    green: true
                }
            },
            {
                text: "Continue with new price",
                onPress: this.onSubmit,
                props: {
                    plain: true,
                    border: true,
                }
            }
        ];

        return (
            <Dialog title={"Surveys that score poorly cost more to run because fewer people stick with them."}
                    actions={actions}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                {this.props.estimate.estCostPerRespondent > 0 && this.props.estimate.prevEstCostPerRespondent > 0 && (
                    <>
                        <div className={styles.price_change_table}>
                            <div className={styles.price_row}>
                                <div className={styles.price_label}>Previous cost per respondent</div>
                                <div className={styles.price_amount}><span className={styles.strike_through}>{fromCents(this.props.estimate.prevEstCostPerRespondent, "USD", true)}</span></div>
                            </div>
                            <div className={styles.price_row}>
                                <div className={styles.price_label}>Updated cost per respondent</div>
                                <div className={styles.price_amount}><span className={styles.colour_red}>{fromCents(this.props.estimate.estCostPerRespondent, "USD", true)}</span></div>
                            </div>
                        </div>
                        <p>Often, a few simple changes make a big difference though! 🥳</p>
                        <p>Click “go back” to edit your survey now and lock in your lower price.</p>
                    </>
                )}
            </Dialog>
        )
    }
}
