/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreateUpdateOrganisationDialog.module.scss";
import classnames from "classnames";
import TextInputField from "../../textInput/TextInputField";
import {reduxForm, reset} from "redux-form";
import connect from "react-redux/es/connect/connect";
import Dialog from "../../dialog/Dialog";
import {createAudience} from "../../../../redux/actions/v1/audiences";
import Routes from "../../../../constants/routes";
import {withRouter} from "react-router-dom";

const INITIAL_STATE = {
    loading: false,
    error: null,
    subscriptionError: false,
};

class CreateUpdateAudienceDialog extends Component {

    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
		onUpgradeOrgPress: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
		onUpgradeOrgPress: () => null,
    };

    state = INITIAL_STATE;

    onHandleSubmit = (values) => {

        this.setState({
            loading: true,
            error: null,
        });

        if (this.props.updating) {

        } else {
            this.props.dispatch(createAudience({
                accountId: this.props.orgId,
                account: values,
                onSuccess: (audience) => this.onSuccess(audience),
                onError: this.onError,
            }));
        }
    };

    onSuccess = (audience) => {
        this.setState({
            loading: false,
            error: null,
        }, this.hide);

        this.props.history.push(`${Routes.Audiences.root}/${audience.id}`)
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
            subscriptionError: err.status,
        })
    };

    hide = () => {

        if (this.state.loading) {
            return;
        }

        this.dialog.hide();
    };

    onClose = () => {
        this.setState(INITIAL_STATE);
        this.props.onClose();
        this.props.dispatch(reset(this.props.form));
    };


    render() {
        const className = classnames(styles.create_update_audience, this.props.className);

        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                className={className}
                disableDismiss={this.state.loading}
                title={this.props.updating ? "Update Audience" : "Create Audience"}
                onClose={this.onClose}
                onSubmit={this.props.handleSubmit(this.onHandleSubmit)}
                error={this.state.error}
                actions={[
					{text: "Cancel", onPress: this.hide, props: {plain: true, disabled: (this.state.loading)}},
					this.state.subscriptionError
                        ? {text: "Upgrade", onPress: this.props.onUpgradeOrgPress, props: {blue: true}}
                        : {text: this.props.updating ? "Update" : "Create", props: {type: "submit", disabled: (!this.props.valid || this.props.pristine), blue: true, loading: this.state.loading}},
                ]}
            >
                <TextInputField name={"name"}
                                label={"Audience Name"}
                                placeholder={"e.g. Customers"}/>
            </Dialog>
        );
    }
}

CreateUpdateAudienceDialog = reduxForm({
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        return errors;
    }
})(CreateUpdateAudienceDialog);

CreateUpdateAudienceDialog =  connect((state, props) => {

    const orgId = props.orgId;

    return {
        form: `createUpdateAudience${orgId}`,
        orgId: orgId,

    };
})(CreateUpdateAudienceDialog);

export default withRouter(CreateUpdateAudienceDialog);