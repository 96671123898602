import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "../../dialog/Dialog";
import TextInput from "../../textInput/TextInput";
import PropTypes from "prop-types";
import _values from "lodash/values";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import styles from "./SharePreviewDialog.module.scss";
import Button from "../../button/Button";
import DeleteBubbleIcon from "../../../../resources/js/DeleteBubbleIcon";
import { emailShareSurveyLink } from "../../../../redux/actions/v1/surveys";
import { showError } from "../../../../redux/actions/v1/snackbars";
import validateEmail from "../../../../constants/validateEmail";
import sendEventToActiveCampaign from "../../../../redux/actions/api/v1/eventTracking";

class SharePreviewDialog extends Component {

    static propTypes = {
        accountId: PropTypes.number,
        surveyId: PropTypes.number
    };

    static defaultProps = {
        accountId: null,
        surveyId: null
    };

    initialState = {
        emailInput: "",
        emailList: {},
        submitting: false
    };

    state = {
        ..._cloneDeep(this.initialState)
    };

    onClose = () => {
        this.setState({ ...this.initialState })
    };

    onSubmit = () => {
        this.setState({ submitting: true }, () => this.props.dispatch(emailShareSurveyLink({
                accountId: this.props.accountId,
                surveyId: this.props.surveyId,
                emailList: _values(this.state.emailList),
                onSuccess: () => {
                    this.hide()
                    sendEventToActiveCampaign(this.props.user.email, "Share_preview", "Shared survey preview")

                },
                onError: (err) => {
                    this.setState({ submitting: false })
                    this.props.dispatch(showError("There was an error sending your preview"))
                }
            }))
        );
    };

    handleEmailInput = (text) => {
        this.setState({ emailInput: text.trim() })
    };

    onEmailAdd = () => {
        if (!this.state.emailInput) {
            return
        }

        if (!validateEmail(this.state.emailInput)) {
            return this.props.dispatch(showError("Invalid email address"))
        }

        this.setState((prevState) => {
            const { emailList, emailInput } = prevState;

            emailList[emailInput] = emailInput;
            return {
                emailList: emailList,
                emailInput: ""
            }
        })
    };

    onEmailRemove = (email) => {
        if (this.state.submitting) {
            return
        }
        this.setState((prevState) => {
            delete prevState.emailList[email];

            return {
                emailList: prevState.emailList
            }
        });
    };

    hide = () => {
        this.setState({ ...this.initialState }, this.dialog.hide());
    };

    render() {

        return (
            <Dialog ref={ref => {
                this.dialog = ref;
                this.props.dialogRef(ref)
            }}
                    contentClassName={styles.share_dialog}
                    onClose={this.onClose}
                    disableDismiss={this.state.submitting}
                    actions={
                        [
                            {
                                text: "Cancel",
                                onPress: this.hide,
                                props: {
                                    plain: true,
                                    disabled: this.state.submitting
                                }
                            },
                            {
                                text: "Submit",
                                onPress: this.onSubmit,
                                props: {
                                    plain: true,
                                    loading: this.state.submitting,
                                    disabled: _isEmpty(this.state.emailList)
                                }
                            }
                        ]
                    }>
                <h2>Share a preview of your survey</h2>
                <div className={styles.email_input}>
                    <TextInput label={"Enter an email address:"}
                               onEnterPress={this.onEmailAdd}
                               onTextChange={this.handleEmailInput}
                               text={this.state.emailInput}/>
                    <Button onPress={this.onEmailAdd}
                            disabled={this.state.submitting || !this.state.emailInput}
                            plain>Add</Button>
                </div>
                <div className={styles.email_list}>
                    {
                        _values(this.state.emailList).map(email => {
                            return (
                                <div className={styles.email_row}
                                     key={email}>{email}
                                    <div className={styles.delete_button}
                                         onClick={() => this.onEmailRemove(email)}>
                                        <DeleteBubbleIcon/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Dialog>
        )
    }
}

export default connect()(SharePreviewDialog);
