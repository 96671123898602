import React, {Component, Fragment} from "react";
import styles from "./Main.module.scss";
import Toolbar from "../widgets/toolbar/Toolbar";
import {Redirect, Route, Switch} from "react-router-dom";
import Routes from "../../constants/routes";
import connect from "react-redux/es/connect/connect";
import ReactGA from "react-ga";
import Onboarding from "./onboarding/Onboarding";
import SnackbarContainer from "../widgets/snackbar/SnackbarContainer";
import Loading from "./loading/Loading";
import {loadUserDetails} from "../../redux/actions/v1/user";
import Surveys from "./surveys/Surveys";
import Accounts from "./accounts/Accounts";
import Audiences from "./audiences/Audiences";
import Settings from "./settings/Settings";
import {setAuthLoading, showOnboardDialog} from "../../redux/actions/v1/auth";
import * as pusher from "../../constants/pusher";
import values from "lodash/values";
import Profile from "./profile/Profile";
import qs from "qs";
import {purgeReduxStore, setActiveOrganisation} from "../../redux/actions/v1/settings";
import {persistor} from "../App";
import {PERSIST_CONFIG} from "../../redux/reducers/root";
import OnboardPasswordDialog from "../widgets/dialogs/onboardPasswordDialog/OnboardPasswordDialog";
import Footer from "../widgets/footer/Footer";
import SurveyReceipt from "./receipt/SurveyReceipt";
import SubscriptionReceipt from "./receipt/SubscriptionReceipt";
import {getSaasTiers} from "../../redux/actions/v1/saas";
import AnalyticsAPI from "src/util/Analytics";

if (process.env.REACT_APP_ENV === "production") {
    ReactGA.initialize("UA-126625499-2");
}

class Main extends Component {
    state = {
        error: null,
        toolbarHeight: 72,
    };

    constructor(props) {
        super(props);
        pusher.connect();
    }

    componentDidMount() {
        if (process.env.REACT_APP_ENV === "production") {
            ReactGA.pageview(this.props.location.pathname + this.props.location.search);
        }
        window.firebase.auth().onAuthStateChanged(this.onAuthStateChanged);

        this.updateToolbarHeight();
    }

    onAuthStateChanged = (user) => {
        if (window.firebase.auth().isSignInWithEmailLink(window.location.href)) {
            this.props.dispatch(setActiveOrganisation(null));
            this.props.dispatch(purgeReduxStore());
            persistor.purge(PERSIST_CONFIG);

            const href = window.location.href;
            const locationSearch = this.props.location.search;
            const search = locationSearch.slice(1, locationSearch.length);
            const searchParams = qs.parse(search);

            let isNewUser = JSON.parse(searchParams.isNewUser);

            if (isNewUser && isNewUser !== "") {
                this.props.dispatch(showOnboardDialog(true));
            }

            window.firebase
                .auth()
                .signInWithEmailLink(searchParams.email, href)
                .then(() => {
                    this.props.history.push(Routes.Surveys.root);
                })
                .catch((err) => {
                    this.props.dispatch(setAuthLoading(false));
                    this.setState({
                        error: err.message,
                    });
                });
        } else {
            // checking if the user is signing up with a saas plan in redux to get payment instead of routing straight to verification page
            if (!!user) {
                this.props.dispatch(getSaasTiers());
                this.loadUserDetails();
                AnalyticsAPI.loginSuccess(user.email);
            } else {
                this.props.dispatch(setAuthLoading(false));
            }
        }
    };

    verifyEmail = () => {
        this.stopLoading();
        this.props.history.push(Routes.Onboarding.Verification);
    };

    signUpWithSaas = () => {
        this.stopLoading();
        this.props.history.push(Routes.Onboarding.SignUp.Payment);
    };

    loadUserDetails = () => {
        this.props.dispatch(
            loadUserDetails({
                onSuccess: this.onLoadUserDetailsSuccess,
                onError: this.stopLoading,
            }),
        );
    };

    onLoadUserDetailsSuccess = (currentOrg) => {
        if (currentOrg) {
            this.stopLoading();
        }
    };

    stopLoading = () => {
        if (!window.firebase.auth().isSignInWithEmailLink(window.location.href)) {
            this.props.dispatch(setAuthLoading(false));
        }
    };

    updateToolbarHeight = () => {
        const toolbar = document.getElementById("toolbar");
        if (!toolbar) {
            window.requestAnimationFrame(this.updateToolbarHeight);
        } else if (this.state.toolbarHeight !== toolbar.offsetHeight && toolbar.offsetHeight) {
            this.setState({toolbarHeight: toolbar.offsetHeight});
        }
    };

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.props.location !== nextProps.location && process.env.REACT_APP_ENV === "production") {
            ReactGA.pageview(this.props.location.pathname + this.props.location.search);
        }
        if (this.props.organisation !== nextProps.organisation) {
            window.requestAnimationFrame(this.updateToolbarHeight);
            const path = this.props.location.pathname;
            if (!path.includes("/*") && !path.includes("/signup")) {
                this.props.dispatch(setAuthLoading(true));
                setTimeout(this.stopLoading, 1500);
            }
        }
        if (this.props.activeOrganisation.logo !== nextProps.activeOrganisation.logo) {
            window.requestAnimationFrame(this.updateToolbarHeight);
        }
    }

    renderMainApp = () => {
        return (
            <Fragment>
                <Toolbar id={"toolbar"}/>
                <main className={styles.main} style={{paddingTop: this.state.toolbarHeight}}>
                    <SnackbarContainer>
                        <Switch>
                            <Route path={Routes.Surveys.root} component={Surveys}/>
                            <Route path={Routes.Accounts.root} component={Accounts}/>
                            <Route path={Routes.Audiences.root} component={Audiences}/>
                            <Route path={Routes.Settings.root} component={Settings}/>
                            <Route path={Routes.Profile.root} component={Profile}/>
                            <Redirect path={"*"} to={Routes.Surveys.root}/>
                        </Switch>
                    </SnackbarContainer>
                </main>
                <Footer/>
                <OnboardPasswordDialog/>
            </Fragment>
        );
    };

    render() {
        const currentUser = window.firebase.auth().currentUser;
        const showOnboard =
            !currentUser ||
            !this.props.organisation ||
            !values(this.props.organisations).length ||
            window.firebase.auth().isSignInWithEmailLink(window.location.href);
        return (
            <Switch>
                <Route exact path={Routes.Receipt.SurveyReceipt} component={SurveyReceipt}/>
                <Route exact path={Routes.Receipt.SubscriptionReceipt} component={SubscriptionReceipt}/>
                {this.props.authLoading ? <Route path={Routes.Loading} component={Loading}/> : null}
                {showOnboard ? <Route path={Routes.Onboarding.root} component={Onboarding}/> : null}
                {!showOnboard ? this.renderMainApp() : null}
            </Switch>
        );
    }
}

export default Main = connect((state) => {
    return {
        user: state.user,
        authLoading: state.auth.loading,
        organisation: state.settings.organisation,
        organisations: state.organisations.accounts,
        saasSignUp: state.settings.saasSignUp,
        activeOrganisation: state.organisations.accounts[state.settings.organisation] || {},
    };
})(Main);
