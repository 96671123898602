/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-01-25.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import values from "lodash/values";
import Pusher from "pusher-js";
import { store } from "../components/App";
import { setSurvey } from "../redux/actions/surveys";
import { audienceUploadComplete } from "../redux/actions/v1/audienceMembers";
import { showSuccess } from "../redux/actions/v1/snackbars";

Pusher.logToConsole = process.env.REACT_APP_ENV === "development";

let socket = undefined;

let channels = {};

export function connect() {
    socket = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: "ap1",
        authEndpoint: `${process.env.REACT_APP_API_URL}/v1/pusher/auth`,
        encrypted: true,
        forceTLS: true,

        authorizer: ({name}) => ({
            authorize: async (socketId, callback) => {

                const token = !!window.firebase.auth().currentUser ? await window.firebase.auth().currentUser.getIdToken() : null;
                const headers = new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": token
                });

                const query = "socket_id=" + encodeURIComponent(socketId) + "&channel_name=" + encodeURIComponent(name)
                const request = new Request(`${process.env.REACT_APP_API_URL}/v1/pusher/auth`, {
                    method: "POST",
                    headers: headers,
                    body: query,
                });

                const auth = await fetch(request).then((resp) => resp.json());
                callback(false, auth);
            }
        }),
    });


    return socket;
}

export function disconnect() {

    if (!socket) {
        return false;
    }

    socket.disconnect();

    return true;
}

export function subscribe(channelName) {
    if (!!channels[channelName]) {
        return channels[channelName];
    }
    socket.unsubscribe(channelName);
    const channel = socket.subscribe(channelName);
    channels[channelName] = channel;
    return channel;
}


export function bind(channelKey, eventName, callback) {

    if (!channelKey) {
        return null;
    }

    const channel = channels[channelKey];
    if (!channel) {
        return null;
    }

    return channel.bind(eventName, (json) => callback(JSON.parse(json)));
}


export function subscribeOrganisation(orgId) {

    if (!socket) {
        return;
    }

    values(channels).map((channel) => {
        socket.unsubscribe(channel.name);
        delete channels[channel.name];
        return null;
    });

    const name = `private--organisation__${orgId}`;
    subscribe(name);
    bind(name, "survey", (survey) => {
        store.dispatch(setSurvey(survey));
    });

    bind(name, "survey", (survey) => {
        store.dispatch(setSurvey(survey));
    });

    bind(name, "survey-campaign-created", (survey) => {
        const text = `Facebook campaign for ${survey.name} successfully created.`;
        store.dispatch(showSuccess(text));
        store.dispatch(setSurvey(survey));
    });

    bind(name, "survey-ads-created", (survey) => {
        const text = `Ads for ${survey.name} have been successfully created.`;
        store.dispatch(showSuccess(text));
        store.dispatch(setSurvey(survey));
    });

    bind(name, "survey-ads-updated", (survey) => {
        const text = `Ads for ${survey.name} have been successfully updated.`;
        store.dispatch(showSuccess(text));
        store.dispatch(setSurvey(survey));
    });

    bind(name, "audience-members-created", (audience) => {
        const text = `Audience members for list ${audience.name} have been successfully created.`;
        store.dispatch(showSuccess(text));
        store.dispatch(audienceUploadComplete(true));
    });

}


export function channel(key) {
    return channels[key];
}

export default {
    connect: connect,
    disconnect: disconnect,
    subscribe: subscribe,
    bind: bind,
    channel: channel,
};

