/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Kyle on 21/01/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, {Component} from "react";
import Header from "../../../../widgets/header/Header";
import Content from "../../../../widgets/content/Content";
import values from "lodash/values";
import styles from "./Overview.module.scss";
import CreateUpdateAudienceDialog from "../../../../widgets/dialogs/createUpdateAudience/CreateUpdateAudienceDialog";
import {getAudiencesForOrganisation} from "../../../../../redux/actions/v1/audiences";
import noAudience from "../../../../../resources/png/noAudience@2x.png";
import Button from "../../../../widgets/button/Button";
import listCardBlue from "../../../../../resources/png/audience_card_blue@2x.png"
import listCardGreen from "../../../../../resources/png/audience_card_green@2x.png"
import listCardRed from "../../../../../resources/png/audience_card_red@2x.png"
import Routes from "../../../../../constants/routes";
import connect from "react-redux/es/connect/connect";
import {v4 as uuid} from "uuid";

class Overview extends Component {

    constructor(props) {
        super(props);

        const noAudiences = !props.audiences;

        this.state = {
            loading: noAudiences,
        };

        this.headerButtons = [
            // {text: "Refresh", onPress: this.getAudiencesForOrganisation, props: {plain: true, border: true}},
            {text: "Add Audience", onPress: this.onAddAudiencePress, props: {greyDark: true}}
        ];

    }

    componentDidMount() {
        const noAudiences = !this.props.audiences;
        if (noAudiences) {
            this.getAudiences();
        }
    }

    getAudiences = () => {

        this.setState({
            loading: true,
        });

        this.props.dispatch(getAudiencesForOrganisation({
            orgId: this.props.orgId,
            onSuccess: () => {
                this.setState({
                    loading: false,
                });
            },
        }));
    };

    renderListRow = (list, image) => {
        return (
            <div className={styles.audience_item} key={uuid()} onClick={() => this.onListRowPress(list)}>
                <img src={image} alt="list card"/>
                <div className={styles.list_name}>
                    <span>{list.name}</span>
                </div>
            </div>
        );
    };

    onListRowPress = (list) => {
        this.props.history.push(`/audiences/${list.id}`);
    };

    onAddAudiencePress = () => {
        !!this.dialog && this.dialog.show();
    };

    onUpgradeOrgPress = () => {
        this.props.history.push(`${Routes.Settings.root}`)
    };

    renderNoAudiences = () => {
        return (
            <div className={styles.no_audience_message}>
                <p>Organise your lists by Audiences. Are they employees, customers or hot leads etc.</p>
                <img src={noAudience} alt="No audience"/>
                <p><Button plain border onPress={this.onAddAudiencePress}>Add Audience</Button></p>
            </div>
        );
    };

    render() {
        const org = this.props.org;
        let counter = 0;
        const cardImages = [listCardBlue, listCardGreen, listCardRed];

        return (
            <Content headerText={"Audiences"}
                     headerButtons={this.headerButtons}
                     loading={this.state.loading}>

                <Header text={org.name} medium noBorder/>
                {!this.props.audiences ?
                    this.renderNoAudiences() :
                    <div className={styles.audiences}>
                        {
                            values((this.props.audiences || {})).map((list) => {
                                counter >= 2 ? counter = 0 : counter += 1;
                                const image = cardImages[counter];
                                
                                return this.renderListRow(list, image);
                            })
                        }
                    </div>}

                <CreateUpdateAudienceDialog
                    dialogRef={(ref) => this.dialog = ref} orgId={this.props.orgId}
                    onUpgradeOrgPress={this.onUpgradeOrgPress}/>

            </Content>
        );
    }
}

export default Overview = connect((state) => {
    const orgId = state.settings.organisation;
    return {
        user: state.user,
        organisations: (values(state.organisations.accounts) || []),
        orgId: orgId,
        org: (state.organisations.accounts[orgId] || {}),

        audiences: state.audiences[orgId],
    };
})(Overview);