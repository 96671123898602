import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CompletedSurveyRow.module.scss";
import RenameSurveyDialog from "../../../../../widgets/dialogs/renameSurveyDialog/RenameSurveyDialog";
import DuplicateSurveyDialog from "../../../../../widgets/dialogs/duplicateSurveyDialog/DuplicateSurveyDialog";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

class CompletedSurveyRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            submitting: false,
            survey: {}
        };
    }

    handleOptionsPress = (e) => {
        this.setState({ anchorEl: e.target });
    };

    handleOptionsClose = () => {
        this.setState({ anchorEl: null });
    };

    onRenamePress = () => {
        this.handleOptionsClose();
        this.renameSurveyDialog.show();
    };

    onDuplicatePress = () => {
        this.handleOptionsClose();
        this.duplicateSurveyDialog.show();
    };

    onCompletedSurveyPress = () => {
        this.props.history.push(`/surveys/${this.props.survey.id}/report`);
    };

    render() {
        const survey = this.props.survey;
        const popoverOpen = Boolean(this.state.anchorEl);

        return (
            <>
                <TableRow className={styles.tableRow}>
                    <TableCell className={styles.tableCell}
                               onClick={this.onCompletedSurveyPress}>{survey.name}</TableCell>
                    <TableCell>{(survey.insights || {}).respondentsCompleted || 0}</TableCell>
                    <TableCell>{survey.startedAt && moment(survey.startedAt).format("MM/DD/YYYY")}</TableCell>
                    <TableCell>{survey.completedAt ? moment(survey.completedAt).format("MM/DD/YYYY") : "Not available"}</TableCell>
                    <TableCell align={"right"}>
                        <IconButton className={styles.options_button}
                                    onClick={this.handleOptionsPress}>
                            <MoreVert />
                        </IconButton>
                        <Popover open={popoverOpen}
                                 onClose={this.handleOptionsClose}
                                 anchorEl={this.state.anchorEl}
                                 elevation={1}>
                            <MenuItem onClick={this.onRenamePress}>Rename</MenuItem>
                            <MenuItem onClick={this.onDuplicatePress}>Duplicate</MenuItem>
                        </Popover>
                    </TableCell>

                </TableRow>

                <RenameSurveyDialog survey={this.props.survey}
                                    dialogRef={ref => this.renameSurveyDialog = ref} />

                <DuplicateSurveyDialog survey={this.props.survey}
                                       dialogRef={ref => this.duplicateSurveyDialog = ref} />
            </>
        );
    }
};

export default connect()(withRouter(CompletedSurveyRow));

CompletedSurveyRow.propTypes = {
    survey: PropTypes.object,
};

CompletedSurveyRow.defaultProps = {
    survey: {},
};
