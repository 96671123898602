/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 9/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/


export default function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}