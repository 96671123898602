/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./SignUp.module.scss";
import TextInputField from "../../../../../../widgets/textInput/TextInputField";
import Error from "../../../../../../widgets/error/Error";
import Button from "../../../../../../widgets/button/Button";
import Card from "../../../../../../widgets/card/Card";
import { reduxForm } from "redux-form";
import validateEmail from "../../../../../../../constants/validateEmail";
import Routes from "../../../../../../../constants/routes";
import { signInWithProviderPopUp, signUp, newsletterSubscribe, updateUser } from "../../../../../../../redux/actions/v1/user";
import { createOrganisation } from "../../../../../../../redux/actions/v1/organisations";
import { setSaasSignUp } from "../../../../../../../redux/actions/v1/settings";
import { setAuthLoading } from "../../../../../../../redux/actions/v1/auth";
import CheckBox from "../../../../../../widgets/checkBox/CheckBox";
import AnalyticsAPI from "../../../../../../../util/Analytics";
import ReCAPTCHA from "react-google-recaptcha";
import { validateRecaptcha } from "../../../../../../../redux/actions/v1/recaptcha";
import { showError } from "../../../../../../../redux/actions/v1/snackbars";
import { setUser } from "../../../../../../../redux/actions/user";
import sendEventToActiveCampaign from "../../../../../../../redux/actions/api/v1/eventTracking";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            newsletterSelected: false,
            passedCaptcha: false,
        };
    }

    googleProvider = new window.firebase.auth.GoogleAuthProvider();

    facebookProvider = new window.firebase.auth.FacebookAuthProvider();
    onSignUpSuccess = (values) => {
        this.props.dispatch(
            createOrganisation({
                data: {
                    name: values.accountName,
                },
                onSuccess: () => {
                    const tier = this.props.saasSignUp.signUp ? this.props.saasSignUp.tier : "free";
                    sendEventToActiveCampaign(values.email, "Sign_up", tier.toUpperCase());
                    this.props.dispatch(setAuthLoading(false));
                },
            }),
        );
        if (!this.props.saasSignUp.signUp) {
            AnalyticsAPI.tierSignupEvent("FREE");
        }
    };

    getTier = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    componentDidMount() {
        // setting whether it is a saas sign up in redux to prevent routing to verification page in Main.js
        if (!!this.getTier("tier")) {
            this.props.dispatch(setSaasSignUp({ signUp: true, tier: this.getTier("tier") }));
        } else {
            this.props.dispatch(setSaasSignUp({ signUp: false, tier: "" }));
        }
    }

    onSignUpSubmit = (values) => {
        this.recaptchaRef.executeAsync().then((r) => {
            this.setState({
                loading: true,
                error: null,
            });
            this.props.dispatch(setAuthLoading(true));

            this.props.dispatch(
                validateRecaptcha({
                    recaptcha: r,
                    onSuccess: (recaptchaResponse) => {
                        if ((recaptchaResponse || {}).success) {
                            this.props.dispatch(
                                signUp({
                                    email: values.email,
                                    password: values.password,
                                    accountName: values.accountName,
                                    onSuccess: () => {
                                        this.props.dispatch(
                                            updateUser({
                                                name: `${values.firstName} ${values.lastName}`,
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.email,
                                                enterprise: window.location.search.includes("landingpage"),
                                                onSuccess: (u) => {
                                                    this.props.dispatch(setUser(u));
                                                    this.onSignUpSuccess(values);
                                                },
                                            }),
                                        );
                                    },
                                    onError: (err) => {
                                        this.setState({
                                            loading: false,
                                            error: err.message,
                                        });
                                        this.props.dispatch(setAuthLoading(false));
                                    },
                                }),
                            );

                            if (this.state.newsletterSelected) {
                                this.props.dispatch(newsletterSubscribe({ email: values.email }));
                            }
                        }
                    },
                }),
            );
        });
    };

    onCheckboxChange = () => {
        this.setState({ newsletterSelected: !this.state.newsletterSelected });
    };

    onGoogleSignInPress = () => {
        this.setState({
            loading: true,
            error: null,
        });

        this.props.dispatch(
            signInWithProviderPopUp({
                provider: this.googleProvider,
                onSuccess: this.onSignUpSuccess,
                onError: (err) => {
                    this.setState({
                        loading: false,
                        error: err.message,
                    });
                },
            }),
        );
    };

    onFacebookSignInPress = () => {
        this.setState({
            loading: true,
            error: null,
        });

        this.props.dispatch(
            signInWithProviderPopUp({
                provider: this.facebookProvider,
                onSuccess: this.onSignUpSuccess,
                onError: (err) => {
                    this.setState({
                        loading: false,
                        error: err.message,
                    });
                },
            }),
        );
    };

    onLogInPress = () => {
        this.props.history.push(Routes.Onboarding.Login);
    };

    onSignUpPress = () => {
        window.lintrk("track", { conversion_id: 3690628 });
    }

    render() {
        return (
            <Card className={styles.sign_up}>
                <div className={styles.header}>
                    <h3 className={styles.header_text_left}>Sign Up</h3>
                    <Button plain onPress={this.onLogInPress}>
                        Log in
                    </Button>
                </div>
                <form
                    className={styles.login_form}
                    id={"sign-up-form"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.props.handleSubmit(this.onSignUpSubmit)();
                    }}>
                    <TextInputField name={"firstName"} label={"First name"} placeholder={"e.g. John"} />
                    <TextInputField name={"lastName"} label={"Last name"} placeholder={"e.g. Smith"} />
                    <TextInputField name={"accountName"} label={"Organisation name"} placeholder={"e.g. My Organisation"} />
                    <TextInputField name={"email"} label={"Email Address"} placeholder={"Email address"} />
                    <TextInputField name={"password"} label={"Password"} placeholder={"Password"} password />
                    <Error error={this.state.error} className={styles.error} />
                    <CheckBox
                        label={"Yes, please keep me updated on Stickybeak updates, news and offers."}
                        className={styles.checkbox}
                        onChange={this.onCheckboxChange}
                        checked={this.state.newsletterSelected}
                    />
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={() => {}}
                        ref={(r) => (this.recaptchaRef = r)}
                        size={"invisible"}
                    />
                    <Button
                        green
                        className={styles.action_button}
                        disabled={!this.props.anyTouched || this.props.pristine || !this.props.valid}
                        loading={this.state.loading}
                        type={"submit"}
                        onPress={this.onSignUpPress}>
                        Get Started
                    </Button>
                </form>
                {!this.props.saasSignUp.signUp && <div className={styles.footer_text}>Sign up FREE. No credit card required</div>}
            </Card>
        );
    }
}

SignUp = reduxForm({
    form: "signUp",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.name || values.name === "") {
            errors.name = "Please enter a name.";
        }

        if (!values.accountName || values.accountName === "") {
            errors.accountName = "Please enter an organisation name.";
        }

        if (!values.email) {
            errors.email = "Invalid email";
        } else if (!validateEmail(values.email)) {
            errors.email = "Please enter a valid email.";
        }

        if (!values.password || values.password === "") {
            errors.password = "Invalid password";
        }
        if (values.password && values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        }

        return errors;
    },
})(SignUp);

export default SignUp = connect((state, props) => {
    const saasSignUp = state.settings.saasSignUp;
    return {
        saasSignUp: saasSignUp,
    };
})(SignUp);
