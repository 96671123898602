import React, { MouseEvent, useRef } from "react";
import { Chart as ChartJS, DoughnutController, ArcElement, ChartEvent, ActiveElement } from "chart.js";
import type { Chart } from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import styles from "./DonutChart.module.scss";
import Choices from "../../types";
import NoDataFallback from "../noDataFallback/NoDataFallback";
import insertNewLines from "src/util/insertNewLines";

interface DonutChartProps {
    choices: Choices[];
    numRespondents: number;
    questionType: string;
    onPress: (choiceId: number) => null;
}

const chartColors = {
    primary: styles.primaryChartColor,
    secondary: styles.secondaryChartColor,
    tertiary: styles.tertiaryChartColor,
    border: styles.hoverBorderColor,
    primaryText: styles.chartTextPrimaryColor,
    secondaryText: styles.chartTextSecondaryColor,
};

ChartJS.register(DoughnutController, ArcElement);

const DonutChart = ({ choices, numRespondents, onPress, questionType }: DonutChartProps) => {
    const donutRef = useRef<Chart<"doughnut">>();
    const ids: Array<number> = choices.map((choice) => choice.id);
    const series: Array<number> = choices.map((choice) => choice.answers);
    const labels: Array<string> = choices.map((choice) => {
        const labelNumWords = choice.value.split(" ").length;
        if (labelNumWords > 10) {
            const splitLabel = insertNewLines(choice.value, 10);
            return splitLabel;
        }

        return choice.value;
    });
    const imageUrls: Array<string | null> = choices.map((choice) => choice.imageUrl);
    const emoji = imageUrls.map((url) => {
        if (url !== null || url !== undefined) {
            const splitUrl = url?.split("/");
            if (splitUrl) {
                return splitUrl[splitUrl.length - 1];
            }
        }
        return null;
    });

    const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const { current: chart } = donutRef;

        if (!chart) return;

        const [element] = getElementAtEvent(chart, event);

        if (!element) return;

        const { index } = element;
        const choiceId = ids[index];

        onPress(choiceId);
    };

    const options = {
        cutout: "70%",
        radius: "65%",
        normalized: true,
        maintainAspectRatio: false,
        elements: {
            arc: {
                borderWidth: 0,
                hoverBorderWidth: 2,
                hoverBorderColor: chartColors.border,
            },
        },
        plugins: {
            datalabels: {
                display: (context: Context) => {
                    return context.dataset.data[context.dataIndex] !== 0;
                },
                font: {
                    size: 15,
                    family: "'Avenir LT Std 45 Book', 'Avenir', 'Avenir', 'Helvetica', 'Arial', 'sans-serif'",
                },
            },
        },
        onHover: function (event: ChartEvent, chartElement: ActiveElement[]) {
            if (event.native && event.native.target) {
                const target = event.native.target as HTMLCanvasElement;
                const [element] = chartElement;
                target.style.cursor = element ? "pointer" : "default";
            }
        },
    };
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: series,
                backgroundColor: [chartColors.primary, chartColors.secondary, chartColors.tertiary],
                datalabels: {
                    labels: {
                        name: {
                            color: "#000000",
                            offset: 20,
                            anchor: "end" as const,
                            align: "end" as const,
                            formatter: (value: number, context: Context) => {
                                if (context.chart.data.labels) {
                                    const emojiName = emoji[context.dataIndex];
                                    let labelEmoji = "";
                                    if (questionType === "LOVE_HATE" || questionType === "EMOJI_RATING") {
                                        if (emojiName?.includes("verySatisfied")) {
                                            labelEmoji = "😀";
                                        } else if (emojiName?.includes("neutral")) {
                                            labelEmoji = "😐";
                                        } else if (emojiName?.includes("veryUnsatisifed")) {
                                            labelEmoji = "😠";
                                        } else if (emojiName?.includes("thumbsUp")) {
                                            labelEmoji = "👍";
                                        } else if (emojiName?.includes("thumbsDown")) {
                                            labelEmoji = "👎";
                                        } else {
                                            labelEmoji = "";
                                        }
                                    }
                                    return `${labelEmoji} ${context.chart.data.labels[context.dataIndex]}`;
                                }
                                return "Loading";
                            },
                        },
                        value: {
                            formatter: (value: number) => {
                                let percentage = Math.round((value / numRespondents || 0) * 100);

                                const seriesLength = series.length;

                                if (series[seriesLength - 1] === value && seriesLength > 2) {
                                    const total = series.reduce(function (previousValue, currentValue, currentIndex, array) {
                                        if (currentIndex !== seriesLength - 1) {
                                            return (
                                                Math.round((previousValue / numRespondents || 0) * 100) +
                                                Math.round((currentValue / numRespondents || 0) * 100)
                                            );
                                        } else {
                                            return previousValue;
                                        }
                                    });
                                    percentage = 100 - total;
                                }
                                return `${percentage}%`;
                            },
                        },
                    },
                },
            },
        ],
    };

    return (
        <div className={styles.container}>
            {series.every((value) => value === 0) ? (
                <NoDataFallback />
            ) : (
                <Doughnut ref={donutRef} plugins={[ChartDataLabels]} options={options} data={chartData} onClick={onClick} />
            )}
        </div>
    );
};
export default DonutChart;
