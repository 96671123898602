/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {matchPath} from "react-router-dom";

export default function isPath(pathA, pathB, exact = true, strict = false) {
    return !!(matchPath(pathA, {
        path: pathB,
        exact: exact,
        strict: strict
    }) || {}).isExact
};