import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";

export default class FindAudienceDialog extends PureComponent {

    static propTypes = {
        index: PropTypes.number,
        dialogRef: PropTypes.func,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        index: null,
        dialogRef: () => null,
        onSubmit: () => null,
    };

    constructor(props) {
        super(props);

        this.actions = [
            {
                text: "OK",
                onPress: this.hide,
                props: {
                    blue: true
                }
            },
        ];
    }

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.props.onSubmit(this.props.index);
        this.dialog.hide();
    };

    render() {
        return (
            <Dialog title={"Find me an audience"}
                    actions={this.actions}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                <p>Select "Find me an audience" in the Audience selection screen after you have created your survey.</p>
                <p>Edit the demographic target settings to find respondents from anywhere in the world.</p>
            </Dialog>
        )
    }
}