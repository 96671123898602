/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export function getUserAPI(token) {
    return window.get(`/v1/users/me`, token);
}

export function updateUserAPI(token, name, firstName, lastName, email, enterprise) {
    return window.put(`/v1/users/me`, token, {name, firstName, lastName, email}, {enterprise});
}
