import {store} from "../components/App";
import values from "lodash/values";


export default function isOrgAdmin(orgId) {

    const accounts = values(store.getState().organisations.accounts);

    const account = (accounts.filter((acc) => acc.id === orgId))[0];

    const role = (account || {role: ""}).role;

    return role === "SUPER_ADMIN";
}
