import * as Type from "../../actions/v1/audiences";

const initialState = {};

export default function audiences(state = initialState, action = {type: null, audience: {}, audiences: []}) {
    switch (action.type) {

        case Type.SET_AUDIENCE: {
            const audience = action.audience;
            if (!audience) {
                return state;
            }

            if (audience.status === "DELETED") {
                const newState = {...state};
                delete newState[audience.account][audience.id];
                !newState[audience.account].length && delete newState[audience.account];
                return newState
            }

            return {
                ...state,
                [audience.account]: {
                    ...(state[audience.account] || {}),
                    [audience.id]: audience
                },
            };
        }

        case Type.SET_AUDIENCES: {

            const newState = {...state};

            (action.audiences || []).map((audience) => {
                if (!!audience) {
                    newState[audience.account] = {
                        ...(newState[audience.account] || {}),
                        [audience.id]: audience,
                    };
                }
                return null;
            });

            return newState;
        }

        case Type.REMOVE_AUDIENCE: {

            const audience = action.audience;
            if (!audience) {
                return state;
            }

            const newState = {...state};
            delete (newState[audience.account] || {})[audience.id];

            return newState;
        }

        default:
            return state
    }
}