import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../redux/store/configureStore";
import Loading from "./routes/loading/Loading";
import Main from "./routes/Main";
import Loader from "./widgets/loader/Loader";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import ThemeProvider from "@mui/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";

const theme = createTheme({
    components: {
        typography: {
            useNextVariants: true,
            fontFamily: `"Avenir-Book",Avenir,"Avenir",Helvetica,Arial,"sans-serif"`,
        },
        MuiInput: {
            root: {
                border: "1px solid #CDCDCD",
                borderRadius: 4,
                minWidth: 204,
            },
            input: {
                minHeight: 28,
                fontSize: 16,
                padding: "4px 9px",
                boxShadow: "0 .125rem .625rem rgba(90, 97, 105, .12)",
            },
            formControl: {
                "label + &": {
                    marginTop: 20,
                },
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
            },
        },
        MuiInputAdornment: {
            root: {},
            positionStart: {
                marginRight: 9,
                marginLeft: 9,
            },
            positionEnd: {
                marginRight: 9,
                marginLeft: 9,
            },

        },
        MuiSelect: {
            select: {
                display: "flex",
                alignItems: "center",
                minWidth: 171,
            },
            selectMenu: {
                height: undefined,
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "bold",
                color: "#444444"
            },
        },
        MuiFormLabel: {
            root: {
                color: "#444444"
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
});

window.firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
});

export const { store, persistor } = configureStore();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class App extends Component {
    render () {
        return (
            <Provider store={store}>
                <PersistGate loading={<Loading/>} persistor={persistor}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Suspense fallback={<Loader/>}>
                                    <Switch>
                                        <Route path={"*"} component={Main}/>
                                    </Switch>
                                </Suspense>
                            </Router>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
