/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 16/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import Dinero from "dinero.js";

export default function toCents(amount, code = "USD") {
    return Dinero({amount: amount, currency: code, precision: -2}).toUnit()
};
