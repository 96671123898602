import React, {Component} from "react";
import {change, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import styles from "./NPSQuestion.module.scss"
let choices = [11];

for (let i = 0; i < 11; i++) {
    choices[i] = {value: i.toString()}
}

const questionDefaultText = "How likely are you to recommend this service to a friend?"

class NPSQuestion extends Component {

    componentDidMount() {

        if(!this.props.question.content[0].text){
            this.props.dispatch(change("editSurvey", `${this.props.name}.content[0].text`, questionDefaultText))
        }

        this.props.dispatch(change("editSurvey", `${this.props.name}.choices`, choices))
    }


    renderScoreBubbles = () => {

        return (

                (choices).map((choice) => {

                    return (
                        <div className={styles.score_bubble}>
                            <div className={styles.score_value}>
                            {choice.value}
                            </div>
                        </div>
                    )
                })

        )
    }


    render() {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <span className={styles.label}>
                        Very unlikely
                    </span>
                    <span className={styles.label}>
                        Very likely
                    </span>
                </div>
                <div className={styles.score_bubbles}>
                    {this.renderScoreBubbles()}
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const question = selector(state, props.name)
    return {
        question
    }
})(NPSQuestion)