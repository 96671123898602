import React, {useEffect, useState} from 'react';
import styles from "./Pagination.module.scss";

export default function Pagination(props) {
    const {data, getNext, getPrev, items, changePage} = props;

    const [currentPage, setCurrentPage] = useState(1);
    const isFirstCursor = (data.prevCursor === data.nextCursor) || !data.prevCursor

    useEffect(() => {
        window.scrollTo({behavior: 'smooth', top: '0px'});
    }, [currentPage]);

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
        getNext()
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
        getPrev()
    }

    function goToPage(item) {
        setCurrentPage((page) => page - 1);
        changePage(item)
    }

    return (
        <div className={styles.pagination}>
            <button
                onClick={goToPreviousPage}
                className={`${styles.prev} ${isFirstCursor ? `${styles.disabled}` : ''}`}>
                prev
            </button>
            {/* show page numbers */}
            {/*{items.map((item, index) => (*/}
            {/*    <button*/}
            {/*        key={index}*/}
            {/*        // onClick={goToPage(item)}*/}
            {/*        className={`${styles.paginationItem} ${currentPage === item ? 'active' : null}`}*/}
            {/*    >*/}
            {/*        <span>{index + 1}</span>*/}
            {/*    </button>*/}
            {/*))}*/}
            <button
                onClick={goToNextPage}
                className={`${styles.next} ${!data.nextCursor ? `${styles.disabled}` : ''}`}>
                next
            </button>
        </div>
    );
}
