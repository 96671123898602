import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./PopperMenu.module.scss";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import omit from "lodash/omit";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";

export default class PopperMenu extends Component {

    static propTypes = {
        className: PropTypes.string,
        anchorEl: PropTypes.any,
        onClose: PropTypes.func,

        anchorOrigin: PropTypes.shape({
            vertical: PropTypes.oneOf(["top", "center", "bottom"]),
            horizontal: PropTypes.oneOf(["left", "center", "right"]),
        }),
        transformOrigin: PropTypes.shape({
            vertical: PropTypes.oneOf(["top", "center", "bottom"]),
            horizontal: PropTypes.oneOf(["left", "center", "right"]),
        }),
    };

    static defaultProps = {
        className: null,
        menuItems: [],
        onClose: () => null,
        anchorOrigin: {
            vertical: "top",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    };

    id = uuid();

    state = {
        anchorEl: null,
        params: null
    };

    renderMenuItem = (item, i) => {
        const Icon = item.icon;

        return (
            <MenuItem onClick={item.onPress} className={styles.menu_item} key={item.text || i}>
                {!!Icon ?
                    <ListItemIcon className={styles.menu_item_icon}>
                        <Icon />
                    </ListItemIcon> : null}
                <ListItemText className={styles.menu_item_text} inset primary={item.text} />
            </MenuItem>
        );
    };

    show = (anchorEl, ...params) => {
        this.setState({
            anchorEl: anchorEl,
            params: params,
        });
    };

    hide = () => {
        this.setState({
            anchorEl: null,
        }, () => {
            const timeout = setTimeout(() => {
                this.setState({
                    params: null,
                });
                clearTimeout(timeout);
            }, 250);
        });
    };

    onClose = () => {
        this.hide();
        this.props.onClose();
    };

    render() {
        const className = classnames(styles.popper_menu, this.props.className);

        const props = omit(this.props, ["menuItems"]);


        let menuItems = this.props.menuItems;
        if (typeof menuItems === "function") {
            menuItems = menuItems(...(this.state.params || []));
        }

        return (
            <Popover id={this.id}
                     anchorOrigin={this.props.anchorOrigin}
                     transformOrigin={this.props.transformOrigin}
                     {...props}
                     onClose={this.onClose}
                     className={className}
                     anchorEl={this.state.anchorEl}
                     open={Boolean(this.state.anchorEl)}
            >
                {menuItems.map(this.renderMenuItem)}
            </Popover>
        );
    }
}
