import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import EstimateAPI from "./api/estimate";

export enum Types {
    SET_ESTIMATE = "estimate:SET_ESTIMATE",
    SET_LOADING = "estimate:SET_LOADING"
}

export const setEstimate = createAction<any>(Types.SET_ESTIMATE);
export const loadingPriceEstimate = createAction(Types.SET_LOADING);

export const Actions = {
    [Types.SET_ESTIMATE]: setEstimate,
    [Types.SET_LOADING]: loadingPriceEstimate
};

export type Actions = typeof Actions;

export const updateEstimateWithSurveyScore = wrapper(EstimateAPI.updatePriceEstimateAPI, {
    action: setEstimate,
});

export const updateSurveyCost = wrapper(EstimateAPI.updateSurveyCostAPI, {
    action: setEstimate
})

export const getEstimate = wrapper(EstimateAPI.getPriceEstimateAPI, {
    action: setEstimate
});

export const createOrUpdateEstimate = wrapper(EstimateAPI.createOrUpdateEstimateAPI, {
    action: setEstimate
});

