import React, { Component } from "react";
import styles from "./CostCalculator.module.scss";
import AlertTriangleIcon from "../../../../../../../../../../../../resources/svg/alert_triangle.svg";
import Button from "../../../../../../../../../../../widgets/button/Button";
import { connect } from "react-redux";
import fromCents from "../../../../../../../../../../../../constants/localization/currencies/fromCents";
import { withRouter } from "react-router-dom";
import Loader from "../../../../../../../../../../../widgets/loader/Loader";
import { isAdmin } from "../../../../../../../../../../../../constants/isAdmin";

class CostCalculator extends Component {
    state = {
        loading: false,
    };

    onSkipClick = () => {
        this.props.history.push(`/surveys/${this.props.surveyId}`);
    };

    renderCalculator = () => {
        const { priceEstimate } = this.props;
        const currency = priceEstimate.currency;

        let budget = priceEstimate.estBudget;
        if (budget < 30000 && this.props.quotaType === "SAMPLE_SIZE") {
            budget = 30000;
        }
        const estBudget = fromCents(budget, currency || "USD", true);
        const estCostPerRespondent = fromCents(priceEstimate.estCostPerRespondent, currency || "USD", true);
        const estNumberRespondents = priceEstimate.estNumberRespondents;
        const noConfidence = priceEstimate.estCostPerRespondent === 0;
        const noLocation = !this.props.formValues.geoLocations || this.props.formValues.geoLocations.length === 0;

        if (!!priceEstimate.loading) {
            return (
                <div className={styles.loading}>
                    <Loader />
                </div>
            );
        }

        if (noConfidence) {
            return (
                <div className={styles.quota_broaden_audience}>
                    <h3>⚠️ Your audience is too narrow</h3>
                    <p>
                        The audience you’ve selected is too narrow for Stickybeak to target these people effectively.
                        <br />
                        We suggest broadening your audience by trying one or more of the following::
                        <br />
                        • If you are targeting a city, try a country or a region instead (i.e. use “USA” or “California” rather than
                        “Los Angeles”)
                        <br />
                        • When searching for a niche audience, try general interest groups (i.e. use “Fantasy films” rather than “Lord
                        of the Rings”)
                        <br />
                        • Check the interest tag type is correct (i.e. try “Interests” rather than “work_employers”)
                        <br />• If you are targeting an age group, try widen your range (i.e. use “18 - 65+” rather than “18 - 45”)
                    </p>
                </div>
            );
        }
        return (
            <>
                <div className={styles.cost}>
                    <h4>Cost</h4>
                    <div className={styles.cost_breakdown}>
                        <div className={styles.table_row}>
                            <p>Price per respondent</p>
                            <p className={styles.cost_column}>{estCostPerRespondent}</p>
                        </div>
                        <div className={styles.table_row}>
                            <p>Quota</p>
                            <p className={styles.cost_column}>{estNumberRespondents}</p>
                        </div>
                        <div className={styles.table_row}>
                            <p>Total</p>
                            <p className={styles.cost_column}>{estBudget}</p>
                        </div>
                    </div>
                    <Button green disabled={noLocation} onPress={this.props.onSubmitTargeting}>
                        Continue
                    </Button>
                </div>
                <div className={styles.skip_button}>
                    <Button plain borderLight onPress={this.onSkipClick}>
                        Skip
                    </Button>
                </div>
            </>
        );
    };

    render() {
        const { formValues, priceEstimate } = this.props;

        return (
            <div className={styles.cost_calculator}>
                <h3>Cost Calculator</h3>
                <div className={styles.progress_checker}>
                    <div className={styles.progress_field_group}>
                        <p>
                            <span>01. </span>Location
                        </p>
                        {!formValues.geoLocations ||
                            (formValues.geoLocations.length === 0 && <img src={AlertTriangleIcon} alt={"alert icon"} />)}
                    </div>
                    <div className={styles.progress_field_group}>
                        <p>
                            <span>02. </span>Set quota
                        </p>
                        {!priceEstimate && <img src={AlertTriangleIcon} alt={"alert icon"} />}
                    </div>
                    <div className={styles.progress_field_group}>
                        <p>
                            <span>03. </span>Interest groups
                        </p>
                        {/*{formValues.detailed && formValues.detailed.length > 0 && (*/}
                        {/*    <img src={AlertTriangleIcon} alt={"alert icon"} />*/}
                        {/*)}*/}
                    </div>
                    {this.props.quotaType !== "SPEND" && (
                        <div className={styles.progress_field_group}>
                            <p>
                                <span>04. </span>Comparison reporting
                            </p>
                            {/*{(formValues.genders.length > 1 || formValues.ageGroups.length > 1) && (*/}
                            {/*    <img src={AlertTriangleIcon} alt={"alert icon"} />*/}
                            {/*)}*/}
                        </div>
                    )}
                </div>
                {this.renderCalculator()}
            </div>
        );
    }
}

export default connect((state) => {
    const formValues = (state.form.audience || {}).values || {};
    const formValueErrors = (state.form.audience || {}).syncErrors || {};

    return {
        formValues: formValues,
        quotaType: formValues.quotaType,
        desiredSampleSize: formValues.desiredSampleSize,
        maxSpend: formValues.maxSpend,

        desiredSampleSizeError: formValueErrors.desiredSampleSize,
        maxSpendError: formValueErrors.maxSpend,

        priceEstimate: state.estimate,
        isAdmin: isAdmin(state.user),
    };
})(withRouter(CostCalculator));
