/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Types from "../../actions/v1/saas";

const initialState = {
    tiers: {},
};

export default function saas(state = initialState, action = {type: null, tiers: [],}) {
    switch (action.type) {

        case Types.SET_SAAS_TIERS: {

            const tiers = {};
            (action.tiers || []).map((tier) => {
                tiers[tier.id] = tier;
                return null;
            });

            return {
                ...state,
                tiers: tiers
            };
        }

        default: {
            return state;
        }
    }
}
