/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../../actions/v1/snackbars";

const initialState = [];

export default function snackbars(state = initialState, action = {type: null, config: null, id: null,}) {
    switch (action.type) {
        case Type.ADD_SNACKBAR: {
            const config = action.config;
            if (!config) {
                return state;
            }

            return [
                ...state,
                config,
            ];
        }

        case Type.REMOVE_SNACKBAR: {
            return [...state].filter((snackbar) => {
                return snackbar.id !== action.id;
            });
        }
        default: {
            return state;
        }
    }
}