import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./ActiveSurveysContent.module.scss";
import noSurveys from "../../../../../../resources/png/no_surveys@2x.png";
import Loader from "../../../../../widgets/loader/Loader";
import EnterpriseSurveyCard from "./enterpriseSurveyCard/EnterpriseSurveyCard";
import ActiveSurveyCard from "./activeSurveyCard/ActiveSurveyCard";
import {withRouter} from "react-router-dom";
import activeSurveyImage from "../../../../../../resources/png/active_survey_01@2x.png";
import activeSurveyImage2 from "../../../../../../resources/png/active_survey_02@2x.png";
import activeSurveyImage3 from "../../../../../../resources/png/active_survey_03@2x.png";
import activeSurveyImage4 from "../../../../../../resources/png/active_survey_04@2x.png";
import {orderBy} from "lodash/collection";

class ActiveSurveysContent extends Component {

    static propTypes = {
        enterprise: PropTypes.bool,
        loading: PropTypes.bool,
        selectedOrgName: PropTypes.string,
        selectedLiveSurveys: PropTypes.array,
        createNewSurvey: PropTypes.func,
    };

    static defaultProps = {
        enterprise: false,
        loading: true,
        selectedOrgName: "",
        selectedLiveSurveys: [],
        createNewSurvey: () => null,
    };

    onCardPress = (survey) => {
        this.props.history.push(`/surveys/${survey.id}/report`);
    };

    render() {
        let counter = 0;
        const surveyCardImages = [activeSurveyImage, activeSurveyImage2, activeSurveyImage3, activeSurveyImage4];

        return (
            this.props.activeSurveys.length ?
                this.props.loading
                    ? <div className={styles.loader_wrapper}>
                        <Loader/>
                    </div>
                    : orderBy(this.props.activeSurveys, ["startedAt"], ["desc"]).map((survey, i) => {
                        counter === surveyCardImages.length - 1 ? counter = 0 : counter += 1;
                        const previewImage = surveyCardImages[counter];
                        if (this.props.enterprise) {
                            return <EnterpriseSurveyCard key={i}
                                                         index={i}
                                                         onCardPress={this.onCardPress}
                                                         survey={survey}
                                                         org={this.props.org}
                                                         previewImage={previewImage}/>
                        }
                        return <ActiveSurveyCard key={i}
                                                 onCardPress={this.onCardPress}
                                                 index={i}
                                                 survey={survey}
                                                 org={this.props.org}
                                                 previewImage={previewImage}/>

                    })
                :

                <div className={styles.no_active_surveys}>
                    <p>No active surveys...</p>
                    <img src={noSurveys} alt="No Surveys" width="605" height="209"/>
                </div>
        );
    }
}

export default ActiveSurveysContent = withRouter(ActiveSurveysContent);