/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Survey from "../../types/model/Survey";
import {Actions, Types} from "../actions/surveys";

type Surveys = {
    items?: Survey[];
    nextCursor?: string;
    prevCursor?: string;
}

type State = {
    [key: number]: Survey;
    activeSurveys?: { [key: number]: Surveys }
    completedSurveys?: { [key: number]: Surveys }
    draftSurveys?: { [key: number]: Survey[] }
};

const initialState: State = {activeSurveys: {}, completedSurveys: {}, draftSurveys: []};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function surveys(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_SURVEY: {
            const {payload} = action as ActionValueTypes<Types.SET_SURVEY>;
            const survey = payload!;
            return {
                ...state,
                [survey.id]: survey,
            };
        }

        case Types.DUPLICATE_SURVEY: {
            const {payload} = action as ActionValueTypes<Types.DUPLICATE_SURVEY>;
            const survey = payload!;
            const newState = {...state};
            newState[survey.id] = survey;
            const orgId = survey.account;
            newState.draftSurveys![orgId].push(survey);
            return newState
        }

        case Types.COMPLETE_SURVEY: {
            const {payload} = action as ActionValueTypes<Types.COMPLETE_SURVEY>
            const survey = payload!;
            const newState = {...state};
            newState[survey.id] = survey;
            const orgId = survey.account;
            const activeSurveys = newState.activeSurveys![orgId]
            const index = activeSurveys.items!.indexOf(activeSurveys.items!.find(s=>s.id===survey.id)!);
            activeSurveys.items!.splice(index, 1)
            newState.activeSurveys![orgId] = activeSurveys;
            return newState
        }

        case Types.SET_DRAFT_SURVEYS: {
            const {payload} = action as ActionValueTypes<Types.SET_DRAFT_SURVEYS>;
            const newSurveys = payload!;
            const newState = {...state};
            newSurveys.forEach((survey) => {
                newState[survey.id] = survey;
            });
            if (newSurveys.length > 0) {
                const accountId = newSurveys[0].account;
                newState.draftSurveys = {}
                newState.draftSurveys[`${accountId}`] = newSurveys;
            }
            return newState;
        }
        case Types.SET_PAGINATED_SURVEYS: {
            const {payload} = action as ActionValueTypes<Types.SET_PAGINATED_SURVEYS>;
            const newSurveys = payload!;
            const newState = {...state};
            if (newSurveys.items.length > 0) {
                const accountId = newSurveys.items[0].account;
                if (!newState.activeSurveys) {
                    newState.activeSurveys = {}
                }
                newState.activeSurveys[accountId] = newSurveys;
            } else {
                newState.activeSurveys = {};
            }
            return newState;
        }
        case Types.SET_COMPLETED_PAGINATED_SURVEYS: {
            const {payload} = action as ActionValueTypes<Types.SET_COMPLETED_PAGINATED_SURVEYS>;
            const newSurveys = payload!;
            const newState = {...state};
            if (newSurveys.items.length > 0) {
                const accountId = newSurveys.items[0].account;
                if (!newState.completedSurveys) {
                    newState.completedSurveys = {}
                }
                newState.completedSurveys[accountId] = newSurveys;
            } else {
                newState.completedSurveys = {};
            }
            return newState;
        }
        case Types.REMOVE_SURVEY: {
            const {payload} = action as ActionValueTypes<Types.REMOVE_SURVEY>;
            const surveyId = payload!;
            const newState = {...state};
            const orgId = newState[surveyId].account
            const draftSurveys = newState.draftSurveys![orgId]
            const index  = draftSurveys.indexOf(newState[surveyId]);
            draftSurveys.splice(index, 1);
            delete newState[surveyId];
            newState.draftSurveys![orgId] = draftSurveys;
            return newState;
        }
        default: {
            return state;
        }
    }
}
