export const TEXT = "TEXT";
export const IMAGE = "IMAGE";
export const VIDEO = "VIDEO";
export const QUESTION_TEXT = "QUESTION_TEXT";

const CONTENT_TYPES = [
	{id: 0, value: "QUESTION_TEXT", text: "Text"},
	{id: 1, value: "IMAGE", text: "Image"},
	// {id: 3, value: "QUESTION_TEXT", text: "Question"},
];

export default CONTENT_TYPES