import React, { Component, Fragment } from "react";
import styles from "./AudienceFormRow.module.scss";
import TextInputField from "../../../../../widgets/textInput/TextInputField";
import Close from "@mui/icons-material/Close";
import DeleteAudienceFormRowDialog
    from "../../../../../widgets/dialogs/deleteAudienceFormRowDialog/DeleteAudienceFormRowDialog";
import PropTypes from "prop-types";
import DatePickerField from "../../../../../widgets/datePicker/DatePickerField";
import { orderBy } from "lodash/collection";
import IconButton from "../../../../../widgets/iconButton/IconButton";
import ToggleSwitchField from "../../../../../widgets/toggleSwitch/ToggleSwitchField";
import * as classnames from "classnames";

export default class AudienceFormRow extends Component {

    static propTypes = {
        audience: PropTypes.object,
    };

    static defaultProps = {
        audience: {},
    };

    state = {
        deleteIndex: null,
        hideEmailError: true,
    };

    onDeleteAudienceRowPress = (index) => {
        this.setState({
            deleteIndex: index
        });
        this.deleteAudienceFormRowDialog.show();
    };

    onDeleteAudienceRowSubmit = (index) => {
        this.props.fields.remove(index);
        this.deleteAudienceFormRowDialog.hide();
    };

    onEmailBlur = () => {
        this.setState({ hideEmailError: false });
    };

    onEmailFocus = () => {
        this.setState({ hideEmailError: true });
    };

    renderTags = (field) => {
        let columns = [];

        if (!!this.props.audience && !!this.props.audience.columns) {
            columns = orderBy(this.props.audience.columns, ["ordinal"], ["asc"]);
        }

        return columns.map((column, i) => {
            const name = `${field}.${column.id}.value.${i}`;

            switch (column.type) {
                case "DATE": {
                    return (
                        <td key={i}>
                            <DatePickerField name={name} className={styles.input_field} />
                        </td>
                    );
                }
                case "DATE_TIME": {
                    return (
                        <td key={i}>
                            <DatePickerField name={name}
                                             className={classnames(styles.input_field, styles.date_picker)} />
                        </td>
                    );
                }
                case "STRING": {
                    return (
                        <td key={i}>
                            <TextInputField name={name} className={styles.input_field} />
                        </td>
                    );
                }
                case "NUMBER": {
                    return (
                        <td key={i}>
                            <TextInputField name={name} className={styles.input_field} />
                        </td>
                    );
                }
                case "BOOLEAN": {
                    return (
                        <td key={i}>
                            <ToggleSwitchField
                                name={name}
                                className={styles.input_field} />
                        </td>
                    );
                }

                default: {
                    return null;
                }
            }
        });
    };

    renderRow = (field, i) => {
        return (
            <tr key={`${field}`}>
                <td className={styles.email}>
                    <TextInputField name={`${field}.email`}
                                    placeholder={"support@stickybeak.co"}
                                    email
                                    className={styles.input_field}
                                    onBlur={this.onEmailBlur}
                                    onFocus={this.onEmailFocus}
                                    hideError={this.state.hideEmailError} />
                </td>
                {this.renderTags(`${field}.tags`)}
                {/*<td width="100%">&nbsp;</td>*/}
                {i !== 0 && !this.props.hideDelete ? <td className={styles.delete_row_button}>
                    <IconButton plain
                                round
                                onPress={() => this.onDeleteAudienceRowPress(i)}>
                        <Close color="action" fontSize={"small"} />
                        Remove
                    </IconButton>
                </td> : <td>&nbsp;</td>}
            </tr>
        );
    };

    render() {
        return (
            <Fragment>
                {this.props.fields.map(this.renderRow)}
                {!this.props.hideDelete &&
                    <DeleteAudienceFormRowDialog dialogRef={(ref) => this.deleteAudienceFormRowDialog = ref}
                                                 onSubmit={this.onDeleteAudienceRowSubmit}
                                                 index={this.state.deleteIndex} />
                }

            </Fragment>
        );
    }
}
