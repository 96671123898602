import React, {Component} from "react";
import styles from "./Metric.module.scss";
import 'react-circular-progressbar/dist/styles.css';
import infoIcon from "../../../../../../../../../resources/svg/info_icon.svg";
import ReactTooltip from "react-tooltip";


export default class Metric extends Component {

    render() {
        const {label, score, value, baseline, brackets, dataTip} = this.props;

        let greatPercentage = (value / (brackets[0]) * 33);
        let okPercentage = (((value - brackets[0]) / (brackets[1] - brackets[0])) * 33) + 33;
        let badPercentage = ((value - brackets[1]) / (brackets[1] - brackets[0])) * 33 + 67;
        let isPercent = (baseline === '0%' ? '%' : null);
        const styleConst = {
            background: (score === "Great" ? '#8DD800' : score === "Ok" ? "#FFDE54" : '#FF5454'),
            width: baseline === '0%' ? value.toString() + '%' :
                (score === "Great" ? greatPercentage.toString() + '%' : score === "Ok" ? okPercentage.toString() + '%' : (badPercentage <= 100 ? badPercentage.toString() + '%' : '100%')),
            borderRadius: badPercentage === 100 || badPercentage>=100 ? '9px' : '9px 0 0 9px'

        };
        if (baseline === "0 free text questions" && value === 0) {
            styleConst.width = '0%'
        } else if (baseline === "0 free text questions" && value === 1) {
            styleConst.width = '33%'
        }
        const baselineStyles = {
            left: baseline === "0 free text questions" ? '0%' : baseline === "0%" ? '0%' : '33%'
        }

        return (

            <div className={styles.metric}>
                <div className={styles.two_col}>
                    <span className={styles.label}>{label}</span>
                    <span className={styles.tooltip}><img src={infoIcon} data-tip={dataTip}
                                                          alt={"Tooltip icon"}/></span>
                </div>
                <div className={styles.metric_bar_graph}>
                    <div className={styles.background}/>


                    <div className={styles.bar} style={styleConst}/>
                    <div className={styles.inner_text}>
                        <span className={styles.score}> {value}{isPercent} </span>
                        <span className={styles.result}>{score}</span>
                    </div>
                    <div className={styles.baseline} style={baselineStyles}>
                        <span>{baseline}</span>
                    </div>
                </div>
                <ReactTooltip className={styles.tooltip_metric} multiline={true}/>
            </div>

        )


    }


}
