/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 13/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import get from "../redux/actions/api/v1/util/get";
import put from "../redux/actions/api/v1/util/put";
import post from "../redux/actions/api/v1/util/post";
import del from "../redux/actions/api/v1/util/del";
import fetchJSON from "../redux/actions/api/v1/util/fetchJSON";

window.get = get;
window.put = put;
window.post = post;
window.del = del;
window.fetchJSON = fetchJSON;

/* eslint-disable no-extend-native */
String.prototype.toProperCase = function () {

    let str = this;
    str = str.replace(new RegExp("_", "g"), " ");

    return str.toLowerCase().replace(/(^|[\s\xA0])[^\s\xA0]/g, function (s) {
        return s.toUpperCase();
    });
};

Number.prototype.formatThousands = function () {
    let number = this;
    number = number / 100;
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

if (process.env.NODE_ENV !== "development") {
    window.console.log = () => null;
}

String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};


