import styles from "../../ReportSideBar.module.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import Filter from "../filter/Filter";
import { SurveyType } from "../../../../../../../../../../../types/model/Survey";
import ComparisonFilter from "../comparisonFilter/ComparisonFilter";
import React from "react";
import useSelector from "../../../../../../../../../../../hooks/useSelector";
import { CardDetails } from "../cardDetails/CardDetails";

export default function FilterTab (props) {

    const {
        surveyId,
        cardDetails,
        getQuestionsReport,
        onFilterSelect,
        demographicSelection,
        onFilterClear,
        loading,
        showCompletedOnly
    } = props;
    const survey = useSelector((state => state.surveys[surveyId]));

    return (
        <>

            <div className={styles.enterprise_demo_data}>
                <div className={styles.progress_bar_container_new}>
                    <h3>Quota</h3>
                    <div className={styles.progress_bar_new}>
                        <ProgressBar completed={cardDetails.progressBarValue}
                                     bgColor={"#8DD800"}
                                     baseBgColor={"rgba(205, 210, 213, 0.6)"}
                                     isLabelVisible={false}/>
                        <div className={styles.progress_bar_content_new}>
                                        <span className={styles.respondents}>
                                            {cardDetails.respondentsLine1}
                                        </span>
                            <span className={styles.progress_bar_right_text}>
                                            {cardDetails.respondentsLine2}
                                        </span>
                        </div>
                    </div>
                </div>

            <Filter getQuestionsReport={getQuestionsReport}/>
            {
                survey.type === SurveyType.FACEBOOK && (
                    <>
                        <ComparisonFilter
                            loading={loading}
                            demographicSelection={demographicSelection}
                            onFilterSelect={onFilterSelect}
                            surveyId={surveyId}
                            onFilterClear={onFilterClear}
                            showCompletedOnly={showCompletedOnly}
                        />
                    </>)
            }

            {survey.type === SurveyType.FACEBOOK &&
                <CardDetails cardDetails={cardDetails}/>
            }
            </div>

        </>
    )
}