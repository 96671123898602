/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { useDispatch as useReduxDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../redux/reducers/root";

export type ReduxDispatch = ThunkDispatch<ApplicationState, any, AnyAction>;

export default function useDispatch(): ReduxDispatch {
    return useReduxDispatch<ReduxDispatch>();
}