/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-05.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


export default function getQuestionCSVReport(header, questionReport, choices = [], isFreeText = false, headerRow = true, question) {
    let questionReportAnswers = [];

    if (!!headerRow) {
        questionReportAnswers.push(["question", "response", "count"]);
    }

    if (isFreeText) {
        Object.keys(questionReport.answers).map((value) => {
            const answers = questionReport.answers[value] || 0;
            questionReportAnswers.push([header, value.split(",").join(" - "), answers]);
            return null;
        });
    } else if (questionReport.type === "MULTI_ATTRIBUTE") {
        const answerArray = [];
        Object.values(questionReport.answers).forEach((a, i) => {
            (a || []).forEach((v, index) => {
                if (!answerArray[index]) {
                    answerArray.push([0, 0, 0, 0, 0]);
                }
                answerArray[index][v - 1] = answerArray[index][v - 1] + 1;
            });
        });
        const content = []
        question.content.forEach((c, i) => {
            if (i > 0) {

                content.push(c.text);
            }
        });
        content.map((c, i)=>(
            questionReportAnswers.push([header, c.split(",").join(" - "), answerArray[i]])
    ))
    } else {
        choices.map((choice) => {
            let answers = (questionReport.answers || {})[choice.id];

            if (typeof answers === "object") {
                answers = JSON.stringify(answers).split("{")
                    .join("")
                    .split("}")
                    .join("")
                    .split("\"")
                    .join("")
                    .split(",")
                    .join(" - ");
            }

            if (!answers) {
                answers = 0;
            }

            questionReportAnswers.push([header, choice.value.split(",").join(" - "), answers]);
            return null;
        });
    }

    if (!!headerRow) {
        let finalString = "";
        questionReportAnswers.map((r, i) => {
            finalString += r.join(",") + "\n";
            return null;
        }).join("");
        return finalString;
    }

    return questionReportAnswers;
}
