import React, { useEffect, useState } from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import styles from "./MultiAttributeQuestion.module.scss"
import Select from "../../../../../../../../../widgets/select/Select";
import { Form, Formik } from "formik";
import FormikInput from "../../../../../../../../../widgets/input/FormikInput";
import Button from "../../../../../../../../../widgets/button/Button";
import Loader from "../../../../../../../../../widgets/loader/Loader";

let choices = [5];

for ( let i = 0 ; i < 5 ; i++ ) {
    choices[i] = { value: i.toString() }
}

const selectOptions = [
    {
        label: "Not important - Very important",
        value: "important"
    },
    {
        label: "Very dissatisfied - Very satisfied",
        value: "satisfied"
    },
    {
        label: "Strongly disagree - Strongly agree",
        value: "agree"
    },
    {
        label: "Custom",
        value: "custom"
    },
]

const labels = {
    important: {
        leftLabel: "Not important",
        rightLabel: "Very important"
    },
    satisfied: {
        leftLabel: "Very dissatisfied",
        rightLabel: "Very satisfied"
    },
    agree: {
        leftLabel: "Strongly disagree",
        rightLabel: "Strongly agree"
    }
}

const MultiAttributeQuestion = (props) => {

    const [response, setResponse] = useState("")
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(true)
    useEffect(() => {
        if ( !props.question.multiAttributeQuestion ) {
            const multiAttributeQuestion = {
                attributes: props.question.content.length-1,
                leftLabel: "Not important",
                rightLabel: "Very important",
                metric: "important"
            }
            props.dispatch(change("editSurvey", `${props.name}.multiAttributeQuestion`, multiAttributeQuestion))
            setResponse("important")
            setInitialValues({
                leftLabel: multiAttributeQuestion.leftLabel,
                rightLabel: multiAttributeQuestion.rightLabel
            })
        } else {
            setResponse(props.question.multiAttributeQuestion.metric)
            setInitialValues({
                leftLabel: props.question.multiAttributeQuestion.leftLabel,
                rightLabel: props.question.multiAttributeQuestion.rightLabel
            })

        }
        props.dispatch(change("editSurvey", `${props.name}.choices`, choices))

    }, [])


    const onSelectChange = (value) => {
        setResponse(value)
        if ( value !== "custom" ) {
            const multiAttributeQuestion = {
                attributes: props.question.content.length-1,
                leftLabel: labels[value].leftLabel,
                rightLabel: labels[value].rightLabel,
                metric: value
            }
            props.dispatch(change("editSurvey", `${props.name}.multiAttributeQuestion`, multiAttributeQuestion))

        } else {
            props.dispatch(change("editSurvey", `${props.name}.multiAttributeQuestion.metric`, value))

        }
    }

    const renderQuestion = () => {

        return (

            <div className={styles.meter}>
                <div className={styles.bubble}/>
                <hr/>
                <div className={styles.bubble}/>
                <hr/>
                <div className={styles.center_bubble}/>
                <hr/>
                <div className={styles.bubble}/>
                <hr/>
                <div className={styles.bubble}/>
            </div>

        )
    }

    const onInputChange = () =>{
        if(saved){
            setSaved(false)
        }
    }

    const onSavePress = async (values) => {
        setLoading(true)
        setSaved(true)
        const multiAttributeQuestion = {
            attributes: props.question.content.length-1,
            leftLabel: values.leftLabel,
            rightLabel: values.rightLabel,
            metric: "custom"
        }
        props.dispatch(change("editSurvey", `${props.name}.multiAttributeQuestion`, multiAttributeQuestion))
        setLoading(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span>
                    Response type
                </span>
                <Select value={response} options={selectOptions} onChange={onSelectChange} className={styles.select}/>

            </div>
            {renderQuestion()}
            {response === "custom" ? (


                    <Formik initialValues={initialValues} onSubmit={onSavePress}>
                        {({ handleSubmit, dirty, isSubmitting, s }) => (
                            <Form>
                                {loading ? (
                                        <Loader/>

                                    )
                                    :
                                    (
                                        <div className={styles.labels}>

                                            <FormikInput name={"leftLabel"} className={styles.input} onChange={onInputChange}/>
                                            {!saved &&
                                                <Button className={styles.button} loading={loading}
                                                        onPress={handleSubmit}>Save</Button>
                                            }
                                            <FormikInput name={"rightLabel"} className={styles.input} onChange={onInputChange}/>

                                        </div>
                                    )}
                            </Form>
                        )}
                    </Formik>

                )
                : (
                    <div className={styles.labels}>
                        <span className={styles.label}>
                            {(labels[response]||{}).leftLabel}
                        </span>
                        <span className={styles.label}>
                            {(labels[response]||{}).rightLabel}
                        </span>
                    </div>
                )}
        </div>
    )
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const question = selector(state, props.name)
    return {
        question
    }
})(MultiAttributeQuestion)