import React, {Component} from "react";
import Dialog from "../../dialog/Dialog";
import styles from "./UpdateDailyCampaignSpendDialog.module.scss"
import Input from "../../input/Input";
import {connect} from "react-redux";
import {updateSurveyDailyCampaignSpend, updateSurveyMaxSpend} from "../../../../redux/actions/surveys";
import {showError, showSuccess} from "../../../../redux/actions/v1/snackbars";
import Button from "../../button/Button";

class UpdateDailyCampaignSpendDialog extends Component {
    hide = () => {
        this.dialog.hide();
    };
    initialState = {
        dailySpend: this.props.currentDailySpend,
        loading: false
    }
    state = {...this.initialState}

    onUpdateDailyCampaignSpend = async (dailyAdSpend) => {
        this.setState({loading: true})
        try {
            await this.props.dispatch(updateSurveyDailyCampaignSpend(
                this.props.surveyId, dailyAdSpend * 100,
            ))
            this.setState({loading: false})
            this.props.dialogRef.current.hide();
            this.props.dispatch(showSuccess("Daily campaign spend successfully updated."))
        } catch (e) {
            this.props.dispatch(showError(e.message))
        }
    }
    onInputChange = (e) => {
        const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (regexp.test(e.target.value.slice(1))) {
            this.setState({dailySpend: e.target.value.slice(1)})
        }

    }

    render() {
        return (
            <Dialog
                rootClassName={styles.dialog_root}
                containerClassName={styles.dialog_container}
                className={styles.edit_daily_spend_dialog}
                actions={[]}
                fullWidth={false}
                title={"Edit daily spend limit"}
                error={this.props.error}
                ref={this.props.dialogRef}>
                <div className={styles.input_container}>
                        <span className={styles.label}>
                            Daily limit
                        </span>
                    <Input value={"$" + this.state.dailySpend}
                           className={styles.input_field}
                           onChange={(e) => this.onInputChange(e)}/>
                </div>
                <div className={styles.buttons}>
                    <Button white disabled={this.state.loading} onPress={() => this.props.dialogRef.current.hide()}>
                        Cancel
                    </Button>
                    <Button green loading={this.state.loading}
                            onPress={() => this.onUpdateDailyCampaignSpend(this.state.dailySpend)}>
                        Update
                    </Button>
                </div>
            </Dialog>
        );
    }
}
export default connect((state, props) => {
})(UpdateDailyCampaignSpendDialog)

