import React, { Component } from "react";
import styles from "./Age.module.scss";
import Button from "../../../../../../../../../../../widgets/button/Button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import classnames from "classnames";
import Radio from "@mui/material/Radio";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value}`}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

class Age extends Component {
    state = {
        ageSlider: true,
        genZGroupActive: false,
        millennialGroupActive: false,
        genXGroupActive: false,
        boomerGroupActive: false
    };

    componentDidMount() {
        this.props.ageGroups.length > 1 ? this.setState({ ageSlider: false }) : this.setState({ ageSlider: true });
    }

    onSliderRadioChange = () => {
        this.setState({ ageSlider: true });
        this.props.fields.removeAll();
        this.props.fields.push({
            minAge: 18,
            maxAge: 65
        });
    };

    onGenRadioChange = () => {
        this.setState({ ageSlider: false });
        this.props.fields.removeAll();
    };

    onSliderChange = (value) => {
        let min = 18;
        let max = 65;
        value.forEach((age, index) => {
            if (index === 0) {
                min = age;
            } else {
                max = age;
            }
        });
        this.props.fields.removeAll();
        this.props.fields.push({
            minAge: min,
            maxAge: max,
        });
    };

    onGenPress = (min, max) => {
        this.props.fields.push({
            minAge: min,
            maxAge: max,
        });
    };
    onRemoveGenPress = (min, max) => {
        this.props.ageGroups.forEach((bucket, index) => {
            if (bucket.minAge === min && bucket.maxAge === max) {
                this.props.fields.remove(index);
            }
        });
    };

    onRemoveButtonPress = () => {
        this.props.fields.removeAll();
        this.props.fields.push({
            minAge: 18,
            maxAge: 65
        });
        this.props.onRemoveClick();
    };

    render() {
        const isAgeSelected = (min, max) => {
            let selected = false;
            this.props.ageGroups.forEach((bucket) => {
                if (bucket.minAge === min && bucket.maxAge === max) {
                    selected = true;
                }
            });
            return selected;
        };

        const { ageGroups } = this.props;
        let minAge = 18;
        let maxAge = 65;

        if (ageGroups && ageGroups.length === 1 && this.state.ageSlider) {
            const ageGroup = ageGroups[0];
            minAge = ageGroup.minAge;
            maxAge = ageGroup.maxAge;
        }


        return (
            <div className={styles.age}>
                <p className={styles.warning_message}>Please note that this may affect pricing.</p>
                <div className={styles.radio_select}>
                    <h3>Select one</h3>
                    <div>
                        <Radio className={styles.radio} checked={this.state.ageSlider}
                               onChange={this.onSliderRadioChange} /> Age range slider
                    </div>
                    <div>
                        <Radio className={styles.radio} onChange={this.onGenRadioChange}
                               checked={!this.state.ageSlider} /> Age groups
                    </div>
                </div>
                {this.state.ageSlider ?
                    <div className={styles.ageSlider}>
                        <Range min={18} max={65} allowCross={false} handle={handle} defaultValue={[minAge, maxAge]}
                               onChange={this.onSliderChange} tipProps={{ visible: true }} />
                        <div className={styles.label}>
                            <span>18</span>
                            <span>65+</span>
                        </div>
                    </div>
                    :
                    <div className={styles.select_generation_group}>
                        <label>Select generation groups (You can select more than one).</label>
                        <div className={styles.generations}>
                            <div className={classnames(styles.generation, { [styles.active]: isAgeSelected(18, 34) })}>
                                <h3>18 - 34</h3>
                                {!isAgeSelected(18, 34) ?
                                    <Button green onPress={() => this.onGenPress(18, 34)}>Select</Button> :
                                    <Button plain borderGreyMid
                                            onPress={() => this.onRemoveGenPress(18, 34)}>Remove</Button>
                                }
                            </div>
                            <div className={classnames(styles.generation, { [styles.active]: isAgeSelected(35, 54) })}>
                                <h3>35 - 54</h3>
                                {!isAgeSelected(35, 54) ?
                                    <Button green onPress={() => this.onGenPress(35, 54)}>Select</Button> :
                                    <Button plain borderGreyMid
                                            onPress={() => this.onRemoveGenPress(35, 54)}>Remove</Button>
                                }
                            </div>
                            <div className={classnames(styles.generation, { [styles.active]: isAgeSelected(55, 65) })}>
                                <h3>55 - 65+</h3>
                                {!isAgeSelected(55, 65) ?
                                    <Button green onPress={() => this.onGenPress(55, 65)}>Select</Button> :
                                    <Button plain borderGreyMid
                                            onPress={() => this.onRemoveGenPress(55, 65)}>Remove</Button>
                                }
                            </div>
                        </div>
                    </div>
                }
                <Button borderDark plain onPress={this.onRemoveButtonPress}>Remove</Button>
            </div>
        );
    }
}


Age = connect((state) => {
    return {
        ageGroups: ((state.form.audience || {}).values || {}).ageGroups || [],
    };
})(Age);


Age.propTypes = {
    className: PropTypes.string,
};

Age.defaultProps = {
    className: null,
};


export default Age;
