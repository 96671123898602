/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import UsersAPI from "../api/user";
import * as AccountsAPI from "../api/v1/organisations";
import wrapper from "../helpers/v1/wrapper";
import { setUser } from "../user";
import { setOrganisations } from "./organisations";
import { purgeReduxStore, setActiveOrganisation } from "./settings";
import {updateUserAPI} from "../api/v1/user";

export const sendPasswordResetToEmail = wrapper((token, email) => {
    return window.firebase.auth().sendPasswordResetEmail(email);
});

export const login = wrapper((token, email, password) =>
    window.firebase.auth().signInWithEmailAndPassword(email, password)
);

export const updateUser = wrapper(updateUserAPI);

export const signUp = wrapper((token, email, password) =>
        window.firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((res) => window.firebase.auth().currentUser
                .getIdToken()
                .then(() => (res))));

export const signInWithProviderPopUp = wrapper((token, provider) =>
    window.firebase.auth().signInWithPopup(provider)
);

export const newsletterSubscribe = wrapper((token, email) => {
    return UsersAPI.subscribeToNewsletter(email)
})

export const signOut = wrapper(() => window.firebase.auth().signOut(), (dispatch) => {
    dispatch(purgeReduxStore());
});

export function loadUserDetails({onSuccess = () => null, onError = () => null}) {
    return async (dispatch, getState) => {
        UsersAPI.getUser()
            .then(async (user) => {
                dispatch(setUser(user));
                const token = !!window.firebase.auth().currentUser ? await window.firebase.auth().currentUser.getIdToken() : null;
                return AccountsAPI.getOrganisationsAPI(token);
            })
            .then((organisations = []) => {
                dispatch(setOrganisations(organisations));

                let currentOrg = getState().settings.organisation;
                const filteredOrgs = organisations.filter((org) => org.id === currentOrg);

                if (!filteredOrgs.length) {
                    currentOrg = null;
                }

                if (!currentOrg) {
                    const firstOrg = organisations[0];

                    if (!!firstOrg) {
                        currentOrg = firstOrg.id;
                    }
                }

                dispatch(setActiveOrganisation(currentOrg));
                return currentOrg;
            })
            .then(onSuccess)
            .catch(onError);
    };
}
