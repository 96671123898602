/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-04-01.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import {connect} from "react-redux";
import Dialog from "../../dialog/Dialog";
import {reduxForm} from "redux-form";
import {showOnboardDialog} from "../../../../redux/actions/v1/auth";
import TextInputField from "../../textInput/TextInputField";

class OnboardPasswordDialog extends Component {

    state = {
        loading: false,
        error: null,
    };

    onSubmit = (values) => {
        const password = values.password;

        this.setState({
            loading: true,
            error: null,
        });

        window.firebase.auth().currentUser.updatePassword(password).then(this.onSuccess).catch(this.onError);
    };

    onSuccess = () => {
        this.props.dispatch(showOnboardDialog(false))
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        });
    };

    render() {

        const actions = [{
            text: "Continue",
            props: {
                type: "submit",
                loading: this.state.loading,
                disabled: this.props.pristine || !this.props.valid,
            }
        }];

        return (
            <Dialog actions={actions}
                    title={"Welcome to Stickybeak"}
                    text={"Your profile has been created, Please set your password below."}
                    isControlled
                    error={this.state.error}
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                    shown={this.props.onboardPassword}>

                <TextInputField name={"password"} label={"Password"} placeholder={"Password"} password/>
                <TextInputField name={"passwordConfirm"} label={"Confirm Password"} placeholder={"Confirm Password"} password/>

            </Dialog>
        );
    }
}

OnboardPasswordDialog = reduxForm({
    form: "onboardPassword",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Required";
        }

        if (!values.passwordConfirm) {
            errors.passwordConfirm = "Required";
        }

        if (!!values.password && values.passwordConfirm) {
            if (values.password !== values.passwordConfirm) {
                errors.password = "Password do not match";
                errors.passwordConfirm = "Password do not match";
            }
        }

        return errors;
    }
})(OnboardPasswordDialog);

export default OnboardPasswordDialog = connect((state) => {
    return {
        onboardPassword: state.auth.onboardPassword,
    };
})(OnboardPasswordDialog);