/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import values from "lodash/values";

export default function wrapper(promise, onDispatchSuccess, onDispatchFailure) {
    return ({onSuccess, onError, ...params} = {}) => {
        return async (dispatch, getState) => {

            const token = !!window.firebase && !!window.firebase.auth() && !!window.firebase.auth().currentUser ? await window.firebase.auth().currentUser.getIdToken() : null;

            promise(token, ...values(params)).then((r) => {
                if (process.env.NODE_ENV === "development") {
                    console.log("API SUCCESS: ", r);
                }
                if (!!onDispatchSuccess) {
                    onDispatchSuccess(dispatch, r, ...values(params), getState);
                }
                if (!!onSuccess && typeof onSuccess === "function") {
                    onSuccess(r, ...values(params), getState);
                }
            }).catch((err) => {
                if (process.env.NODE_ENV === "development") {
                    console.log("API FAILURE: ", err);
                }
                if (!!onDispatchFailure && typeof onDispatchFailure === "function") {
                    onDispatchFailure(dispatch, err, ...values(params), getState);
                }
                if (!!onError && typeof onError === "function") {
                    onError(err, ...values(params), getState);
                }
            });
        }
    }
}