import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change } from "redux-form";
import styles from "./SelectAudiences.module.scss";
import classnames from "classnames";
import SelectAudienceCard from "./card/SelectAudienceCard";
import HollyBallSpin from "../../../../../../../../../../resources/js/hollyBallSpin/HollyBallSpin";
import UsaWithPins from "../../../../../../../../../../resources/svg/usa_map_with_pins.svg";
import shareLink from "../../../../../../../../../../resources/svg/shareable-qr.svg";
import Header from "../../../../../../../../../widgets/header/Header";
import Dialog from "../../../../../../../../../widgets/dialog/Dialog";
import shareableNoQr from "../../../../../../../../../../resources/svg/shareable-link-no-qr.svg";
import shareableQr from "../../../../../../../../../../resources/svg/qr-code.svg";
import sendEventToActiveCampaign from "../../../../../../../../../../redux/actions/api/v1/eventTracking";
import { updateSurvey } from "../../../../../../../../../../redux/actions/surveys";

class SelectAudiences extends Component {

    state = {
        loading: false
    };

    onFacebookAudiencePress = () => {
        this.setState({ loading: true });
        this.props.dispatch(updateSurvey(this.props.orgId, this.props.survey.id, {
            ...this.props.survey,
            facebookType: "NICHE",
            type: "FACEBOOK"
        })).then(() => {
            this.props.input.onChange("FACEBOOK");
            this.props.dispatch(change("audience", "representativeAudience", "null"));
            sendEventToActiveCampaign(this.props.user.email, "Selected_audience", "facebook");
            this.setState({ loading: false });
        });
    };

    onRepresentationAudiencePress = () => {
        this.setState({ loading: true });
        this.props.dispatch(updateSurvey(this.props.orgId, this.props.survey.id, {
            ...this.props.survey,
            facebookType: "NAT_REP",
            type: "FACEBOOK"
        })).then(() => {
            this.props.input.onChange("REPRESENTATION");
            sendEventToActiveCampaign(this.props.user.email, "Selected_audience", "representative");
            this.setState({ loading: false });
        });
    };

    onShareableLinkPress = () => {
        this.setState({ loading: true });
        this.props.dispatch(updateSurvey(this.props.orgId, this.props.survey.id, {
            ...this.props.survey,
            facebookType: null,
            type: "LINK"
        })).then(() => {
            this.props.input.onChange("LINK");
            this.props.dispatch(change("audience", "representativeAudience", "null"));
            sendEventToActiveCampaign(this.props.user.email, "Selected_audience", "link");
            this.props.onShareableLinkClick();
            this.setState({ loading: false });
        });
    };

    render() {
        const className = classnames(styles.select_audiences, this.props.className);

        const type = (this.props.input || {}).value;

        return (
            <div className={className}>
                <Header text={"1. Select Audience"} medium noBorder />

                <div
                    className={classnames(styles.content, { [styles.no_border]: this.props.hideBorder })}>
                    <SelectAudienceCard isSelected={type === "FACEBOOK"}
                                        header={"Find a niche audience"}
                                        summary={"Select this option to send your survey to people that have specific demographic characteristics or interests - such as age, location, gender, and interest groups."}
                                        top={<HollyBallSpin className={styles.holly_ball_spin} />}
                                        onSelectPress={this.onFacebookAudiencePress}
                                        buttonId={"niche_select"}
                                        loading={this.state.loading}
                                        onInfoPress={() => this.facebookInfoDialog.show()} />
                    
                    {/*<SelectAudienceCard isSelected={type === "REPRESENTATION"}*/}
                    {/*                    header={"Find a national audience"}*/}
                    {/*                    summary={"Select this option if you would like us to find people who make up a broadly nationally representative sample."}*/}
                    {/*                    top={<img src={UsaWithPins} alt={"Map with pins"}*/}
                    {/*                              className={styles.united_states} />}*/}
                    {/*                    onSelectPress={this.onRepresentationAudiencePress}*/}
                    {/*                    buttonId={"national_select"}*/}
                    {/*                    onInfoPress={() => this.representativeInfoDialog.show()} />*/}
                    {(this.props.tier === "FREE" && !this.props.isEnterprise) &&
                        <SelectAudienceCard isSelected={type === "LINK"}
                                            header={"Shareable link"}
                                            summary={"Select this option to generate a link. Copy the link and send to people you know."}
                                            top={<img src={shareableNoQr} alt={"Share link"} />}
                                            onSelectPress={this.onShareableLinkPress}
                                            onInfoPress={() => this.shareableLinkDialog.show()}
                                            buttonId={"shareable_select"} />
                    }
                    {(this.props.tier === "FREE" && !this.props.isEnterprise) &&
                        <SelectAudienceCard isSelected={false}
                                            header={"QR Code"}
                                            onClick={this.props.onUpgradeTierPress}
                                            summary={"Select this option to generate a digital link and QR code to share or print on receipts or packaging."}
                                            top={<img src={shareableQr} alt={"Share link"} />}
                                            onSelectPress={this.onShareableLinkPress}
                                            buttonId={"upgrade_prompt"} />

                    }
                    {(this.props.tier !== "FREE" || this.props.isEnterprise) &&
                        <SelectAudienceCard isSelected={type === "LINK"}
                                            header={"Shareable link"}
                                            onInfoPress={() => this.shareableLinkDialog.show()}
                                            summary={"Select this option to generate a digital link and QR code to share or print on receipts or packaging."}
                                            top={<img src={shareLink} alt={"Share link"} />}
                                            onSelectPress={this.onShareableLinkPress}
                                            buttonId={"shareable_select"} />
                    }
                </div>

                <Dialog title={"Niche audience"}
                        titleClassName={styles.info_dialog_title}
                        contentClassName={styles.info_dialog_content}
                        text={"Stickybeak includes powerful social media targeting features that enable you to reach hard-to-find and niche audiences. Once your survey is ready to go, you’ll get the option of sending it to specific audiences identified via their social media interests and characteristics. Your survey will then be automatically deployed to these people. What you pay is dependent on how difficult these audiences are to reach, but all the costs are presented transparently."}
                        actions={[{
                            text: "Okay",
                            onPress: () => this.facebookInfoDialog.hide(),
                            props: {
                                blue: true
                            }
                        }]}
                        actionsClassName={styles.info_dialog_button}
                        ref={ref => this.facebookInfoDialog = ref} />

                <Dialog title={"Representative audience"}
                        titleClassName={styles.info_dialog_title}
                        contentClassName={styles.info_dialog_content}
                        text={"This is a feature set in development and currently available only in the USA, UK, and New Zealand. It’s useful if you want to get a sense of the broad national mood on an issue or test sentiment towards a product or brand country-wide. This feature automatically sets census-based audience quotas on age, gender, and geography and returns broadly representative results across these criteria."}
                        actions={[{
                            text: "Okay",
                            onPress: () => this.representativeInfoDialog.hide(),
                            props: {
                                blue: true
                            }
                        }]}
                        actionsClassName={styles.info_dialog_button}
                        ref={ref => this.representativeInfoDialog = ref} />
                <Dialog title={"Shareable link"}
                        titleClassName={styles.info_dialog_title}
                        contentClassName={styles.info_dialog_content}
                        text={"Selecting a shareable link or QR code means you’ve already got access to the audience you’d like to survey. You might have email addresses or want to print a QR code onto packaging, receipts or at an event. Once you create your survey, you’ll be given a unique URL or digital and downloadable QR code to distribute yourself."}
                        actions={[{
                            text: "Okay",
                            onPress: () => this.shareableLinkDialog.hide(),
                            props: {
                                blue: true
                            }
                        }]}
                        actionsClassName={styles.info_dialog_button}
                        ref={ref => this.shareableLinkDialog = ref} />
            </div>
        );
    }
}

SelectAudiences = connect((state, props) => {
    const orgId = state.settings.organisation;
    const account = state.organisations.accounts[orgId];

    let tier = account.subscription.tier.name.toUpperCase();
    const isEnterprise = state.organisations.accounts[orgId].accountType === "ENTERPRISE";
    const user = state.user;
    // let tier = "FREE"
    return {
        account: account,
        tier: tier,
        isEnterprise: isEnterprise,
        user,
        orgId
    };
})(SelectAudiences);

SelectAudiences.propTypes = {
    className: PropTypes.string,
};

SelectAudiences.defaultProps = {
    className: null,
};

export default SelectAudiences;
