import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {orderBy} from "lodash/collection";
import styles from "../addManually/AddManually.module.scss";
import {FieldArray, reduxForm, reset} from "redux-form";
import AudienceFormRow from "../addManually/audienceFormRow/AudienceFormRow";
import Content from "../../../../widgets/content/Content";
import Button from "../../../../widgets/button/Button";
import {clearAudienceMembers, updateAudienceMembers} from "../../../../../redux/actions/v1/audienceMembers";
import {showError} from "../../../../../redux/actions/v1/snackbars";

class UpdateAudienceMembers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedColumnId: null,
            loading: false
        };

        if (!props.updateAudienceMembers) {
            props.history.push(`/audiences/${props.match.params.audienceId}`)
        }
    }

    renderColumnHeadings = () => {
        let columns = [];
        if (!!this.props.audience.columns) {
            columns = orderBy(this.props.audience.columns, ["ordinal"], ["asc"]);
        }

        return columns.map((column) => {
            return (
                <th key={column.id} className={styles.delete_row_button}>
                    {column.name}
                </th>

            )
        })
    };

    onSubmit = (values) => {
        this.setState({loading: true});
        this.props.dispatch(updateAudienceMembers({
            orgId: this.props.orgId,
            audienceId: this.props.audienceId,
            data: values.respondents,
            onSuccess: () => {
                this.setState({loading: false});
                this.props.dispatch(clearAudienceMembers(this.props.audienceId));
                if (this.props.onSavePress) {
                    this.props.onSavePress()
                } else {
                    this.props.history.push(`/audiences/${this.props.audienceId}`);
                }
            },
            onError: (e) => {
                this.setState({loading: false});
                this.props.dispatch(showError(e.message));
            }
        }));

    };

    onCancelPress = () => {
        this.props.dispatch(reset("updateAudienceMembers"));
        this.props.history.push(`/audiences/${this.props.audienceId}`);
    };

    render() {
        return (
            <Content headerText={"Edit Contacts"}
                     loading={this.state.loading}
                     headerButtons={[]}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <table>
                        <thead>
                        <tr>
                            <th>Email</th>
                            {this.renderColumnHeadings()}
                            <th width="100%">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <FieldArray name={"respondents"}
                                    component={AudienceFormRow}
                                    hideDelete
                                    audience={this.props.audience}/>
                        </tbody>
                    </table>

                    <div>
                        <Button green type="submit">Save</Button>
                        <Button plain onPress={this.onCancelPress}>Cancel</Button>
                    </div>
                </form>
            </Content>
        )
    }
}

UpdateAudienceMembers = reduxForm({
    form: "updateAudienceMembers",
})(UpdateAudienceMembers);

export default UpdateAudienceMembers = connect((state, props) => {
    const orgId = state.settings.organisation;
    // const organisation = (state.organisations.accounts[orgId] || {});
    const audienceId = Number(((props.match || {}).params || {}).audienceId) || props.audienceId;
    const audience = ((state.audiences[orgId] || {})[audienceId] || {});

    let values = [];
    if (!!state.audienceMembers.updateAudienceMembers) {
        state.audienceMembers.updateAudienceMembers.map((member) => {
            values.push({id: member.id, email: member.email, tags: member.tags});
            return null;
        })
    }

    return {
        updateAudienceMembers: state.audienceMembers.updateAudienceMembers,
        audience: audience,
        audienceId: audienceId,
        orgId: orgId,
        initialValues: {
            respondents: values
        }
    }
})(UpdateAudienceMembers);