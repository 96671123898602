/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import AdSet from "../../types/model/AdSet";
import AdSetsAPI from "./api/adSets";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_AD_SETS = "adSets:SET_AD_SETS",
}

export const setAdSets = createAction<null | AdSet[]>(Types.SET_AD_SETS);

export const Actions = {
    [Types.SET_AD_SETS]: setAdSets,
};
export type Actions = typeof Actions;

export const getAdSetsForSurvey = wrapper(AdSetsAPI.getAdSetsForSurvey, {
    action: setAdSets,
});