import React, { Component } from "react";
import PropTypes, { oneOfType } from "prop-types";
import Dialog from "../../dialog/Dialog";
import styles from "./ShareableLinkSurveyDialog.module.scss";
import { connect } from "react-redux";
import Loader from "../../loader/Loader";
import { getShareSurveyLink } from "../../../../redux/actions/v1/surveys";
import QRCode from "qrcode";

class ShareableLinkSurveyDialog extends Component {
    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        surveyId: oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        accountId: oneOfType([PropTypes.string, PropTypes.number]).isRequired
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            link: null,
            copySuccess: false,
        };

        if (props.accountId && props.surveyId) {
            props.dispatch(getShareSurveyLink({
                accountId: props.accountId,
                surveyId: props.surveyId,
                onSuccess: this.getLinkSuccess,
                onError: (error) => {
                    this.setState({ loading: false });
                }
            }));
        }

        this.textArea = React.createRef();
    }

    getLinkSuccess = async (link) => {
        const svg = await this.getQRCodeSvg(link);
        const dataUri = 'data:image/svg+xml,' + encodeURIComponent(svg);
        this.setState({
            loading: false,
            link: link,
            qrCodeSvg: svg,
            qrCodeDataUri: dataUri,
        });
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.props.onClose();
    };

    onError = (err) => {
        this.setState({
            loading: false,
            error: err.message,
        });
    };

    onCopyPress = () => {
        this.textArea.current.select();
        document.execCommand("copy");
        this.textArea.current.focus();
        this.setState({ copySuccess: true }, () => {
            window.setTimeout(() => this.setState({ copySuccess: false }), 5000);
        });
    };

    getQRCodeSvg = (url) => {
        return new Promise((res, rej) => {
            QRCode.toString(url, {type:'svg', margin:1}, (err, result) => err ? rej(err) : res(result));
        });
    };

    render() {
        if (!this.props.surveyId) {
            return null;
        }

        return (
            <Dialog
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                className={styles.shareable_link_survey_loading_dialog}
                contentClassName={styles.shareable_link_survey_loading_content}
                onClose={this.onClose}
                disableDismiss={this.state.loading}
                actions={
                    [
                        {
                            text: "Copy link", onPress: this.onCopyPress, props: { green: true }
                        },
                        {
                            text: "Close", onPress: this.hide, props: { plain: true }
                        },
                    ]
                }>
                <div className={styles.gif_container}>
                    <div className={styles.text}>
                        <h2>Share Survey</h2>
                        <p>Your shareable link</p>
                        {this.state.loading ? <Loader/> : (
                            <div className={styles.link_wrapper}>
                                <textarea ref={this.textArea}
                                          onClick={this.onCopyPress}
                                          defaultValue={this.state.link}
                                          readOnly
                                          rows={1}
                                          cols={50}/>
                                {this.props.upgradeRequired &&
                                    <div className={styles.qrcode_wrapper}>
                                        <div className={styles.qr_code}
                                             dangerouslySetInnerHTML={{__html: this.state.qrCodeSvg}}/>
                                        <div className={styles.download}>
                                            <p>Your shareable QR code</p>
                                            <a href={this.state.qrCodeDataUri}
                                               download={"qrcode.svg"}>Download</a>
                                        </div>
                                    </div>
                                }

                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default connect((state, props)=>{
    const orgId = state.settings.organisation
    const account = state.organisations.accounts[orgId];
    const tier = account.subscription.tier.name.toUpperCase();
    const upgradeRequired = (tier==="FREE" && account.type!=="ENTERPRISE")
    return {
        account: account,
        tier: tier,
        upgradeRequired: upgradeRequired
    }
})(ShareableLinkSurveyDialog);
