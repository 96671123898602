import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import AutoCompleteInput from "../../../../../../../../../../../../../widgets/autoCompleteInput/AutoCompleteInput";
import { searchLocations } from "../../../../../../../../../../../../../../redux/actions/v1/facebook";
import debounce from "lodash/debounce";
import styles from "../../../../../../../../../../../../../widgets/autoCompleteInput/AutoCompleteInput.module.scss";
import MenuItem from "@mui/material/MenuItem";

class LocationInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            suggestions: [],
        };

        this.onTextChangeDebounce = debounce(this.debouncedSuggestionTextChange, 15);
    }

    onSuggestionTextChange = (text) => {
        this.props.onSuggestionTextChange(text);
        this.onTextChangeDebounce(text);
    };

    debouncedSuggestionTextChange = (text) => {

        if (text.length <= 0) {

            this.setState({
                suggestions: []
            });

            return;
        }

        this.setState({
            loading: true
        });

        this.props.dispatch(searchLocations({
            params: {
                q: text
            },
            onSuccess: (suggestions) => {
                this.setState({
                    loading: false,
                    suggestions: suggestions,
                });
            }
        }));
    };

    onSuggestionPress = (suggestion) => {

        this.setState({
            suggestions: [],
        });

        this.props.onSuggestionPress(suggestion);
    };

    renderLocationSuggestion = (suggestion, onSuggestionPress, i) => {
        const name = suggestion.name;
        const region = suggestion.region;
        const country = suggestion.country_name;
        const type = suggestion.type;
        let suggestionText = "";

        if (type === "city") {
            suggestionText = `${name}, ${region}, ${country}`;
        } else if (type === "region") {
            suggestionText = `${name}, ${country}`;
        } else {
            suggestionText = country;
        }

        return (
            <MenuItem key={i}
                      onClick={onSuggestionPress(suggestion)}
                      className={styles.suggestion}>
                {suggestionText}
                <div className={styles.chip}>{suggestion.type}</div>
            </MenuItem>
        );
    };

    render() {
        return (
            <AutoCompleteInput suggestions={this.state.suggestions}
                               text={this.props.text}
                               disabled={this.props.disabled}
                               loading={this.state.loading}
                               onSuggestionTextChange={this.onSuggestionTextChange}
                               onSuggestionPress={this.onSuggestionPress}
                               renderSuggestion={this.renderLocationSuggestion} />
        );
    }
}

LocationInput = connect(() => {
    return {};
})(LocationInput);


LocationInput.propTypes = {
    onSuggestionTextChange: PropTypes.func,
    onSuggestionPress: PropTypes.func,
    text: PropTypes.string,
    countryCode: PropTypes.string,
    regionCode: PropTypes.string,
};

LocationInput.defaultProps = {
    onSuggestionTextChange: () => null,
    onSuggestionPress: () => null,
    text: "",
    countryCode: null,
    regionCode: null,
};


export default LocationInput;
