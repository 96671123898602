import React, { Component } from "react";
import MatRadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import MatFormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import styles from "./RadioGroup.module.scss";

export default class RadioGroup extends Component {

    static propTypes = {
        checkboxName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string,
            // color: PropTypes.string,
        })),
        value: PropTypes.string,
        onChange: PropTypes.func,
        horizontal: PropTypes.bool
    };

    static defaultProps = {
        checkboxName: "",
        options: [{
            text: "",
            value: "",
        }],
        value: "",
        onChange: () => null
    };


    onChange = (e) => {
        this.props.onChange(e.target.value);
    };

    render() {
        return (
            <MatRadioGroup
                name={this.props.checkboxName}
                value={this.props.value}
                onChange={this.onChange}>
                <div style={{display: this.props.horizontal?"flex": "block"}}>
                {this.props.options.map((option, i) => {
                    return (
                        <MatFormControlLabel
                            key={i}
                            className={styles.text}
                            value={option.value}
                            control={<Radio className={styles.radio_button} />}
                            label={option.text}
                        />
                    );
                })}
                </div>

            </MatRadioGroup>
        );
    }
}
