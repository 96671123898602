/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import Survey from "../../../types/model/Survey";
import put from "./util/put";
import post from "./util/post";
import get from "./util/get";


function updateSurvey(accountId: number, surveyId: number, survey: Survey): Promise<Survey> {
    return put(`/v1/accounts/${accountId}/surveys/${surveyId}`, survey);
}

function updateSurveyDailyAdSetSpend(surveyId: number, spend: number): Promise<Survey> {
    return put(`/v1/surveys/${surveyId}/ad-spend/ad-set`, spend);
}

function updateSurveyDailyCampaignSpend(surveyId: number, spend: number): Promise<Survey> {
    return put(`/v1/surveys/${surveyId}/ad-spend/ad-set`, spend);
}

function updateSurveyScore(accountId: number, surveyId: number, score: number) {
    return put(`/v1/accounts/${accountId}/surveys/${surveyId}/score`, null, { score });
}

function updateSurveyLanguage(accountId: number, surveyId: number, language: string) {
    return put(`/v1/accounts/${accountId}/surveys/${surveyId}/language`, null, { language });
}

export function updateSurveyMaxSpendAPI(surveyId: number, maxSpend: number, cardPayment: boolean) {
    return put(`/v1/surveys/${surveyId}/ad-spend/max-spend`, { maxSpend: maxSpend, cardPayment: cardPayment })
}

export function getSurveyAdCreatives(surveyId: number) {
    return get(`/v1/surveys/${surveyId}/ad-creatives`)
}

export function createAdCreative(dtos: { text: string, survey: number, id?: number, contentType: string, status?: string }[], surveyId: number) {
    return post(`/v1/surveys/${surveyId}/ad-creatives`, dtos)
}

export function uploadCustomVideo(file: any) {
    return post("/v1/video-upload", file)
}

export function addFundsToSurveyAPI(payAsYouGo: {
    amount: number,
    currency: string,
    cardId: any,
}, accountId: number, surveyId: number) {
    return post(`/v1/accounts/${accountId}/surveys/${surveyId}/ad-spend/add-funds`, payAsYouGo)
}

const SurveysAPI = {
        updateSurvey,
        updateSurveyDailyCampaignSpend,
        updateSurveyDailyAdSetSpend,
        updateSurveyScore,
        updateSurveyMaxSpendAPI,
        addFundsToSurveyAPI,
        updateSurveyLanguage,
    }
;

export default SurveysAPI;
