import React from "react";
import useFormikField, { FormikFieldExtraProps } from "../../../hooks/useFormikField";
import { ChangeEvent } from "./Cleave";
import Input, { InputProps } from "./Input";

export type FormikInputProps = {
    name: string;
    maxLength?: string;
} & Omit<InputProps, "name" | "value" | "error" | "variant"> & FormikFieldExtraProps;

export default function FormikInput(props: FormikInputProps) {
    const { options, ...otherProps } = props;

    const { value, disabled, showError, helperText, field } = useFormikField(props);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e);
        field.onChange(e);
    };

    return (
        <Input
            {...otherProps}
            options={options}
            field={field}
            value={value}
            onChange={onChange}
            disabled={disabled}
            error={showError}
            helperText={helperText}
        />
    );
}
