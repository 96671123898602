import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import * as QuestionsAPI from "../api/v1/questions";

export const SET_SURVEY_QUESTIONS = "questions:SET_SURVEY_QUESTIONS";
export const SET_SURVEY_QUESTION = "questions:SET_SURVEY_QUESTION";
export const REMOVE_SURVEY_QUESTION = "questions:REMOVE_SURVEY_QUESTION";
export const REMOVE_SURVEY_QUESTIONS = "questions:REMOVE_SURVEY_QUESTIONS";
export const SET_SURVEY_ENDING = "surveyEnding: SET_SURVEY_ENDING";
export const SET_CUSTOM_SURVEY_ENDING = "surveyEnding: SET_CUSTOM_SURVEY_ENDING";
export const REMOVE_CUSTOM_SURVEY_ENDING = "surveyEnding: REMOVE_CUSTOM_SURVEY_ENDING";

export const setSurveyQuestions = createAction(SET_SURVEY_QUESTIONS, "questions");
export const setSurveyQuestion = createAction(SET_SURVEY_QUESTION, "question");
export const removeSurveyQuestion = createAction(REMOVE_SURVEY_QUESTION, "question");
export const removeSurveyQuestions = createAction(REMOVE_SURVEY_QUESTIONS, "surveyId");
export const setSurveyEnding = createAction(SET_SURVEY_ENDING, "endings");
export const setCustomSurveyEnding = createAction(SET_CUSTOM_SURVEY_ENDING, "customSurveyEndings", "surveyId");
export const removeCustomSurveyEnding = createAction(REMOVE_CUSTOM_SURVEY_ENDING, "customSurveyEnding")


export const getSurveyQuestions = wrapper(QuestionsAPI.getSurveyQuestionsAPI, (dispatch, questions) => {
    dispatch(setSurveyQuestions(questions))
});

export const createSurveyQuestion = wrapper(QuestionsAPI.createQuestionAPI, (dispatch, question) => {
    dispatch(setSurveyQuestion(question))
});

export const getSurveyEnding = wrapper(QuestionsAPI.getSurveyEndingAPI, (dispatch, endingQuestions)=>{
    dispatch(setSurveyEnding(endingQuestions))
})

export const getSurveyLanguageOptions = wrapper(QuestionsAPI.getSurveyLanguageOptionsAPI);

export const deleteSurveyQuestion = wrapper(QuestionsAPI.deleteQuestionAPI, (dispatch, question) => {
    dispatch(removeSurveyQuestion(question))
});

export const updateSurveyQuestion = wrapper(QuestionsAPI.updateQuestionAPI, (dispatch, question) => {
    dispatch(setSurveyQuestion(question))
});
export const updateSurveyQuestions = wrapper(QuestionsAPI.updateQuestionsAPI, (dispatch, questions) => {
    dispatch(setSurveyQuestions(questions))
});

export const getCustomSurveyEnding = wrapper(QuestionsAPI.getCustomSurveyEndingAPI, (dispatch, customSurveyEnding)=>{
    dispatch(setCustomSurveyEnding(customSurveyEnding))
})

export const createUpdateCustomSurveyEnding = wrapper(QuestionsAPI.createUpdateCustomSurveyEndingAPI, (dispatch, surveyId, customSurveyEnding)=>{
    dispatch(setCustomSurveyEnding(surveyId, customSurveyEnding))
})

export const deleteCustomSurveyEnding = wrapper(QuestionsAPI.deleteCustomSurveyEndingAPI, (dispatch, customSurveyEnding)=>{
    dispatch(removeCustomSurveyEnding(customSurveyEnding))
})