import { Action, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import root, { ApplicationState } from "../reducers/root";

export default function configureStore() {
    const store = createStore<unknown, Action<unknown>, unknown, ApplicationState>(
        root,
        composeWithDevTools(applyMiddleware(thunk)));

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
}
