import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./Settings.module.scss";
import {
    deleteOrganisationCard,
    getOrganisationCards,
    getUsersForOrganisation,
    setOrganisationDefaultCard, updateOrganisation,
    getOrganisationTransactions
} from "../../../redux/actions/v1/organisations";
import Header from "../../widgets/header/Header";
import values from "lodash/values";
import Chip from "../../widgets/chip/Chip";
import PopperMenu from "../../widgets/popperMenu/PopperMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../widgets/loader/Loader";
import UserAccountDialog from "../../widgets/dialogs/userAccountDialog/UserAccountDialog";
import isOrgAdmin from "../../../constants/isOrgAdmin";
import CreateUpdateOrganisation
    from "../../widgets/dialogs/createUpdateOrganisation/CreateUpdateOrganisation";
import CreditCardRow from "../../widgets/creditCardRow/CreditCardRow";
import CreditCardDialog from "../../widgets/dialogs/creditCardDialog/CreditCardDialog";
import { Elements } from "@stripe/react-stripe-js";
import Content from "../../widgets/content/Content";
import LeaveOrgDialog from "../../widgets/dialogs/leaveOrgDialog/LeaveOrgDialog";
import RemoveUserFromOrgDialog
    from "../../widgets/dialogs/removeUserfromOrgDialog/RemoveUserFromOrgDialog";
import Subscriptions from "./subscriptions/Subscriptions";
import sadAgents from "../../../resources/png/noCreditCardAdded@2x.png";
import { orderBy } from "lodash/collection";
import { AccountType } from "../../../types/model/StickybeakAccount";
import UploadImageDialog from "../../widgets/dialogs/uploadImageDialog/UploadImageDialog";
import { showError, showSuccess } from "../../../redux/actions/v1/snackbars";
import {
    getOrganisationCompletedSurveys,
    getOrganisationLiveSurveys, getSurvey
} from "../../../redux/actions/v1/surveys";
import Routes from "../../../constants/routes";
import bluePadlock from "../../../resources/svg/padlock-blue-circle.svg";
import yellowPadlock from "../../../resources/svg/padlock-yellow-circle.svg";
import UpgradePromptDialog from "../../widgets/dialogs/upgradePromptDialog/UpgradePromptDialog";
import { getSaasTiers } from "../../../redux/actions/v1/saas";
import sendEventToActiveCampaign from "../../../redux/actions/api/v1/eventTracking";
import { isAdmin } from "../../../constants/isAdmin";
import MaterialTable from "@material-table/core";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { loadStripe } from "@stripe/stripe-js";

class Settings extends Component {

    constructor (props) {
        super(props);

        this.state = {
            usersLoading: true,
            cardsLoading: true,
            surveysLoading: true,

            selectedUserId: null,
            selectedCardId: null,
        };
        props.dispatch(getSaasTiers());
        props.dispatch(getOrganisationTransactions({ accountId: this.props.orgId }));
        props.dispatch(getOrganisationLiveSurveys({
            accountId: this.props.orgId,
            onSuccess: () => {
                props.dispatch(getOrganisationCompletedSurveys({
                    accountId: this.props.orgId,
                    onSuccess: () => this.setState({ surveysLoading: false })
                }));

            }
        }));
        // props.dispatch(getOrganisationCompletedSurveys({ accountId: this.props.orgId }))
        props.dispatch(getUsersForOrganisation({
            accountId: this.props.orgId,
            onSuccess: () => {
                this.setState({
                    usersLoading: false,
                });
            },
        }));

        if ( props.isOrgAdmin || props.isAdmin ) {
            props.dispatch(getOrganisationCards({
                accountId: this.props.orgId,
                onSuccess: () => {
                    this.setState({
                        cardsLoading: false,
                    });
                },
            }));
        }

        this.userMenuItems = (user = {}) => {

            const isYou = (user.user === props.user.id);

            // if (isYou) {
            //     return [
            //         {
            //             text: "Leave organisation",
            //             icon: DeleteIcon,
            //             onPress: this.onLeavePress
            //         }
            //     ];
            // }

            return [
                {
                    text: "Update user role",
                    icon: SettingsIcon,
                    onPress: () => this.onUpdateUserPress(user)
                },
                {
                    text: "Remove user",
                    icon: DeleteIcon,
                    onPress: () => this.onRemoveUserPress(user)
                },
            ];
        };

    }

    renderUserRow = (user, i) => {
        const isYou = (user.user === this.props.user.id);
        const isPending = (user.status === "PENDING");
        return (
            <div className={styles.row_wrapper} key={user.id || i}>
                <MenuItem className={styles.row}>
                    <div className={styles.column}>
                        <span>{user.name || user.email}</span>
                    </div>
                    <div className={styles.column}>
                        {this.renderOrgRoleChip(user.role)}
                        {isYou ? this.renderStickybeakAdminChip() : null}
                        {isYou ? this.renderIsYouChip() : null}
                        {isPending ? this.renderPendingChip() : null}
                    </div>
                </MenuItem>
                <div className={styles.settings_button}>
                    {this.renderRowSettingsButton(user)}
                </div>
            </div>
        );
    };

    renderIsYouChip = () => {
        return (
            <Chip label={"You"} className={styles.extra_chip}/>
        );
    };

    renderPendingChip = () => {
        return (
            <Chip label={"Pending"} className={styles.extra_chip}/>
        );
    };

    renderOrgRoleChip = (role) => {

        const roleText = role.toProperCase();

        if ( role === "SUPER_ADMIN" ) {
            return <Chip purple label={roleText}/>;
        }

        return (
            <Chip label={roleText}/>
        );
    };

    renderStickybeakAdminChip = () => {
        if ( this.props.isAdmin ) {
            return <Chip orange label={"Stickybeak Admin"} className={styles.extra_chip}/>;
        }
    };

    renderRowSettingsButton = (user) => {
        const isYou = (user.user === this.props.user.id);

        const disabled = !this.props.isOrgAdmin && !isYou && !this.props.isAdmin;

        return (
            <IconButton onClick={(e) => this.onUserSettingsPress(e, user)} disabled={disabled}>
                <MoreVert/>
            </IconButton>
        );
    };

    renderCreditCardRow = (card) => {
        return (
            <CreditCardRow key={card.id}
                           card={card}
                           onSetDefaultCardPress={this.onSetDefaultCardPress}
                           onEditCardPress={this.onEditCardPress}
                           onRemoveCardPress={this.onRemoveCardPress}/>
        );
    };

    onEditOrgPress = () => {
        this.orgDialog.show();
    };

    onAddNewUserPress = () => {
        this.userAccountDialog.show();
    };

    onUserSettingsPress = (e, user) => {
        e.preventDefault();
        const target = e.currentTarget;
        this.setState({
            selectedUserId: user.id,
        });
        this.userSettingsPopper.show(target, user);
    };

    onUpdateUserPress = () => {
        this.userSettingsPopper.hide();
        this.userAccountDialog.show();
    };

    onRemoveUserPress = () => {
        this.userSettingsPopper.hide();
        this.removeUserFromOrgDialog.show();
    };

    onAddNewCardPress = () => {
        this.creditCardDialog.show();
        sendEventToActiveCampaign(this.props.user.email, "Clicked_add_card", "Clicked add card");
    };

    onSetDefaultCardPress = (card) => {
        this.onCardChangeStart();
        this.props.dispatch(setOrganisationDefaultCard({
            orgId: this.props.orgId,
            stripeCardId: card.id,
            onSuccess: this.onCardChangeSuccess,
        }));
    };

    onEditCardPress = (card) => {
        this.setState({
            selectedCardId: card.id,
        });

        this.creditCardDialog.show();
    };

    onLeavePress = () => {
        this.userSettingsPopper.hide();
        this.leaveOrgDialog.show();
    };

    onCardChangeStart = () => {
        this.setState({
            cardsLoading: true,
        });
    };

    onCardChangeSuccess = () => {
        this.setState({
            cardsLoading: false,
        });
    };

    onRemoveCardPress = (card) => {
        this.onCardChangeStart();
        this.props.dispatch(deleteOrganisationCard({
            orgId: this.props.orgId,
            stripeCardId: card.id,
            onSuccess: this.onCardChangeSuccess,
        }));
    };

    onDialogsClose = () => {
        const timeout = setTimeout(() => {
            this.setState({
                selectedUserId: null,
                selectedCardId: null,
            });
            clearTimeout(timeout);
        }, 300);
    };

    onEditLogoPress = () => {
        this.logoDialog.show();
    };

    onLogoSubmit = (url) => {
        this.props.dispatch(updateOrganisation({
            accountId: this.props.orgId,
            account: {
                ...this.props.org,
                logo: url,
            },
            onSuccess: () => this.props.dispatch(showSuccess("Logo successfully saved")),
            onError: () => this.props.dispatch(showError("There was an error saving your logo"))
        }));
    };

    renderOrgAdminComponents = () => {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        return (
            <Fragment>

                <CreateUpdateOrganisation
                    dialogRef={(ref) => this.orgDialog = ref}
                    orgId={this.props.orgId}
                />

                <UserAccountDialog dialogRef={(ref) => this.userAccountDialog = ref}
                                   orgId={this.props.orgId}
                                   userId={this.state.selectedUserId}
                                   onClose={this.onDialogsClose}/>

                <Elements stripe={stripePromise}>
                    <CreditCardDialog dialogRef={(ref) => this.creditCardDialog = ref}
                                      onClose={this.onDialogsClose}
                                      orgId={this.props.orgId}
                                      cardId={this.state.selectedCardId}/>
                </Elements>

                <RemoveUserFromOrgDialog dialogRef={(ref) => this.removeUserFromOrgDialog = ref}
                                         orgId={this.props.orgId}
                                         userId={this.state.selectedUserId}
                                         onClose={this.onDialogsClose}/>
            </Fragment>
        );
    };

    renderOrgLogo = () => {
        if ( (this.props.isOrgAdmin || this.props.isAdmin) && this.props.isEnterprise ) {
            return (
                <>
                    <Header text={"Choose organisation logo"}
                            medium
                            buttons={[{
                                text: "Select Organisation Logo",
                                onPress: this.onEditLogoPress,
                                props: {
                                    plain: true,
                                    border: true
                                }
                            }]}/>
                    <img className={styles.organisation_logo} src={this.props.org.logo} alt={""}/>
                </>
            );
        }
    };
    renderSubscriptions = () => {
        if ( this.props.isEnterprise ) {
            return (
                <>
                    <Header text={"Subscriptions"}
                            medium
                            buttons={[{
                                text: "Edit Organisation Name",
                                onPress: this.onEditOrgPress,
                                props: {
                                    plain: true,
                                    border: true
                                }
                            }]}/>
                    <div className={styles.subscription_message}>
                        <strong>You are currently on the Enterprise tier.</strong>&nbsp;
                        <br/>
                        Contact <a style={{ color: "#333333" }}
                                   href={"mailto:support@stickybeak.co"}>Support</a> if you
                        need help.
                    </div>
                </>
            );
        }
        if ( !this.props.isOrgAdmin && !this.props.isAdmin ) {
            return null;
        }
        return (
            <Fragment>
                <Header text={"Subscriptions"}
                        medium
                        buttons={[{
                            text: "Edit Organisation Name",
                            onPress: this.onEditOrgPress,
                            props: {
                                plain: true,
                                border: true
                            }
                        }]}/>
                <Subscriptions org={this.props.org}/>
            </Fragment>
        );
    };
    renderCards = () => {
        if ( !this.props.isOrgAdmin && !this.props.isAdmin ) {
            return null;
        }
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 25
            }}>
                <Header className={styles.cards_header}
                        text={"Payment method"}
                        medium
                        noBorder
                        buttons={[{
                            text: "Add card",
                            onPress: this.onAddNewCardPress,
                            props: {
                                plain: true,
                                border: true
                            }
                        }]}/>
                <div className={styles.billing}>
                    <div className={styles.list_container}>
                        {this.props.orgCards.length === 0 ?
                            <div className={styles.no_credit_card_added_container}>
                                <img src={sadAgents} alt={""}/>
                                <h3>Woops! You have no cards added</h3>
                            </div> : orderBy(this.props.orgCards, ["default"], ["desc"])
                                .map(this.renderCreditCardRow)}
                        {this.state.cardsLoading ?
                            <div className={styles.cards_loading}>
                                <Loader large/>
                            </div> : null}
                    </div>
                </div>
            </div>
        );
    };

    onViewReceiptPress = (transaction) => {
        transaction.invoice ? this.handleReceiptDownload(transaction) :
            this.props.dispatch(getSurvey({
                orgId: this.props.orgId,
                surveyId: transaction.chargeDTO.survey,
                onSuccess: (survey) => {
                    this.handleReceiptDownload(transaction, survey);
                }
            }));
    };

    formatDate = (dateToFormat) => {
        const newDate = dateToFormat.toDateString()
            .slice(4)
            .split(" ");
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months.indexOf(newDate[0]) + 1;
        return `${newDate[1]}/${month.toString().length < 2 ? 0 : ""}${month}/${newDate[2].slice(2)}`;
    };

    formatInvoiceNumber = (number) => {
        let numberString = "0000";
        if ( number !== null ) {
            const invoiceNumberLength = number.toString().length;

            numberString = `${numberString.slice(0, 4 - invoiceNumberLength)}${number.toString()}`;
        }
        return numberString;
    };

    handleReceiptDownload = async (transaction, survey) => {
        let params = {};
        let siteUrlParam;
        let url;
        let missingParam = false;

        if ( transaction.invoice ) {
            params = {
                orgName: this.props.org.name,
                subscription: transaction.invoice.lines[0].description,
                dateFrom: new Date(transaction.invoice.periodStart).toDateString(),
                dateTil: new Date(transaction.invoice.periodEnd).toDateString(),
                currency: transaction.chargeDTO.currency.toUpperCase(),
                amount: ((transaction.chargeDTO.amount) / 100).toFixed(2),
                dateOfCharge: new Date(transaction.chargeDTO.created),
                country: transaction.chargeDTO.country,
                last4: transaction.chargeDTO.last4,
                invoiceNumber: `${transaction.chargeDTO.account}-${this.formatInvoiceNumber(transaction.chargeDTO.invoiceNumber)}`
            };
            siteUrlParam = encodeURI(`${window.origin}${Routes.Receipt.SubscriptionReceipt}?params=${JSON.stringify(params)}`);
            url = `${process.env.REACT_APP_PDF_SERVICE_URL}/pdf?url=${siteUrlParam}&title=subscription-receipt`;
            if ( Object.values(params)
                .includes(null) ) {
                missingParam = true;
            }
        } else {
            params = {
                orgName: this.props.org.name,
                survey: survey.name,
                totalRespondents: survey.desiredSampleSize,
                costPerRespondent: ((transaction.chargeDTO.amount) / 100 / (survey.desiredSampleSize)).toFixed(2),
                currency: transaction.chargeDTO.currency.toUpperCase(),
                amount: ((transaction.chargeDTO.amount) / 100).toFixed(2),
                dateOfCharge: new Date(transaction.chargeDTO.created),
                country: transaction.chargeDTO.country,
                last4: transaction.chargeDTO.last4,
                invoiceNumber: `${transaction.chargeDTO.account}-${this.formatInvoiceNumber(transaction.chargeDTO.invoiceNumber)}`
            };
            siteUrlParam = encodeURI(`${window.origin}${Routes.Receipt.SurveyReceipt}?params=${JSON.stringify(params)}`);
            url = `${process.env.REACT_APP_PDF_SERVICE_URL}/pdf?url=${siteUrlParam}&title=survey-receipt`;
            if ( !survey.desiredSampleSize ) {
                delete params.totalRespondents;
                delete params.costPerRespondent;
            }
            if ( Object.values(params)
                .includes(null) ) {
                missingParam = true;
            }
        }

        if ( !missingParam ) {
            try {
                window.open(url);
            } catch ( err ) {
                this.props.dispatch(showError(err.message));
            }
        } else {

            this.props.dispatch(showError("Unable to generate receipt"));
        }
    };
    parseData = (transactions) => {
        const orderedTransactions = orderBy(transactions, ["chargeDTO.created"], ["desc"]);

        const parsedTransactions = orderedTransactions.map((transaction) => {
            return {
                date: this.formatDate(new Date(transaction.chargeDTO.created)),
                amount: `${transaction.chargeDTO.currency.toUpperCase()}$${((transaction.chargeDTO.amount) / 100).toFixed(2)}`,
                ...transaction
            };
        });
        return parsedTransactions;
    };
    onUpgradePress = () => {
        this.upgradePromptDialog.show();
    };

    render () {
        const userButtons = [];
        if ( this.props.isOrgAdmin || this.props.isAdmin ) {
            if ( !this.props.upgradeRequired ) {
                userButtons.push({
                    text: "Add user",
                    onPress: this.onAddNewUserPress,
                    props: {
                        plain: true,
                        border: true
                    }
                });
            } else if ( this.props.upgradeRequired ) {
                userButtons.push({
                    text: (
                        <div className={styles.upgrade_prompt}>
                            <div className={styles.padlocks_wrapper}>
                                <img src={bluePadlock} alt={"blue padlock"}/>
                                <img className={styles.yellow_padlock} src={yellowPadlock} alt={"yellow padlock"}/>
                            </div>
                            <span className={styles.upgrade_text}>Upgrade your plan to <br/> add more users</span>
                        </div>
                    ),
                    onPress: this.onUpgradePress,
                    props: {
                        plain: true,
                        border: false
                    }
                });
            }
        }
        const columns = [
            {
                title: "Date",
                field: "date"
            },
            {
                title: "Amount",
                field: "amount"
            },
            {
                field: "",
                render: (transaction) => {
                    return (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end"
                        }}>
                            <a style={{ textDecoration: "black underline" }}
                               onClick={() => this.onViewReceiptPress(transaction)}>View receipt</a>
                        </div>

                    );
                }
            }];
        return (
            <Content headerText={"Settings"}>
                <div className={styles.organisation}>
                    {this.renderSubscriptions()}
                    {this.renderOrgLogo()}
                    <div className={styles.cards_and_transactions}>
                        <div className={styles.wrapper}>
                            <Header className={styles.transactions_header}
                                    text={"Billing"}
                                    medium
                                    noBorder

                            />
                            <MaterialTable
                                style={{
                                    width: "100%",
                                    boxShadow: "none",
                                    borderWidth: 0,
                                }}
                                isLoading={this.state.surveysLoading}
                                options=
                                    {{
                                        toolbar: false,
                                        draggable: false,
                                        search: false,
                                        // header: false,
                                        paging: Object.values(this.props.transactions).length > 10,
                                        pageSize: 10
                                    }}
                                columns={columns} data={this.parseData(this.props.transactions)}/>
                        </div>
                        {this.renderCards()}
                    </div>
                    <Header text={"Users"} medium buttons={userButtons}/>
                    <div className={styles.list_container}>
                        {this.state.usersLoading ?
                            <div className={styles.loader}>
                                <Loader large/>
                            </div> : this.props.orgUsers.map(this.renderUserRow)}
                    </div>
                    <PopperMenu
                        ref={(ref) => this.userSettingsPopper = ref}
                        menuItems={this.userMenuItems}
                        onClose={this.onDialogsClose}
                    />
                    {this.renderOrgAdminComponents()}
                    <LeaveOrgDialog dialogRef={(ref) => this.leaveOrgDialog = ref}
                                    orgId={this.props.orgId}
                                    userId={this.props.user.id}
                                    orgUsers={this.props.orgUsers}
                                    onClose={this.onDialogsClose}/>

                </div>
                <UploadImageDialog title={"Upload logo"} dialogRef={(ref) => this.logoDialog = ref}
                                   onSubmit={this.onLogoSubmit}/>
                <UpgradePromptDialog
                    dialogRef={(ref) => this.upgradePromptDialog = ref}
                />
            </Content>
        );
    }
}

export default Settings = connect((state) => {
    const currentOrg = state.settings.organisation;
    const selectedAccount = (state.organisations.accounts[currentOrg] || {});
    const surveys = state.surveys || [];
    const transactions = selectedAccount.transactions || [];
    const tier = selectedAccount.subscription.tier.name.toUpperCase();
    // const tier = "FREE"
    const maxUsers = selectedAccount.subscription.tier.maxUsers;
    const numUsers = Object.values(state.organisations.users[currentOrg] || []).length;
    const upgradeRequired = (tier === "FREE" && selectedAccount.accountType !== "ENTERPRISE" && numUsers === maxUsers);
    const saasPlans = state.saasPlans;
    return {
        user: state.user,
        orgId: currentOrg,
        isOrgAdmin: isOrgAdmin(currentOrg),
        isAdmin: isAdmin(state.user),
        org: selectedAccount,
        transactions: transactions,
        surveys: surveys,
        accountUsers: numUsers,
        upgradeRequired: upgradeRequired,
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
        orgUsers: values(state.organisations.users[currentOrg]),
        orgCards: values(state.organisations.cards[currentOrg])
    };
})(Settings);
