import React, { Fragment, PureComponent } from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./CancelServiceDialog.module.scss";
import connect from "react-redux/es/connect/connect";

class CancelServiceDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        onSubmit: PropTypes.func,
        orgId: PropTypes.number,
        currentSub: PropTypes.object,
        upgradeTier: PropTypes.object,
    };

    static defaultProps = {
        dialogRef: () => null,
        onSubmit: () => null,
        orgId: null,
        currentSub: null,
        upgradeTier: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            freeTier: false,
        };
    }

    hide = () => {
        this.dialog.hide();
    };

    onSuccess = () => {
        this.setState({
            loading: false,
            error: null
        });
        this.dialog.hide();
    };

    onError = (error) => {
        this.setState({
            loading: false,
            error: error.message,
        });
    };

    onSubmit = () => {
        // this.setState({
        // 	loading: true,
        // });
        //
        // this.props.dispatch(cancelSaaSSubscription({
        // 	orgId: this.props.orgId,
        // 	onSuccess: this.onSuccess,
        // 	onError: this.onError
        // }));
        window.location.href = "mailto:support@stickybeak.co?subject=Please delete my account";
    };
    renderCancelMessage = () => {
        const upgradeTier = this.props.upgradeTier || {};
        const upgradeTierName = upgradeTier.name;
        const currentTier = this.props.currentSub || {};
        const tier = (currentTier.tier || {}).name;

        if (tier && tier !== "FREE") {
            if (upgradeTierName !== "FREE") {
                return (
                    <Fragment>
                        <h3>Deleting your account is permanent.</h3>
                        <p>All of your account and survey data will be destroyed - forever......ever....ever...</p>
                        <p>Why not downgrade your plan to FREE and keep your data instead ;)</p>
                    </Fragment>
                );
            }
        }
        return (
            <Fragment>
                <h3>Deleting your account is permanent.</h3>
                <p>All of your account and survey data will be destroyed - forever......ever....ever...</p>
            </Fragment>
        );
    };


    render() {

        let actions = [
            {
                text: "Back",
                onPress: this.hide,
                props: {
                    plain: true,
                    green: true
                }
            },
            {
                text: "Delete my account",
                onPress: this.onSubmit,
                props: {
                    plain: true,
                    loading: this.state.loading,
                }
            }
        ];

        return (
            <Dialog title={"Are you sure?"}
                    className={styles.cancel_service}
                    actions={actions}
                    actionsClassName={styles.dialog_actions}
                    disableDismiss={this.state.loading}
                    error={this.state.error}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}>
                {this.renderCancelMessage}
            </Dialog>
        );
    }
}

export default CancelServiceDialog = connect()(CancelServiceDialog);
