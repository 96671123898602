/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 8/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { ReactNode } from "react";
import styles from "../ReportSideBar.module.scss";

type Props = {
    left: ReactNode;
    right: ReactNode;
}

export default function TableRow(props: Props) {
    const { left, right } = props;
    return (
        <div className={styles.table_row}>
            <div className={styles.left}>
                <span>{left}</span>
            </div>
            <div className={styles.right}>
                <span>{right}</span>
            </div>
        </div>
    );
}
