import * as Type from "../../actions/v1/audienceMembers";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
    updateAudienceMembers: null,
    audienceSize: 0,
    audienceUploadComplete: false
};

export default function audiences(state = initialState, action = {type: null, audienceId: null, audienceMembers: {}}) {
    switch (action.type) {

        // case Type.SET_AUDIENCE_MEMBER: {
        //     const audienceMember = action.audienceMember;
        //     if (!audienceMember) {
        //         return state;
        //     }
        //
        //     return {
        //         ...state,
        //         [audienceMember.audience]: {
        //             ...(state[audienceMember.audience] || {}),
        //             [audienceMember.id]: audienceMember
        //         },
        //     };
        // }

        case Type.SET_AUDIENCE_MEMBERS: {
            const audienceId = action.audienceId;
            const audienceMembers = action.audienceMembers;

            if (!audienceId || !audienceMembers) {
                return {
                    ...state
                };
            }

            const newState = {...state};
            const audience = cloneDeep(newState[audienceId] || {members: {}, pages: []});
            const newMembers = {...audience.members};
            const items = (audienceMembers.items || []).map((member) => {
                const memberId = member.id;
                newMembers[memberId] = member;
                return memberId;
            });

            const page = {
                items: items,
                cursor: audienceMembers.cursor,
            };

            const pages = (audience.pages || []);
            pages.push(page);

            return {
                ...state,
                [audienceId]: {
                    members: newMembers,
                    pages: pages,
                },
            };
        }

        case Type.CLEAR_AUDIENCE: {

            const audienceId = action.audienceId;
            const audience = cloneDeep(state[audienceId] || {members: {}, pages: []});

            return {
                ...state,
                [audienceId]: {
                    members: {
                        ...audience.members
                    },
                    pages: [],
                },
            }
        }

        case Type.CLEAR_AUDIENCE_MEMBERS:
            let newState = {...state};
            if(!!state[action.audienceId]) {
                delete newState[action.audienceId];
            }
            return newState;

        case Type.REMOVE_AUDIENCE: {

          return state;
        }

        case Type.UPDATE_AUDIENCE_MEMBERS:
            return {
                ...state,
                updateAudienceMembers: action.audienceMembers
            };

        case Type.SET_AUDIENCE_LIST_SIZE:
            return {
                ...state,
                audienceSize: action.audienceSize
            };
        case Type.AUDIENCE_UPLOAD_COMPLETE:
            return {
                ...state,
                audienceUploadComplete: action.bool
            };
        default:
            return state
    }
}