/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 26/11/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from 'react';
import Field from "redux-form/es/Field";
import Select from "./Select";
import PropTypes from "prop-types";

const SelectField = (props) => {
    return (
        <Field {...props} component={Select}/>
    );
};

SelectField.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    options: PropTypes.array,
    native: PropTypes.bool,
};

export default SelectField;
