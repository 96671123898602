/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/model/User";
import { Actions, Types } from "../actions/user";

type State = User | null;

const initialState: State = null;

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function user(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_USER: {
            const { payload } = action as ActionValueTypes<Types.SET_USER>;
            return payload!;
        }
        default: {
            return state;
        }
    }
}