import React from "react";

export default function DeleteTrashcanIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
			<defs>
				<style>
					{`.delete_icon_a{fill:#9ba5a7}.delete_icon_b{fill:#fff;fill-rule:evenodd}`}
				</style>
			</defs>
			<g id="Group_16541" data-name="Group 16541" transform="translate(-1035 -281)">
				<g id="Group_16504" data-name="Group 16504" transform="translate(170 -405)">
					<g id="Group_16503" data-name="Group 16503" transform="translate(865 686)">
						<g id="Group_15230" data-name="Group 15230">
							<circle id="Ellipse_1196" cx="18" cy="18" r="18" className="delete_icon_a" data-name="Ellipse 1196"
									fillOpacity="0.0"/>
						</g>
					</g>
				</g>
				<path id="Path_6872"
					  d="M12.257 6.717v8.171a1.535 1.535 0 0 1-1.537 1.537H2.549a1.535 1.535 0 0 1-1.537-1.537V6.717zM1.537 3.155h1.79V2.9A2.808 2.808 0 0 1 6.146.1h1.013a2.808 2.808 0 0 1 2.82 2.8v.253h1.772a1.535 1.535 0 0 1 1.537 1.537V5.7H0V4.692a1.535 1.535 0 0 1 1.537-1.537zm3.308 0h3.579V2.9a1.287 1.287 0 0 0-.38-.9 1.255 1.255 0 0 0-.9-.38H6.128A1.3 1.3 0 0 0 4.845 2.9v.253zm1.79 5.1a.505.505 0 0 0-.506.506v5.622a.506.506 0 1 0 1.012 0V8.759a.505.505 0 0 0-.506-.506zm2.549 0a.517.517 0 0 0-.506.506v5.622a.506.506 0 1 0 1.012 0V8.759a.494.494 0 0 0-.506-.506zm-5.1 0a.505.505 0 0 0-.506.506v5.622a.506.506 0 1 0 1.012 0V8.759a.517.517 0 0 0-.504-.506z"
					  className="delete_icon_b" data-name="Path 6872" transform="translate(1046 290.875)"/>
			</g>
		</svg>
	)
}