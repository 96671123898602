import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./MediaItem.module.scss";
import UploadImageDialog from "../../../../../../../../../../widgets/dialogs/uploadImageDialog/UploadImageDialog";

export default class MediaItem extends Component {

    static propTypes = {
        source: PropTypes.string,
        contentType: PropTypes.string,
    };

    static defaultProps = {
        source: null,
        contentType: null,
    };

    constructor(props) {
        super(props);

        if (props.input && props.initialValue) {
            props.input.onChange(props.initialValue);
        }

        this.uploadImageDialog = null;
    }

    onEditImagePress = () => {
        this.uploadImageDialog.show();
    };

    onUploadImageSubmit = (url) => {
        this.props.input.onChange(url);
    };

    render() {
        const isImage = this.props.contentType === "IMAGE";
        // const isVideo = this.props.contentType === "VIDEO";

        return (
            <div className={styles.media_item_container}>
                {isImage && (
                    <img src={this.props.input.value} alt={""} onClick={this.onEditImagePress}/>
                )}
                <UploadImageDialog dialogRef={(ref) => this.uploadImageDialog = ref}
                                   onSubmit={this.onUploadImageSubmit}
                />
            </div>
        );
    }
}