import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import * as TemplatesApi from "../api/v1/templates"

export const SET_EMOJI_RATING_TEMPLATES = "templates: SET_EMOJI_RATING_TEMPLATES";

export const setEmojiRatingTemplates = createAction(SET_EMOJI_RATING_TEMPLATES, "templates");

export const getEmojiRatingTemplates = wrapper(TemplatesApi.getEmojiRatingTemplatesAPI, (dispatch, templates)=>{
    dispatch(setEmojiRatingTemplates(templates))
});