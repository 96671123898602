/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import Field from "redux-form/es/Field";
import MessageItem from "./MessageItem";

function MessageItemField(props) {
    return (
        <Field {...props} component={MessageItem} editable={props.editable}/>
    );
}

MessageItemField.propTypes = MessageItem.propTypes;

MessageItemField.defaultProps = MessageItem.defaultProps;

export default MessageItemField;

