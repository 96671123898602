import {
    FREE_TEXT,
    SINGLE_CHOICE,
    EMOJI_RATING,
    RATING,
    LOVE_HATE,
    NPS
} from "../constants/QuestionTypes";

const GREAT_SCORE = 20;
const OK_SCORE = 10;
const BAD_SCORE = 0;

const GREAT = "Great";
const OK = "Ok";
const BAD = "Bad";

export default function getSurveyScore(questions) {
    const questionCount = questions.length;
    let freeTextCount = 0;
    let singleChoiceCount = 0;
    questions.forEach((question) => {
        if (question.type === FREE_TEXT) {
            freeTextCount++;
        } else if (question.type === SINGLE_CHOICE || question.type === EMOJI_RATING || question.type === RATING || question.type === LOVE_HATE || question.type=== NPS ) {
            singleChoiceCount++;
        }
    });
    const nonSingleChoicePercent = (questionCount > 0 ? Math.floor((questionCount - singleChoiceCount) / questionCount * 100) : 0);

    let totalQuestionWordCount = 0;
    let longestQuestionWordCount = 0;
    questions.forEach((question) => {
        const finalChoice = question.content[question.content.length - 1];
        const wordCount = (finalChoice.text || "").split(/\s+/).length;
        if (wordCount >= longestQuestionWordCount) {
            longestQuestionWordCount = wordCount;
        }
        totalQuestionWordCount += wordCount;
    });
    const averageQuestionWordCount = (questionCount > 0 ? Math.floor(totalQuestionWordCount / questionCount) : 0);

    const questionMetrics = {
        numberQuestions: {
            score: questions.length,
            text: "",
        },
        averageQuestionWordCount: {
            score: averageQuestionWordCount,
            text: "",
        },
        freeTextCount: {
            score: freeTextCount,
            text: "",
        },
        longestQuestion: {
            score: longestQuestionWordCount,
            text: "",
        },
        nonSingleChoicePercentage: {
            score: nonSingleChoicePercent,
            text: "",
        }
    };

    let overallScore = 0;
    const {
        numberQuestions: { score: num },
        averageQuestionWordCount: { score: avg },
        freeTextCount: { score: free },
        longestQuestion: { score: long },
        nonSingleChoicePercentage: { score: nonSingle }
    } = questionMetrics;

    // score number of questions
    if (num < 7) {
        overallScore += GREAT_SCORE;
        questionMetrics.numberQuestions.text = GREAT;
    } else if (num < 11) {
        overallScore += OK_SCORE;
        questionMetrics.numberQuestions.text = OK;
    } else if (num >= 11) {
        overallScore += BAD_SCORE;
        questionMetrics.numberQuestions.text = BAD;
    }

    // score average words per question
    if (avg < 11) {
        overallScore += GREAT_SCORE;
        questionMetrics.averageQuestionWordCount.text = GREAT;
    } else if (avg > 10 && avg < 16) {
        overallScore += OK_SCORE;
        questionMetrics.averageQuestionWordCount.text = OK;
    } else if (avg >= 16) {
        overallScore += BAD_SCORE;
        questionMetrics.averageQuestionWordCount.text = BAD;
    }

    // score free text question count
    if (!free) {
        overallScore += GREAT_SCORE;
        questionMetrics.freeTextCount.text = GREAT;
    } else if (free === 1) {
        overallScore += OK_SCORE;
        questionMetrics.freeTextCount.text = OK;
    } else if (free > 1) {
        overallScore += BAD_SCORE;
        questionMetrics.freeTextCount.text = BAD;
    }

    // score longest question
    if (long < 26) {
        overallScore += GREAT_SCORE;
        questionMetrics.longestQuestion.text = GREAT;
    } else if (long > 25 && long < 36) {
        overallScore += OK_SCORE;
        questionMetrics.longestQuestion.text = OK;
    } else if (long >= 36) {
        overallScore += BAD_SCORE;
        questionMetrics.longestQuestion.text = BAD;
    }

    // score percentage not multi-choice single answer
    if (!nonSingle) {
        overallScore += GREAT_SCORE;
        questionMetrics.nonSingleChoicePercentage.text = GREAT;
    } else if (nonSingle > 0 && nonSingle < 31) {
        overallScore += OK_SCORE;
        questionMetrics.nonSingleChoicePercentage.text = OK;
    } else if (nonSingle >= 31) {
        overallScore += BAD_SCORE;
        questionMetrics.nonSingleChoicePercentage.text = BAD;
    }

    questionMetrics.overallScore = overallScore;
    return questionMetrics;
}
