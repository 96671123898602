import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import {showSuccess} from "./snackbars";
import * as AudiencesAPI from "../api/v1/audiences";

export const SET_AUDIENCE = "audience:SET_AUDIENCE";
export const SET_AUDIENCES = "audience:SET_AUDIENCES";
export const REMOVE_AUDIENCE = "audience:REMOVE_AUDIENCE";


export const setAudience = createAction(SET_AUDIENCE, "audience");
export const setAudiences = createAction(SET_AUDIENCES, "audiences");
export const removeAudience = createAction(REMOVE_AUDIENCE, "audience");

export const createAudience = wrapper(AudiencesAPI.createAudienceAPI, (dispatch, audience) => {
    dispatch(showSuccess("Successfully created audience."));
    dispatch(setAudience(audience));
});

export const updateAudience = wrapper(AudiencesAPI.updateAudienceAPI, (dispatch, audience) => {
    dispatch(showSuccess("Successfully update audience."));
    dispatch(setAudience(audience));
});

export const deleteAudience = wrapper(AudiencesAPI.deleteAudienceAPI, (dispatch, audience) => {
    dispatch(showSuccess("Successfully deleted audience."));
    dispatch(setAudience(audience));
});

export const getAudiencesForOrganisation = wrapper(AudiencesAPI.getAudiencesForOrganisationAPI, (dispatch, audience) => {
    dispatch(setAudiences(audience));
});

export const createAudienceColumn = wrapper(AudiencesAPI.createAudienceColumnAPI, (dispatch, audience) => {
   dispatch(setAudience(audience));
});

export const deleteAudienceColumn = wrapper(AudiencesAPI.deleteAudienceColumnAPI, (dispatch, audience) => {
    dispatch(setAudience(audience));
});