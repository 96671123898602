import PropTypes from "prop-types";
import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { formValueSelector } from "redux-form";
import { FREE_TEXT } from "../../../../../../../../../../constants/QuestionTypes";
import classNames from "classnames";
import { SurveyStatus } from "../../../../../../../../../../types/model/Survey";
import styles from "./EndingQuestionBlock.module.scss";
import MessageItem from "../../../../../../../../../widgets/messageItem/MessageItem";
import Paper from "@mui/material/Paper";

class EndingQuestionBlock extends Component {

    static propTypes = {
        index: PropTypes.number,
        name: PropTypes.string,

        onConfirmDeleteSurveyRow: PropTypes.func
    };

    static defaultProps = {
        index: null,
        name: null,

    };

    state = {
        deleteRow: false,
        deleteRows: []
    };

    getStyle = () => {
        let endingStyle;
        if (this.props.endingQuestion.id === -1001) {
            endingStyle = styles.question_container_full;
        }
        if (this.props.endingQuestion.id === -1002) {
            endingStyle = styles.question_container_half_left;
        }
        if (this.props.endingQuestion.id === -1006) {
            endingStyle = styles.question_container_half_right_top;
        }
        if (this.props.endingQuestion.id === -1007) {
            endingStyle = styles.question_container_half_right_bottom;
        }
        return endingStyle;
    };

    render() {
        const survey = this.props.survey;
        const { status } = survey;
        const isLive = status === SurveyStatus.LIVE;
        const choices = this.props.endingQuestion.choices || [];


        const className = classNames(styles.question_container, this.getStyle());
        let i = 0;
        return (
            <div className={className}>
                <div className={styles.question_content_outer}>
                    <div className={styles.right}>
                        <div className={styles.question_block}>
                            <div className={styles.messages_wrapper}>
                                <div className={styles.question_content}>
                                    <div className={styles.question_content_wrapper_inner}>
                                        <div className={styles.question_content}>
                                            <div className={styles.agent_icon} />
                                            <div className={styles.content_messages}>
                                                <div className={styles.content_wrapper}>
                                                    <div className={styles.content_message_wrapper}>
                                                        <div className={styles.question_bubble}>
                                                            {this.props.endingQuestion.multilineContent ?
                                                                this.props.endingQuestion.multilineContent.map((content) => {
                                                                        if (i === 0 && this.props.hideFirstLine) {
                                                                            i += 1;
                                                                            return null;
                                                                        }
                                                                        i += 1;
                                                                        return (
                                                                            <div
                                                                                className={styles.content_bubble}>
                                                                                <div
                                                                                    className={styles.question_block}>
                                                                                    <Paper
                                                                                        className={styles.text_bubble}>
                                                                    <span
                                                                        className={styles.question_text}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: content
                                                                        }} />
                                                                                    </Paper>
                                                                                </div>


                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                                :
                                                                <div
                                                                    className={styles.content_bubble}>
                                                                    <div
                                                                        className={styles.question_block}>
                                                                        <Paper
                                                                            className={styles.text_bubble}>
                                                                    <span
                                                                        className={styles.question_text}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: this.props.endingQuestion.content
                                                                        }} />
                                                                        </Paper>
                                                                    </div>


                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.selection_bubble_container}>
                                                    {this.props.endingQuestion.type === "FREE_TEXT" &&
                                                        <div className={styles.choice_wrapper}>
                                                            <MessageItem editable={false}
                                                                         clickable={false}
                                                                         right
                                                                         classNameText={styles.choice_text}
                                                                         containerClassName={styles.choice_bubble}
                                                                         placeholder={"Free text response..."} />
                                                        </div>
                                                    }
                                                    {choices.map((choice, i) => {
                                                        return (
                                                            <div key={i} className={styles.choice_wrapper}>
                                                                <MessageItem
                                                                    placeholder={choice.value}
                                                                    containerClassName={styles.choice_bubble}
                                                                    elementId={`${this.props.name}.value`}
                                                                    classNameText={styles.choice_text}
                                                                    right
                                                                    editable={false}
                                                                    clickable={false}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");
    const org = state.organisations.accounts[state.settings.organisation];
    const survey = state.form.editSurvey;
    const surveyType = props.survey.type;
    let tier = org.subscription.tier.name;
    const isEnterprise = state.organisations.accounts[state.settings.organisation].accountType === "ENTERPRISE";
    // let tier = "FREE"
    const upgradeRequired = (tier === "FREE" && surveyType !== "FACEBOOK" && !isEnterprise);
    return {
        question: selector(state, props.name) || state.surveyEnding[props.index],
        surveyQuestions: selector(state, "questions"),
        upgradeRequired: upgradeRequired
    };
})(EndingQuestionBlock);
