/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Types from "../../actions/v1/auth";

const initialState = {
    loading: true,
    onboardPassword: false,
};

export default function auth(state = initialState, action = {type: null}) {
    switch (action.type) {

        case Types.SET_AUTH_LOADING: {
            return {
                ...state,
                loading: !!action.loading,
            }
        }

        case Types.SHOW_ONBOARD_DIALOG: {
            return {
                ...state,
                onboardPassword: !!action.onboardPassword,
            }
        }

        default: {
            return state;
        }
    }
}
