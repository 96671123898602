import styles from "../uploadImageDialog/UploadImageDialog.module.scss";
import uploadIcon from "../../../../resources/svg/upload_icon.svg";
import Dialog from "../../dialog/Dialog";
import React, { useState } from "react";


export const UploadVideoDialog = (props) => {

    const [loading, setLoading] = useState(false)

    const onFileChange = async (f) => {
        setLoading(true)
        const formData = new FormData()
        formData.append("inputFile", f.target.files[0])
        await props.onSubmit(formData)
        setLoading(false)
    }
    return (
        <Dialog title={loading ? "Your video is uploading, this may take a minute..." : "Upload a video"}
                rootClassName={styles.dialog}
            // actions={actions}
            // actionToHide={onCancelPress}
                loading={loading}
                ref={props.dialogRef}>
            <div className={styles.file_input_label}>Upload video</div>
            <label className={styles.file_input}>
                Click here to upload video
                <input type={"file"} onChange={onFileChange} accept={"video/*"} multiple={false}/>
                <img src={uploadIcon} alt={""}/>
            </label>
        </Dialog>
    )
}