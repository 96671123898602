import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ToggleSwitch.module.scss";
import Switch from "@mui/material/Switch";
import withStyles from "@mui/styles/withStyles";

class ToggleSwitch extends Component {

    static propTypes = {
        label: PropTypes.string,
        labelClassName: PropTypes.string,
        className: PropTypes.string,
        switchClassName: PropTypes.string,
        error: PropTypes.string,
        controlled: PropTypes.bool,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        label: null,
        labelClassName: null,
        className: null,
        switchClassName: null,
        error: null,
        controlled: false,
        checked: false,
        onChange: () => null,
    };

    state = {
        checked: false,
    };

    handleChange = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            this.props.onChange(this.state.checked);

            if (!!this.props.input) {
                this.props.input.onChange(this.state.checked);
            }
        });
    };

    render() {

        const classes = this.props.classes;
        const className = classnames(styles.switch_wrapper, this.props.className);
        const labelClassName = classnames(styles.switch_label, this.props.labelClassName);

        let error = this.props.error;

        if (!!this.props.meta && !!this.props.meta.error) {
            error = this.props.meta.error;
        }

        let checked = this.state.checked;

        if (!!this.props.controlled) {
            checked = this.props.checked;
        }

        return (
            <div className={className}>
                <h4 className={labelClassName}>{this.props.label}</h4>
                <Switch checked={checked}
                        onChange={this.handleChange}
                        value={checked}
                        classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked,
                        }}
                        disableRipple />
                <h4 className={labelClassName}>{this.props.subLabel}</h4>
                {!!error ? <label className={styles.error_text}>{error}</label> : null}
            </div>
        );
    }
}

const styleOverride = theme => ({
    iOSSwitchBase: {
        "&$iOSChecked": {
            color: theme.palette.common.white,
            "& + $iOSBar": {
                backgroundColor: "#8DD800",
            },
        },
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: "translateX(15px)",
        "& + $iOSBar": {
            opacity: 1,
            border: "none",
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: "solid 1px",
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

export default withStyles(styleOverride)(ToggleSwitch);
