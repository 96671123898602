import moment from "moment";
import {SurveyType} from "../types/model/Survey";

export default function getSurveyCardDetails(survey, respondentCount){
    const {name: surveyName, startedAt, type, desiredSampleSize, status, targeting} = survey;
    const cardDetails = {
        respondentsLine1: "",
        respondentsLine2: "",
        dailyAdSetSpend: "",
        progressBarValue: 0,
        surveyType: "",
        timeElapsed: moment(survey.createdAt).fromNow(true),
        timeRemaining: "",
        budget: 'N/A',
        tags: [],
        unchargedSurvey: false,
        representativeLocation: "",
    }
    let tags = {
        locations: [],
        age: [],
        gender: "",
        interests: []
    }

    if (type === SurveyType.FACEBOOK) {
        if (targeting.representativePreset) {
            cardDetails.surveyType = "National audience"
            cardDetails.representativeLocation = targeting.representativePreset
        } else {
            cardDetails.surveyType = "Niche audience";
        }
        cardDetails.budget = `USD $${Math.floor((survey.maxSpend) / 100).toFixed(2)}`;
        cardDetails.dailyAdSetSpend = `USD $${Math.floor((survey.targeting.dailyAdSetSpend) / 100).toFixed(2)}`;
    } else if (type === SurveyType.AUDIENCE) {
        cardDetails.surveyType = "Email list";
        cardDetails.budget = `N/A`;
    } else if (type === SurveyType.LINK) {
        cardDetails.surveyType = "Shareable link";
        cardDetails.timeRemaining = "N/A";
    }

    targeting.geoLocations.map((location)=>{

        tags.locations = [...tags.locations, `${location.locations[0].country+ (location.locations[0].region?": ":"")||""}${!!location.locations[0].region?location.locations[0].region+"; ":""}${!!location.locations[0].city?location.locations[0].city:""}`]
    })
    targeting.detailed.map((interest)=>{
        tags.interests =[...tags.interests, `${interest.name}`]
    })
    if(targeting.genders[0] ==="MALE" && targeting.genders.length === 1){
        tags.gender = "Men"
    }else if (targeting.genders[0] === "FEMALE"){
        tags.gender = "Women"
    }else{
        tags.gender = "Men & Women"
    }
    targeting.ageGroups.map((ageGroup)=>{
        tags.age = [...tags.age, `${ageGroup.minAge} - ${ageGroup.maxAge === 65? "65+" : ageGroup.maxAge}`]
    })
    cardDetails.tags=tags

    if (!desiredSampleSize) {
        cardDetails.respondentsLine1 = `${respondentCount}`;
        cardDetails.respondentsLine2 = "Completes";
        cardDetails.progressBarValue = 100;
    } else if (respondentCount >= desiredSampleSize) {
        cardDetails.respondentsLine1 = "Done";
        cardDetails.respondentsLine2 = `${respondentCount} of ${desiredSampleSize}`;
        cardDetails.progressBarValue = 100;
    } else {
        cardDetails.respondentsLine1 = `${respondentCount}`;
        cardDetails.respondentsLine2 = `(of ${desiredSampleSize})`
        cardDetails.progressBarValue = (respondentCount / desiredSampleSize) * 100;
    }

    if(survey.freeSurveyLaunch) {
        cardDetails.unchargedSurvey = true;
    }
    return cardDetails
}