/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-28.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import PropTypes from "prop-types";

export default function GreenTickCircle(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79" {...props}>
            <defs>
                <style>{`.a{fill:#8dd800;}.b{fill:#fff;}`}</style>
            </defs>
            <g transform="translate(13396 11141)">
                <circle className="a" cx="39.5" cy="39.5" r="39.5" transform="translate(-13396 -11141)"/>
                <path className="b" d="M31.47-8.123l-22,27.154L-6.813,3.956-3.479.328,8.927,11.749l18.7-22.977Z" transform="translate(-13368.5 -11105.59)"/>
            </g>
        </svg>
    );
}

GreenTickCircle.propTypes = {
    className: PropTypes.string,
};

GreenTickCircle.defaultProps = {
    className: null,
};