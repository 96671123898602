/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-05-14.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SelectAudienceCard.module.scss";
import classnames from "classnames";
import Button from "../../../../../../../../../../widgets/button/Button";
import Card from "../../../../../../../../../../widgets/card/Card";
import bluePadlock from "../../../../../../../../../../../resources/svg/padlock-blue-circle.svg";
import yellowPadlock
    from "../../../../../../../../../../../resources/svg/padlock-yellow-circle.svg";

class SelectAudienceCard extends Component {
    render() {
        const className = classnames(styles.select_audience_card, this.props.className, {
            [styles.selected]: this.props.isSelected,
        });
        const isSelected = this.props.isSelected;
        return (
            <Card className={className}>
                <div className={styles.top}>
                    {this.props.top}
                </div>
                <div className={styles.content}>
                    <div className={styles.text}>
                        <h2>{this.props.header}</h2>
                        <p>{this.props.summary}</p>
                    </div>

                    <div className={styles.buttons}>
                        {this.props.buttonId !== "upgrade_prompt" &&
                            <Button id={this.props.buttonId}
                                    plain={isSelected}
                                    borderGreyMid={isSelected}
                                    green={!isSelected}
                                    disabled={isSelected}
                                    loading={this.props.loading}
                                    onPress={this.props.onSelectPress}>Select</Button>
                        }{this.props.buttonId === "upgrade_prompt" &&
                        <div onClick={this.props.onClick}
                             className={styles.upgrade_prompt}>
                            <div className={styles.padlocks}>
                                <img src={bluePadlock} alt={"blue padlock"} />
                                <img className={styles.yellow_padlock}
                                     src={yellowPadlock} alt={"yellow padlock"} />
                            </div>
                            <span>
                                Upgrade plan to generate QR code surveys
                            </span>
                        </div>
                    }
                        {this.props.onInfoPress && this.props.buttonId !== "upgrade_prompt" ?
                            <Button plain onPress={this.props.onInfoPress}>Info</Button> : null}
                        {this.props.buttonId === "upgrade_prompt" ?
                            <a target={"_blank"} href={"https://stickybeak.co/pricing"}>Learn more
                                about our plans</a> : null}
                    </div>
                </div>
            </Card>
        );
    }
}

SelectAudienceCard.propTypes = {
    className: PropTypes.string,
    buttonId: PropTypes.string,
    isSelected: PropTypes.bool,

    top: PropTypes.any,

    header: PropTypes.string,
    summary: PropTypes.string,

    onSelectPress: PropTypes.func,
    onInfoPress: PropTypes.func,
};

SelectAudienceCard.defaultProps = {
    className: null,
    isSelected: false,

    top: null,

    header: "",
    summary: "",

    onSelectPress: () => null,
    onInfoPress: () => null,
};

export default SelectAudienceCard;
