import React from "react";
import PropTypes from "prop-types";
import styles from "./Card.module.scss";
import classnames from "classnames";
import Paper from "@mui/material/Paper";

export default function Card(props) {
    const className = classnames(styles.card, props.className);
    return (
        <Paper {...props} className={className} component={props.component}>
            {props.children}
        </Paper>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    component: PropTypes.string,
};

Card.defaultProps = {
    className: null,
    component: "div",
};
