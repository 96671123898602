/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-28.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import styles from "./SignUp.module.scss";
import {Route, Switch} from "react-router-dom"
import Routes from "../../../../../constants/routes";
import SignUp from "./routes/signUp/SignUp";
import Payment from "./routes/payment/Payment";

export default class SignUpRoot extends Component {
    render() {
        return (
            <Switch className={styles.sign_up} location={this.props.location}>
                <Route path={Routes.Onboarding.SignUp.Payment} component={Payment}/>
                <Route path={Routes.Onboarding.SignUp.root} component={SignUp}/>
            </Switch>
        );
    }
}