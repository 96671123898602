/*eslint-disable @typescript-eslint/no-explicit-any*/
import instance from "./instance";

export default function post(url: string, data?: unknown, params?: unknown): Promise<any> {
    return instance
        .post(url, data, {
            params,
        })
        .then((data) => data.data);
}
