import * as Types from "../../actions/v1/templates"

const initialState = {};

export default function emojiTemplates(state=initialState, action){
    switch(action.type){
        case Types.SET_EMOJI_RATING_TEMPLATES: {
            const templates = action.templates;
            const emojiTemplates = Object.values(templates)
            function sortTemplates(a, b) {
                if (a.choices.length > b.choices.length) {
                    return -1
                }
                if (a.choices.length < b.choices.length) {
                    return 1
                }
                if (a.choices.length===b.choices.length){

                    if (a.responseType < b.responseType) {
                        return 1
                    }
                    if(a.responseType > b.responseType){
                        return -1
                    }
                    if (a.responseType === b.responseType){
                        if(a.iconType<b.iconType){
                            return -1
                        }
                        if(a.iconType>b.iconType){
                            return 1
                        }
                    }
                }
                return 1
            }
            emojiTemplates.sort(sortTemplates)
            const templateState = {...state}
            emojiTemplates.forEach((template) => {
                templateState[template.id] = template;
            })
            return templateState;
        }
        default:{
            return state;
        }

    }
}