/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-28.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component } from "react";
import styles from "./Verification.module.scss";
import Card from "../../../../widgets/card/Card";
import GreenTickCircle from "../../../../../resources/js/GreenTickCircle";
import Button from "../../../../widgets/button/Button";
import { signOut } from "../../../../../redux/actions/v1/user";
import connect from "react-redux/es/connect/connect";
import Routes from "../../../../../constants/routes";
import { sendVerificationEmail } from "../../../../../redux/actions/user";

class Verification extends Component {

    state = {
        loading: false,
        linkSent: false,
        disabled: true
    };

    componentDidMount() {
        window.setTimeout(() => {
            this.setState({ disabled: false });
        }, 60000);
    }

    onSignIntoDifferentAccountPress = () => {
        this.props.dispatch(signOut());
        this.props.history.push(Routes.Onboarding.Login);
    };

    onResendVerificationLinkPress = () => {
        this.setState({
            loading: true,
        });

        window.firebase.auth().currentUser.getIdToken().then((t) => {
            this.props.dispatch(sendVerificationEmail(t)).then((r) => {
                this.setState({ linkSent: true, loading: false });
            }).catch((error => this.setState({ linkSent: true, loading: false })));
        });
    };

    render() {
        return (
            <Card className={styles.verification}>
                <GreenTickCircle className={styles.green_tick_circle} />
                <h2>Account Activation</h2>
                <p>Please check your email to activate your account.</p>
                {/*<div className={styles.tip}>Tip: you can only send yourself 1 verification email per minute.</div>*/}

                {this.state.linkSent ? <p>Verification link sent.</p> :
                    <Button loading={this.state.loading} disabled={this.state.disabled}
                            onPress={this.onResendVerificationLinkPress}>
                        Resend Verification Link
                    </Button>
                }
                <Button plain onPress={this.onSignIntoDifferentAccountPress}>Sign into different Account</Button>
                <a href={"mailto:support@stickybeak.co?subject=I can't log in to my account"} style={{color: "black"}}>Trouble logging in?</a>
            </Card>
        );
    }
}


export default Verification = connect((state) => {
    return {};
})(Verification);
