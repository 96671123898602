/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Kyle
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";

export default function DeleteBubbleIcon(props) {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="11.313" height="11.313" viewBox="0 0 11.313 11.313">
            <g id="Group_16953" data-name="Group 16953" transform="translate(-905.843 -520.843)">
                <line id="Line_854" data-name="Line 854" x2="12" transform="translate(907.257 522.257) rotate(45)" fill="none" stroke="#485991" strokeLinecap="round" strokeWidth="2"/>
                <line id="Line_855" data-name="Line 855" x2="12" transform="translate(915.743 522.257) rotate(135)" fill="none" stroke="#485991" strokeLinecap="round" strokeWidth="2"/>
            </g>
        </svg>
    );
}

