

export function createAudienceMembersAPI(token, orgId, audienceId, data) {
    return window.post(`/v1/accounts/${orgId}/audiences/${audienceId}/members`, token, data);
}

export function createAudienceMembersFromCSVAPI(token, orgId, audienceId, data) {
    return window.post(`/v1/accounts/${orgId}/audiences/${audienceId}/members/csv`, token, data);
}

export function getAudienceMembersAPI(token, orgId, audienceId, search, cursor) {

    const params = {
        search: search,
        cursor: cursor
    };

    return window.get(`/v1/accounts/${orgId}/audiences/${audienceId}/members`, token, params);
}

export function updateAudienceMembersAPI(token, orgId, audienceId, data) {
    return window.put(`/v1/accounts/${orgId}/audiences/${audienceId}/members`, token, data);
}

export function deleteAudienceMembersAPI(token, orgId, audienceId, data) {
    return window.del(`/v1/accounts/${orgId}/audiences/${audienceId}/members`, token, data);
}

export function getAudienceListSizeAPI(token, orgId, audienceId) {
    return window.get(`/v1/accounts/${orgId}/audiences/${audienceId}/count`, token);
}