import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './Content.module.scss';
import classnames from "classnames";
import Header from "../header/Header";
import Loader from "../loader/Loader";

class Content extends Component {

    static propTypes = {
        containerClassName: PropTypes.string,
        className: PropTypes.string,

        headerText: PropTypes.string,
        headerButtons: PropTypes.array,
        headerActions: PropTypes.node,
        sideContent: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.node,
            PropTypes.element
        ]),
        sideContentTitle: PropTypes.string,
        sideContentClass: PropTypes.string,
        sideContentOrganisations: PropTypes.string,
        sideContentRight: PropTypes.bool,

        loading: PropTypes.bool,
        sideContentLoading: PropTypes.bool,

        shouldComponentUpdate: PropTypes.bool,

    };

    static defaultProps = {
        containerClassName: null,
        containerClasses: [],
        className: null,
        classes: [],

        headerText: null,
        headerButtons: [],
        headerActions: null,
        sideContent: null,
        sideContentTitle: null,
        sideContentClass: null,
        sideContentRight: false,

        loading: false,
        sideContentLoading: false,
        shouldComponentUpdate: true,
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.shouldComponentUpdate;
    }

    renderSideContent() {
        const SideContent = this.props.sideContent;
        const sideContentClass = classnames(styles.side_content, this.props.sideContentClass);
        const isReactClass = SideContent instanceof Component.constructor;

        return (
            <div className={sideContentClass}>
                {!!this.props.sideContentTitle ? <h2>{this.props.sideContentTitle}</h2> : null}
                {this.props.sideContentLoading ?
                    <div className={styles.loader}>
                        <Loader large/>
                    </div> : isReactClass ?
                        <SideContent/> : React.isValidElement(SideContent) ? SideContent : typeof SideContent === "function" ? SideContent() : null}
            </div>
        )
    }

    render() {
        const contentClassName = classnames(styles.content, this.props.containerClassName);
        const className = classnames(styles.inner, {
            [styles.no_side_bar_inner]: !this.props.sideContent,
        }, this.props.className);

        return (
            <div className={contentClassName}>
                {!!this.props.headerText ? <Header className={styles.header} text={this.props.headerText} buttons={this.props.headerButtons}/> : null}
                {!!this.props.headerActions ? <Header className={styles.header} actions={this.props.headerActions} buttons={this.props.headerButtons} /> : null}
                <div className={className}>
                    {!!this.props.sideContent && !this.props.sideContentRight && this.renderSideContent()}
                    <div className={styles.children_wrapper}>
                        {this.props.loading ?
                            <div className={styles.loader}>
                                <Loader large/>
                            </div> : this.props.children}
                    </div>
                    {!!this.props.sideContent && this.props.sideContentRight && this.renderSideContent()}
                </div>
            </div>
        );
    }
}

export default Content;