import * as Types from "../../actions/v1/settings";
import * as pusher from "../../../constants/pusher";
import moment from "moment-timezone";

const initialState = {
    organisation: null,
    currency: "USD",
    timezone: moment.tz.guess(true),
    saasSignUp: {signUp: false, tier: ""}
};

export default function settings(state = initialState, action) {
    switch (action.type) {

        case Types.SET_ACTIVE_ORGANISATION: {
            const orgId = action.orgId;

            if (!orgId) {
                return state;
            }

            // const organisation = store.getState().organisations.accounts[orgId];
            //
            // let currency = initialState.currency;
            // if (!!organisation && !!organisation.currency) {
            //     currency = organisation.currency;
            // }

            pusher.subscribeOrganisation(orgId);
            return {
                ...state,
                organisation: orgId,
            }
        }

        case Types.SET_CURRENCY: {
            const currency = action.currency;

            if (!currency) {
                return state;
            }

            return {
                ...state,
                currency: currency,
            }
        }

        case Types.SET_TIMEZONE: {
            const timezone = action.timezone;

            if (!timezone) {
                return state;
            }

            return {
                ...state,
                timezone: timezone,
            }
        }

        case Types.SET_SAAS_SIGN_UP:{
            const saasSignUp = action.saasSignUp
            if(!saasSignUp.signUp){
                return {
                    ...state,
                    saasSignUp: {signUp: false, tier: ""}
                }
            }
            return {
                ...state,
                saasSignUp: saasSignUp
            }
        }

        default: {
            return state
        }
    }
}