import Survey from "../../types/model/Survey";
import SurveysAPI from "./api/surveys";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import PaginatedSurvey from "../../types/model/PaginatedSurveys";

export enum Types {
    SET_SURVEY = "surveys:SET_SURVEY",
    SET_DRAFT_SURVEYS = "surveys:SET_DRAFT_SURVEYS",
    SET_COMPLETED_PAGINATED_SURVEYS = "surveys:SET_COMPLETED_PAGINATED_SURVEYS",
    SET_PAGINATED_SURVEYS = "surveys:SET_PAGINATED_SURVEYS",
    REMOVE_SURVEY = "surveys:REMOVE_SURVEY",
    DUPLICATE_SURVEY = "surveys:DUPLICATE_SURVEY",
    COMPLETE_SURVEY ="surveys:COMPLETE_SURVEY"
}

export const setSurvey = createAction<Survey>(Types.SET_SURVEY);
export const setDraftSurveys = createAction<Survey[]>(Types.SET_DRAFT_SURVEYS);
export const setPaginatedSurveys = createAction<PaginatedSurvey>(Types.SET_PAGINATED_SURVEYS);
export const setCompletedPaginatedSurveys = createAction<PaginatedSurvey>(Types.SET_COMPLETED_PAGINATED_SURVEYS);
export const removeSurvey = createAction<number>(Types.REMOVE_SURVEY);
export const duplicateSurveyAction = createAction<Survey>(Types.DUPLICATE_SURVEY);
export const completeSurveyAction = createAction<Survey>(Types.COMPLETE_SURVEY);

export const Actions = {
    [Types.SET_SURVEY]: setSurvey,
    [Types.SET_DRAFT_SURVEYS]: setDraftSurveys,
    [Types.SET_PAGINATED_SURVEYS]: setPaginatedSurveys,
    [Types.SET_COMPLETED_PAGINATED_SURVEYS]: setCompletedPaginatedSurveys,
    [Types.REMOVE_SURVEY]: removeSurvey,
    [Types.DUPLICATE_SURVEY]: duplicateSurveyAction,
    [Types.COMPLETE_SURVEY]: completeSurveyAction
};
export type Actions = typeof Actions;

export const updateSurvey = wrapper(SurveysAPI.updateSurvey, {
    action: setSurvey,
});


export const updateSurveyDailyAdSetSpend = wrapper(SurveysAPI.updateSurveyDailyAdSetSpend, {
    action: setSurvey,
});

export const updateSurveyMaxSpend = wrapper(SurveysAPI.updateSurveyMaxSpendAPI, {
    action: setSurvey
})

export const addFundsToSurvey = wrapper(SurveysAPI.addFundsToSurveyAPI, {
    action: setSurvey
})

export const updateSurveyDailyCampaignSpend = wrapper(SurveysAPI.updateSurveyDailyCampaignSpend, {
    action: setSurvey,
});

export const updateSurveyScore = wrapper(SurveysAPI.updateSurveyScore, {
    action: setSurvey
});

export const updateSurveyLanguage = wrapper(SurveysAPI.updateSurveyLanguage, {
    action: setSurvey
});
