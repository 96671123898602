import put from "./util/put";
import get from "./util/get";
import post from "./util/post";

function updatePriceEstimateAPI(accountId: number, surveyId: number, estimate: any) {
    return put(`/v1/accounts/${accountId}/surveys/${surveyId}/estimate/score`, estimate);
}

function getPriceEstimateAPI(accountId: number, surveyId: number) {
    return get(`/v1/accounts/${accountId}/surveys/${surveyId}/estimate`);
}

function updateSurveyCostAPI(accountId:number, surveyId: number, dto: {budget: number, costPerRespondent: number}){
    return post(`/v1/accounts/${accountId}/surveys/${surveyId}/estimate-update`, dto)
}

function createOrUpdateEstimateAPI(surveyId: number, data: any, params: any) {
    return post(`/v1/facebook/pricing/estimate/survey/${surveyId}`, data, params);
}

const EstimateAPI = {
    updatePriceEstimateAPI,
    getPriceEstimateAPI,
    createOrUpdateEstimateAPI,
    updateSurveyCostAPI
};

export default EstimateAPI;
