import classNames from "classnames";
import { FieldInputProps } from "formik";
import React from "react";
import { ChangeEvent } from "./Cleave";
import styles from "./Input.module.scss";
import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";

export type SelectOption = {
    label: string;
    value: string | null;
};

export type SelectOptions = SelectOption[];

export type InputProps = {
    options?: SelectOption[];
    value: string | number | undefined | null;
    initialValue?: string | number | undefined | null;
    field?: FieldInputProps<string | number>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    maxlength?: string
} & Omit<TextFieldProps, "name" | "value" | "variant" | "onChange">;

export default function Input(props: InputProps) {
    const { options, field, SelectProps, maxlength, ...otherProps } = props;
    let { value } = props;

    const isSelect = !!options?.length;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e);
    };

    const className = classNames(styles.input, props.className);
    value = value ?? "";
    return (
        <TextField
            {...field}
            {...otherProps}
            className={className}
            value={value}
            select={isSelect}
            onChange={onChange}
            inputProps={{
                maxLength: maxlength
            }}
            SelectProps={{
                fullWidth: true,
                ...SelectProps,
            }}>
            {isSelect &&
            options!.map(({ label, value: optionValue }) => (
                <MenuItem key={optionValue || "null"} value={optionValue || "null"}>
                    {label}
                </MenuItem>
            ))}
        </TextField>
    );
}
