import React, {Component} from "react"
import PropTypes from "prop-types"
import styles from "./CommentBlock.module.scss"
import FieldArray from "redux-form/es/FieldArray";
import QuestionContent from "../questionContent/QuestionContent";
import IconButton from "../../../../../../../../../widgets/iconButton/IconButton";
import connect from "react-redux/es/connect/connect";
import {formValueSelector} from "redux-form";
import AddRow from "../addRow/AddRow";
import CreateSurveyRowDialog from "../../../../../../../../../widgets/dialogs/createSurveyRowDialog/CreateSurveyRowDialog";
import DeleteTrashcanIcon from "../../../../../../../../../../resources/js/DeleteTrashcanIcon";
import DeleteSurveyRowDialog from "../../../../../../../../../widgets/dialogs/deleteSurveyRowDialog/DeleteSurveyRowDialog";
import {Animated} from "react-animated-css/lib/index";


class CommentBlock extends Component {

    static propTypes = {
        formName: PropTypes.string,
        name: PropTypes.string,
        index: PropTypes.number,
        onAddNewRowSubmit: PropTypes.func,
        onDeleteRowPress: PropTypes.func,
        onConfirmDeleteSurveyRow: PropTypes.func,
    };

    static defaultProps = {
        formName: null,
        name: null,
        index: null,
        onAddNewRowSubmit: () => null,
        onDeleteRowPress: () => null,
    };

    onDeleteRowPress = () => {
        this.deleteSurveyRowDialog.show()
    };

    onAddNewRowPress = () => {
        this.createRowDialog.show()
    };

    renderEditorTools = () => {
        return (
            <IconButton text={"Delete"}
                        textGrey
                        onPress={this.onDeleteRowPress}
                        className={styles.button}>
                <DeleteTrashcanIcon/>
            </IconButton>
        )
    };
    onConfirmDeleteSurveyRow = () => {
        this.props.onDeleteRowPress(this.props.index);
        this.deleteSurveyRowDialog.hide()
    };

    render() {
        // const questionNumber = `Question ${this.props.index + 1}`;
        const isComment = this.props.question.type === "COMMENT";

        return (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                <div className={styles.question_container}>
                    <div className={styles.question_content}>
                        <div className={styles.left}>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.question_block}>
                                <div className={styles.messages_wrapper}>
                                    <FieldArray name={`${this.props.name}.content`}
                                                component={QuestionContent}
                                                isComment={isComment}/>
                                </div>
                                <div className={styles.tools_wrapper}>
                                    {this.renderEditorTools()}
                                </div>
                            </div>
                            <AddRow text={"Add Question"} onAddNewQuestionPress={this.onAddNewRowPress}/>
                        </div>
                    </div>


                    <CreateSurveyRowDialog dialogRef={(ref) => this.createRowDialog = ref}
                                           rowIndex={this.props.index}
                                           onSubmit={this.props.onAddNewRowSubmit}/>
                    <DeleteSurveyRowDialog dialogRef={(ref) => this.deleteSurveyRowDialog = ref}
                                           onSubmit={this.onConfirmDeleteSurveyRow}/>
                </div>
            </Animated>
        )
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey");

    return {
        question: selector(state, props.name),
        numberOfQuestions: (selector(state, "questions")).length
    }
})(CommentBlock);
