import classnames from "classnames";
import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./AddContactsToAudienceDialog.module.scss";
import Dialog from "../../dialog/Dialog";
import CSVReader from 'react-csv-reader';

export default class AddContactsToAudienceDialog extends Component {

    static propTypes = {
        className: PropTypes.string,
        dialogRef: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        data: PropTypes.array,
    };

    static defaultProps = {
        className: null,
        dialogRef: () => null,
        onClose: () => null,
        data: [],
    };

    state = {
        loading: false,
    };

    hide = () => {
        this.dialog.hide();
    };

    onClose = () => {
        this.setState({
            loading: false,
            data: null
        });
        this.props.onClose();
    };

    onCSVLoaded = (data) => {
        let trimmedData = [];
        data.map((d) => {
            let newObject = {};
            Object.keys(d).map((key) => {
                newObject[key.trim()] = d[key];
                return null;
            });
            trimmedData.push(newObject);
            return null;
        });

        this.setState({
            data: trimmedData,
            loading: true
        }, () => {

        });

        this.props.onUpload(trimmedData);
    };

    onCSVErrorLoaded = () => {
        alert("error uploading CSV");
    };

    render() {
        return (
            <Dialog
                title={"Upload Respondents"}
                ref={(ref) => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}
                disableDismiss={this.state.loading}
                loading={this.state.loading}
                onClose={this.onClose}
                className={styles.audience_dialog}
                actions={[
                    {
                        text: "Cancel", onPress: this.hide, props: {plain: true}
                    }]}>
                <div className={classnames(styles.column, styles.upload_contact)}>
                    <p>Click on the button to upload a .CSV file</p>
                    <CSVReader
                        onFileLoaded={this.onCSVLoaded}
                        onError={this.onCSVErrorLoaded}
                        inputId="csvField"
                        parserOptions={{header: true}}
                        cssClass={styles.csv_reader_wrapper}
                        cssInputClass={styles.csv_reader_input}
                    />
                </div>
            </Dialog>

        );
    }
}