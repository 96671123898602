/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import QuestionReport from "../../types/model/QuestionReport";
import SurveyReport from "../../types/model/SurveyReport";
import { Actions, Types } from "../actions/reports";
import SurveyFiltering from "../../types/model/SurveyFiltering";

type State = {
    surveys: {
        [key: number]: SurveyReport | null;
    };
    questions: {
        [key: number]: QuestionReport | null;
    };
    filtering: {
        [key: number]: SurveyFiltering | null;
    };
};

const initialState: State = {
    surveys: {},
    questions: {},
    filtering:{}
}

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function reports(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_SURVEY_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_SURVEY_REPORT>;
            const { surveyId, report } = payload!;
            const surveyReports = { ...state.surveys };
            surveyReports[surveyId] = report;

            return {
                ...state,
                surveys: surveyReports,
            };
        }
        case Types.SET_QUESTION_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_QUESTION_REPORT>;
            const { questionId, report } = payload!;

            const questionReports = { ...state.questions };
            questionReports[questionId] = report;
            return {
                ...state,
                questions: questionReports,
            };
        }
        case Types.SET_SURVEY_FILTERING: {
            const { payload } = action as ActionValueTypes<Types.SET_SURVEY_FILTERING>;
            const { surveyId, report } = payload!;

            const surveyFiltering = { ...state.filtering };
            surveyFiltering[surveyId] = report;

            return {
                ...state,
                filtering: surveyFiltering,
            };
        }
        default: {
            return state;
        }
    }
}
