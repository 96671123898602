/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 25/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import Field from "redux-form/es/Field";
import TextInput from "./TextInput";

export default class TextInputField extends Component {

    static propTypes = TextInput.propTypes;

    static defaultProps = TextInput.defaultProps;

    render() {
        return (
            <Field {...this.props} component={TextInput}/>
        );
    }
}
