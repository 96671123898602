import React, { Component } from "react"
import styles from "./RenderSelectionBubbles.module.scss";
import TooltipIcon from "../../../../../../../../../../resources/js/TooltipIcon";
import PropTypes from "prop-types";
import MessageItemField from "../../../../../../../../../widgets/messageItem/MessageItemField";
import DeleteBubbleIcon from "../../../../../../../../../../resources/js/DeleteBubbleIcon";
import { connect } from "react-redux";
import { setSurveyFormFocused } from "../../../../../../../../../../redux/actions/v1/surveyFormState";
import { formValueSelector } from "redux-form";

class ChoiceBubble extends Component {

    static propTypes = {
        addNewChoice: PropTypes.func,
    };

    static defaultProps = {
        addNewChoice: () => null,
    };

    state = {
        hideError: true
    };

    onDeletePress = () => {
        this.props.onDeleteChoicePress(this.props.index)
    };

    onFocus = () => {
        this.props.dispatch(setSurveyFormFocused(true))
        this.setState({ hideError: true })
    };

    onBlur = () => {
        this.props.dispatch(setSurveyFormFocused(false))
        this.setState({ hideError: false })
    };

    render () {
        let logic;
        const nextQuestion = this.props.choice.nextQuestion
        let nextOrdinal;
        if(nextQuestion){
            nextOrdinal = (this.props.questions.find(q=>(q.id||"").toString()===nextQuestion.toString())||{}).ordinal
        }

        const qualifyingChoice = this.props.choice.qualifyingChoice
        const terminatingChoice = this.props.choice.terminatingChoice || this.props.choice.completingChoice;
        if (nextQuestion){
            logic = nextOrdinal+1
        }else if(qualifyingChoice){
            logic = "Q"
        } else if(terminatingChoice){
            logic = "End"
        }
        return (
            <div className={styles.choice_wrapper}>
                <MessageItemField
                    placeholder={"..."}
                    containerClassName={styles.choice_bubble}
                    elementId={`${this.props.name}.value`}
                    classNameText={styles.choice_text}
                    name={`${this.props.name}.value`}
                    editable
                    right
                    autoFocus={this.props.autoFocus}
                    // onKeyDown={(e) => this.props.onKeyDown(e)}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    hideError={this.state.hideError}
                />
                {this.props.index > 0
                    ? <div className={styles.delete_button}
                           onClick={this.onDeletePress}><DeleteBubbleIcon/>
                    </div>
                    : null
                }
                {((nextQuestion && nextOrdinal) || qualifyingChoice || terminatingChoice) &&
                    <div className={styles.logic_indicator}>
                    <div className={styles.dotted_line}/>
                    <div className={styles.indicator}>{logic}</div>
                </div>}
            </div>
        )
    }
}

class RenderSelectionBubbles extends Component {
    constructor (props) {
        super(props);
        this.state = {
            enableAutoFocus: false
        }
    }

    componentDidMount () {
        this.setState({ enableAutoFocus: true });
    }

    addNewChoice = () => {
        this.props.fields.push({
            value: ""
        })
    };

    onDeleteChoicePress = (index) => {
        this.props.fields.remove(index)
    };

    // onKeyDown = (e) => {
    //     e.stopPropagation();
    //     if (e.key === "Enter" || e.keyCode === 13) {
    //         e.preventDefault();
    //         this.props.fields.push({});
    //     }
    // };

    render () {

        return (
            <div className={styles.selection_bubble_container}>
                {this.props.fields.map((name, i) => {
                    return (
                        <ChoiceBubble key={name}
                                      name={name}
                                      choice={this.props.fields.get(i)}
                                      index={i}
                                      autoFocus={this.state.enableAutoFocus}
                                      dispatch={this.props.dispatch}
                                      questions={this.props.questions}
                            // onKeyDown={this.onKeyDown}
                                      onDeleteChoicePress={this.onDeleteChoicePress}
                                      addNewChoice={this.addNewChoice}/>
                    )
                })}
                {this.props.fields.length ? null : <span className={styles.no_response_bubbles}>Add option</span>}

                <div className={styles.add_response_button} onClick={this.addNewChoice}>
                    <TooltipIcon/>
                </div>

                {/*<Button*/}
                {/*className={styles.choice_button}*/}
                {/*round*/}
                {/*blueDark*/}
                {/*onPress={this.addNewChoice}*/}
                {/*>+*/}
                {/*</Button>*/}
            </div>
        )
    }
}

export default connect((state, props) => {
    const selector = formValueSelector("editSurvey")
    const questions = selector(state, "questions");
    return {
        question: props.question,
        questions
    }
})(RenderSelectionBubbles);
