import React, { Component } from "react";
import Dialog from "../../dialog/Dialog";
import styles from "./UpdateMaxSpendDialog.module.scss";
import Input from "../../input/Input";
import { connect } from "react-redux";
import { addFundsToSurvey, updateSurveyMaxSpend } from "../../../../redux/actions/surveys";
import { showError, showSuccess } from "../../../../redux/actions/v1/snackbars";
import Button from "../../button/Button";
import { addOrganisationCard, createUpdateSaaSSubscription } from "../../../../redux/actions/v1/organisations";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CreditCardForm from "../../creditCardForm/CreditCardForm";
import Select from "../../select/Select";
import CreditCardDialog from "../creditCardDialog/CreditCardDialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { loadStripe } from "@stripe/stripe-js";

class UpdateMaxSpendDialog extends Component {
    hide = () => {
        this.dialog.hide();
    };
    initialState = {
        spendCap: 0,
        loading: false,
        selectedCard: (this.props.cards || [])[0] || "",
        error: null,
    };

    ccRef = React.createRef();

    state = { ...this.initialState };

    onAddFunds = async (spendCap) => {
        try {
            await this.props.dispatch(updateSurveyMaxSpend(
                this.props.surveyId,
                spendCap * 100,
                this.state.selectedCard !== "nocard"
            ));
            this.props.dispatch(showSuccess("Survey spend cap successfully updated."));
        } catch ( e ) {
            this.props.dispatch(showError(e.message));
        }
    };

    onCCSubmit = async () => {
        this.setState({ loading: true });
        if ( this.state.selectedCard !== "nocard" ) {
            try {
                if ( !this.state.selectedCard && this.ccRef ) {
                    this.ccRef.getCreditCardToken();
                } else {
                    await this.props.dispatch(addFundsToSurvey({
                        amount: this.state.spendCap * 100,
                        currency: "USD",
                        card: this.state.selectedCard,
                    }, this.props.orgId, this.props.surveyId));
                    await this.onAddFunds(Number(this.state.spendCap) + Number(this.props.currentMaxSpend) / 100);
                    this.setState({ loading: false });
                    this.props.dialogRef.current.hide();
                }

            } catch ( e ) {
                this.onCCError(e);
            }
        } else {
            await this.onAddFunds(Number(this.state.spendCap) + Number(this.props.currentMaxSpend) / 100);
            this.setState({ loading: false });
            this.props.dialogRef.current.hide();
        }
    };

    onCCError = (error) => {
        this.setState({
            error: error.message,
            loading: false
        });
        this.props.dispatch(showError(error.message));
    };

    onCCSuccess = (token) => {
        if ( !this.props.cards ) {
            this.props.dispatch(addOrganisationCard({
                accountId: this.props.orgId,
                stripeCardToken: token,
                onError: this.onCCError,
                onSuccess: () => {

                    this.props.dispatch(addFundsToSurvey({
                        amount: this.state.spendCap,
                        currency: this.props.currency,
                        card: this.state.selectedCard,
                    }, this.props.orgId, this.props.surveyId));
                    ;

                }
            }));
        } else {
            this.props.dispatch(addFundsToSurvey({
                amount: this.state.spendCap,
                currency: "USD",
                card: this.state.selectedCard,
            }, this.props.orgId, this.props.surveyId));
            ;
        }


    };

    onInputChange = (e) => {
        const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
        if ( regexp.test(e.target.value.slice(1)) ) {
            this.setState({ spendCap: e.target.value.slice(1) });
        }

    };

    onCCSelect = (card) => {
        this.setState({ selectedCard: card });
    };

    onAddCardPress = () => {
        this.props.dialogRef.current.hide();
        this.ccDialogRef.show();
    };

    onAddCardSuccess = () => {
        this.ccDialogRef.hide();
        this.props.dialogRef.current.show();
    };

    render () {
        let cards;

        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        if ( !!this.props.cards ) {
            cards = Object.values(this.props.cards)
                .map(card => {
                    return {
                        value: card.id,
                        label: (!!card.name ? card.name : "Card ending in " + card.last4),
                        description: (!!card.name ? card.name : "Card ending in " + card.last4)
                    };
                });
        }
        if ( this.props.isAdmin ) {
            cards = [...(cards || []), {
                value: "nocard",
                label: "Skip card payment",
                description: "Skip card payment"
            }];
        }
        return (
            <>
                <Dialog
                    rootClassName={styles.dialog_root}
                    containerClassName={styles.dialog_container}
                    className={styles.dialog}
                    contentClassName={styles.add_funds_dialog}
                    custom
                    fullWidth={false}
                    error={this.props.error}
                    ref={this.props.dialogRef}>
                    <div className={styles.header}>
                        <span className={styles.header_text}>Add funds</span>
                        <div className={styles.close_wrapper}>
                            <IconButton onClick={() => this.props.dialogRef.current.hide()}
                                        disabled={this.state.loading}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <span className={styles.label}>
                            Add funds
                        </span>
                        <Input value={"$" + this.state.spendCap}
                               className={styles.input_field}
                               onChange={(e) => this.onInputChange(e)}/>
                    </div>
                    {!cards && (
                        <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => (
                                    <CreditCardForm onSuccess={this.onCCSuccess}
                                                    onError={this.onCCError}
                                                    ccRef={(ref) => this.ccRef = ref}
                                                    className={styles.cc_form} shown
                                                    stripe={stripe}
                                                    elements={elements}
                                                    selectedAccount={this.props.account}
                                                    orgId={this.props.orgId} showPoweredBy={false}/>
                                )}
                            </ElementsConsumer>
                        </Elements>
                    )}

                    {cards && !this.state.loading && (
                        <Select label={"Select Card"}
                                onChange={(card) => this.onCCSelect(card)}
                                labelClassName={styles.card_select_label}
                                value={this.state.selectedCard}
                                className={styles.card_select} options={cards}
                        />
                    )}
                    <div className={styles.buttons}>
                        <Button className={styles.button} green onPress={this.onCCSubmit}
                                disabled={!this.state.selectedCard}
                                loading={this.state.loading}>
                            Pay
                        </Button>
                        <Button className={styles.button} white loading={this.state.loading}
                                onPress={this.onAddCardPress}>
                            Add card
                        </Button>
                    </div>


                </Dialog>
                <Elements stripe={stripePromise}>
                    <CreditCardDialog dialogRef={ref => this.ccDialogRef = ref} orgId={this.props.orgId}
                                      onCardSuccess={this.onAddCardSuccess}/>
                </Elements>
            </>
        );
    }
}

export default connect((state, props) => {
    const selectedOrg = state.settings.organisation;
    return {
        orgId: selectedOrg,
        account: state.organisations.accounts[selectedOrg],
        cards: state.organisations.cards[selectedOrg]
    };
})(UpdateMaxSpendDialog);
