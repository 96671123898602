import React, { Component } from "react";
import styles from "./Score.module.scss";
import Header from "../../../../../../../widgets/header/Header";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import redKey from "../../../../../../../../resources/png/score_red.png";
import yellowKey from "../../../../../../../../resources/png/score_yellow.png";
import greenKey from "../../../../../../../../resources/png/score_green.png";
import Metric from "./components/metric";
import { connect } from "react-redux";
import _values from "lodash/values";
import getSurveyProps from "../../../../../../../../constants/getSurveyProps";
import getSurveyScore from "../../../../../../../../util/getSurveyScore";
import {
    createOrUpdateEstimate
} from "../../../../../../../../redux/actions/estimate";
import fromCents from "../../../../../../../../constants/localization/currencies/fromCents";
import isRepresentativeSurvey from "../../../../../../../../util/isRepresentativeSurvey";
import Loader from "../../../../../../../widgets/loader/Loader";
import sendEventToActiveCampaign from "../../../../../../../../redux/actions/api/v1/eventTracking";

class Score extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        document.getElementById("divToScroll").scrollIntoView();
        if (!isRepresentativeSurvey(this.props.survey) && this.props.survey.type === "FACEBOOK") {
            this.props.dispatch(createOrUpdateEstimate(this.props.survey.id, this.props.survey.targeting)).then((r) => {
                this.setState({ loading: false });
            });
        } else {
            this.setState({ loading: false });
        }
        sendEventToActiveCampaign(this.props.user.email, "Score_viewed", "Score viewed")

    }

    render() {
        const questionMetrics = getSurveyScore(this.props.surveyQuestions);
        const overallScore = questionMetrics.overallScore;

        const scoreCategory = {
            great: {
                headerText: "Snappy - good to go!",
                summaryText: "Nice work! Keeping this score high means your respondents will be more fully engaged and you’re more likely to run a successful project!",
                circleStyles: {
                    pathColor: `#8DD800`,
                    textColor: `#8DD800`,
                    trailColor: `#E8F7CC`,
                    backgroundColor: `#E8F7CC`,
                }
            },
            ok: {
                headerText: "Good - but room for improvement",
                summaryText: "Not bad, but see if you can improve your survey so respondents have a better, faster experience and are even more inclined to give you the results you’re after.",
                circleStyles: {
                    pathColor: `#FFDE54`,
                    textColor: `#FFDE54`,
                    trailColor: `#FFF9DD`,
                    backgroundColor: `#FFF9DD`,
                }
            },
            bad: {
                headerText: "Borrrrrring - high risk!",
                summaryText: "People won’t tell you what you want to know if we put them to sleep with a difficult survey. We need to improve this by making it shorter and snappier so your respondents aren’t nodding off 😴 as they answer your questions.",
                circleStyles: {
                    pathColor: `#FF5454`,
                    textColor: `#FF5454`,
                    trailColor: `#FFE1DE`,
                    backgroundColor: `#FFE1DE`,
                }
            }
        };

        let headerText;
        let summaryText;
        let circleStyles;
        if (overallScore > 89) {
            headerText = scoreCategory.great.headerText;
            summaryText = scoreCategory.great.summaryText;
            circleStyles = scoreCategory.great.circleStyles;
        } else if (overallScore > 49) {
            headerText = scoreCategory.ok.headerText;
            summaryText = scoreCategory.ok.summaryText;
            circleStyles = scoreCategory.ok.circleStyles;
        } else {
            headerText = scoreCategory.bad.headerText;
            summaryText = scoreCategory.bad.summaryText;
            circleStyles = scoreCategory.bad.circleStyles;
        }

        return (
            <div className={styles.main} id={"divToScroll"}>
                {this.state.loading ? <Loader /> : (
                    <>
                        <div className={styles.content}>
                            <Header text={"How well will my survey perform?"} className={styles.header} />

                            <div className={styles.score_summary}>
                                <div className={styles.summary_content}>
                                    <div className={styles.two_col}>
                                        <div className={styles.circular_progress_bar}>
                                            <div className={styles.progress_bar}>
                                                <CircularProgressbar
                                                    value={overallScore}
                                                    text={`${overallScore}`}
                                                    strokeWidth={7}
                                                    background={true}
                                                    styles={buildStyles({
                                                            pathTransitionDuration: 0.5,
                                                            ...circleStyles,
                                                        }
                                                    )}
                                                />
                                            </div>

                                            <div className={styles.key}><img src={redKey} alt={"Red key"} width="16"
                                                                             height="16" /> 0-49
                                            </div>
                                            <div className={styles.key}><img src={yellowKey} alt={"Yellow key"}
                                                                             width="16"
                                                                             height="16" /> 50-89
                                            </div>
                                            <div className={styles.key}><img src={greenKey} alt={"Green key"} width="16"
                                                                             height="16" /> 90-100
                                            </div>
                                        </div>

                                        <div className={styles.summary_text}>
                                            <h3 onClick={this.onContinueSelect}>{headerText}</h3>
                                            <p>{summaryText}</p>
                                            {!isRepresentativeSurvey(this.props.survey) &&
                                            this.props.survey.type === "FACEBOOK" &&
                                            this.props.estimate &&
                                            this.props.estimate.estNumberRespondents > 0 &&
                                            this.props.estimate.estCostPerRespondent > 0 &&
                                            !!this.props.estimate.prevEstCostPerRespondent &&
                                            (
                                                <div className={styles.price_change_alert}>
                                                    <div className={styles.header_row}>
                                                        <div className={styles.emoji}>👻</div>
                                                        <div className={styles.header_text}>
                                                            Surveys that score poorly cost more to run because fewer
                                                            people
                                                            stick with them.
                                                        </div>
                                                    </div>
                                                    <div className={styles.price_change_table}>
                                                        <div className={styles.price_row}>
                                                            <div className={styles.price_label}>
                                                                Previous cost per respondent
                                                            </div>
                                                            <div className={styles.price_amount}>
                                                        <span
                                                            className={styles.strike_through}>{fromCents(this.props.estimate.prevEstCostPerRespondent, "USD", true)}</span>
                                                            </div>
                                                        </div>
                                                        <div className={styles.price_row}>
                                                            <div className={styles.price_label}>
                                                                Updated cost per respondent
                                                            </div>
                                                            <div className={styles.price_amount}>
                                                        <span
                                                            className={styles.colour_red}>{fromCents(this.props.estimate.estCostPerRespondent, "USD", true)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.scroll_down}>
                                Scroll down for full report
                            </div>

                            <div className={styles.full_report}>
                                <div className={styles.header_line}>
                                    <h2>Your survey score</h2>
                                    <div className={styles.recommended_key}>
                                        Recommended
                                    </div>
                                </div>

                                <div className={styles.report_container}>
                                    <Metric label="Number of questions"
                                            score={questionMetrics.numberQuestions.text}
                                            value={questionMetrics.numberQuestions.score}
                                            baseline="6 questions"
                                            brackets={[6, 10]}
                                            dataTip='Fewer questions will raise the completion rate of your survey and be less taxing for respondents. Try to focus on just the things you really need to know, or the key information you need for decision-making. You can always run another survey later to help unpick more complex issues.' />
                                    <Metric label="Number of free text questions"
                                            score={questionMetrics.freeTextCount.text}
                                            value={questionMetrics.freeTextCount.score}
                                            baseline="0 free text questions"
                                            brackets={[0, 1]}
                                            dataTip='Wherever possible provide users with a list of answers to choose from. This makes the survey easier for them to do, and the results simpler for you to interpret. If you do use open-ended questions try to keep them to a minimum.' />
                                    <Metric label="Avg. words per question"
                                            score={questionMetrics.averageQuestionWordCount.text}
                                            value={questionMetrics.averageQuestionWordCount.score}
                                            baseline="10 words"
                                            brackets={[10, 15]}
                                            dataTip='Keeping your questions short and snappy means a better experience for users. The quicker they can read and understand your question the more likely they are to engage. Clear, simple questions also tend to get you better results.' />
                                    <Metric label="% of survey that's not multi-choice"
                                            score={questionMetrics.nonSingleChoicePercentage.text}
                                            value={questionMetrics.nonSingleChoicePercentage.score}
                                            baseline="0%"
                                            brackets={[0, 30]}
                                            dataTip='Keeping your surveys quick and easy to answer hugely increases engagement and completion rates. Simple, single-answer mutichoice questions tend to work best on Stickybeak. Keeping the percentage of other question types low will really help your survey.' />
                                    <Metric label="Longest question word count"
                                            score={questionMetrics.longestQuestion.text}
                                            value={questionMetrics.longestQuestion.score}
                                            baseline="25 words"
                                            brackets={[25, 35]}
                                            dataTip='Many users complete Stickybeak surveys on their phones where screen real estate is at a premium. Questions that are too wordy tend to put respondents off. Trimming down your longest questions can help improve survey completion.' />
                                </div>
                            </div>

                            <div className={styles.tips_section}>
                                <h2>More ideas to help get more responses</h2>
                                <div className={styles.tips}>
                                    <div className={styles.tip_block}>
                                        <h3>Make a good first impression</h3>
                                        <p>For best results, try to keep your questions short. Stickybeak surveys are
                                            conversational, and conducted in a chat-style format.</p>
                                    </div>
                                    <div className={styles.tip_block}>
                                        <h3>Multi-choice first question</h3>
                                        <p>Avoid open-ended questions at the start of your survey. Studies show this
                                            reduces
                                            completion. Instead, start with an easy-to-answer multi-choice “warmup”
                                            question.</p>
                                    </div>
                                    <div className={styles.tip_block}>
                                        <h3>Less is more</h3>
                                        <p>Respondents will see how many questions you’re asking before they begin. If
                                            there are
                                            too many, they may not even start!</p>
                                    </div>
                                    <div className={styles.tip_block}>
                                        <h3>Keep responses short</h3>
                                        <p>Keep response options short (2-3 words at most)</p>
                                    </div>
                                    <div className={styles.tip_block}>
                                        <h3>What is a good response rate?</h3>
                                        <p>It’s not so much about “how many?” as “who?”. Short, engaging surveys tend to
                                            attract
                                            the best and most representative responders online.</p>
                                    </div>
                                    <div className={styles.tip_block}>
                                        <h3>Utilise rows</h3>
                                        <p>We don’t recommend it, but if you must use preamble to a question, make use
                                            of “rows”
                                            by breaking content up into easy-to-read chunks.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

        );
    }
}

export default connect((state, props) => {
    const surveyProps = getSurveyProps(state, props);
    const estimate = state.estimate;
    const user = state.user
    return {
        surveyQuestions: _values(surveyProps.activeQuestions) || [],
        survey: surveyProps.survey,
        estimate: estimate,
        user
    };
})(Score);
