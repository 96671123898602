/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 24/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import styles from "./Onboarding.module.scss";
import {Redirect, Route, Switch} from "react-router-dom"
import Routes from "../../../constants/routes";
import Login from "./routes/login/Login";
import SignUp from "./routes/signUp/SignUp";
import connect from "react-redux/es/connect/connect";
import Verification from "./routes/verification/Verification";
import Payment from "./routes/signUp/routes/payment/Payment";

class Onboarding extends Component {

    render() {

        const currentUser = window.firebase.auth().currentUser;
        return (
            <div className={styles.onboarding_container}>
                <Switch location={this.props.location}>
                    <Route path={Routes.Onboarding.SignUp.Payment} component={Payment}/>
                    {/*{!!currentUser && !currentUser.emailVerified ? <Route path={Routes.Onboarding.Verification} component={Verification}/> : null}*/}
                    <Route path={Routes.Onboarding.SignUp.root} component={SignUp}/>
                    <Route path={Routes.Onboarding.Login} component={Login}/>
                    <Redirect from={"*"} to={Routes.Onboarding.Login}/>
                </Switch>
            </div>
        );
    }
}

export default Onboarding = connect((state) => {
    return {
        user: state.user,
    };
})(Onboarding);
