import React, {Component} from "react";
import PropTypes from "prop-types";
import fromCents from "../../../../../../../../../../constants/localization/currencies/fromCents";
import styles from "../SummaryCard.module.scss";
import {v4 as uuid} from "uuid";
import connect from "react-redux/es/connect/connect";
import Card from "../../../../../../../../../widgets/card/Card";
import CreditCardSelectDialog
    from "../../../../../../../../../widgets/dialogs/creditCardSelectDialog/CreditCardSelectDialog";
import {AccountType} from "../../../../../../../../../../types/model/StickybeakAccount";

class RepresentativeSummary extends Component {

    static propTypes = {
        survey: PropTypes.object,
        questions: PropTypes.number,
        skipLogicQuestions: PropTypes.number,
        validatingQuestionCount: PropTypes.number,
        audienceType: PropTypes.string,
        selectedCard: PropTypes.string,
        onCardSelect: PropTypes.func,
        onAddCard: PropTypes.func,
    };

    static defaultProps = {
        survey: {},
        skipLogicQuestions: null,
        validatingQuestionCount: null,
        audienceType: null,
        audienceMembers: [],
        selectedCard: ""
    };

    onOpenDialog = () => {
        this.cardSelectDialog.show();
    }

    onCloseDialog = () => {
        this.cardSelectDialog.hide();
    }

    renderRow = (label, value) => {
        return (
            <div className={styles.card_row}>
                <div className={styles.card_row_main}>
                    <h4>{label}</h4>
                    <div>
                        {
                            Array.isArray(value)
                                ? value.map((v) => <p key={uuid()}>{v}</p>)
                                : value
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderRegionalDetails = (country) => {
        switch (country) {
            case "US": {
                return (
                    <>
                        {this.renderRow("Division 1: New England", "47 (4.71%)")}
                        {this.renderRow("Division 2: Mid-Atlantic", "127 (12.11%)")}
                        {this.renderRow("Division 3: East North Central", "143 (14.46%)")}
                        {this.renderRow("Division 4: West North Central", "65 (6.46%)")}
                        {this.renderRow("Division 5: South Atlantic", "202 (20.01%)")}
                        {this.renderRow("Division 6: East South Central", "59 (5.94%)")}
                        {this.renderRow("Division 7: West South Central", "119 (11.75%)")}
                        {this.renderRow("Division 8: Mountain", "74 (7.30%)")}
                        {this.renderRow("Division 9: Pacific", "162 (16.27%)")}
                        {this.renderRow("Total respondents", "1,000")}
                        {this.renderRow("Total", `${fromCents(500000, "USD", true)}`)}
                    </>
                )
            }
            case "UK": {
                return (
                    <>
                        {this.renderRow("North East", "44 (4.19%)")}
                        {this.renderRow("North West", "118 (11.25%)")}
                        {this.renderRow("Yorkshire and The Humber", "88 (8.39%)")}
                        {this.renderRow("East Midlands", "74 (7.05%)")}
                        {this.renderRow("West Midlands", "93 (8.87%)")}
                        {this.renderRow("East", "98 (9.34%)")}
                        {this.renderRow("London", "135 (12.87%)")}
                        {this.renderRow("South East", "142 (13.54%)")}
                        {this.renderRow("South West", "89 (8.48%)")}
                        {this.renderRow("Wales", "52 (4.96%)")}
                        {this.renderRow("Scotland", "87 (8.29%)")}
                        {this.renderRow("Northern Ireland", "29 (2.76%)")}
                        {this.renderRow("Total respondents", "1,000")}
                        {this.renderRow("Total", `${fromCents(500000, "USD", true)}`)}
                    </>
                )
            }
            case "AU": {
                return (
                    <>
                        {this.renderRow("New South Wales", "320 (32.02%)")}
                        {this.renderRow("Victoria", " 255 (25.46%)")}
                        {this.renderRow("Queensland", "199 (19.91%)")}
                        {this.renderRow("South Australia", "73 (7.27%)")}
                        {this.renderRow("Western Australia", "105 (10.50%)")}
                        {this.renderRow("Tasmania/Australian Capital Territory/Northern Territory", "48 (4.84%)")}
                        {this.renderRow("Total respondents", "1,000")}
                        {this.renderRow("Total", `${fromCents(500000, "USD", true)}`)}
                    </>
                )
            }
            case "NZ": {
                return (
                    <>
                        {this.renderRow("Northland/ Waikato/ BOP/ Gisborne", "119 (19.93%)")}
                        {this.renderRow("Auckland", "196 (32.71%)")}
                        {this.renderRow("Wellington", "67 (11.13%)")}
                        {this.renderRow("Hawkes Bay/ Taranaki/ Manawatu-Wanganui", "67 (11.12%)")}
                        {this.renderRow("Canterbury", "78 (13.08%)")}
                        {this.renderRow("West Coast/ Otago/ Southland/ Tasman/ Nelson/ Marlborough", "72 (12.02%)")}
                        {this.renderRow("Total respondents", "600")}
                        {this.renderRow("Total", `${fromCents(300000, "USD", true)}`)}
                    </>
                )
            }
            default: {
                return null;
            }
        }
    }

    render() {
        const ageGroupsArray = [
            "18 - 39",
            "40 - 59",
            "60+"
        ];
        const country = this.props.survey.targeting.representativePreset;
        const genderPercentages = {
            US: {
                females: "51.52%",
                males: "48.48%"
            },
            UK: {
                females: "51.48%",
                males: "48.52%"
            },
            AU: {
                females: "51.12%",
                males: "48.88%"
            },
            NZ: {
                females: "52.07%",
                males: "47.93%"
            }
        };

        const selectedCard = this.props.cards ? this.props.cards[this.props.selectedCard] : null;

        return (
            <>
                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Survey details</h3>
                    {this.renderRow("Name", this.props.survey.name)}
                    {this.renderRow("Questions", this.props.questions)}
                    {this.renderRow("Skip logic", this.props.skipLogicQuestions)}
                    {this.renderRow("Validating questions", this.props.validatingQuestionCount)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience details</h3>
                    {this.renderRow("Audience type", this.props.audienceType)}
                    {this.renderRow("Country", country)}
                    {this.renderRow("Age groups", ageGroupsArray)}
                    {this.renderRow("Females", genderPercentages[country].females)}
                    {this.renderRow("Males", genderPercentages[country].males)}
                </Card>

                <Card className={styles.summary_card}>
                    <h3 className={styles.summary_header}>Audience size and budget</h3>
                    {this.renderRow("Find me respondents in these areas")}
                    {this.renderRegionalDetails(country)}
                    {this.props.isEnterprise && (
                        <div className={styles.card_row}>
                            <div className={styles.card_row_main}>
                                <div>
                                    <h4>Payment method</h4>
                                    <span className={styles.change_payment_text}
                                          onClick={this.onOpenDialog}>Change</span>
                                </div>
                                <div>
                                    {
                                        selectedCard
                                            ? (
                                                <>
                                                    <h4>{selectedCard.name}</h4>
                                                    <h4>●●●● ●●●● ●●●● {`${selectedCard.last4}`}</h4>
                                                </>
                                            )
                                            : <h4>No card selected</h4>
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                </Card>

                <CreditCardSelectDialog dialogRef={(ref) => this.cardSelectDialog = ref}
                                        submitCard={(id) => this.props.onCardSelect(id)}
                                        onAddCard={this.props.onAddCard}
                                        initialValue={this.props.selectedCard}
                                        cards={this.props.cards}/>
            </>
        )
    }
}

export default connect((state) => {
    const orgId = state.settings.organisation;
    const selectedAccount = state.organisations.accounts[orgId];

    return {
        priceEstimate: state.estimate,
        cards: state.organisations.cards[orgId],
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
    }
})(RepresentativeSummary);
