import * as Types from "../../actions/v1/questions";

const initialState = {};
export default function surveyEnding(state = initialState, action) {

    switch (action.type) {
        case Types.SET_SURVEY_ENDING: {
            const surveyEndingQuestions = action.endings
            return {
                ...state,
                defaultEndings: surveyEndingQuestions
            }
        }
        case Types.SET_CUSTOM_SURVEY_ENDING: {
            const customEndingQuestions = action.customSurveyEndings
            const currentSurveyId = customEndingQuestions.survey
            const endings = {}
            customEndingQuestions.forEach(ending => {
                endings[ending.survey] = ending
            })
            return {
                ...state,
                customEndings: endings
            }
        }
        case Types.REMOVE_CUSTOM_SURVEY_ENDING: {
            const currentEndings = {...state}
            const customEndingQuestion = action.customSurveyEnding
            const currentSurveyId = customEndingQuestion.survey
            delete currentEndings.customEndings[currentSurveyId]
            return {
                ...state,
                customEndings: currentEndings
            }
        }

        default: {
            return state
        }
    }
}