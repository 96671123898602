import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreditCard.module.scss";
import classnames from "classnames";
import Card from "../card/Card";
import Button from "../button/Button";

export default class CreditCard extends Component {

    static propTypes = {
        className: PropTypes.string,
        card: PropTypes.object,
        cardName: PropTypes.string,
        onEditCardPress: PropTypes.func,
        onRemoveCardPress: PropTypes.func,
        onSetDefaultCardPress: PropTypes.func,
        isDefault: PropTypes.bool,
        loading: PropTypes.bool
    };
    static defaultProps = {
        className: null,
    };

    render() {
        const className = classnames(styles.credit_card, this.props.className);
        const card = (this.props.card || {});
        const cardName = card.name;
        const last4 = card.last4;
        const cardExpiry = `${card.expMonth < 10 ? "0" : ""}${card.expMonth}/${(card.expYear).toString()
            .slice(2)}`;
        return (
            <Card className={className}
                  style={this.props.isDefault ? {backgroundColor: "#F1F3F2"} : {}}>
                <div className={styles.card_name_wrapper}>
                    <div className={styles.card_name}>
                        <span className={styles.header}>{cardName || "Credit card"}</span>
                        <Button plain labelClassName={styles.label}
                                onPress={this.props.onEditCardPress}
                                disabled={this.props.loading}>Edit</Button>
                    </div>
                    <div className={styles.center}>
                    <span className={styles.card_number}>
                        <span className={styles.dots}>●●●● ●●●● ●●●● </span>
                        <span className={styles.last4}>{last4}</span>
                    </span>
                        <span className={styles.details_text}>{cardExpiry}</span>
                        <div
                            className={styles.button_group}
                            >
                            <Button
                                labelClassName={this.props.isDefault ? styles.label_selected : styles.label}
                                plain active={this.props.isDefault}
                                onPress={this.props.onSetDefaultCardPress}
                                className={this.props.isDefault ? styles.button : ""}
                                loading={this.props.loading}>{this.props.isDefault ? "Using this card" : "Use card"}</Button>
                            {!this.props.isDefault &&
                            <Button labelClassName={styles.label} plain
                                    onPress={this.props.onRemoveCardPress}
                                    disabled={this.props.isDefault}>Remove Card</Button>}
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}
