export function getSurveyAPI(token, accountId, surveyId) {
    return window.get(`/v1/accounts/${accountId}/surveys/${surveyId}`, token);
}

export function createOrganisationSurveyAPI(token, accountId, survey) {
    return window.post(`/v1/accounts/${accountId}/surveys`, token, survey);
}

export function createOrganisationTemplateSurveyAPI(token, accountId, templateId, survey) {
    return window.post(`/v1/accounts/${accountId}/surveys/template/${templateId}`, token, survey);
}

export function updateOrganisationSurveyAPI(token, accountId, surveyId, survey) {
    return window.put(`/v1/accounts/${accountId}/surveys/${surveyId}`, token, survey);
}

export function startOrganisationSurveyFacebookAPI(token, accountId, surveyId, payAsYouGo) {
    return window.put(`/v1/accounts/${accountId}/surveys/${surveyId}/start/facebook`, token, payAsYouGo);
}

export function startOrganisationFreeSurveyFacebookAPI(token, accountId, surveyId, payAsYouGo) {
    return window.put(`/v1/admin/accounts/${accountId}/surveys/${surveyId}/start/facebook`, token, payAsYouGo);
}

export function startOrganisationSurveyAudienceAPI(token, accountId, surveyId, audienceId) {
    return window.put(`/v1/accounts/${accountId}/surveys/${surveyId}/start/audience/${audienceId}`, token);
}

export function startOrganisationShareableLinkSurveyAPI(token, accountId, surveyId) {
    return window.put(`/v1/accounts/${accountId}/surveys/${surveyId}/start/link`, token);
}

export function deleteOrganisationSurveyAPI(token, accountId, surveyId) {
    return window.del(`/v1/accounts/${accountId}/surveys/${surveyId}`, token);
}

export function duplicateOrganisationSurveyAPI(token, accountId, surveyId, newSurveyData) {
    return window.post(`/v1/accounts/${accountId}/surveys/${surveyId}/duplicate`, token, newSurveyData);
}

export function completeOrganisationSurveyAPI(token, accountId, surveyId) {
    return window.put(`/v1/accounts/${accountId}/surveys/${surveyId}/complete`, token);
}

export function getOrganisationLiveSurveysAPI(token, accountId, cursor, limit, search) {
    const params = {
        cursor: cursor,
        limit: limit,
        search: search || ""
    };

    return window.get(`/v1/accounts/${accountId}/surveys`, token, params);
}

export function getOrganisationDraftSurveysAPI(token, accountId) {
    return window.get(`/v1/accounts/${accountId}/surveys/drafts`, token);
}

export function getOrganisationCompletedSurveysAPI(token, accountId, cursor, limit, search) {
    const params = {
        cursor: cursor,
        limit: limit,
        search: search || ""
    };

    return window.get(`/v1/accounts/${accountId}/surveys/completed`, token, params);
}

export function getSurveyAgentsAPI(token) {
    return window.get("/v1/agents", token);
}

export function getSurveyTemplatesAPI(token) {
    return window.get("/v1/surveys/templates", token);
}

export function getShareSurveyLinkAPI(token, accountId, surveyId) {
    return window.get(`/v1/accounts/${accountId}/surveys/${surveyId}/link`, token);
}

export function emailShareSurveyLinkAPI(token, accountId, surveyId, emailList) {
    return window.post(`/v1/accounts/${accountId}/surveys/${surveyId}/share`, token, emailList);
}
