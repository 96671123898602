import * as Types from "../../actions/v1/surveys";

const initialState = {
	agents: {},
};

export default function surveyAgents(state = initialState, action = {type: null, agents: {}}) {
	switch (action.type) {

		case Types.SET_SURVEY_AGENTS: {
			const agents = {};

			(action.agents || []).map((agent) => {
				agents[agent.id] = agent;
				return null;
			});

			return {...state, agents: agents}
		}

		default: {
			return state
		}
	}
}