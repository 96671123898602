import createAction from "../helpers/v1/createAction";
import wrapper from "../helpers/v1/wrapper";
import {showSuccess} from "./snackbars";
import * as AudienceMembersAPI from "../api/v1/audienceMembers";

export const SET_AUDIENCE_MEMBER = "audienceMembers:SET_AUDIENCE";
export const SET_AUDIENCE_MEMBERS = "audienceMembers:SET_AUDIENCES";
export const CLEAR_AUDIENCE = "audienceMembers:CLEAR_AUDIENCE";
export const REMOVE_AUDIENCE = "audienceMembers:REMOVE_AUDIENCE";
export const CLEAR_AUDIENCE_MEMBERS = "audienceMembers:CLEAR_AUDIENCE_MEMBERS";
export const UPDATE_AUDIENCE_MEMBERS = "audienceMembers:UPDATE_AUDIENCE_MEMBERS";
export const SET_AUDIENCE_LIST_SIZE = "audienceMembers:SET_AUDIENCE_LIST_SIZE";
export const AUDIENCE_UPLOAD_COMPLETE = "audienceMembers:AUDIENCE_UPLOAD_COMPLETE";

export const setAudienceMember = createAction(SET_AUDIENCE_MEMBER, "audienceMember");
export const setAudienceMembers = createAction(SET_AUDIENCE_MEMBERS, "orgId", "audienceId", "audienceMembers");
export const clearAudience = createAction(CLEAR_AUDIENCE, "audienceId");
export const removeAudienceMember = createAction(REMOVE_AUDIENCE, "audienceMember");
export const clearAudienceMembers = createAction(CLEAR_AUDIENCE_MEMBERS, "audienceId");
export const setUpdateAudienceMembers = createAction(UPDATE_AUDIENCE_MEMBERS, "audienceMembers");
export const setAudienceListSize = createAction(SET_AUDIENCE_LIST_SIZE, "audienceSize");

export const createAudienceMembers = wrapper(AudienceMembersAPI.createAudienceMembersAPI, (dispatch, audienceMembers, orgId, audienceId) => {
    dispatch(showSuccess("Successfully created respondents."));
    dispatch(clearAudienceMembers(audienceId));
});

export const getAudienceMembers = wrapper(AudienceMembersAPI.getAudienceMembersAPI, (dispatch, audienceMembers, orgId, audienceId) => {
    dispatch(setAudienceMembers(orgId, audienceId, audienceMembers));
});

export const createAudienceMembersFromCSV = wrapper(AudienceMembersAPI.createAudienceMembersFromCSVAPI, (dispatch, audienceMembers, orgId, audienceId) => {
    dispatch(showSuccess("Uploading audience respondents. This can take upto 5 minutes depending on the size of your list."));
    dispatch(clearAudienceMembers(audienceId));
});

export const updateAudienceMembers = wrapper(AudienceMembersAPI.updateAudienceMembersAPI, (dispatch, audienceMembers, orgId, audienceId) => {
    dispatch(showSuccess("Successfully updated respondents."));
    dispatch(clearAudienceMembers(audienceId));
});

export const deleteAudienceMembers = wrapper(AudienceMembersAPI.deleteAudienceMembersAPI, (dispatch, audienceMembers, orgId, audienceId) => {
    dispatch(showSuccess("Successfully deleted respondents."));
    dispatch(clearAudienceMembers(audienceId));
});

export const getAudienceListSize = wrapper(AudienceMembersAPI.getAudienceListSizeAPI, (dispatch, audienceSize) => {
    dispatch(setAudienceListSize(audienceSize));
});

export const audienceUploadComplete = createAction(AUDIENCE_UPLOAD_COMPLETE, "bool");