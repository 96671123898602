import React, { Component } from "react";
import styles from "./UpgradeSaasPlan.module.scss";
import Button from "../../../../../../../../widgets/button/Button";
import CreditCardForm from "../../../../../../../../widgets/creditCardForm/CreditCardForm";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import close from "../../../../../../../../../resources/svg/close.svg";
import { connect } from "react-redux";
import Dialog from "../../../../../../../../widgets/dialog/Dialog";
import Select from "../../../../../../../../widgets/select/Select";
import { getSaasTiers } from "../../../../../../../../../redux/actions/v1/saas";
import {
    addOrganisationCard,
    createUpdateSaaSSubscription, getOrganisationCards
} from "../../../../../../../../../redux/actions/v1/organisations";
import { showError, showSuccess } from "../../../../../../../../../redux/actions/v1/snackbars";
import infoIcon from "../../../../../../../../../resources/svg/info.svg";
import PoweredByStripe from "../../../../../../../../../resources/js/PoweredByStripe";
import unlock from "../../../../../../../../../resources/svg/open-padlock.svg";
import ReactTooltip from "react-tooltip";
import {
    getQuestionReport,
    setQuestionReport
} from "../../../../../../../../../redux/actions/reports";
import { loadStripe } from "@stripe/stripe-js";


class UpgradeSaasPlan extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedPlan: props.selectedPlan,
            loading: false,
            error: null,
            selectedCard: null

        };
        this.dialogRef = React.createRef();
        this.ccRef = React.createRef();
    }

    componentDidMount () {
        this.props.dispatch(getSaasTiers());
        this.props.dispatch(getOrganisationCards({
            orgID: this.props.orgID
        }));

        if ( !!this.props.cards ) {
            this.setState({ selectedCard: Object.keys(this.props.cards)[0] });
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if ( prevProps.cards !== this.props.cards && !!this.props.cards ) {
            this.setState({ selectedCard: Object.keys(this.props.cards)[0] });
        }
    }

    onUpgradeSuccess = () => {
        this.props.activeQuestions.forEach((question) => {
            this.props.dispatch(setQuestionReport({
                questionId: question.id,
                report: null,
            }));

            this.props.dispatch(getQuestionReport(question.id, {
                showCompletedOnly: true,
            }))
                .then(() => {

                    this.setState({ loading: false });
                })
                .catch(this.onCCError);

        });
        this.props.dispatch(showSuccess(
            `Upgrade successful! You're now on our ${(this.state.selectedPlan === undefined ? "FREE" : this.state.selectedPlan.name)} tier`
        ));
    };

    onCCSuccess = (token) => {
        this.props.dispatch(addOrganisationCard({
            accountId: this.props.orgID,
            stripeCardToken: token,
            onSuccess: () => {
                this.props.dispatch(createUpdateSaaSSubscription({
                    accountId: this.props.orgID,
                    subscriptionId: this.state.selectedPlan.pricing.find((price) => price.interval === "month").id,
                    upgradeLater: false,
                    onSuccess: this.onUpgradeSuccess,
                    onError: this.onCCError
                }));
            },
            onError: this.onCCError
        }));
    };

    onCCError = (error) => {
        this.setState({
            error: error.message,
            loading: false
        });
        this.props.dispatch(showError(error.message));
    };

    onCCSubmit = () => {
        this.setState({ loading: true });

        if ( !this.state.selectedCard && this.ccRef ) {
            this.ccRef.getCreditCardToken();
        } else {
            this.props.dispatch(createUpdateSaaSSubscription({
                accountId: this.props.orgID,
                subscriptionId: this.state.selectedPlan.pricing.find((price) => price.interval === "month").id,
                upgradeLater: false,
                card: this.state.selectedCard,
                onSuccess: this.onUpgradeSuccess,
                onError: this.onCCError
            }));
        }
    };

    onCCSelect = (card) => {
        this.setState({ selectedCard: card });
    };

    getPlanText = (plan) => {
        let price = (plan.pricing.find((price) => price.interval === "month").amount) / 100;
        let text = "";
        let maxRespondents = plan.maxRespondentsPerSurvey;
        if ( maxRespondents === null ) {
            maxRespondents = "Unlimited";
        } else {
            maxRespondents = "Up to " + maxRespondents;
        }
        text = maxRespondents + " responses - $" + price.toString()
            + "USD/m";
        return text;
    };

    onUnlockPress = (tier) => {
        this.setState({ selectedPlan: tier.value });
        this.dialogRef.show();
    };

    onSelect = (value) => {
        this.setState({ selectedPlan: value });
    };

    render () {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        let tierName = this.props.tier;
        let maxUsers = this.props.account.subscription.tier.maxRespondentsPerSurvey;
        const totalResponses = this.props.respondents;
        if ( maxUsers === null || this.props.account.accountType === "ENTERPRISE" ) {
            maxUsers = totalResponses;
        }

        const percentageMissing = ((totalResponses - maxUsers) / totalResponses) * 100;
        const widthValue = (percentageMissing) + "%";
        let width = {
            width: widthValue
        };
        let supportText = "Upgrade to unlock " + (totalResponses - maxUsers) + " more respondents.";
        let usersHiddenText = (totalResponses - maxUsers) + " Hidden";
        let tierId = this.props.account.subscription.tier.id;
        const tierMonthlyPrice = (this.props.saasPlans[tierId].pricing.find((price) => price.interval === "month") || {}).amount;
        const tiers = Object.values(this.props.saasPlans)
            .filter(tier => tier.pricing.find((price) => price.interval === "month").amount > tierMonthlyPrice)
            .map(tier => {
                return {
                    value: tier,
                    label: <div className={styles.tier_container}>
                        <div className={styles.tier_label}>{tier.name}</div>
                        <div className={styles.tier_description}>{this.getPlanText(tier)}</div>
                    </div>,
                    description: <div className={styles.tier_container}>
                        <div className={styles.tier_description}>{this.getPlanText(tier)}</div>
                    </div>
                };
            });

        let cards;

        if ( !!this.props.cards ) {
            cards = Object.values(this.props.cards)
                .map(card => {
                    return {
                        value: card.id,
                        label: (!!card.name ? card.name : "Card ending in " + card.last4)
                    };
                });
        }

        let tooltip = "For more information about our <br/> subscription tiers, visit our pricing page";
        let selectLabel = (
            <div className={styles.select_header}>
                <div>
                    Select subscription
                </div>
                <div data-tip={tooltip}>
                    <img src={infoIcon} className={styles.info_icon} height={22} alt={"info"}/>
                </div>
                <ReactTooltip clickable={true} type={"info"} class={styles.tooltip} delayHide={500}
                              backgroundColor={"white"} textColor={"black"} insecure={true}
                              effect={"solid"} place={"right"} multiline={true}>
                    For more information about our <br/> subscription tiers, visit our <a
                    href="http://stickybeak.co/pricing" target="_blank" rel={"noopener noreferrer"}>pricing page</a>
                </ReactTooltip>
            </div>
        );

        return (
            <div className={styles.upgrade_saas_plan}>
                <div className={styles.card}>
                    <div className={styles.text_row}>
                        <div className={styles.heading}>You are missing out on responses!</div>
                        <div className={styles.supporting_text}>
                            You're currently on our {tierName.toUpperCase()} tier. {supportText}
                        </div>
                    </div>
                    <div className={styles.status_bar_row}>
                        <div className={styles.status_bar}>
                            <div className={styles.background}/>
                            <div className={styles.bar}/>
                            <div className={styles.bar_text}>Max {maxUsers}</div>
                            {percentageMissing <= 10 ? (
                                <>
                                    <div className={styles.background_text}>{usersHiddenText}</div>
                                    <div className={styles.grey_section}/>
                                </>
                            ) : (
                                <>
                                    <div style={width}
                                         className={styles.background_text}>{usersHiddenText}</div>
                                    <div className={styles.grey_section}/>
                                </>
                            )}
                        </div>
                        <Button red onPress={() => this.onUnlockPress(tiers[0])}
                                disabled={this.state.loading}>
                            Unlock now
                        </Button>
                        <Dialog custom ref={(ref) => this.dialogRef = ref}
                                className={styles.cc_dialog}>
                            <div className={styles.dialog_title}>
                                <h3>
                                    Unlock more responses
                                </h3>
                                <img alt={"close"} src={close} onClick={() => {
                                    this.dialogRef.hide();
                                }} height={16} width={16} className={styles.dialog_cross}/>
                            </div>
                            <Select label={selectLabel}
                                    onChange={this.onSelect} labelClassName={styles.select_label}
                                    className={styles.select} options={tiers}/>

                            {!cards && (

                                <Elements stripe={stripePromise}>
                                    <ElementsConsumer>
                                        {({ stripe, elements }) => (
                                            <CreditCardForm onSuccess={this.onCCSuccess}
                                                            onError={this.onCCError}
                                                            ccRef={(ref) => this.ccRef = ref}
                                                            className={styles.cc_form} shown
                                                            stripe={stripe}
                                                            elements={elements}
                                                            selectedAccount={this.props.account}
                                                            orgId={this.props.orgID} showPoweredBy={false}/>
                                        )}
                                    </ElementsConsumer>
                                </Elements>

                            )}

                            {cards && !this.state.loading && (
                                <Select label={"Select Card"}
                                        onChange={(card) => this.onCCSelect(card)}
                                        labelClassName={styles.card_select_label}
                                        value={this.state.selectedCard}
                                        className={styles.card_select} options={cards}
                                />
                            )}

                            <Button green textWhite className={styles.button}
                                    loading={this.state.loading}
                                    onPress={this.onCCSubmit}>
                                <img src={unlock} height={20} alt={"lock"}/> Upgrade and unlock
                            </Button>

                            <div className={styles.power_by_stripe_wrapper}>
                                <a href={"http://stripe.com"} target={"_blank"}
                                   rel={"noopener noreferrer"}>
                                    <PoweredByStripe/>
                                </a>
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
        const selectedOrg = state.settings.organisation;
        return {
            orgID: selectedOrg,
            account: state.organisations.accounts[selectedOrg],
            saasPlans: state.saas.tiers,
            cards: state.organisations.cards[selectedOrg]
        };
    }
)(UpgradeSaasPlan);
