import Field from "redux-form/es/Field";
import React from "react";
import MediaItem from "./MediaItem";

function MediaItemField(props) {
	return (
		<Field {...props} component={MediaItem} editable/>
	);
}

MediaItemField.propTypes = MediaItem.propTypes;

MediaItemField.defaultProps = MediaItem.defaultProps;

export default MediaItemField;