import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./SurveyToolbar.module.scss";
import classnames from "classnames";
import Button from "../../../../../../../../widgets/button/Button";

export default class SurveyToolbar extends Component {

    static propTypes = {
        className: PropTypes.string,
        survey: PropTypes.object,
        organisation: PropTypes.object,
        buttons: PropTypes.array,
    };

    static defaultProps = {
        className: null,
        survey: null,
        organisation: {},
        buttons: [],
    };

    renderButtons =() => {
        return this.props.buttons.map(this.renderButton);
    };

    renderButton =(button, i) => {
        const className = classnames(styles.button, button.className);
        return (
            <Button key={i} onPress={button.onPress} {...(button.props || {})} className={className}>{button.text}</Button>
        );
    };

    render() {
        const className = classnames(styles.survey_toolbar, this.props.className);

        const survey = this.props.survey || {status: ""};
        const orgName = (this.props.organisation || {}).name;

        return (
            <nav className={className}>
                <div className={styles.header_wrapper}>
                    <div className={styles.header_text}>
                        <span className={styles.organisation_text}>{orgName}</span>
                        <span className={styles.title_text}>{survey.name}</span>
                        {survey.status === "LIVE" ?
                            <span className={styles.chip_live}>{survey.status.toUpperCase()}</span> :
                            <span className={styles.chip}>{survey.status.toUpperCase()}</span>}
                    </div>
                    <div className={styles.header_buttons}>
                        {this.renderButtons()}
                        {/*<Button className={styles.button}*/}
                        {/*        plain*/}
                        {/*        borderDark*/}
                        {/*        onPress={this.props.handleSubmit(this.onPreviewSurveyPress)}>Preview Survey</Button>*/}
                        {/*<Button className={styles.button}*/}
                        {/*        plain*/}
                        {/*        borderDark={!this.props.pristine && this.props.valid}*/}
                        {/*        disabled={this.props.pristine || !this.props.valid}*/}
                        {/*        onPress={this.props.handleSubmit(this.onSavePress)}>Save</Button>*/}
                        {/*<Button className={styles.button}*/}
                        {/*        plain*/}
                        {/*        borderDark={!this.props.pristine && this.props.valid}*/}
                        {/*        disabled={this.props.pristine || !this.props.valid}*/}
                        {/*        onPress={this.props.handleSubmit(this.onSaveAndClosePress)}>Save &amp; Close</Button>*/}
                        {/*<Button className={styles.button}*/}
                        {/*        plain*/}
                        {/*        borderDark*/}
                        {/*        onPress={this.onClosePress}>Close</Button>*/}
                        {/*<Button className={styles.button}*/}
                        {/*        green*/}
                        {/*        disabled={!this.props.valid}*/}
                        {/*        onPress={this.props.handleSubmit(this.onNextPress)}>Continue</Button>*/}
                    </div>
                </div>
            </nav>
        );
    }
}