/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-29.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./Payment.module.scss";
import Card from "../../../../../../widgets/card/Card";
import { signOut } from "../../../../../../../redux/actions/v1/user";
import Routes from "../../../../../../../constants/routes";
import Button from "../../../../../../widgets/button/Button";
import moment from "moment";
import { reduxForm } from "redux-form";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CreditCardForm from "../../../../../../widgets/creditCardForm/CreditCardForm";
import Error from "../../../../../../widgets/error/Error";
import {
    addOrganisationCard,
    createUpdateSaaSSubscription
} from "../../../../../../../redux/actions/v1/organisations";
import { getSaasTiers } from "../../../../../../../redux/actions/v1/saas";
import PoweredByStripe from "../../../../../../../resources/js/PoweredByStripe";
import padlock from "../../../../../../../resources/svg/padlock.svg";
import AnalyticsAPI from "../../../../../../../util/Analytics";
import sendEventToActiveCampaign from "../../../../../../../redux/actions/api/v1/eventTracking";
import { loadStripe } from "@stripe/stripe-js";

const basicFeatures = (
    <div>
        <h3>You’re one step away from your Stickybeak Basic account - we can’t wait to have you!</h3>

        <p>The features you’ll have access to on your Basic account are:</p>
        <ul className={styles.list}>
            <li>
                <p>3 users access to your account</p>
            </li>
            <li>
                <p>Up to 200 responses per survey</p>
            </li>
            <li>
                <p>Access to QR code surveys</p>
            </li>
        </ul>
    </div>
);

const unlimitedFeatures = (
    <div>
        <h3>You’re one step away from your Stickybeak Unlimited account - we can’t wait to have you!</h3>

        <p>The features you’ll have access to on your Unlimited account are:</p>
        <ul className={styles.list}>
            <li>
                <p>Unlimited users</p>
            </li>
            <li>
                <p>Unlimited responses per survey</p>
            </li>
            <li>
                <p>Access to QR code surveys</p>
            </li>
        </ul>
    </div>
);


class Payment extends Component {

    state = {
        loading: false,
        error: null,
    };

    onSignIntoDifferentAccountPress = () => {
        this.props.dispatch(signOut());
        this.props.history.push(Routes.Onboarding.Login);
    };

    componentDidMount () {
        this.props.dispatch(getSaasTiers());
    }

    onSubmit = () => {
        this.setState({
            loading: true,
            error: null,
        });
        this.creditCardForm.getCreditCardToken();
    };

    onCreditCardSuccess = (token) => {
        this.setState({ loading: true });

        this.props.dispatch(addOrganisationCard({
            accountId: this.props.orgID,
            stripeCardToken: token,
            onSuccess: () => {
                this.props.dispatch(createUpdateSaaSSubscription({
                    accountId: this.props.orgID,
                    subscriptionId: this.props.tier.pricing.find((price) => price.interval === "month").id,
                    upgradeLater: false,
                    onSuccess: this.onTierUpgradeSuccess,
                    onError: this.onCCError
                }));
            },
            onError: this.onCCError
        }));
    };

    onTierUpgradeSuccess = () => {
        this.setState({ loading: false });
        AnalyticsAPI.tierSignupEvent(this.props.tier.name.toUpperCase());
        this.props.history.push(Routes.Surveys.root);
        sendEventToActiveCampaign(this.props.user.email, "Sign_up", this.props.tier.name.toUpperCase())
    };

    onError = (message) => {
        this.setState({
            loading: false,
            error: message,
        });
    };

    render () {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        return (
            <Card className={styles.payment} component={"form"} onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className={styles.left}>
                    {this.props.selectedTier === "UNLIMITED" ? unlimitedFeatures : basicFeatures}

                    <Button plain onPress={this.onSignIntoDifferentAccountPress}>Sign into different Account</Button>
                </div>

                <div className={styles.right}>
                    <div className={styles.right_top}>
                        <div className={styles.spacer}/>
                        <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => (
                                    <CreditCardForm ccRef={(ref) => this.creditCardForm = ref}
                                                    onSuccess={this.onCreditCardSuccess}
                                                    onError={this.onError}
                                                    stripe={stripe}
                                                    elements={elements}
                                                    selectedAccount={this.props.account}
                                                    orgId={this.props.orgID}
                                                    showPoweredBy={false}
                                    />
                                )}
                            </ElementsConsumer>
                        </Elements>
                        <div className={styles.power_by_stripe_wrapper}>
                            <img src={padlock} style={{ marginRight: 5, marginBottom: 5 }}/>
                            <a href={"http://stripe.com"} target={"_blank"} rel={"noopener noreferrer"}>
                                <PoweredByStripe/>
                            </a>
                        </div>
                    </div>
                    <div className={styles.right_bottom}>
                        <Error error={this.state.error} className={styles.error}/>
                        <Button type={"submit"} loading={this.state.loading}>Get Started</Button>
                    </div>
                </div>
            </Card>
        );
    }
}

Payment = reduxForm({
    form: "signUpPayment",
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};


        return errors;
    },
})(Payment);

export default Payment = connect((state) => {
    const selectedOrg = state.settings.organisation;
    const tier = Object.values(state.saas.tiers).find(tier => tier.name.toUpperCase() === state.settings.saasSignUp.tier)
    const selectedTier = state.settings.saasSignUp.tier
    const user = state.user;
    return {
        orgID: selectedOrg,
        account: state.organisations.accounts[selectedOrg],
        formValues: ((state.form.signUpPayment || {}).values || {}),
        tier: tier,
        selectedTier: selectedTier,
        user
    };
})(Payment);
