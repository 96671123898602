/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { useSelector as useReduxSelector } from "react-redux";
import { ApplicationState } from "../redux/reducers/root";

// export default function useSelector<T, K extends keyof ApplicationState>(selector: K | ((state: ApplicationState) => T)) {
//     if (typeof selector === "string") {
//         return useReduxSelector<ApplicationState, ApplicationState[K]>((state) => state[selector]);
//     }
//     return useReduxSelector<ApplicationState, T>(selector);
// }

export default function useSelector<T>(selector: (state: ApplicationState) => T, equalityFn?: (left: T, right: T) => boolean) {
    return useReduxSelector<ApplicationState, T>(selector, equalityFn);
}



