/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-05-14.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import values from "lodash/values";

export default function getSurveyProps(state, props) {

    const orgId = state.settings.organisation;
    const surveyId = Number(props.match.params.surveyId);
    const survey = state.surveys[surveyId];

    const questions = state.surveyQuestions[surveyId] || {};
    const activeQuestions = values(questions).filter((question) => {
        return question.status === "ACTIVE"
    });

    return {
        orgId: orgId,
        organisation: state.organisations.accounts[orgId],
        surveyId: surveyId,
        survey: survey,

        questions: questions,
        activeQuestions: activeQuestions
    }
}