import React, {Component} from "react";
import Dialog from "../../dialog/Dialog";
import TextInputField from "../../textInput/TextInputField";
import {reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createOrganisation} from "../../../../redux/actions/v1/organisations";

class CreateOrganisationDialog extends Component {

    static propTypes = {
        dialogRef: PropTypes.func,
    };

    static defaultProps = {
        dialogRef: () => null
    };

    state = {
        loading: false,
        error: null,
    };

    onSubmit = () => {
        this.setState({
            loading: true,
            error: null,
        });
        const data = {
            name: this.props.formValues.name,
        };

        this.props.dispatch(createOrganisation({
            data: data,
            onSuccess: this.onCreateOrgSuccess,
            onError: (err) => this.onError(err.message),
        }));
    };

    onCreateOrgSuccess = () => {
    };

    onError = (message) => {
        this.setState({
            loading: false,
            error: message,
        });
    };

    render() {
        return (
            <Dialog title={"Create new organisation"}
                    disableDismiss={this.state.loading}
                    error={this.state.error}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref);
                    }}
                    actions={[
                        {
                            text: "Cancel",
                            onPress: () => this.dialog.hide(),
                            props: {plain: true, disabled: this.state.loading}
                        },
                        {
                            text: "Create",
                            onPress: this.props.handleSubmit(this.onSubmit),
                            props: {
                                disabled: (!this.props.valid || this.props.pristine),
                                blue: true,
                                loading: this.state.loading
                            }
                        }
                    ]}>
                <br/>
                <TextInputField name={"name"}
                                label={"Organisation name"}
                                placeholder={"e.g. Stickybeak"}/>
                <br/>
            </Dialog>
        )
    }
}

CreateOrganisationDialog = reduxForm({
    form: "createOrganisation",
    enableReinitialize: true,
})(CreateOrganisationDialog);

export default CreateOrganisationDialog = connect((state) => {
    return {
        formValues: ((state.form.createOrganisation || {}).values || {}),
    }
})(CreateOrganisationDialog)