import React, {Component} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./KeepPlanDialog.module.scss";
import {cancelSaaSSubscriptionUpgrade} from "../../../../redux/actions/v1/organisations";
import {connect} from "react-redux";

class KeepPlanDialog extends Component {

    static propTypes = {
        dialogRef: PropTypes.func,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        dialogRef: () => null,
        onSubmit: () => null,
        loading: false,
    };

    state = {
        loading: false
    };

    hide = () => {
        this.dialog.hide();
    };

    getTierDetail = (isUpgrade) => {
        let tier = this.props.currentTier;

        if (isUpgrade) {
            tier = this.props.upgradeTier;
        }
        const maxUsers = !tier.maxUsers ? "Unlimited" : tier.maxUsers;
        const maxRespondentsPerSurvey = !tier.maxRespondentsPerSurvey ? "Unlimited" : tier.maxRespondentsPerSurvey;
        const maxAudiences = !tier.maxAudiences ? "Unlimited" : tier.maxAudiences;
        // const maxAudienceMembers = !tier.maxAudienceMembers ? "Unlimited" : tier.maxAudienceMembers;

        return (
            <p>
                {tier.name}
                <br/>
                Up to {maxUsers === "Unlimited" || maxUsers > 1 ? `${maxUsers} Users` : `${maxUsers} User`}
                <br/>
                {maxRespondentsPerSurvey} responses per survey
                <br/>
                {maxAudiences} contact lists
                <br/><br/>
            </p>
        );
    };

    onPress = (isUpgrade) => {
        if (isUpgrade) {
            this.setState({loading: true});
            this.props.dispatch(cancelSaaSSubscriptionUpgrade({
                accountId: this.props.orgId,
                onSuccess: () => {
                    this.setState({loading: false});
                }
            }));
        } else {
            this.hide();
        }
    };

    render() {
        if (!this.props.upgradeTier || !this.props.currentTier) {
            return null;
        }

        let isUpgrade = this.props.upgradeTier.maxUsers > this.props.currentTier.maxUsers;
        const actions = [
            {
                text: `${isUpgrade ? "Keep upgrade" : "Keep current plan"}`,
                onPress: () => this.onPress(!isUpgrade),
                props: {
                    green: true,
                    loading: this.state.loading,
                    disabled: this.state.loading
                }
            },
            {
                text: `${isUpgrade ? "Keep current plan" : "Keep downgrade"}`,
                onPress: () => this.onPress(isUpgrade),
                props: {loading: this.state.loading, disabled: this.state.loading, white: true, borderLight: true}
            },
            {
                text: "Close",
                onPress: this.hide,
                props: {disabled: this.state.loading, white: true, borderLight: true}
            },
        ];

        return (
            <Dialog title={"Are you sure?"}
                    actions={actions}
                    className={styles.keep_plan_dialog}
                    actionsClassName={styles.dialog_actions}
                    disableDismiss={this.props.loading}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                <div className={styles.tier_detail}>
                    {this.getTierDetail(isUpgrade)}
                </div>
            </Dialog>
        )
    }
}

export default KeepPlanDialog = connect()(KeepPlanDialog);
