import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import _cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import Dialog from "../../dialog/Dialog";
import GreenTick from "../../../../resources/js/GreenTick";
import styles from "./QuestionRandomiseDialog.module.scss";
import Button from "../../button/Button";
import { v4 as uuid } from "uuid";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class QuestionRandomiseDialog extends Component {

    state = {
        choices: []
    };

    handleDonePress = () => {
        this.onSubmit();
    };

    handleCancelPress = () => {
        this.handleHide();
        this.onDialogCancel();
    };

    handleHide = () => {
        this.dialog.hide();
    };

    onDialogShow = () => {
        const choicesCopy = _cloneDeep(this.props.question.choices);
        choicesCopy.map(choice => choice.uuid = uuid());
        this.setState({
            choices: choicesCopy
        });
    };

    onChoiceToggle = (uuid) => {
        this.setState((prevState) => {
            prevState.choices.map(choice => {
                if (choice.uuid === uuid) {
                    choice.randomiseChoice = !choice.randomiseChoice;
                }
                return null;
            });

            return {
                choices: prevState.choices
            };
        });
    };

    onDialogCancel = () => {
        this.setState({
            choices: []
        });
    };

    onSubmit = () => {
        const newQuestion = _cloneDeep(this.props.question);
        newQuestion.choices = this.state.choices;
        newQuestion.choices.map(choice => delete choice.uuid);

        this.props.dispatch(change("editSurvey", this.props.fieldName, newQuestion));
        this.dialog.hide();
    };

    renderQuestionChoiceList = (choices) => {
        return choices.map(choice => {
            return (
                <Button className={styles.choice_row}
                        onPress={() => this.onChoiceToggle(choice.uuid)}
                        key={choice.uuid}>
                    {choice.randomiseChoice ? <GreenTick className={styles.choice_tick} /> : <div />}
                    <span className={styles.choice_text}>{choice.value}</span>
                </Button>
            );
        });
    };

    render() {
        const { choices } = this.state;

        return (
            <Dialog
                custom
                onShow={this.onDialogShow}
                actionToHide={this.onDialogCancel}
                ref={ref => {
                    this.dialog = ref;
                    this.props.dialogRef(ref);
                }}>

                <DialogTitle className={styles.title}>Randomise</DialogTitle>
                <DialogContentText className={styles.subtitle}>Which responses would you like to randomise? Unrandomised
                    options will be last.</DialogContentText>
                <div className={styles.choice_container}>
                    {choices && this.renderQuestionChoiceList(choices)}
                </div>
                <div className={styles.action_buttons}>
                    <Button onPress={this.handleCancelPress} plain>Cancel</Button>
                    <Button onPress={this.handleDonePress} blue disabled={false}>Done</Button>
                </div>
            </Dialog>
        );
    }
}

export default connect()(QuestionRandomiseDialog);

QuestionRandomiseDialog.propTypes = {
    question: PropTypes.object,
    fieldName: PropTypes.string,
    dialogRef: PropTypes.func,
    saveChoices: PropTypes.func
};

QuestionRandomiseDialog.defaultProps = {
    question: {},
    fieldName: "",
    dialogRef: () => null,
    saveChoices: () => null
};

