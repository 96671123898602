/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, {Component} from "react";
import PropTypes from "prop-types";
import styles from "./CreditCardRow.module.scss";
import classnames from "classnames";
import CreditCard from "../creditCard/CreditCard";
import Button from "../button/Button";

export default class CreditCardRow extends Component {

    static propTypes = {
        className: PropTypes.string,
        card: PropTypes.object,
        selected: PropTypes.bool,
        loading: PropTypes.bool,

        onSetDefaultCardPress: () => null,
        onEditCardPress: () => null,
        onRemoveCardPress: () => null,
    };

    static defaultProps = {
        className: null,
        card: {},
        selected: false,
        loading: false,

        onSetDefaultCardPress: () => null,
        onEditCardPress: () => null,
        onRemoveCardPress: () => null,
    };

    onSetDefaultCardPress = () => {
        const card = this.props.card;
        if (!card.default) {
            this.props.onSetDefaultCardPress(card);
        }
    };

    onEditCardPress = () => {
        this.props.onEditCardPress(this.props.card);
    };

    onRemoveCardPress = () => {
        this.props.onRemoveCardPress(this.props.card);
    };

    render() {
        const card = (this.props.card || {});
        const isDefault = (!!card.default);
        const className = classnames(styles.credit_card_row, {
            [styles.default]: isDefault,
        }, this.props.className);

        const loading = this.props.loading;

        return (
            <div className={className}>
                <div className={styles.card}>


                    <div className={styles.details}>
                        {/*<div className={styles.header_row}>*/}
                        {/*    */}
                        {/*</div>*/}

                        <div className={styles.row}>
                            <CreditCard card={card}
                                        onSetDefaultCardPress={this.onSetDefaultCardPress}
                                        onRemoveCardPress={this.onRemoveCardPress}
                                        onEditCardPress={this.onEditCardPress} loading={loading}
                                        isDefault={isDefault}/>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}