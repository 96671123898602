enum UserAccountRole {
    USER = "USER",
    SUPER_ADMIN = "SUPER_ADMIN",
}

export enum AccountType {
    STANDARD = "STANDARD",
    ENTERPRISE = "ENTERPRISE",
}

export default interface UserAccount {
    name: string;
    role: UserAccountRole;
    accountType: AccountType;
    stripeCustomerId: string;
    subscription: any;
}