import {FREE_TEXT} from "../constants/QuestionTypes";
export default function validateSurveyQuestions(currentQuestions){
    let isEmptyChoice = false;
    let isEmptyContent = false;
    //see if there is an empty question content or answer choice
    for (let i = 0; i < currentQuestions.length; i++) {
        let question = (currentQuestions[i] || { content: [] });
        for (let j = 0; j < question.content.length; j++) {
            const content = question.content[j];
            if (!content.text&&content.type==="QUESTION_TEXT") {
                isEmptyContent = true;
            }
        }

        if (isEmptyContent) {
            break;
        }


        if (!question.choices) {
            if (question.type !== FREE_TEXT) {
                isEmptyChoice = true;
                break;
            }
        }

        if(question.type !== FREE_TEXT) {
            for (let j = 0; j < question.choices.length; j++) {
                if (question.choices[j].value === "" || question.choices[j].value === "<br/>" || question.choices[j].value === "<div><br/></div>") {
                    isEmptyChoice = true;
                    break;
                }
            }
        }
    }
    if (isEmptyChoice || isEmptyContent) {
        return false
    }else{
        return true
    }
}