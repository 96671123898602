import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MessageItem.module.scss";
import omit from "lodash/omit";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import Paper from "@mui/material/Paper";

class MessageItem extends Component {

    static propTypes = {
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        classNameText: PropTypes.string,
        isCustomEnding: PropTypes.bool,
        placeholder: PropTypes.string,
        message: PropTypes.string,
        right: PropTypes.bool,
        clickable: PropTypes.bool,
        editable: PropTypes.bool,
        characterLimit: PropTypes.number,
        onPress: PropTypes.func,
        inputRef: PropTypes.func,
        onTextChange: PropTypes.func,
        hideError: PropTypes.bool,
        elementId: PropTypes.string
    };

    static defaultProps = {
        className: null,
        containerClassName: null,
        classNameText: null,
        isCustomEnding: false,
        placeholder: null,
        message: null,
        right: false,
        editable: false,
        characterLimit: null,
        clickable: false,
        onPress: () => null,
        inputRef: () => null,
        // onTextChange: () => null,
        hideError: null,
        elementId: null
    };

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            value: "",
            isMounted: false
        };

        this.contentEditable = React.createRef();
    }

    componentDidMount() {
        if (this.props.autoFocus && this.contentEditable && !this.contentEditable.current.innerText) {
            this.setState({ isMounted: true }, () => this.contentEditable.current.focus());
        }
    }

    handleCharacterLimit = (text = "") => {
        if (!!this.props.characterLimit) {
            return text.length >= this.props.characterLimit;
        }

        return false;
    };

    onFocus = () => {
        if (!!this.props.onFocus) {
            this.props.onFocus();
        }

        if (!!this.props.input) {
            this.props.input.onFocus();
        }

        this.setState({
            focused: true,
        });
    };

    onBlur = () => {
        if (!!this.props.onBlur) {
            this.props.onBlur();
        }

        if (!!this.props.input) {
            this.props.input.onBlur();
        }

        this.contentEditable.current.blur();
        this.setState({
            focused: false,
        });
    };

    onKeyDown = (e) => {
        const backSpace = 8;
        const enter = 13;
        !!this.props.onKeyDown && this.props.onKeyDown(e);

        if (e.key === "Enter" || e.keyCode === enter) {
            e.stopPropagation();
            e.preventDefault();
            this.onBlur();
        }

        if (e.target.innerText && e.keyCode !== backSpace) {
            !!this.handleCharacterLimit(e.target.innerText) && e.preventDefault();
        }
    };

    onTextChange = (e) => {
        let text = null;

        if (e.target) {
            text = e.target.value;
        }

        text = sanitizeHtml(text, {
            allowedTags: [],
            allowedAttributes: {}
        });

        if (this.handleCharacterLimit(text)) {
            text = text.substring(0, this.props.characterLimit);
        }

        if (!!this.props.input.onChange) {
            this.props.input.onChange(text);
        }

        if (!!this.props.onTextChange) {
            this.props.onTextChange(text, e);
        }
    };

    render() {
        const props = this.props;

        let error = null;
        if (!!this.props.meta) {
            error = this.props.meta.error;
        }

        const classNameContainer = classnames(styles.message_item_container, props.containerClassName);

        const className = classnames(styles.message_item, props.className,
            {
                [styles.right]: props.right,
                [styles.message_item_editable]: this.props.editable ,
            });

        const classNameText = classnames(styles.message_text, props.classNameText,
            {
                [styles.message_text_editable]: !!this.props.editable|| this.props.isCustomEnding,
                [styles.message_text_editable_focused]: (!!this.props.editable && !!this.state.focused),
                [styles.message_text_editable_error]: (!!this.props.editable && (!!error)),
            });

        const omittedProps = omit({ ...this.props }, [...Object.keys(MessageItem.defaultProps), "dispatch", "meta", "input"]);

        let text = !!this.props.message ? this.props.message : !!this.props.children ? this.props.children : "";

        if (this.props.input && this.props.input.value) {
            text = this.props.input.value;
        }

        let helperText = null;
        if (!!this.props.helperText) {
            helperText = this.props.helperText;
        } else if (this.handleCharacterLimit(text)) {
            helperText = `Your question is getting a bit long, we recommend you limit questions to ${this.props.characterLimit} characters for the best response rate.`;
        }

        const hasErrorText = !!error && error !== " " ? error : false;

        return (
            <div className={classNameContainer}>
                <Paper {...omittedProps} className={className}>
                    {this.props.editable
                        ? <ContentEditable id={this.props.elementId}
                                           innerRef={this.contentEditable}
                                           disabled={!this.props.editable}
                                           html={text}
                                           placeholder={this.props.placeholder}
                                           className={classNameText}
                                           onChange={this.onTextChange}
                                           onKeyDown={this.onKeyDown}
                                           onFocus={this.onFocus}
                                           onBlur={this.onBlur}
                        />
                        : <div className={classNameText}>{text || this.props.placeholder}</div>}
                </Paper>

                {!!hasErrorText && !this.props.hideError
                    ? <label className={styles.error_text}>{error}</label>
                    : !!helperText
                        ? <label className={styles.helper_text}>{helperText}</label>
                        : null}

            </div>
        );
    }
}

export default connect()(MessageItem);
