import React, {Component} from "react"
import PropTypes from "prop-types"

import styles from "./QuestionChoices.module.scss";
import * as QuestionTypes from "../../../../../../../../../../constants/QuestionTypes"
import MessageItem from "../../../../../../../../../widgets/messageItem/MessageItem";
import FieldArray from "redux-form/es/FieldArray";
import RenderSelectionBubbles from "../renderSelectionBubbles/RenderSelectionBubbles";
import RenderEmojiRatingBubbles from "../renderEmojiRatingBubbles/RenderEmojiRatingBubbles";
import RatingQuestion from "../ratingQuestion/RatingQuestion";
import MultiAttributeQuestion from "../multiAttributeQuestion/MultiAttributeQuestion"
import {change} from "redux-form";
import NPSQuestion from "../npsQuestion/NPSQuestion";

function ChoiceWrapper(props) {
    return (
        <div className={styles.choices_wrapper}>
            {props.children}
            <div className={styles.agent_icon}/>
        </div>
    )
}

export default class QuestionChoices extends Component {

    static propTypes = {
        name: PropTypes.string,
    };

    static defaultProps = {
        name: "",
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedQuestion: null
        }
    }

    render() {
        switch (this.props.responseType) {
            case QuestionTypes.SINGLE_CHOICE:
            case QuestionTypes.MULTI_CHOICE:
            case QuestionTypes.RANKING:
            case QuestionTypes.LONG_FORM_SINGLE_CHOICE:
            case QuestionTypes.LONG_FORM_MULTI_CHOICE: {
                return (
                    <ChoiceWrapper>
                        <FieldArray name={`${this.props.name}.choices`}
                                    props={{question: this.props.question}}
                                    component={RenderSelectionBubbles}/>
                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.RATING:{
                return(
                    <ChoiceWrapper>
                        <RatingQuestion name={this.props.name}/>
                    </ChoiceWrapper>

                )
            }
            case QuestionTypes.EMOJI_RATING:{
                return(
                    <ChoiceWrapper>
                        <FieldArray name={`${this.props.name}.choices`} component={RenderEmojiRatingBubbles}
                        />
                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.LOVE_HATE:{
                this.props.dispatch(change("editSurvey", `${this.props.name}.type`, "EMOJI_RATING"))
                return(
                <ChoiceWrapper>
                    <FieldArray name={`${this.props.name}.choices`} props={{loveHate: true}} component={RenderEmojiRatingBubbles}
                    />
                </ChoiceWrapper>
                )
            }

            case QuestionTypes.FREE_TEXT: {
                return (
                    <ChoiceWrapper>
                        <MessageItem
                            right
                            containerClassName={styles.message_item}
                            classNameText={styles.message_item_text}>Free text response</MessageItem>
                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.IMAGE_COMPARE: {
                return (
                    <ChoiceWrapper>

                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.NPS: {
                return (
                    <ChoiceWrapper>
                        <NPSQuestion name={this.props.name}/>
                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.MULTI_ATTRIBUTE: {
                return (
                    <ChoiceWrapper>
                        <MultiAttributeQuestion name={this.props.name}/>
                    </ChoiceWrapper>
                )
            }
            case QuestionTypes.NEW_PROFIT: {
                return (
                    null
                )
            }


            default: {
                return (
                    null
                )
            }
        }
    }
}