/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-05-14.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import PropTypes from "prop-types";

function DeleteCircle(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54.126" height="54.113" viewBox="0 0 54.126 54.113" className={props.className}>
            <g transform="translate(-235.076 -1048.074)">
                <circle cx="24.056" cy="24.056" r="24.056" transform="translate(237.854 1050.858)" fill="#fff"/>
                <path d="M318.775,256.84A27.057,27.057,0,1,0,345.839,283.9a27.077,27.077,0,0,0-27.064-27.062Zm-7.752,14.537,7.752,7.741,7.741-7.741a3.424,3.424,0,0,1,2.406-1.007,3.384,3.384,0,0,1,3.387,3.376,3.334,3.334,0,0,1-1.02,2.4l-7.741,7.753,7.741,7.741a3.365,3.365,0,0,1-2.367,5.778,3.4,3.4,0,0,1-2.406-.994l-7.741-7.741-7.752,7.741a3.378,3.378,0,1,1-4.771-4.784l7.741-7.741-7.741-7.753a3.323,3.323,0,0,1-1.007-2.4,3.373,3.373,0,0,1,5.778-2.368Z"
                      transform="translate(-56.637 791.234)" fill="#444" fillRule="evenodd"/>
            </g>
        </svg>
    );
}

DeleteCircle.propTypes = {
    className: PropTypes.string,
};

DeleteCircle.defaultProps = {
    className: null,
};

export default DeleteCircle;