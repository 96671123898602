import React, {Component} from "react";
import styles from "./AudienceCreationChoices.module.scss";
import Card from "../card/Card";
import KeyboardImage from "../../../resources/js/KeyboardImage";
import Button from "../button/Button";
import UploadArrow from "../../../resources/js/UploadArrow";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class AudienceCreationChoices extends Component {
    static propTypes = {
        onAddManualPress: PropTypes.func,
        onUploadRespondentsPress: PropTypes.func,
        fullWidthCards: PropTypes.bool
    };

    static defaultProps = {
        fullWidthCards: false
    };

    render() {
        return (
            <div className={styles.no_lists}>
                <h2 className={styles.no_lists_header}>Add people to your audience</h2>
                <div className={styles.cards}>
                    <Card className={classnames(styles.card, {[styles.full_width]: this.props.fullWidthCards})}>
                        <div className={styles.card_image}>
                            <KeyboardImage/>
                        </div>
                        <div className={styles.card_content}>
                            <div className={styles.card_text}>
                                <h2>Add manually</h2>
                                <p>Select this option if you would like to type in your people
                                    one-by-one.</p>
                            </div>
                            <div className={styles.card_buttons}>
                                <Button className={styles.button_positive} green
                                        onPress={this.props.onAddManualPress}>Add manually</Button>
                                <Button className={styles.button_info} plain>info</Button>
                            </div>
                        </div>
                    </Card>

                    <Card className={classnames(styles.card, {[styles.full_width]: this.props.fullWidthCards})}>
                        <div className={styles.card_image}>
                            <UploadArrow/>
                        </div>
                        <div className={styles.card_content}>
                            <div className={styles.card_text}>
                                <h2>Upload spreadsheet</h2>
                                <p>Select this option if you would like to upload a spreadsheet database
                                    of
                                    people</p>
                            </div>
                            <div className={styles.card_buttons}>
                                <Button className={styles.button_positive} green
                                        onPress={this.props.onUploadRespondentsPress}>Upload list</Button>
                                <Button className={styles.button_info} plain>info</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}