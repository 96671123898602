/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import { AxiosRequestConfig } from "axios";
import instance from "./instance";

export default function get(url: string, config?: AxiosRequestConfig): Promise<any> {
    return instance
        .get(url, config)
        .then((data) => data.data);
}
