import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Summary.module.scss";
import Header from "../../../../../../../widgets/header/Header";
import Button from "../../../../../../../widgets/button/Button";
import getSurveyProps from "../../../../../../../../constants/getSurveyProps";
import values from "lodash/values";
import _isEmpty from "lodash/isEmpty";
import {
    startShareableLinkSurvey,
    startSurveyAudience,
    startSurveyFacebook, startFreeSurveyFacebook
} from "../../../../../../../../redux/actions/v1/surveys";
import Routes from "../../../../../../../../constants/routes";
import { withRouter } from "react-router-dom";
import { getAudienceListSize, getAudienceMembers } from "../../../../../../../../redux/actions/v1/audienceMembers";
import ListSummary from "./components/listSummary/ListSummary";
import NicheSummary from "./components/nicheSummary/NicheSummary";
import RepresentativeSummary from "./components/representativeSummary/RepresentativeSummary";
import Loader from "../../../../../../../widgets/loader/Loader";
import LinkSummary from "./components/linkSummary/LinkSummary";
import ShareableLinkSurveyDialog
    from "../../../../../../../widgets/dialogs/shareableLinkSurveyDialog/ShareableLinkSurveyDialog";
import CreditCardDialog from "../../../../../../../widgets/dialogs/creditCardDialog/CreditCardDialog";
import { Elements } from "@stripe/react-stripe-js";
import { getOrganisationCards } from "../../../../../../../../redux/actions/v1/organisations";
import { AccountType } from "../../../../../../../../types/model/StickybeakAccount";
import { QUESTION_LIMIT } from "../../../../../../../../constants/surveyBuilderConstants";
import { getEstimate, loadingPriceEstimate } from "../../../../../../../../redux/actions/estimate";
import isRepresentativeSurvey from "../../../../../../../../util/isRepresentativeSurvey";
import AnalyticsAPI from "../../../../../../../../util/Analytics";
import sendEventToActiveCampaign from "../../../../../../../../redux/actions/api/v1/eventTracking";
import { Roles } from "../../../../../../../../types/model/User";
import { isAdmin } from "../../../../../../../../constants/isAdmin";
import { loadStripe } from "@stripe/stripe-js";


class Summary extends Component {

    constructor (props) {
        super(props);

        let loadingPriceEstimate = false;
        if ( !isRepresentativeSurvey(props.survey) && !props.priceEstimate ) {
            loadingPriceEstimate = true;
        }

        this.state = {
            loading: false,
            loadingPriceEstimate: loadingPriceEstimate,
            loadingAudienceSize: false,
            loadingCards: false,
            selectedCard: ""
        };

        this.shareableLinkSurveyDialog = null;
    }


    componentDidMount () {
        if ( !!this.props.audienceId ) {
            this.props.dispatch(getAudienceMembers({
                orgId: this.props.orgId,
                audienceId: this.props.audienceId,
                onSuccess: () => {
                }
            }));

            this.setState({ loadingAudienceSize: true });
            this.props.dispatch(getAudienceListSize({
                orgId: this.props.orgId,
                audienceId: this.props.audienceId,
                onSuccess: () => {
                    this.setState({ loadingAudienceSize: false });

                }
            }))

        }

        if ( this.props.survey.type === "FACEBOOK" && !(this.props.survey.targeting || {}).representativePreset && !this.props.priceEstimate ) {
            // this.props.dispatch(getPriceEstimate({
            //     surveyId: this.props.surveyId,
            //     targeting: this.props.survey.targeting,
            //     params: {
            //         currency: "USD",
            //     },
            //     onSuccess: () => {
            //         this.setState({
            //             loadingPriceEstimate: false,
            //         });
            //     },
            // }));
            this.props.dispatch(getEstimate(this.props.orgId, this.props.surveyId));
        }

        if ( !this.props.cards ) {
            this.setState({
                loadingCards: true
            });
            this.props.dispatch(getOrganisationCards({
                accountId: this.props.orgId,
                onSuccess: () => this.setState({ loadingCards: false }),
                onError: () => this.setState({ loadingCards: false })
            }));
        }
        sendEventToActiveCampaign(this.props.user.email, "Summary_viewed", "Summary viewed")

    }

    handleAddCard = () => {
        sendEventToActiveCampaign(this.props.user.email, "Clicked_add_card", "Clicked add card")
        this.creditCardDialog.show();
    };

    onAddCardSuccess = () => {
        sendEventToActiveCampaign(this.props.user.email, "Card_added", "Added card at summary page")
        AnalyticsAPI.logSurveyEvent("Payment Method Success")
    }

    handleCardSelect = (cardId) => {
        this.setState({ selectedCard: cardId });
    };

    handleContinuePress = () => {
        if ( !this.props.orgHasPaymentMethod && this.props.survey.type === "FACEBOOK" ) {
            return this.creditCardDialog.show();
        }

        this.onStartSurveyPress();
    };

    handleFreeSurveyPress = () => {
        this.setState({
            loading: true
        });
        let surveyType = "FACEBOOK";
        if ( this.props.survey.targeting.representativePreset ) {
            surveyType = "NATIONAL_REPRESENTATIVE"
        }
        let budget = (this.props.priceEstimate || {}).estBudget;

        if ( budget < 20000 ) {
            budget = 20000;
        }

        this.props.dispatch(startFreeSurveyFacebook({
            accountId: this.props.orgId,
            surveyId: this.props.surveyId,
            payAsYouGo: {
                amount: budget,
                currency: "NZD",
                cardId: this.state.selectedCard || null,
            },
            onSuccess: () => {
                this.setState({
                    loading: false
                });
                this.props.history.replace(Routes.Surveys.root);
                sendEventToActiveCampaign(this.props.user.email, "Launched_survey", `${surveyType}`)
                sendEventToActiveCampaign(this.props.user.email, "Survey_pricing", `${budget}`)
                this.props.dispatch(loadingPriceEstimate(true));
            },
            onError: (e) => {
                this.setState({ error: e.message, loading: false });
            }
        }));
    };

    onStartSurveyPress = () => {
        switch ( this.props.survey.type ) {
            case "AUDIENCE": {
                this.setState({
                    loading: true
                });

                this.props.dispatch(startSurveyAudience({
                    accountId: this.props.orgId,
                    surveyId: this.props.surveyId,
                    audienceId: this.props.audienceId,
                    onSuccess: () => {
                        this.setState({
                            loading: false
                        });
                        this.props.history.replace(Routes.Surveys.root);
                    },
                    onError: (e) => {
                        this.setState({ error: e.message, loading: false });
                    }
                }));
                break;
            }
            case "FACEBOOK": {
                this.setState({
                    loading: true
                });
                let surveyType = "FACEBOOK";
                if ( this.props.survey.targeting.representativePreset ) {
                    surveyType = "NATIONAL_REPRESENTATIVE"
                }
                let budget = (this.props.priceEstimate || {}).estBudget;

                if ( budget < 20000 ) {
                    budget = 20000;
                }

                this.props.dispatch(startSurveyFacebook({
                    accountId: this.props.orgId,
                    surveyId: this.props.surveyId,
                    payAsYouGo: {
                        amount: budget,
                        currency: "USD",
                        cardId: this.state.selectedCard || null,
                    },
                    onSuccess: () => {
                        this.setState({
                            loading: false
                        });
                        AnalyticsAPI.surveyPaymentEvent(budget, this.props.tier.toUpperCase(), surveyType)
                        this.props.history.replace(Routes.Surveys.root);
                        sendEventToActiveCampaign(this.props.user.email, "Launched_survey", `${surveyType}`)
                        sendEventToActiveCampaign(this.props.user.email, "Survey_pricing", `${budget}`)

                    },
                    onError: (e) => {
                        this.setState({ error: e.message, loading: false });
                    }
                }));
                break;
            }
            case "LINK":
                this.setState({ loading: true });
                this.props.dispatch(startShareableLinkSurvey({
                    accountId: this.props.orgId,
                    surveyId: this.props.surveyId,
                    onSuccess: () => {
                        this.shareableLinkSurveyDialog.show();
                        this.setState({ loading: false });
                        AnalyticsAPI.surveyPaymentEvent(0, this.props.tier.toUpperCase(), this.props.survey.type)
                        sendEventToActiveCampaign(this.props.user.email, "Launched_survey", `${this.props.survey.type}`)


                    },
                    onError: (e) => {
                        this.setState({ loading: false, error: e.message });
                    }
                }));
                break;
            default: {
                return null;
            }
        }
    };

    renderSummaryItems () {

        const skipLogicQuestions = values(this.props.questions).filter((question) => {
            return question.choicesHaveSkipLogic || question.nextQuestion || question.terminatingQuestion;
        });
        let validatingQuestionCount = 0;

        skipLogicQuestions.filter((question) => {
            question.choices.map((choice) => {
                if ( choice.qualifyingChoice ) validatingQuestionCount++;
                return null;
            });
            return null;
        });

        if ( this.props.survey.type === "AUDIENCE" ) {
            return (
                <ListSummary organisation={this.props.organisation}
                             survey={this.props.survey}
                             questions={values(this.props.questions).length}
                             skipLogicQuestions={skipLogicQuestions.length}
                             validatingQuestionCount={validatingQuestionCount}
                             audienceType={"Email list"}
                             audience={this.props.audience}
                             validAudience={this.props.validAudience}
                             audienceSize={this.props.audienceSize}
                             loadingAudienceSize={this.state.loadingAudienceSize}
                             audienceMembers={this.props.audienceMembers}
                             subscription={this.props.subscription}/>
            );
        } else if ( this.props.survey.targeting.representativePreset ) {
            return (
                <RepresentativeSummary survey={this.props.survey}
                                       questions={values(this.props.questions).length}
                                       skipLogicQuestions={skipLogicQuestions.length}
                                       validatingQuestionCount={validatingQuestionCount}
                                       audienceType={"Find a nationally representative audience"}
                                       onAddCard={this.handleAddCard}
                                       selectedCard={this.state.selectedCard}
                                       onCardSelect={this.handleCardSelect}/>
            );
        } else if ( this.props.survey.type === "FACEBOOK" ) {
            return (
                <NicheSummary survey={this.props.survey}
                              surveyId={this.props.surveyId}
                              questions={values(this.props.questions).length}
                              skipLogicQuestions={skipLogicQuestions.length}
                              validatingQuestionCount={validatingQuestionCount}
                              audienceType={"Find a niche audience"}
                              onAddCard={this.handleAddCard}
                              selectedCard={this.state.selectedCard}
                              onCardSelect={this.handleCardSelect}/>
            );
        } else if ( this.props.survey.type === "LINK" ) {
            return (
                <LinkSummary survey={this.props.survey}
                             surveyId={this.props.surveyId}
                             organisation={this.props.organisation}
                             subscription={this.props.subscription}
                             questions={values(this.props.questions).length}
                             skipLogicQuestions={skipLogicQuestions.length}
                             validatingQuestionCount={validatingQuestionCount}
                             audienceType={"Shareable Link"}
                />
            );
        }
    }

    renderNoAudienceSelected = () => (
        <div className={styles.no_audience_selected}>
            <h3>You haven't selected your audience yet, please go back and choose an audience before starting your
                survey.</h3>
            <Button
                className={styles.audience_button}
                green
                onPress={() => this.props.history.push(`/surveys/${this.props.survey.id}/audience`)}>
                Select audience</Button>
        </div>
    );

    onShareableLinkSurveyDialogClose = () => {
        this.props.history.replace(Routes.Surveys.root);
        this.shareableLinkSurveyDialog.hide();
    };

    onCloseCCDialog = () => {
    }

    render () {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        let isEmptyAudience = false;
        let cardSelectionRequired = this.props.survey.type === "FACEBOOK" && this.props.isEnterprise && !this.state.selectedCard;
        let exceedingLimit = (this.props.survey.type === "FACEBOOK" && (this.props.activeQuestions.length > QUESTION_LIMIT));
        console.log("Super admin", !this.props.isSuperAdmin)
        let exceedingRespondentCap = (!this.props.isSuperAdmin && this.props.survey.type === "FACEBOOK" && this.props.survey.desiredSampleSize > 200 && !this.props.survey.targeting.representativePreset)
        console.log("survey", this.props.survey)
        const noAudience = (this.props.survey.type === "FACEBOOK" && !(this.props.survey || {}).targeting.representativePreset) &&
            (!this.props.priceEstimate || !this.props.priceEstimate.estCostPerRespondent);

        if ( this.props.survey.type === "AUDIENCE" && !this.state.loadingAudienceSize && this.props.audienceSize === 0 ) {
            isEmptyAudience = true;
        }

        let continueButtonText = "Confirm and start";
        if ( this.props.survey.type === "FACEBOOK" && !this.props.orgHasPaymentMethod ) {
            continueButtonText = "Add payment method";
        }
        return (
            <div className={styles.summary}>
                <Header text={"Summary"}/>
                {
                    this.props.survey.type
                        ? (
                            <div className={styles.summary_content}>
                                <div className={styles.summary_row}>
                                    {this.renderSummaryItems()}
                                    {this.state.loadingPriceEstimate && (
                                        <div className={styles.loading}>
                                            <Loader/>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.continue_button_container}>
                                    {this.props.isSuperAdmin && this.props.survey.type === "FACEBOOK" ?
                                        <Button className={styles.continue_button}
                                                red
                                                onPress={this.handleFreeSurveyPress}
                                                disabled={(this.state.loadingPriceEstimate ||
                                                    isEmptyAudience ||
                                                    this.state.loadingAudienceSize ||
                                                    exceedingLimit ||
                                                    noAudience ||
                                                    values(this.props.questions).length === 0)}
                                                loading={this.state.loading || this.state.loadingCards}>{"Start Uncharged Survey"}</Button> : null}
                                    <Button className={styles.continue_button}
                                            green
                                            onPress={this.handleContinuePress}
                                            disabled={(this.state.loadingPriceEstimate ||
                                                isEmptyAudience ||
                                                this.state.loadingAudienceSize ||
                                                cardSelectionRequired ||
                                                exceedingLimit ||
                                                exceedingRespondentCap ||
                                                noAudience ||
                                                this.props.isSaving ||
                                                values(this.props.questions).length === 0)}
                                            loading={this.state.loading || this.state.loadingCards}>{continueButtonText}</Button>
                                </div>
                                {this.state.error && <div className={styles.error}>{this.state.error}</div>}

                            </div>
                        )
                        : this.renderNoAudienceSelected()
                }
                <Elements stripe={stripePromise}>
                    <CreditCardDialog dialogRef={(ref) => this.creditCardDialog = ref}
                                      onClose={this.onCloseCCDialog}
                                      onCardSuccess={this.onAddCardSuccess}
                                      orgId={this.props.orgId}/>
                </Elements>
                <ShareableLinkSurveyDialog accountId={this.props.orgId}
                                           surveyId={this.props.surveyId}
                                           dialogRef={(ref) => this.shareableLinkSurveyDialog = ref}
                                           onClose={this.onShareableLinkSurveyDialogClose}
                />
            </div>
        );
    }
}

export default Summary = connect((state, props) => {
    const surveyProps = getSurveyProps(state, props);
    const audienceId = surveyProps.survey.audienceListId;
    const validAudience = !!((state.audiences || {})[surveyProps.orgId] || {})[audienceId];
    const orgId = state.settings.organisation;
    const selectedAccount = state.organisations.accounts[orgId];
    const audience = (((state.audiences || {})[orgId] || {})[audienceId] || {});
    const cards = state.organisations.cards[orgId];
    const tier = state.organisations.accounts[orgId].subscription.tier.name
    const user = state.user
    const saving = state.surveyFormState.isSaving.isSaving
    return {
        ...getSurveyProps(state, props),
        subscription: selectedAccount.subscription,
        audienceId: audienceId,
        audience: audience,
        validAudience: validAudience,
        audienceMembers: values(((state.audienceMembers || {})[audienceId] || {}).members || {}),
        audienceSize: state.audienceMembers.audienceSize,
        priceEstimate: state.estimate || {},
        tier: tier,
        orgHasPaymentMethod: !_isEmpty(cards),
        cards: state.organisations.cards[orgId],
        isEnterprise: selectedAccount.accountType === AccountType.ENTERPRISE,
        user,
        isSuperAdmin: isAdmin(user),
        isSaving: saving
    };
})(withRouter(Summary));
