import User from "../../types/model/User";
import UserAPI from "./api/user";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_USER = "user:SET_USER",
}

export const setUser = createAction<User | null>(Types.SET_USER);

export const Actions = {
    [Types.SET_USER]: setUser,
};
export type Actions = typeof Actions;

export const getUser = wrapper(UserAPI.getUser, {
    action: setUser,
});

export const updateUser = wrapper(UserAPI.updateUser, {
    action: setUser,
});

export const sendVerificationEmail = wrapper(UserAPI.sendVerificationEmail);

