import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./FindMyAudience.module.scss";
import classnames from "classnames";
import Header from "../../../../../../../../../widgets/header/Header";
import Locations from "./components/locations/Locations";
import { Field, FieldArray } from "redux-form";
import Age from "./components/age/Age";
import Genders from "./components/genders/Genders";
import Interests from "./components/interests/Interests";
import FormGroupheader from "./components/formGroupHeader/FormGroupheader";
import Button from "../../../../../../../../../widgets/button/Button";
import QuotaPicker from "./components/quotaPicker/QuotaPicker";
import CostCalculator from "./components/costCalculator/CostCalculator";

class FindMyAudience extends Component {

    state = {
        genderOptionAdded: false,
        ageOptionAdded: true,
    };

    componentDidMount() {
        const nicheContainer = document.getElementById("niche-audience-container");
        if (nicheContainer) {
            nicheContainer.scrollIntoView({
                behavior: "smooth"
            });
        }
        if (this.props.formValues) {
            if (!this.props.formValues.genders.includes("ALL")) {
                this.setState({ genderOptionAdded: true });
            }

            if (this.props.formValues.ageGroups.length === 1) {
                const ageGroup = this.props.formValues.ageGroups[0];
                if (ageGroup.minAge === 18 && ageGroup.maxAge === 65) {
                    this.setState({ ageOptionAdded: false });
                }
            }
        } else {
            this.setState({ ageOptionAdded: false });
        }
    }

    onAddGenderClick = () => {
        this.setState({ genderOptionAdded: true });
    };

    onAddAgeClick = () => {
        this.setState({ ageOptionAdded: true });
    };

    onRemoveAgeClick = () => {
        this.setState({ ageOptionAdded: false });
    };

    onRemoveGenderClick = () => {
        this.setState({ genderOptionAdded: false });
    };

    render() {
        const className = classnames(styles.find_my_audience, this.props.className);

        return (
            <div id={"niche-audience-container"} className={className}>
                <Header text={"2. Build your audience"} medium noBorder />

                <div className={styles.content}>
                    <div className={styles.content_inner}>
                        <div className={styles.content_left}>
                            <div className={styles.form_group}>
                                <FormGroupheader
                                    step={"01. "}
                                    heading={"Set location"}
                                    description={"To target your respondents by location, search below for a city, region or country and select an option from the dropdown."}
                                />
                                <div className={styles.form_group_single_field}>
                                    <FieldArray name={"geoLocations"} component={Locations} />
                                </div>
                            </div>
                            <div className={styles.form_group}>
                                <FormGroupheader
                                    step={"02. "}
                                    heading={"Set audience size"}
                                />
                                <div className={styles.form_group_single_field}>
                                    <QuotaPicker surveyId={this.props.surveyId} />
                                </div>
                            </div>
                            <div className={styles.form_group}>
                                <FormGroupheader
                                    strapline={"Optional add-ons"}
                                    step={"03. "}
                                    heading={"Target interest groups"}
                                    description={"To target your respondents by interest group, search below for an interest or behaviour (such as “cheese”, “environmentalism”, “football”)."}
                                />
                                <div className={styles.form_group_single_field}>
                                    <FieldArray name={"detailed"} component={Interests} />
                                </div>
                            </div>
                            {this.props.formValues.quotaType !== "SPEND" && (
                                <div className={styles.form_group}>
                                    <FormGroupheader
                                        strapline={"Optional add-ons"}
                                        step={"04. "}
                                        heading={"Comparison reporting"}
                                    />
                                    <div className={styles.form_group_double_field}>
                                        <div className={styles.form_group_selector}>
                                            <h3>Genders</h3>
                                            <p>I would like to know how men and/or women think differently about my
                                                survey.</p>
                                            {this.state.genderOptionAdded ?
                                                <Field name={"genders"} component={Genders}
                                                       onRemoveClick={this.onRemoveGenderClick} /> :
                                                <Button greyDark onPress={this.onAddGenderClick}>Add</Button>
                                            }
                                        </div>
                                        <div className={styles.form_group_selector}>
                                            <h3>Age groups / generations</h3>
                                            <p>I would like to know whether age groups or generations think differently
                                                about my survey.</p>
                                            {this.state.ageOptionAdded ?
                                                <FieldArray name={"ageGroups"} component={Age}
                                                            onRemoveClick={this.onRemoveAgeClick} /> :
                                                <Button greyDark onPress={this.onAddAgeClick}>Add</Button>}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.content_right}>
                            <CostCalculator surveyId={this.props.surveyId}
                                            onSubmitTargeting={this.props.onSubmitTargeting} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


FindMyAudience.propTypes = {
    className: PropTypes.string,
    orgId: PropTypes.number,
    surveyId: PropTypes.number,
    onSubmitTargeting: PropTypes.func
};

FindMyAudience.defaultProps = {
    className: null,
    orgId: null,
    surveyId: null,
    onSubmitTargeting: () => null
};

FindMyAudience = connect((state) => {
    const formValues = ((state.form.audience || {}).values || {});

    return {
        formValues: formValues,
    };
})(FindMyAudience);

export default FindMyAudience;
