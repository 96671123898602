/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import QuestionReport from "../../../types/model/QuestionReport";
import SurveyReport from "../../../types/model/SurveyReport";
import get from "./util/get";
import SurveyFiltering from "../../../types/model/SurveyFiltering";

function getSurveyReport(surveyId: number): Promise<SurveyReport> {
    return get(`/v1/reports/surveys/${surveyId}`);
}

function getQuestionReport(questionId: number, params: object): Promise<QuestionReport> {
    return get(`/v1/reports/questions/${questionId}`, {
        params
    });
}

function getSurveyFiltering(surveyId: number, params: object): Promise<SurveyFiltering> {
    return get(`/v1/reports/surveys/${surveyId}/filtering`, {
        params
    });
}

const ReportAPI = {
    getSurveyReport,
    getQuestionReport,
    getSurveyFiltering
};

export default ReportAPI;