import styles from "../../ReportSideBar.module.scss";
import React from "react"
export const CardDetails = (props) =>{
    const {cardDetails} = props;

    return(
        <div className={styles.report_sidebar_bottom}>
            <div className={styles.survey_detail_section}>
                <h3>
                    Audience overview
                </h3>

                <h4>
                    {`Talking to people living in${cardDetails.tags.locations.length > 1 ? " either" : ""}:`}
                </h4>
                <div className={styles.targeted}>
                    {cardDetails.surveyType === "National audience" ?
                        <span className={styles.target}>
                                                {cardDetails.representativeLocation}
                                            </span>
                        :
                        cardDetails.tags.locations.map((location, i) => (
                            <span className={styles.target} key={`targeting-location-${i}`}>
                                                {location}
                                            </span>
                        ))
                    }
                </div>
                {!!cardDetails.tags.interests.length &&
                    <>
                        <h4>
                            {`Talking to people interested in${cardDetails.tags.interests.length > 1 ? " either" : ""}:`}
                        </h4>

                        <div className={styles.targeted}>
                            {
                                cardDetails.tags.interests.map((interest, i) => (
                                    <span className={styles.target} key={`targeting-interest-${i}`}>
                                                {interest}
                                            </span>
                                ))
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}