import React, {Component, Fragment} from "react";
import connect from "react-redux/es/connect/connect";
import {change, formValueSelector, reduxForm} from "redux-form";
import getFormSyncErrors from "redux-form/es/getFormSyncErrors";
import getSurveyProps from "../../../../../../../../constants/getSurveyProps";
import Routes from "../../../../../../../../constants/routes";
import {
    createUpdateCustomSurveyEnding,
    getCustomSurveyEnding, getSurveyEnding, getSurveyLanguageOptions,
    updateSurveyQuestions
} from "../../../../../../../../redux/actions/v1/questions";
import {showError, showSuccess} from "../../../../../../../../redux/actions/v1/snackbars";
import {deleteSurvey} from "../../../../../../../../redux/actions/v1/surveys";
import Button from "../../../../../../../widgets/button/Button";
import CreateSurveyRowDialog
    from "../../../../../../../widgets/dialogs/createSurveyRowDialog/CreateSurveyRowDialog";
import DeleteSurveyDialog
    from "../../../../../../../widgets/dialogs/deleteSurveyDialog/DeleteSurveyDialog";
import SharePreviewDialog
    from "../../../../../../../widgets/dialogs/sharePreviewDialog/SharePreviewDialog";
import SurveySaveErrorDialog
    from "../../../../../../../widgets/dialogs/surveySaveErrorDialog/SurveySaveErrorDialog";
import Header from "../../../../../../../widgets/header/Header";
import Loader from "../../../../../../../widgets/loader/Loader";
import AddQuestion from "./components/addQuestion/AddQuestion";
import PreviewDialog from "./components/previewDialog/PreviewDialog";
import SurveyRows from "./components/surveyRows/SurveyRows";
import styles from "./SurveyForm.module.scss";
import {EMOJI_RATING, FREE_TEXT} from "../../../../../../../../constants/QuestionTypes";
import {QUESTION_LIMIT} from "../../../../../../../../constants/surveyBuilderConstants";
import {getEmojiRatingTemplates} from "../../../../../../../../redux/actions/v1/templates";
import CompletedSurveyRow from "../../../../completedSurveys/completedSurveyRow/CompletedSurveyRow";
import getSurveyScore from "../../../../../../../../util/getSurveyScore";
import {updateSurveyScore} from "../../../../../../../../redux/actions/surveys";
import sendEventToActiveCampaign from "../../../../../../../../redux/actions/api/v1/eventTracking";
import { setSurveyFormSaving } from "../../../../../../../../redux/actions/v1/surveyFormState";


class SurveyForm extends Component {

    state = {
        loading: false,
        deleting: false,
        showPreviewDialog: false,
        loadingEnding: false,
        languages: ["English"],
    };
    autoSaveTimer = null;
    justSwapped = false;

    setTimer = () => {
        this.autoSaveTimer = setTimeout(() => {
            this.autoSave();
        }, 1500);
    };

    clearTimer = () => {
        clearTimeout(this.autoSaveTimer);
    };

    componentDidMount() {
        this.setState({loadingEnding:true})
        this.getCustomSurveyEnding().then(()=>this.setState({loadingEnding: false}));
        this.getEmojiRatingTemplates();
        this.getSurveyEndingQuestions();
        this.getSurveyLanguageOptions();
    }

    componentDidUpdate(prevProps) {
        const currentQuestions = this.props.formQuestions;
        const prevQuestions = prevProps.formQuestions;
        if (!currentQuestions || !prevQuestions) {
            return;
        }
        let newChoiceAdded = false;
        let isEmptyChoice = false;
        let isEmptyContent = false;
        let isDifferentContent = false;
        //see if there is an empty question content or answer choice
        for (let i = 0; i < currentQuestions.length; i++) {
            let question = (currentQuestions[i] || {content: []});

            for (let j = 0; j < question.content.length; j++) {
                const content = question.content[j];

                if (!content.text) {
                    isEmptyContent = true;
                }
            }

            if (isEmptyContent) {
                break;
            }

            //check to stop out of range error before checking if new content
            if (!!prevQuestions[i] && prevQuestions.length && question.content !== prevQuestions[i].content) {
                isDifferentContent = true;
            }

            if (!question.choices) {
                if (question.type === FREE_TEXT) {
                    break;
                }

                isEmptyChoice = true;
                break;
            }

            for (let j = 0; j < question.choices.length; j++) {
                if (question.choices[j].value === "" || question.choices[j].value === "<br/>" || question.choices[j].value === "<div><br/></div>") {
                    isEmptyChoice = true;
                    break;
                }
            }

            if ((!!question.choices && question.choices.length !== ((prevQuestions[i] || {}).choices || []).length) && !!prevQuestions[i] && question.content[0].text === prevQuestions[i].content[0].text) {
                newChoiceAdded = true;
                // break
            }
        }

        //if shouldn't save stop save timeout
        if (isEmptyChoice || isEmptyContent) {
            // this.setState({ableToSave: false})
            // return this.clearTimer();
        }

        if (isDifferentContent && !isEmptyChoice && !isEmptyContent) {
            // this.clearTimer();
            // return this.setTimer();
        }

        //if able to save and change in question choices
        if (newChoiceAdded && !isEmptyChoice && !isEmptyContent) {
            // this.clearTimer();
            // return this.setTimer();
        }
        //change in number of questions
        if ((currentQuestions.length !== prevQuestions.length) && !isEmptyChoice && !isEmptyContent) {
            // this.clearTimer();
            // return this.setTimer();
        }
        //if focused stop save timeout
        if (this.props.isFocused) {
            // return this.clearTimer();
        }
        //if was focused and now unfocused save
        if ((this.props.isFocused !== prevProps.isFocused) && !this.props.isFocused && !isEmptyChoice && !isEmptyContent) {
            // this.clearTimer();
            // return this.setTimer();
        }
    };

    autoSave = () => {
        return this.props.handleSubmit(values => this.saveSurveyQuestions(values.questions))();
    };

    onNewOrderSubmit = () => {
        this.clearTimer();
        return this.setTimer();
    };

    onDeletePress = () => {
        this.deleteSurveyDialog.show();
    };

    onDeleteSurveySubmit = () => {
        this.setState({
            deleting: true
        });
        this.props.dispatch(deleteSurvey({
            orgId: this.props.orgId,
            surveyId: this.props.surveyId,
            onSuccess: () => {
                this.deleteSurveyDialog.hide();
                this.props.history.push(Routes.Surveys.root);
            },
            onError: this.onError,
        }));
    };

    getEmojiRatingTemplates = () => {
        this.props.dispatch(getEmojiRatingTemplates({}))
    }
    getSurveyEndingQuestions = () => {
        this.props.dispatch(getSurveyEnding({
            surveyId: this.props.surveyId
        }))
    }

    getCustomSurveyEnding = async () => {
        await this.props.dispatch(getCustomSurveyEnding({surveyId: this.props.surveyId}));
    }

    getSurveyLanguageOptions = () => {
        this.props.dispatch(getSurveyLanguageOptions({
            surveyId: this.props.surveyId,
            onSuccess: (languages) => {
                this.setState({
                    languages: languages
                });
            }
        }))
    }

    onSaveSurvey = async (questions) => {
        this.setState({
            loading: true
        });
        try {
            await this.saveSurveyQuestions(questions);
            sendEventToActiveCampaign(this.props.user.email, "Saved_survey", "Saved survey");
        } catch (e) {
            this.onError(e);
            this.setState({
                loading: false,
            });
            throw e;
        }
    };

    saveSurveyQuestions = async (questions) => {
        this.props.dispatch(setSurveyFormSaving({isSaving: true}))
        const normalizedQuestions = (questions || []).map((question) => {
            if (question.type === FREE_TEXT) {
                return {
                    ...question
                };
            }

            const choices = (question.choices || []).filter((choice) => !!choice && !!choice.value && choice.value !== "" && choice.value !== "<br/>" && choice.value !== "<div><br/></div>");
            return {
                ...question,
                choices: choices,
            };
        });
        const surveyScore = getSurveyScore(questions);
        if (!!this.props.customEndingForm) {
            await this.props.dispatch(createUpdateCustomSurveyEnding({
                surveyId: this.props.surveyId,
                customEnding: [{content: this.props.customEndingForm}]
            }));
        }
        await this.props.dispatch(updateSurveyScore(this.props.orgId, this.props.surveyId, surveyScore.overallScore));
        await this.props.dispatch(updateSurveyQuestions({
            surveyId: this.props.surveyId,
            questions: normalizedQuestions,
            onSuccess: () =>{
                this.setState({loading: false});
                this.props.dispatch(setSurveyFormSaving({isSaving: false}))
                return this.props.dispatch(showSuccess("Survey successfully saved"));
            }
        }));


    };

    onError = (err) => {
        this.props.dispatch(showError(err.message));
        this.setState({
            loading: false,
            deleting: false,
        });
    };

    onSavePress = () => {
        if (!!this.props.formErrors.questionCount) {
            return this.props.dispatch(showError(this.props.formErrors.questionCount));
        }

        if (!!Object.keys(this.props.formErrors).length) {

            const error = this.props.formErrors[Object.keys(this.props.formErrors)[0]];
            return this.props.dispatch(showError(typeof error === "string" ? error : "Your survey has some errors"));
        }
        else if(!!Object.keys(this.props.endingFormErrors).length){
            const error = this.props.endingFormErrors[Object.keys(this.props.endingFormErrors)[0]];
            return this.props.dispatch(showError(typeof error === "string"? error: "Your custom ending has some errors"))
        }
        return this.props.handleSubmit((values) => this.onSaveSurvey(values.questions))();
    };

    onPreviewPress = () => {
        if (!!Object.keys(this.props.formErrors).length) {
            const error = this.props.formErrors[Object.keys(this.props.formErrors)[0]];
            return this.props.dispatch(showError(typeof error === "string" ? error : "Your survey has some errors"));
        }
        return this.props.handleSubmit((values) => this.onPreviewSurvey(values))();
    };

    onSharePreviewPress = () => {
        if (!!Object.keys(this.props.formErrors).length) {
            const error = this.props.formErrors[Object.keys(this.props.formErrors)[0]];
            return this.props.dispatch(showError(typeof error === "string" ? error : "Your survey has some errors"));
        }
        return this.props.handleSubmit((values) => this.onSharePreview(values))();
    };

    onSharePreview = (values) => {
        this.sharePreviewDialog.show();
        return this.onSaveSurvey(values.questions);
    };

    onPreviewSurvey = async (values) => {
        if (this.props.survey.status === "LIVE") {
            window.open(`${process.env.REACT_APP_CHATBOT_URL}?preview_survey_id=${this.props.survey.id}`, "_blank");
        } else {
            this.setState({loadingQuestions: true});
            this.previewDialog.show();
            await this.onSaveSurvey(values.questions);
            this.setState({loadingQuestions: false});
        }
    };

    previewDialogPress = async () => {
        window.open(`${process.env.REACT_APP_CHATBOT_URL}?preview_survey_id=${this.props.survey.id}`, "_blank");
        this.previewDialog.hide();
        sendEventToActiveCampaign(this.props.user.email, "Preview_survey", "Previewed survey")
    };

    onAddFirstQuestionPress = () => {
        this.createQuestionDialog.show();
    };

    onAddFirstQuestionSubmit = (index, question) => {
        this.props.dispatch(change("editSurvey", "questions", [question]));
        sendEventToActiveCampaign(this.props.user.email, "Added_first_question", "Added first question to survey")
    };

    render() {
        const formQuestions = (this.props.formQuestions || {});
        const isSurveyLive = (this.props.survey || {}).status === "LIVE";
        return (
            <Fragment>
                {this.state.loading
                    ? <div className={styles.loader_wrapper}>
                        <Loader large/>
                    </div>
                    : <Fragment>
                        <Header text={this.props.surveyName || "Untitled"} noBorder
                                buttons={isSurveyLive ? [] : [
                                    {
                                        text: "Share Survey Preview",
                                        onPress: this.onSharePreviewPress,
                                        props: {
                                            plain: true,
                                            border: true
                                        }
                                    },
                                    {
                                        text: "Preview Survey",
                                        onPress: this.onPreviewPress,
                                        props: {
                                            plain: true,
                                            border: true
                                        }
                                    }
                                ]}/>

                        <div className={styles.survey_editor_spacer}/>
                        {
                            !!formQuestions.length
                                ? null
                                : <AddQuestion onPress={this.onAddFirstQuestionPress}/>
                        }
                        <SurveyRows onNewOrderSubmit={this.onNewOrderSubmit}
                                    name={"questions"}
                                    survey={this.props.survey}
                                    showSurveyEnding={true}
                                    loadingEnding={this.state.loadingEnding}
                                    languages = {this.state.languages}/>
                        <div className={styles.survey_footer}>
                            <Button plain onPress={this.onDeletePress}>Delete Survey</Button>
                        </div>
                    </Fragment>
                }

                <SurveySaveErrorDialog dialogRef={(ref) => this.surveySaveErrorDialog = ref}
                                       formErrors={this.props.formErrors}/>

                <DeleteSurveyDialog dialogRef={(ref) => this.deleteSurveyDialog = ref}
                                    onSubmit={this.onDeleteSurveySubmit}
                                    loading={this.state.deleting}/>

                <CreateSurveyRowDialog dialogRef={(ref) => this.createQuestionDialog = ref}
                                       questionIndex={this.props.index}
                                       onSubmit={this.onAddFirstQuestionSubmit}/>

                <PreviewDialog dialogRef={(ref) => this.previewDialog = ref}
                               onPress={this.previewDialogPress}
                               loading={this.state.loadingQuestions}/>

                <SharePreviewDialog dialogRef={ref => this.sharePreviewDialog = ref}
                                    user={this.props.user}
                                    surveyId={this.props.surveyId}
                                    accountId={this.props.orgId}/>

            </Fragment>

        );
    }
}

SurveyForm = reduxForm({
    enableReinitialize: true,
    form: "editSurvey",
    validate: (values) => {
        const errors = {};
        const questionsArrayErrors = [];
        if ((values.type === "FACEBOOK" && (values.questions || []).length > QUESTION_LIMIT)) {
            errors.questionCount = `This type of survey has a limit of ${QUESTION_LIMIT} questions.`;
        }
        (values.questions || []).forEach((question, questionIndex) => {
            const questionErrors = {};
            const contentArrayErrors = [];
            const choicesArrayErrors = [];

            ((question.content || []).forEach((content, contentIndex) => {
                if (content.type === "QUESTION_TEXT" && (!content || !content.text || content.text === "" || content.text === "<br/>" || content.text === "<div><br/></div>")) {
                    const contentErrors = {};
                    contentErrors.text = " ";
                    contentArrayErrors[contentIndex] = contentErrors;
                }
            }));

            const {type} = question;
            const isChoiceQuestion = type === "SINGLE_CHOICE" || type === "MULTI_CHOICE" || type === "LONG_FORM_SINGLE_CHOICE" || type === "LONG_FORM_MULTI_CHOICE" || type === "RANKING";
            if (isChoiceQuestion && (question.choices || []).length === 0) {
                questionErrors.choice = "Question requires choices, one of your questions does not have any.";
                questionsArrayErrors[questionIndex] = questionErrors;
            }
            if (question.type === "RANKING" && (question.choices || []).length > 6) {
                choicesArrayErrors[question.choices.length - 1] = {value: "You can only have 6 options with ranking questions."};
            }
            if (choicesArrayErrors.length) {
                questionErrors.choices = choicesArrayErrors;
                questionsArrayErrors[questionIndex] = questionErrors;
            }
            if (contentArrayErrors.length) {
                questionErrors.content = contentArrayErrors;
                questionsArrayErrors[questionIndex] = questionErrors;
            }
        });
        if (questionsArrayErrors.length) {
            errors.questions = questionsArrayErrors;
        }

        return errors;
    },
})(SurveyForm);

export default SurveyForm = connect((state, props) => {

    const surveyProps = getSurveyProps(state, props);
    const selector = formValueSelector("editSurvey");
    const formQuestions = selector(state, "questions");
    const surveyName = selector(state, "name");
    const surveyEndingSelector = formValueSelector("customEnding");
    const customEndingForm = surveyEndingSelector(state, "content");
    return {
        ...surveyProps,
        user: state.user,
        formQuestions: formQuestions,
        initialValues: {
            name: (surveyProps.survey || {}).name,
            type: surveyProps.survey.type,
            questions: surveyProps.activeQuestions || [],
        },
        isFocused: state.surveyFormState.isFocused,
        surveyName: surveyName,
        formErrors: getFormSyncErrors("editSurvey")(state),
        customEndingForm: customEndingForm,
        endingFormErrors: getFormSyncErrors("customEnding")(state),

    };
}, null, null, {forwardRef: true})(SurveyForm);



