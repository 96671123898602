export const creativeContentTypes = [
    {
        label: "OLDIE_POLLING_STATION",
        value: "OLDIE_POLLING_STATION",
        creativeUrl: "https://i.imgur.com/HgYRf15.mp4"
    },
    {
        label: "RUNNING_REUBEN",
        value: "RUNNING_REUBEN",
        creativeUrl: "https://i.imgur.com/Oghpi80.gif",

    },
    {
        label: "PHONE-ON-TRAIN",
        value: "ONE_MINUTE_POLL",
        creativeUrl: "https://i.imgur.com/lnlXQ1l.mp4"
    },
    {
        label: "POOL",
        value: "POOL",
        creativeUrl: "https://i.imgur.com/dUBHWgu.png"
    },
    {
        label: "POOL_OXFAM",
        value: "POOL_OXFAM",
        creativeUrl: "https://i.imgur.com/zmIIk93.png"
    },
    {
        label: "SLOT_MACHINE",
        value: "SLOT_MACHINE",
        creativeUrl: "https://i.imgur.com/aipv5sN.mp4"
    },
    {
        label: "YELLOW",
        value: "YELLOW",
        creativeUrl: "https://i.imgur.com/rLQtbVz.png"
    },
    {
        label: "YELLOW_OXFAM",
        value: "YELLOW_OXFAM",
        creativeUrl: "https://i.imgur.com/p8sRrDX.png"
    }
]