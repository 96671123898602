import React from "react";

export default function UploadArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54.008" height="64.487" viewBox="0 0 54.008 64.487">
            <g id="upload_arrow" transform="translate(-492.496 -289.387)">
                <g id="Group_17220" data-name="Group 17220" transform="translate(0 -8)">
                    <path id="Polygon_1" data-name="Polygon 1" d="M27,0l27,32.092H0Z" transform="translate(492.496 297.387)" fill="#dfe2e3"/>
                    <rect id="Rectangle_3524" data-name="Rectangle 3524" width="20.351" height="32.875" transform="translate(509.716 317.738)" fill="#dfe2e3"/>
                </g>
                <rect id="Rectangle_3565" data-name="Rectangle 3565" width="52.351" height="6.875" transform="translate(493.716 347)" fill="#dfe2e3"/>
            </g>
        </svg>
    )
}