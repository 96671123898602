import * as Types from "../../actions/v1/surveys";

const initialState = {};

export default function surveyTemplates(state = initialState, action = {type: null, templates: {}}) {
	switch (action.type) {

		case Types.SET_SURVEY_TEMPLATES: {
			const templates = {...state};

			(action.templates || []).map((template) => {
				templates[template.id] = template;
				return null;
			});

			return templates
		}

		default: {
			return state
		}
	}
}