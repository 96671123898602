import CustomEndingBlock from "../customEndingBlock/CustomEndingBlock";
import EndingQuestionBlock from "../endingQuestionBlock/EndingQuestionBlock";
import React from "react";
import styles from "../surveyRows/SurveyRows.module.scss";
import RenderCustomEndingButtons from "../renderCustomEndingButtons/RenderCustomEndingButtons";


export default class SurveyEnding extends React.Component {


    render(){
        if (this.props.name.includes("customEnding")) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                <CustomEndingBlock key={this.props.name}
                                   name={this.props.name}
                                   index={this.props.rowIndex}
                                   formName={this.props.form}
                                   survey={this.props.survey}
                                   editable={this.props.editable}
                                   deletingContentRow={this.props.deletingContentRow}
                                   afterDelete={this.props.afterDelete}
                                   upgradeRequired={this.props.upgradeRequired}
                />
                </div>
            )
        } else if (this.props.name.includes("surveyEnding")) {
            return(
                <div style={{opacity: 0.8}}>
                <EndingQuestionBlock key={this.props.name} index={this.props.rowIndex}
                                        endingQuestion={this.props.endingQuestions[this.props.rowIndex]}
                                        survey={this.props.survey} name={this.props.name}
                                        hideFirstLine={this.props.hideFirstLine}
            />
                </div>
            )
        }
    }
}
