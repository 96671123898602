import React, { Component } from "react";
import { connect } from "react-redux";
import values from "lodash/values";
import { getOrganisationDraftSurveys, getOrganisationLiveSurveys } from "../../../../../redux/actions/v1/surveys";
import Header from "../../../../widgets/header/Header";
import Content from "../../../../widgets/content/Content";
import DraftSurveyContent from "./draftSurveySideContent/DraftSurveyContent";
import Routes from "../../../../../constants/routes";
import styles from "./SurveyDashboard.module.scss";
import ActiveSurveysContent from "./activeSurveysContent/ActiveSurveysContent";
import NoOrganisationDialog from "../../../../widgets/dialogs/noOrganisationDialog/NoOrganisationDialog";
import CreateUpdateSurveyDialog from "../../../../widgets/dialogs/createUpdateSurveyDialog/CreateUpdateSurveyDialog";
import { getSaasTiers } from "../../../../../redux/actions/v1/saas";
import sendEventToActiveCampaign from "../../../../../redux/actions/api/v1/eventTracking";
import Pagination from "./activeSurveysContent/Pagination/Pagination";
import SearchSurvey from "../../../../widgets/searchSurvey/SearchSurvey";
import { debounce } from "lodash";
import Loader from "../../../../widgets/loader/Loader";

class DraftLiveSurveys extends Component {

    constructor(props) {
        super(props);

        this.headerButtons = [
            {
                text: "Completed Surveys",
                onPress: () => this.props.history.push(Routes.Surveys.Completed),
                props: {
                    plain: true,
                    border: true
                }
            },
            {
                text: "Create New",
                onPress: this.onCreateNewPress,
                props: {
                    greyDark: true
                }
            }];

        this.state = {
            loadingLiveSurveys: false,
            loadingDraftSurveys: false,
            cursorList: [],
            search: ""
        };

        this.onSearchChangeDebounce = debounce(this.onSearchSurveys, 250, { trailing: true, leading: false });
    }

    componentDidMount() {
        this.onGetSurveys();
        this.props.dispatch(getSaasTiers());
    }

    getNextSurveys = () => {
        const cursor = this.props.activeSurveys?.nextCursor;
        this.onGetSurveys(cursor);
        const cursorList = this.state.cursorList;
        this.setState({ cursorList: [...cursorList, cursor] }
            , function () {
                console.log(this.state.cursorList);
            });
    };

    getPrevSurveys = () => {
        const cursorList = this.state.cursorList;
        if (cursorList.length > 0) {
            const lastIndex = this.state.cursorList.length - 1;
            this.setState({ cursorList: cursorList.filter((item, index) => index !== lastIndex) }
                , function () {
                    console.log(this.state.cursorList);
                });
            const cursor = this.state.cursorList[this.state.cursorList.length - 2];
            this.onGetSurveys(cursor);
        }
    };

    onGetSurveys = (cursor) => {

        if (this.state.loading) {
            return;
        }

        const orgId = this.props.orgId;
        this.setState({
            loadingLiveSurveys: true,
            loadingDraftSurveys: true,
        });

        this.props.dispatch(getOrganisationLiveSurveys({
            orgId: orgId,
            cursor: cursor,
            limit: 9,
            onSuccess: () => {
                this.setState({
                    loadingLiveSurveys: false,
                });
            }
        }));

        this.props.dispatch(getOrganisationDraftSurveys({
            orgId: orgId,
            onSuccess: () => {
                this.setState({
                    loadingDraftSurveys: false,
                });
            }
        }));
    };

    onCompletedSurveysPress = () => {
        this.props.history.push(Routes.Surveys.Completed);
    };

    onCreateNewPress = () => {
        if (!this.props.orgId) {
            return this.noOrgDialog.show();
        }
        sendEventToActiveCampaign(this.props.user.email, "Clicked_create_new_survey", "Clicked create new survey");
        this.props.history.push(Routes.Surveys.Create.SelectTemplate);
        // !!this.createNewSurveyDialog && this.createNewSurveyDialog.show()
    };

    renderSideContent = () => {
        const sortedDraftsArray = this.props.draftSurveys?.sort((a, b) => b.id - a.id);

        return (
            <DraftSurveyContent
                draftSurveys={sortedDraftsArray}
                orgId={this.props.orgId}
                organisations={values(this.props.organisations)}
                loading={this.state.loadingDraftSurveys}
                org={this.props.organisation.name}
            />
        );
    };

    renderActiveSurveys = () => {
        return (
            <ActiveSurveysContent activeSurveys={this.props.activeSurveys?.items || []}
                                  org={this.props.organisation}
                                  loading={this.state.loadingLiveSurveys} />
        );
    };

    onSearchChange = (text) => {
        this.setState({ search: text });
        this.onSearchChangeDebounce(text);
    };

    onClearSearchPress = () => {
        this.setState({ search: "" });
        this.onSearchChangeDebounce("");
    };

    onSearchSurveys = (text) => {
        this.setState({
            loadingLiveSurveys: true
        });

        this.props.dispatch(getOrganisationLiveSurveys({
            orgId: this.props.orgId,
            cursor: "",
            limit: 9,
            search: text,
            onSuccess: () => {
                this.setState({
                    loadingLiveSurveys: false
                });
            }
        }));
    };

    render() {
        return (
            <Content
                headerText={"Surveys"}
                headerButtons={this.headerButtons}
                sideContent={this.renderSideContent}
                sideContentTitle={"Drafts"}>

                <Header
                    actions={<SearchSurvey value={this.state.search}
                                           onChange={this.onSearchChange}
                                           title={"Active Surveys"}
                                           onClearPress={this.onClearSearchPress} />}
                    noBorder />

                {this.state.loadingLiveSurveys ? (
                    <Loader />
                ) : (
                    <>
                        <div className={styles.live_surveys}>
                            {this.renderActiveSurveys()}
                        </div>

                        {(this.props.activeSurveys?.nextCursor || this.state.cursorList.length > 0) &&
                            <Pagination data={this.props.activeSurveys}
                                        getNext={this.getNextSurveys}
                                        getPrev={this.getPrevSurveys}
                                        items={[null].concat(this.state.cursorList)}
                                        changePage={this.onGetSurveys}
                            />}
                    </>
                )}
                <CreateUpdateSurveyDialog dialogRef={(ref) => this.createNewSurveyDialog = ref} />
                <NoOrganisationDialog dialogRef={(ref) => this.noOrgDialog = ref} />
            </Content>

        );
    }
}

export default DraftLiveSurveys = connect((state) => {

    const organisations = (state.organisations.accounts || {});

    const orgId = state.settings.organisation;

    const surveys = state.surveys;
    const surveysList = values(surveys);

    const draftSurveys = state.surveys.draftSurveys[orgId];
    // surveysList.filter((survey) => {
    // return survey.status === "DRAFT"
    // });

    const activeSurveys = state.surveys.activeSurveys[orgId];
    const user = state.user;
    return {
        organisations: organisations,
        orgId: orgId,
        organisation: (organisations[orgId] || {}),
        user: user,
        surveys: surveys,
        surveysList: surveysList,

        activeSurveys: activeSurveys,
        draftSurveys: draftSurveys,
    };
})(DraftLiveSurveys);
