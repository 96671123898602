import React, { PureComponent } from "react";
import styles from "./ComparisonFilter.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "@mui/material/Button";

export default class ComparisonButton extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        onPress: PropTypes.func,
        style: PropTypes.object,
        selected: PropTypes.bool,
        text: PropTypes.string,
        number: PropTypes.number,
        percentage: PropTypes.number,
        fill: PropTypes.number,
        loading: PropTypes.bool,
        showCompletedOnly: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        wrapperClassName: null,
        onPress: () => null,
        style: {},
        selected: false,
        text: "",
        number: 0,
        percentage: 0,
        fill: 0,
        loading: false,
        showCompletedOnly: true,
    };

    render() {

        const wrapperClassName = classnames(styles.comparison_filter_demographic_button, this.props.wrapperClassName);

        let className = classnames(styles.comparison_filter_demographic, {
            [styles.answer_selected]: !!this.props.selected
        }, this.props.className, this.props.style);
        return (
            <div className={wrapperClassName}>
                <h4>{this.props.demographic }</h4>
                <Button className={className} disabled={this.props.loading || !this.props.showCompletedOnly} onClick={this.props.onPress}>
                    <div className={styles.fill_container}>
                        {
                        <div className={styles.fill} style={{width: `${this.props.fill}%`}}/>
                        }
                    </div>
                    <div className={styles.demographic_content}>
                        <span className={styles.demographic_text} dangerouslySetInnerHTML={{ __html: this.props.text }}/>
                            <span className={styles.demographic_value}>({this.props.number}) {this.props.percentage}%</span>
                    </div>
                </Button>
            </div>
        );
    }
}
