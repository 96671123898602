import User from "../../../types/model/User";
import get from "./util/get";
import put from "./util/put";
import UpdateUserDTO from "../../../types/dto/UpdateUserDTO";
import post from "./util/post";

function getUser(): Promise<User> {
    return get("/v1/users/me");
}

function updateUser(data: UpdateUserDTO): Promise<User> {
    return put("/v1/users/me", data);
}

function sendVerificationEmail(token: string) {
    return post("/v1/users/me/verify", null, { token });
}

function subscribeToNewsletter(email:any){
    const contactEmail = email
    const data ={
        lists: ["56ad1327-b682-4dcb-b03f-88fffe1769b2"],
        contact:{
            email:contactEmail
        }
    }
    return post("/v1/sendgrid-contacts", data)
}

const UserAPI = {
    getUser,
    updateUser,
    sendVerificationEmail,
    subscribeToNewsletter
};

export default UserAPI;
