import React, {PureComponent} from "react";
import Dialog from "../../dialog/Dialog";
import PropTypes from "prop-types";
import styles from "./SurveySaveErrorDialog.module.scss"

export default class SurveySaveErrorDialog extends PureComponent {

    static propTypes = {
        dialogRef: PropTypes.func,
        formErrors: PropTypes.object,
    };

    static defaultProps = {
        dialogRef: () => null,
        formErrors: {},
    };

    hide = () => {
        this.dialog.hide();
    };

    onSubmit = () => {
        this.props.onSubmit();
    };

    render() {

        const actions = [
            {
                text: "Okay",
                onPress: this.hide,
                props: {
                    blue: true
                }
            }
        ];

        let emptyContent = "";
        let emptyChoice = "";

        (this.props.formErrors.questions || []).forEach((error) => {
            if (error.content) {
                emptyContent = "there are some incomplete questions in your survey"
            }
            if (error.choices) {
                emptyChoice = "there are some incomplete answers in your survey"
            }
        });

        return (
            <Dialog title={"Save Error"}
                    actions={actions}
                    ref={(ref) => {
                        this.dialog = ref;
                        this.props.dialogRef(ref)
                    }}>
                <p className={styles.text}>
                    {
                        emptyContent && emptyChoice
                            ? `We can't save your survey yet, ${emptyContent} and ${emptyChoice}`
                            : `We can't save your survey yet, ${emptyContent}${emptyChoice}.`
                    }
                </p>
            </Dialog>
        )
    }
}