import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CheckBox.module.scss";
import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default class CheckBox extends Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.any,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        label: "",
        checked: false,
        onChange: () => null,
    };

    onChange = (e) => {
        const checked = e.target.checked;
        this.props.onChange(checked);

        if (!!this.props.input) {
            this.props.input.onChange(checked);
        }
    };

    render() {
        const className = classnames(styles.check_box, this.props.className);

        let checked = this.props.checked;
        if (!!this.props.input) {
            checked = this.props.input.value;
        }

        checked = Boolean(checked);

        return (
            <FormControlLabel
                className={className}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={this.onChange}
                        value={(checked || "").toString()}
                        classes={{
                            root: styles.root,
                            checked: styles.checked,
                        }}
                    />
                }
                label={this.props.label}
            />
        );
    }
}
