
import * as Type from "../../actions/v1/organisations";

const initialState = {
    accounts: {},
    users: {},
    cards: {},
};

export default function accounts(state = initialState, action = {type: null, account: {}, accounts: [], user: {}, users: [], card: {}, cards: [],}) {
    switch (action.type) {
        case Type.SET_SUBSCRIPTION_DIFFERENCE:
            return {
                ...state,
                difference: action.difference
            };
        case Type.SET_ORGANISATION: {
            const account = action.account;
            if (account.hasOwnProperty("type")) {
                account['accountType'] = account['type'];
                delete account['type']
            }
            if (account.hasOwnProperty("saaSSubscription")) {
                account['subscription'] = account['saaSSubscription'];
                delete account['saaSSubscription']
            }

            if (!account) {
                return state;
            }
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    [account.id]: account
                },
            };
        }

        case Type.SET_ORGANISATIONS: {

            const accounts = action.accounts;
            const newState = {...state.accounts};

            accounts.map((account) => {
                newState[account.id] = account;
                return null;
            });

            return {
                ...state,
                accounts: newState
            };
        }

        case Type.SET_ORGANISATION_TRANSACTIONS: {
            const account = action.account;
            const transactions = action.transactions;
            const accounts = {...state.accounts};
            const newAccount = {
                ...state.accounts[account],
                transactions: transactions
            }
            return {
                ...state,
                accounts: {
                    ...accounts,
                    [account]: newAccount
                }
            }
        }

        case Type.REMOVE_ORGANISATION: {

            const orgId = action.orgId;
            const accounts = {...state.accounts};
            const users = {...state.users};
            const cards = {...state.cards};

            delete accounts[orgId];
            delete users[orgId];
            delete cards[orgId];

            return {
                accounts: accounts,
                users: users,
                cards: cards,
            };
        }

        case Type.SET_ORGANISATION_USER: {
            const user = action.user;
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.orgId]: {
                        ...(state.users[action.orgId] || {}),
                        [user.id]: user,
                    },
                },
            };
        }

        case Type.SET_ORGANISATION_USERS: {

            const users = action.users;
            const newState = {...state.users};

            users.map((user) => {
                newState[action.orgId] = {
                    ...(newState[action.orgId] || {}),
                    [user.id]: user,
                };
                return null;
            });

            return {
                ...state,
                users: newState
            };
        }

        case Type.REMOVE_ORGANISATION_USER: {

            const orgId = action.orgId;
            const userId = action.userId;

            const org = (state.users[orgId] || {});
            delete org[userId];

            return {
                ...state,
                users: {
                    ...state.users,
                    [orgId]: org,
                },
            };
        }

        case Type.SET_ORGANISATION_CARD: {
            const card = action.card;

            return {
                ...state,
                cards: {
                    ...state.cards,
                    [action.orgId]: {
                        ...(state.cards[action.orgId] || {}),
                        [card.id]: card,
                    },
                },
            };
        }

        case Type.SET_ORGANISATION_CARDS: {

            const cards = action.cards;
            const newState = {...state.cards};

            cards.map((card) => {
                newState[action.orgId] = {
                    ...(newState[action.orgId] || {}),
                    [card.id]: card,
                };
                return null;
            });

            return {
                ...state,
                cards: newState
            };
        }

        case Type.REMOVE_ORGANISATION_CARD: {

            const orgId = action.orgId;
            const stripeCardId = action.stripeCardId;
            const orgCards = {...(state.cards[orgId] || {})};
            delete orgCards[stripeCardId];

            return {
                ...state,
                cards: {
                    ...state.cards,
                    [orgId]: orgCards
                }
            };
        }

        default: {
            return state;
        }
    }
}