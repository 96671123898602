import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./Dialog.module.scss";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import Loader from "../loader/Loader";
import Button from "../button/Button";
import MatDialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default class Dialog extends Component {

    static propTypes = {
        rootClassName: PropTypes.string,
        containerClassName: PropTypes.string,
        className: PropTypes.string,
        titleClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        actionsClassName: PropTypes.string,

        isControlled: PropTypes.bool,
        shown: PropTypes.bool,
        onShow: PropTypes.func,
        onClose: PropTypes.func,
        actionToHide: PropTypes.func,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,

        error: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        actions: PropTypes.array,

        width: PropTypes.oneOf(["xs", "sm", "md", "lg", false]),
        fullWidth: PropTypes.bool,
        disableDismiss: PropTypes.bool,
        custom: PropTypes.bool,
    };

    static defaultProps = {
        rootClassName: null,
        containerClassName: null,
        className: null,
        titleClassName: null,
        contentClassName: null,
        actionsClassName: null,

        isControlled: false,
        shown: false,
        onShow: () => null,
        onClose: () => null,
        actionToHide: () => null,
        onSubmit: null,
        loading: false,

        error: null,
        title: null,
        text: null,
        actions: [],

        width: "xs",
        fullWidth: true,
        disableDismiss: false,
        custom: false,
    };

    id = uuid();

    state = {
        show: false,
        params: false,
    };

    show = (params) => {
        this.setState({
            show: true,
            params: params,
        });
        this.onShow();
    };

    getParams = () => {
        return this.state.params;
    };

    hide = () => {
        this.setState({
            show: false,
            params: null,
        }, this.onClose);
    };

    actionToHide = () => {
        console.log("Action to hide");
        if (!this.props.disableDismiss) {
            if (!this.props.loading) {
                this.props.actionToHide();
                this.hide();
            }
        }
    };

    onShow = () => {
        this.props.onShow();
    };

    onClose = () => {
        console.log("dialog on close");
        this.props.onClose();
        // setTimeout(this.props.onClose, 200)
    };

    renderActionButton = (config, i) => {

        if (!config) {
            return null;
        }

        return (
            <Button key={i} onPress={config.onPress} {...config.props}>{config.text}</Button>
        );
    };

    renderChildren = () => {
        let children = this.props.children;
        if (typeof children === "function") {
            children = children(this.state.params);
        }

        return children;
    };

    render() {
        const rootClassName = classnames(styles.root, this.props.rootClassName);
        const containerClassName = classnames(styles.container, this.props.containerClassName);
        const className = classnames(styles.dialog, this.props.className);
        const titleClassName = classnames(styles.dialog_title, this.props.titleClassName);
        const contentClassName = classnames(styles.dialog_content, this.props.contentClassName);
        const actionsClassName = classnames(styles.dialog_actions, this.props.actionsClassName);

        const Wrapper = !!this.props.onSubmit ? `form` : Fragment;
        const wrapperProps = !!this.props.onSubmit ? { onSubmit: this.props.onSubmit } : {};

        let actions = this.props.actions;
        if (typeof actions === "function") {
            actions = actions(this.state.params);
        }

        return (
            <MatDialog
                open={!!this.props.isControlled ? !!this.props.shown : !!this.state.show}
                onClose={this.actionToHide}
                onBackdropClick={this.actionToHide}
                onEscapeKeyDown={this.actionToHide}
                classes={{
                    container: containerClassName,
                    paper: className,
                }}
                className={rootClassName}
                aria-labelledby={this.id}>
                {!!this.props.custom ? this.renderChildren() :
                    <Wrapper {...wrapperProps}>
                        {!!this.props.title ? <DialogTitle id={this.id}
                                                           className={titleClassName}>{this.props.title}</DialogTitle> : null}
                        <DialogContent
                            className={classnames(contentClassName, { [styles.hide_padding]: this.props.loading && this.props.showGif })}>
                            <Collapse in={!this.props.loading}>
                                <Fragment>
                                    {!!this.props.text ? <DialogContentText
                                        className={styles.dialog_content_text}>{this.props.text}</DialogContentText> : null}
                                    {this.renderChildren()}
                                    {!!this.props.error ? <DialogContentText
                                        className={styles.dialog_content_text_error}>{this.props.error}</DialogContentText> : null}
                                </Fragment>
                            </Collapse>

                            <Collapse in={this.props.loading}>
                                <Loader />
                            </Collapse>
                        </DialogContent>

                        {!!(this.props.actions || []).length ?
                            <DialogActions className={actionsClassName}>
                                {actions.map(this.renderActionButton)}
                            </DialogActions> : null}
                    </Wrapper>}
            </MatDialog>
        );
    }
}
