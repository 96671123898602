/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 13/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactLoading from 'react-loading';
import styles from "./Loader.module.scss";
import omit from "lodash/omit";

export default function Loader(props) {
    const className = classnames(styles.loader, props.className);

    const newProps = omit({...props}, "large");

    if (props.large) {
        newProps.width = 65;
        newProps.height = 65;
    }

    return (
        <ReactLoading delay={0} {...newProps} className={className}/>
    );
}

Loader.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    large: PropTypes.bool,
};

Loader.defaultProps = {
    className: null,
    type: "bubbles",
    height: 36,
    width: 36,
    color: "#9BA5A7",
    large: false,
};
