import React, {Component} from "react";
import classnames from "classnames";
import styles from "./PricingCard.module.scss";
import Button from "../button/Button";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import greenTick from "../../../resources/png/tick_in_a_circle@2x.png";

class PricingCard extends Component {

    static propTypes = {
        className: PropTypes.string,
        buttonClassName: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        body: PropTypes.any,
        content: PropTypes.any,

        style: PropTypes.object,
        onPress: PropTypes.func,
        buttonProps: PropTypes.object,
        buttonText: PropTypes.any,
        buttonImage: PropTypes.any,
    };

    static defaultProps = {
        className: null,
        buttonClassName: null,
        title: null,
        subtitle: null,
        body: null,
        content: null,

        style: {},
        onPress: () => null,
        buttonProps: {},
        buttonText: null,
        buttonImage: null,
    };

    render() {
        const extraStyles = [
            {[styles.is_selected_container]: this.props.isSelected},
            {[styles.is_upgrading_container]: this.props.isUpgrading},
        ];

        const buttonProps = {};

        let isUpgrade = false;
        let hideButton = this.props.isSelected;
        let buttonText = this.props.buttonText ? this.props.buttonText : "Change plan";
        let status = "Current plan";

        if (this.props.isUpgrading) {
            isUpgrade = this.props.upgradeTier.maxUsers > this.props.currentTier.maxUsers;

            if (isUpgrade) {
                buttonText = "Upgrade now";
                status = `Upgrading plan on ${this.props.endTerm}`;
                buttonProps.green = true;
            } else {
                buttonText = "";
                hideButton = true;
                status = `Downgrading plan on ${this.props.endTerm}`;
            }
        }

        if (this.props.isSelected && !!this.props.upgradeTier) {
            isUpgrade = this.props.upgradeTier.maxUsers > this.props.currentTier.maxUsers;
            hideButton = false;
            buttonText = "Keep plan";
            if (!isUpgrade) {
                buttonProps.green = true;
            }
        }

        return (
            <div className={classnames(styles.subscriptions_option, this.props.className, extraStyles)}>
                <div className={styles.content}>
                    {!!this.props.title ? <h1 id={this.id}>{this.props.title}</h1> : null}
                    {!!this.props.body ? this.props.body : null}
                    <p>{!!this.props.subtitle ? this.props.subtitle : null}</p>
                    {(this.props.isSelected || this.props.isUpgrading) && (
                        <div className={styles.is_selected}>
                            <span>
                                <img src={greenTick} alt={"green_tick"}/>
                            </span>
                            <p className={styles.is_selected_text}>
                                {status}
                            </p>
                        </div>)}
                </div>
                <div className={styles.button_wrapper}>
                    {buttonText && !hideButton &&
                    <Button className={this.props.buttonClassName}
                            onPress={this.props.onPress} {...buttonProps}>
                        {buttonText}
                    </Button>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(PricingCard);