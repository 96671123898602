import React from "react";
import QuestionCard from "./components/questionCard/QuestionCard";
import {connect} from "react-redux"
import values from "lodash/values";
import styles from "./Report.module.scss";
import Header from "../../../../../../widgets/header/Header";
import UpgradeSaasPlan from "./components/upgradeSaasPlan/UpgradeSaasPlan";

class ReportPdf extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        };
    }
    renderQuestionCard = (question) => {
        return (
            <QuestionCard key={question.id}
                          survey={this.props.survey}
                          loading={false}
                          questionFilter={{}}
                          question={question}
                          onChoiceSelect={this.onChoiceSelect} />
        );
    };
    render() {
        return (
            <div className={styles.main}>
                <div className={styles.content}>
                    <div className={styles.question_cards_wrapper} id={"report"}>
                        <div className={styles.question_cards}>
                            {this.props.activeQuestions.map(this.renderQuestionCard)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ReportPdf = connect((state, props)=>{
    const orgId = state.settings.organisation;
    const surveyId = props.match.params.surveyId;
    const survey = state.surveys[surveyId];
    const questions = state.surveyQuestions[surveyId];
    const activeQuestions = values(questions)
        .filter((question) => {
            return question.status === "ACTIVE";
        });
    return{
        user: state.user,
        orgId: orgId,
        organisation: state.organisations.accounts[orgId],
        surveyId: surveyId,
        survey: survey,
        questions: questions,
        activeQuestions: activeQuestions
    }
})(ReportPdf)